// import FindInvoiceModal from 'general/components/Modal/FindInvoiceModal'
import { useAppSelector } from 'hooks/useRedux'
import ImportTicketStepBar from '../ImportTicketStepBar'
import ImportTicketStepOne from '../ImportTicketStepOne'
import ImportTicketStepTwo from '../ImportTicketStepTwo'
import ImportTicketStepThree from '../ImportTicketStepThree'

function ImportTicketCard(props) {
  const { handleChangeFile, sheetNamesOption, ticketSerials } = props

  const { currentStep } = useAppSelector((state) => state.importTicket)

  return (
    <div className="card card-custom w-100 flex-grow-1 overflow-auto">
      <div className="card-header border-0 py-5 px-3 gap-2">
        <ImportTicketStepBar />
      </div>
      <div className="flex-fill p-10">
        {currentStep === 1 && (
          <ImportTicketStepOne
            ticketSerials={ticketSerials}
            handleChangeFile={handleChangeFile}
            sheetNamesOption={sheetNamesOption}
          />
        )}
        {/* {currentStep === 2 && (
          <ImportTicketStepTwo ticketSerials={ticketSerials} />
        )} */}
        {currentStep === 2 && <ImportTicketStepThree />}
      </div>
    </div>
  )
}

export default ImportTicketCard
