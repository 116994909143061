import { useQuery } from '@tanstack/react-query'
import ModalEditGoods from 'features/Category/components/ModalEditGoods'
import { goodsQueries } from 'features/Category/screens/GoodsScreen/queries'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import CustomDataTable from 'general/components/CustomDataTable'
import Utils from 'general/utils/Utils'
import useSearchDebounce from 'hooks/useSearchDebounce'
import useToken from 'hooks/useToken'
import { forwardRef, useEffect, useMemo, useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import './style.scss'

export default function GoodsAutoComplete({
  rowData,
  classNames,
  showBorder,
  disabled,
  transparent,
  name,
  currentType,
  placeholder,
  onChangeHandler,
  onBlurHandler,
}) {
  // const [inputValue, setInputValue] = useState('')
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const [popupIsOpen, setPopupOpen] = useState(false)
  const [showingModalEditGoods, setShowingModalEditGoods] = useState(false)

  const { data: goods, isPending: isSearchingGoods } = useQuery(
    goodsQueries.listOfGoods({
      page: 0,
      limit: 20,
      q: debouncedSearch,
      status: 'ACTIVE',
    }),
  )

  useEffect(() => setSearch(rowData.name), [rowData.name, setSearch])

  const toggleState = {
    popup: setPopupOpen,
    modal: setShowingModalEditGoods,
  }

  return (
    <>
      <div className="BaseTextField">
        <div className={`${classNames?.wrapper}`}>
          <div
            className={`BaseTextField_Group rounded input-group ${
              showBorder ? '' : 'border-0'
            } ${!disabled && 'bg-white'} ${
              transparent && 'bg-transparent'
            }  d-flex flex-row  justify-content-between ${
              disabled && 'BaseTextField_Disabled'
            }`}
          >
            <OverlayTrigger
              show={popupIsOpen}
              placement="bottom-start"
              flip
              onToggle={(state) => setPopupOpen(state)}
              offset={10}
              trigger={'click'}
              rootClose
              overlay={
                <GoodsTable
                  input={search}
                  data={goods?.data}
                  rowData={rowData}
                  onChangeHandler={onChangeHandler}
                  toggleState={toggleState}
                  isLoading={isSearchingGoods}
                />
              }
            >
              <input
                className={`BaseTextField_Input w-100 px-3 border-0 bg-transparent ${classNames?.input}`}
                id={name}
                name={name}
                disabled={disabled}
                autocomplete="off"
                type={currentType ?? 'text'}
                placeholder={placeholder}
                value={search}
                onChange={(ev) => {
                  setSearch(ev.target.value)
                  rowData.name = ev.target.value
                }}
                onFocus={(ev) => {
                  setTimeout(ev.target.select.bind(ev.target), 20)
                }}
                onBlur={(ev) =>
                  onBlurHandler(ev.target.value, rowData, 'addRow')
                }
                onKeyDown={() => setPopupOpen(true)}
              />
            </OverlayTrigger>
          </div>
        </div>
      </div>
      <ModalEditGoods
        show={showingModalEditGoods}
        onClose={() => setShowingModalEditGoods(false)}
        active={'CREATE_NEW'}
        goodsItem={{
          name: search,
          priceAfterTax: true,
        }}
      />
    </>
  )
}

const GoodsTable = forwardRef(
  (
    {
      rowData,
      className,
      onChangeHandler,
      toggleState,
      data,
      isLoading,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const { token } = useToken()
    const columns = useMemo(
      () => [
        {
          id: 'good_table_code',
          name: 'Mã hàng hoá',
          minWidth: '150px',
          selector: (row) => row?.code,
        },
        {
          id: 'good_table_name',
          name: 'Tên hàng hoá',
          grow: 2,
          minWidth: '200px',
          selector: (row) => row?.name,
        },
      ],
      [],
    )

    return (
      <>
        <Popover
          {...props}
          className={`${className} max-w-100 rounded-1`}
          ref={ref}
        >
          <Popover.Body className="p-0">
            <CustomDataTable
              columns={columns}
              dataSource={data}
              isLoading={isLoading}
              isClickable
              showStripe={false}
              wrapperClassName="max-h-300px"
              noShowEmpty
              handleClickRow={(row) => {
                onChangeHandler(row, rowData, 'addRow')
                toggleState.popup(false)
              }}
              customTableStyle={{
                table: {
                  style: {
                    fontFamily: token.fontFamily,
                  },
                },
                headCells: {
                  style: {
                    color: token.colorText,
                    fontSize: '15px',
                    fontWeight: token.fontWeightStrong,
                  },
                },
                cells: {
                  style: {
                    color: token.colorText,
                    fontSize: token.fontSizeSM,
                  },
                },
                rows: {
                  style: {
                    '&:not(:last-of-type)': {
                      border: 'none',
                    },
                    '&:last-of-type': {
                      borderBottom: '1px solid #ebedf3',
                    },
                  },
                },
              }}
            />

            <CustomAntButton
              text={Utils.cn(t('AddNew'), props.input ?? '')}
              iconCn="fa-regular fa-plus"
              antProps={{
                type: 'link',
                className: 'text-primary',
                onClick: () => {
                  toggleState.modal(true)
                  toggleState.popup(false)
                },
              }}
            />
          </Popover.Body>
        </Popover>
      </>
    )
  },
)
