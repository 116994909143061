import React, { useEffect, useMemo, useState } from 'react'
import './style.scss'

import GuestBaseLayout from 'general/components/eInvoiceComponents/BaseLayout/GuestBaseLayout'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import invoiceApi from 'api/invoiceApi'
import Utils from 'general/utils/Utils'
import moment from 'moment'
import invoiceReportApi from 'api/invoiceReportApi'
import { useDispatch } from 'react-redux'
import { setAppSpinning } from 'app/appSlice'
import ToastHelper from 'general/helpers/ToastHelper'
import toolApi from 'api/toolApi'
import customProtocolCheck from 'custom-protocol-check'
import wsHelperInstance from 'general/helpers/WebSocketHelper'
import Global from 'general/utils/Global'
import { MAX_WAIT_SIGN_INVOICE } from 'general/constants/AppConstants'
import ModalConnectConfig from 'features/System/components/Modals/ModalConnectConfig'
import ModalChooseSignMethod from './components/ModalChooseSignMethod'

const reportTypeToVnese = (reportType) => {
  return reportType == 'CANCEL' ? 'hủy' : 'điều chỉnh'
}

function GuestSignInvoiceReport() {
  const dispatch = useDispatch()
  const { invoiceId } = useParams()
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const jwtToken = searchParams.get('jwtToken')

  const _reportType = useMemo(
    () => pathname?.split('/')?.at(1),
    [pathname],
  ) /* bien-ban-huy/bien-ban-dieu-chinh */
  let reportType = useMemo(
    () =>
      _reportType == 'bien-ban-huy'
        ? 'CANCEL'
        : _reportType == 'bien-ban-dieu-chinh'
          ? 'ADJUST'
          : '',
    [_reportType],
  )

  const [invoice, setInvoice] = useState()
  const [showModalConnectConfig, setShowModalConnectConfig] = useState(false)
  const [showModalChooseSignMethod, setShowModalChooseSignMethod] =
    useState(true)

  const report = useMemo(() => invoice?.report, [invoice])

  useEffect(() => {
    fetchInvoiceData(invoiceId)
  }, [invoiceId])

  useEffect(() => {
    if (!invoice) return

    if (invoice.reportType && invoice.reportType != reportType) {
      ToastHelper.showError(
        `Không thể lập biên bản ${reportTypeToVnese(
          reportType,
        )} khi hóa đơn đã có biên bản ${reportTypeToVnese(invoice.reportType)}`,
      )
      navigate('/hoa-don-dau-ra/hoa-don/danh-sach-hoa-don')
    }
  }, [invoice])

  const fetchInvoiceData = async (invoiceId) => {
    try {
      const res = await invoiceApi.findById(invoiceId, jwtToken)
      if (res?.result === 'success') {
        setInvoice(res?.data)
      } else {
        ToastHelper.showError('Không tìm thấy dữ liệu')
        navigate('/hoa-don-dau-ra/hoa-don/danh-sach-hoa-don')
      }
    } catch (err) {
      ToastHelper.showError('Có lỗi khi tìm dữ liệu')
      navigate('/hoa-don-dau-ra/hoa-don/danh-sach-hoa-don')
    }
  }

  const previewCancellationReport = async (reportId) => {
    dispatch(setAppSpinning(true))
    try {
      let res = await invoiceReportApi.preview({
        reportId,
        jwtToken,
      })
      dispatch(setAppSpinning(false))

      let _newFile = new File(
        [res],
        `Biên bản ${reportTypeToVnese(reportType)}.pdf`,
        {
          type: 'application/pdf',
        },
      )
      var _fileURL = URL.createObjectURL(_newFile)
      window.open(_fileURL, '_blank')
    } catch (e) {
      console.log(e)
      dispatch(setAppSpinning(false))
    }
  }

  const customerSignCancellationReport = async () => {
    try {
      const { url } = await toolApi.genToolUrl({
        param2: 'signReport',
        jwtToken,
      })
      if (url) {
        let notInstalledCb = () => {
          alert('Chua cai tool!')
        }

        customProtocolCheck(
          url,
          notInstalledCb,
          () => {
            Utils.openInCurrentTab(url)
          },
          2500,
        )

        if (reportType == 'CANCEL') {
          wsHelperInstance.onExecutingSignCancellationReport(
            report.reportId,
            url
              .replace(process.env.REACT_APP_BASE_URL_SCHEMA, '')
              .split(',')
              ?.at(0),
          )
        } else {
          wsHelperInstance.onExecutingSignAdjustReport(
            report.reportId,
            url
              .replace(process.env.REACT_APP_BASE_URL_SCHEMA, '')
              .split(',')
              ?.at(0),
          )
        }

        Global.gExecutingSignInvoiceReportTimeOut = setTimeout(() => {
          dispatch(setAppSpinning(false))
        }, MAX_WAIT_SIGN_INVOICE)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleApplyHSM = async (values) => {
    try {
      dispatch(setAppSpinning(true))
      await invoiceReportApi.hsmReportSign({
        reportId: report.reportId,
        hsmAgreementUUID: values.agreementUUID,
        hsmPasscode: values.passcode,
        jwtToken,
      })
      dispatch(setAppSpinning(false))

      navigate(`/bien-ban-huy/ky/${invoiceId}?jwtToken=${jwtToken}`)
    } catch (e) {
      console.log(e)
      dispatch(setAppSpinning(false))
    }
  }

  return (
    <GuestBaseLayout nonPadding={true}>
      <div
        className="GuestSignInvoiceReport pt-5"
        style={{ backgroundColor: '#f0f2f5' }}
      >
        <div className="invoice-report-container mb-5">
          <div className="invoice-report-paper mx-auto">
            <div classN ame="d-flex flex-column justify-content-center">
              <div className="text-center pt-5">
                <div
                  className="text-uppercase font-sfpro-bold"
                  style={{ fontSize: '20px', fontWeight: '400' }}
                >
                  CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM
                </div>

                <div
                  className="font-sfpro-bold"
                  style={{ fontSize: '16px', fontWeight: '400' }}
                >
                  Độc lập - Tự do - Hạnh phúc
                </div>
                <div
                  className="font-sfpro-bold"
                  style={{
                    fontSize: '20px',
                    fontWeight: '700',
                    marginTop: '30px',
                  }}
                >
                  {`BIÊN BẢN ${reportTypeToVnese(
                    reportType,
                  ).toUpperCase()} HÓA ĐƠN`}
                </div>
              </div>
            </div>

            <div
              className="d-flex align-items-end justify-content-center"
              style={{ minHeight: '44px', paddingBottom: '8px' }}
            >
              <div className="invoice-report-label">Số biên bản:</div>
              <div>
                <input
                  type="text"
                  className="form-control input-dot"
                  readOnly={/* screenAction == 'xem' */ true}
                  value={report?.no}
                  required
                />
              </div>
            </div>

            <div
              className=" mt-5 "
              style={{ minHeight: '44px', paddingBottom: '8px' }}
            >
              <div className="invoice-report-label">
                - Căn cứ Nghị định 123/2020/NĐ-CP ngày 19/10/2020 của Chính phủ
                quy định về hóa đơn, chứng từ.
              </div>
              <div className="invoice-report-label">
                - Căn cứ Thông tư 78/2021/TT-BTC ngày 17/09/2021 của Bộ Tài
                chính hướng dẫn thực hiện Nghị định số 123/2020/NĐ-CP ngày 19
                tháng 10 năm 2020 của Chính phủ quy định về hóa đơn, chứng từ.
              </div>{' '}
              <div className="invoice-report-label">
                - Căn cứ vào thỏa thuận giữa các bên.
              </div>
            </div>

            <div
              className="d-flex align-items-end"
              style={{ minHeight: '44px', paddingBottom: '8px' }}
            >
              <div className="d-flex justify-content-center">
                <span className="invoice-date">
                  Hôm nay, ngày {moment(report?.date, 'YYYY-MM-DD').date()}{' '}
                  tháng {moment(report?.date, 'YYYY-MM-DD').month() + 1} năm{' '}
                  {moment(report?.date, 'YYYY-MM-DD').year()} chúng tôi gồm có:
                </span>
              </div>
            </div>

            <div
              className="d-flex align-items-end"
              style={{ minHeight: '44px', paddingBottom: '8px' }}
            >
              <div className="invoice-report-label-bold">Bên A (Bên bán): </div>
              <div className="" style={{ flexBasis: 'auto' }}>
                {report?.sellerName}
              </div>
            </div>

            <div
              className="d-flex align-items-end"
              style={{ minHeight: '44px', paddingBottom: '8px' }}
            >
              <div className="invoice-report-label">Địa chỉ: </div>
              <div className="" style={{ flexBasis: 'auto' }}>
                {report?.sellerFullAddress}
              </div>
            </div>

            <div className="d-flex flex-row justify-content-between">
              <div
                className="d-flex align-items-end"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="invoice-report-label">Mã số thuế: </div>
                <div className="" style={{ flexBasis: 'auto' }}>
                  {report?.sellerTaxCode}
                </div>
              </div>
              <div
                className="d-flex align-items-end"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="invoice-report-label">Số điện thoại: </div>
                <div className="" style={{ flexBasis: 'auto' }}>
                  <input
                    type="text"
                    className="form-control input-dot"
                    style={{ flex: 'auto' }}
                    value={report?.sellerPhone}
                    readOnly={/* screenAction == 'xem' */ true}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-row justify-content-between">
              <div
                className="d-flex align-items-end"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="invoice-report-label">Đại diện: </div>
                <div className="" style={{ flexBasis: 'auto' }}>
                  <input
                    type="text"
                    className="form-control input-dot"
                    style={{ flex: 'auto' }}
                    value={report?.sellerContactPersonName}
                    readOnly={/* screenAction == 'xem' */ true}
                  />
                </div>
              </div>

              <div
                className="d-flex align-items-end"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="invoice-report-label">Chức vụ: </div>
                <div className="" style={{ flexBasis: 'auto' }}>
                  <input
                    type="text"
                    className="form-control input-dot"
                    style={{ flex: 'auto' }}
                    value={report?.sellerContactPersonPosition}
                    readOnly={/* screenAction == 'xem' */ true}
                  />
                </div>
              </div>
            </div>

            <div
              className="d-flex align-items-end"
              style={{ minHeight: '44px', paddingBottom: '8px' }}
            >
              <div className="invoice-report-label-bold">Bên B (Bên bán): </div>
              <div className="flex-grow-1" style={{ flexBasis: 'auto' }}>
                <input
                  type="text"
                  className="form-control input-dot"
                  style={{ flex: 'auto' }}
                  value={report?.customerName}
                  readOnly={/* screenAction == 'xem' */ true}
                />
              </div>
            </div>

            <div
              className="d-flex align-items-end"
              style={{ minHeight: '44px', paddingBottom: '8px' }}
            >
              <div className="invoice-report-label">Địa chỉ: </div>
              <div className="flex-grow-1" style={{ flexBasis: 'auto' }}>
                <input
                  type="text"
                  className="form-control input-dot w-100"
                  style={{ flex: 'auto' }}
                  value={report?.customerFullAddress}
                  readOnly={/* screenAction == 'xem' */ true}
                />
              </div>
            </div>

            <div className="d-flex flex-row justify-content-between">
              <div
                className="d-flex align-items-end"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="invoice-report-label">Mã số thuế: </div>
                <div className="" style={{ flexBasis: 'auto' }}>
                  <input
                    type="text"
                    className="form-control input-dot"
                    style={{ flex: 'auto' }}
                    value={report?.customerTaxCode}
                    readOnly={/* screenAction == 'xem' */ true}
                  />
                </div>
              </div>
              <div
                className="d-flex align-items-end"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="invoice-report-label">Số điện thoại: </div>
                <div className="" style={{ flexBasis: 'auto' }}>
                  <input
                    type="text"
                    className="form-control input-dot"
                    style={{ flex: 'auto' }}
                    value={report?.customerPhone}
                    readOnly={/* screenAction == 'xem' */ true}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-row justify-content-between">
              <div
                className="d-flex align-items-end"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="invoice-report-label">Đại diện: </div>
                <div className="" style={{ flexBasis: 'auto' }}>
                  <input
                    type="text"
                    className="form-control input-dot"
                    style={{ flex: 'auto' }}
                    value={report?.customerContactPersonName}
                    readOnly={/* screenAction == 'xem' */ true}
                  />
                </div>
              </div>

              <div
                className="d-flex align-items-end"
                style={{ minHeight: '44px', paddingBottom: '8px' }}
              >
                <div className="invoice-report-label">Chức vụ: </div>
                <div className="" style={{ flexBasis: 'auto' }}>
                  <input
                    type="text"
                    className="form-control input-dot"
                    style={{ flex: 'auto' }}
                    value={report?.customerContactPersonPosition}
                    readOnly={/* screenAction == 'xem' */ true}
                  />
                </div>
              </div>
            </div>

            <div
              className="d-flex align-items-end"
              style={{ minHeight: '44px', paddingBottom: '8px' }}
            >
              <div className="invoice-report-label">
                {`Hai bên thống nhất lập biên bản này để ${reportTypeToVnese(
                  reportType,
                )} hóa đơn theo quy
                định.`}
              </div>
            </div>

            <div
              className="d-flex align-items-end"
              style={{ minHeight: '44px', paddingBottom: '8px' }}
            >
              <div className="invoice-report-label">{`Hóa đơn bị ${reportTypeToVnese(
                reportType,
              )}: `}</div>
              <div className="" style={{ flexBasis: 'auto' }}>
                <div className="invoice-report-label">
                  Ký hiệu {invoice?.serial}, số {invoice?.no}, ngày{' '}
                  {Utils.shortenDate(invoice?.date)}
                </div>
              </div>
            </div>

            <div
              className="d-flex align-items-end"
              style={{ minHeight: '44px', paddingBottom: '8px' }}
            >
              <div className="invoice-report-label">{`Lý do ${reportTypeToVnese(
                reportType,
              )}: `}</div>
              <div className="flex-grow-1">
                <input
                  type="text"
                  className="form-control input-dot"
                  value={report?.reason}
                  readOnly={/* screenAction == 'xem' */ true}
                />
              </div>
            </div>

            <div
              className="d-flex align-items-end"
              style={{ minHeight: '44px', paddingBottom: '8px' }}
            >
              Chúng tôi xin cam đoan các thông tin khai ở trên là hoàn toàn
              chính xác. Nếu có bất kỳ sai sót nào chúng tôi xin chịu trách
              nhiệm trước pháp luật.
            </div>

            <div className="d-flex flex-row justify-content-between align-items-start px-30 mb-5">
              <div className="text-center">
                <div>
                  <div className="font-sfpro-bold">Đại diện bên A</div>
                  <div className="mb-4">(Chữ ký số, chữ ký điện)</div>
                  <button
                    className="btn btn-light d-inline-flex align-items-center"
                    style={{ color: '#041847', backgroundColor: '#e4e6eb' }}
                    disabled={true}
                  >
                    <i
                      className="fal fa-file-contract"
                      style={{ color: '#041847' }}
                    ></i>
                    Ký điện tử
                  </button>
                </div>
              </div>

              <div className="text-center">
                <div>
                  <div className="font-sfpro-bold">Đại diện bên B</div>
                  <div className="mb-4">(Chữ ký số, chữ ký điện)</div>
                  {[4, 5].includes(invoice?.reportStatus) && (
                    <button
                      className="btn btn-light d-inline-flex align-items-center"
                      style={{ color: '#041847', backgroundColor: '#e4e6eb' }}
                      disabled={![4].includes(invoice?.reportStatus)}
                      onClick={
                        /* customerSignCancellationReport */ () =>
                          setShowModalChooseSignMethod(true)
                      }
                    >
                      <i
                        className="fal fa-file-contract"
                        style={{ color: '#041847' }}
                      ></i>
                      Ký điện tử
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-center align-items-center mb-5">
          <button
            className="btn btn-primary"
            onClick={() => previewCancellationReport(report?.reportId)}
          >
            Tải xuống
          </button>
        </div>
      </div>

      <ModalConnectConfig
        title={'Kết nối HSM'}
        description={'Thiết lập kết nối HSM để ký số'}
        method={'HSM'}
        open={showModalConnectConfig}
        onOpenChange={setShowModalConnectConfig}
        onApply={handleApplyHSM}
      />

      <ModalChooseSignMethod
        show={showModalChooseSignMethod}
        setShow={setShowModalChooseSignMethod}
        header={'Chọn phương thức ký số'}
        content={'Vui lòng chọn phương thức ký số'}
        handleChooseUSBToken={customerSignCancellationReport}
        handleChooseHSM={() => {
          setShowModalChooseSignMethod(false)
          setShowModalConnectConfig(true)
        }}
      />
    </GuestBaseLayout>
  )
}

export default GuestSignInvoiceReport
