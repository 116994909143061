import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import routeApi from 'api/routeApi'
import { assign, cloneDeep, filter, keys, map, merge } from 'lodash'

// MARK: --- Thunks ---
export const thunkGetListRoute = createAsyncThunk(
  'get-list-route',
  async () => {
    const res = await routeApi.getListOfRoute()
    return res
  },
)

/**
 * @typedef {{
 * modalName: keyof initialState['modalStates'],
 * status: boolean
 * }} SetModalType
 *
 * @typedef {string[]} SetSelectedTicket
 */

const initialState = {
  isGettingListOfRoute: false,
  listOfRoute: [],
  modalStates: {
    cancel: false,
    explaination: false,
    sign: false,
    delete: false,
    download: false,
    sentMail: false,
    transmission: false,
    sendDraftInvoice: false,
    selectCancelationMethod: false,
    selectReplacementMethod: false,
    selectAdjustInvoiceMethod: false,
    noRight: false,
    detectMultipleCustomer: false,
    upload: false,
    adjustReplaceTicket: false,
    bulkTicketMail: false,
    cloneMultiInvoiceModal: false,
  },
  selectedTickets: [],
  toggledClearRows: false,
}

export const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    /**
     * @param {import('@reduxjs/toolkit').PayloadAction<SetModalType>} action
     */
    setModalsState: (state, action) => {
      const previousModalState = cloneDeep(state.modalStates)
      const notCurrentModal = filter(
        keys(previousModalState),
        (m) => m !== action.payload.modalName,
      )
      const turnOthersOff = assign(
        {},
        ...map(notCurrentModal, (m) => ({ [m]: false })),
      )
      state.modalStates = merge(turnOthersOff, {
        [action.payload.modalName]: action.payload.status,
      })
    },
    /**
     * @param {import('@reduxjs/toolkit').PayloadAction<SetSelectedTicket>} action
     */
    setSelectedTicket: (state, action) => {
      state.selectedTickets = action.payload
      // state.toggledClearRows = false
    },
    setClearRows: (state) => {
      state.toggledClearRows = !state.toggledClearRows
    },
  },
  extraReducers: (builder) => {
    // sign in
    builder.addCase(thunkGetListRoute.pending, (state) => {
      state.isGettingListOfRoute = true
    })
    builder.addCase(thunkGetListRoute.rejected, (state) => {
      state.isGettingListOfRoute = false
    })
    builder.addCase(thunkGetListRoute.fulfilled, (state, action) => {
      const { payload } = action
      const { routes } = payload
      state.isGettingListOfRoute = false
      state.listOfRoute = routes
    })
  },
})

const { reducer, actions } = ticketSlice
export const {
  setOnlineStatus,
  setActiveInvoice,
  setClearRows,
  setModalsState,
  setSelectedTicket,
} = actions
export default reducer
