import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import {
  FormCustom,
  SelectCustom,
  InputCustom,
  RedStar,
  DivTddCode,
  DatePickerCustom,
  DivCenterImg,
  UploadCustom,
  IconFileImageOutlined,
  DivUpdateFileLogo,
  DivRemmoveFileLogo,
  RadioCustom,
  DivFlex,
  DivBorder,
  TextlogoFont,
  TextFont,
  ButtonCustom,
  DivfontS,
  ColorPickerCustom,
  LabelForm,
  CheckboxCustom,
  DivFlexRight,
  DivBackGroundTab,
  IconRightOutlined,
  InputNumberCumstom,
  SliderCumstom,
  DivCustomForm,
} from 'features/TaxDeductionDocuments/styled'
import { Empty } from 'antd'
import AppResource from 'general/constants/AppResource'
import { stubFalse } from 'lodash'
import ModalBorderBackground from './Modal/modalBorderBackground'
import ModalContentBackground from './Modal/modalContentBackground'
import Maintenance from 'features/TaxDeduction/component/Maintenance'
import { findKeyInBorderBackground } from 'general/utils/Utils'
import SelectOptions from 'general/constants/SelectOptions'

function TabBackground({
  form,
  resetForm,
  tabActiveKey,
  chonseBorder,
  setChonseBorder,
  fileBorderBackground,
  setFileBorderBackground,
  chonseContent,
  setChonseContent,
  fileContentBackground,
  setFileContentBackground,
  fileOwnWallpaper,
  setFileOwnWallpaper,
  colorBorderBackground,
  setColorBorderBackground,
  backgroundImg,
  setBackgroundImg,
  contentBackgroundColor,
  setContentBackgroundColor,
  formState,
  setFormState,
}) {
  const { t } = useTranslation()
  const [ownWallpaperColor, setOwnWallpaperColor] = useState(
    formState.ownWallpaperColor,
  )
  const [percent, setPercent] = useState(
    form.getFieldValue('opacityContentBackground'),
  )

  const [percentTextOwnWallpaper, setPercentTextOwnWallpaper] = useState(
    form.getFieldValue('opacityTextOwnWallpaper') ?? 50,
  )
  const [visibleModalBorder, setVisibleModalBorder] = useState(false)
  const [visibleModalContent, setVisibleModalContent] = useState(false)
  const [ownWallpaper, setOwnWallpaper] = useState(formState.ownWallpaper ?? 1)
  const [boldOwnWallpaper, setBoldOwnWallpaper] = useState(
    formState.boldOwnWallpaper,
  )

  useMemo(() => {
    if (tabActiveKey === '2') {
      // setColorBorderBackground(form.getFieldValue('borderBackgroundColor'))
      // setFileContentBackground(form.getFieldValue('contentBackgroundColor'))
      // const borderBackground = findKeyInBorderBackground(
      //   AppResource.borderBackground,
      //   form.getFieldValue('borderBackground'),
      // )
      // const contentBackground = findKeyInBorderBackground(
      //   AppResource.contentBackground,
      //   form.getFieldValue('backgroundColor'),
      // )
      // setChonseBorder(borderBackground)
      // setChonseContent(contentBackground)
    }
  }, [tabActiveKey])

  const options = []
  for (let i = 30; i <= 100; i++) {
    options.push({ label: `${i}`, value: i })
  }

  return (
    <>
      {/* backgroundborder */}
      <FormCustom.Item name={'borderBackgroundColor'}>
        <div>
          <LabelForm paddingLeft="22px">
            {t('taxDeductionDocuments.boderBackground')}
          </LabelForm>
        </div>
        {chonseBorder && (
          <DivFlexRight>
            <div>
              <ColorPickerCustom
                defaultFormat="hex"
                onChange={(value, hex) => {
                  form.setFieldsValue({
                    borderBackgroundColor: hex,
                  })
                  setColorBorderBackground(hex)
                }}
              >
                <LabelForm
                  color={colorBorderBackground}
                  borderBottom={'5px solid'}
                  paddingBottom={'2px'}
                >
                  {t('taxDeductionDocuments.changeColor')}
                </LabelForm>
              </ColorPickerCustom>
            </div>
            <div>
              <LabelForm
                color={'red'}
                onClick={() => {
                  setFileBorderBackground(null)
                  form.setFieldsValue({
                    borderBackground: null,
                  })
                  setChonseBorder(null)
                }}
              >
                {t('taxDeductionDocuments.remove')}
              </LabelForm>
            </div>
          </DivFlexRight>
        )}

        <DivBackGroundTab
          marginTop="30px"
          paddingBottom={'40px'}
          paddingTop={'20px'}
          onClick={() => {
            setVisibleModalBorder(true)
          }}
        >
          <LabelForm>
            {chonseBorder
              ? `${t('taxDeductionDocuments.boderBackground')} ${chonseBorder?.key}`
              : `${t('taxDeductionDocuments.chonseBackgroundDefault')}`}
          </LabelForm>
          <IconRightOutlined float={'right'} />
        </DivBackGroundTab>
      </FormCustom.Item>
      {/* backgroundcontent */}
      <FormCustom.Item name={'borderBackgroundColor'}>
        <div className="pt-3">
          <LabelForm paddingLeft="22px">
            {t('taxDeductionDocuments.backgroundDefault')}
          </LabelForm>
        </div>
        {chonseContent?.url && (
          <DivFlexRight>
            <div>
              <ColorPickerCustom
                defaultFormat="hex"
                onChange={(value, hex) => {
                  form.setFieldsValue({
                    contentBackgroundColor: hex,
                  })
                  setContentBackgroundColor(hex)
                }}
              >
                <LabelForm
                  color={contentBackgroundColor}
                  borderBottom={'5px solid'}
                  paddingBottom={'2px'}
                >
                  {t('taxDeductionDocuments.changeColor')}
                </LabelForm>
              </ColorPickerCustom>
            </div>
            <div>
              <LabelForm
                color={'red'}
                onClick={() => {
                  setFileContentBackground(null)
                  form.setFieldsValue({
                    contentBackground: null,
                  })
                  setChonseContent({ x: 0, y: 0, width: 500, height: 500 })
                }}
              >
                {t('taxDeductionDocuments.remove')}
              </LabelForm>
            </div>
          </DivFlexRight>
        )}

        <DivBackGroundTab
          marginTop="30px"
          paddingBottom={'40px'}
          paddingTop={'20px'}
          onClick={() => {
            setVisibleModalContent(true)
          }}
        >
          <LabelForm>
            {chonseContent?.url
              ? `${t('taxDeductionDocuments.contentBackground')} ${chonseContent?.key}`
              : `${t('taxDeductionDocuments.chonseContentBackgroundDefault')}`}
          </LabelForm>
          <IconRightOutlined float={'right'} />
        </DivBackGroundTab>
      </FormCustom.Item>
      <DivFlex className="pt-3">
        <LabelForm paddingLeft="22px">
          {t('taxDeductionDocuments.opactity')}
        </LabelForm>
        <FormCustom.Item name={'opacityContentBackground'}>
          <SliderCumstom
            min={0}
            max={100}
            step={1}
            width={'300px'}
            onChange={(value) => {
              setPercent(value)
              form.setFieldsValue({ opacityContentBackground: value })
            }}
          />
        </FormCustom.Item>
        <LabelForm paddingLeft="22px">{percent}%</LabelForm>
      </DivFlex>
      <div className="pt-3">
        <LabelForm paddingLeft="22px">
          {t('taxDeductionDocuments.backgroundReserved')}
        </LabelForm>
      </div>
      21232-{formState.ownWallpaper}
      <FormCustom.Item name={'ownWallpaper'}>
        <RadioCustom.Group
          onChange={(e) => {
            form.setFieldsValue({ ownWallpaper: e.target.value })
            setOwnWallpaper(e.target.value)
          }}
        >
          <DivFlex paddingLeft="22px" paddingTop={'20px'}>
            <div>
              <RadioCustom value={1}>
                {t('taxDeductionDocuments.logoC')}
              </RadioCustom>
            </div>
            <div>
              <RadioCustom value={2}>
                {t('taxDeductionDocuments.textC')}
              </RadioCustom>
            </div>
          </DivFlex>
        </RadioCustom.Group>
      </FormCustom.Item>
      <DivCustomForm
        paddingLeft={'22px'}
        style={{ display: formState.ownWallpaper == 1 ? '' : 'none' }}
      >
        <FormCustom.Item
          style={{ height: 250, width: 250 }}
          name="fileOwnWallpaper"
        >
          <DivCenterImg urlBackGroupImage={fileOwnWallpaper?.url}>
            {fileOwnWallpaper?.url ? <></> : <IconFileImageOutlined />}
          </DivCenterImg>
          <UploadCustom
            showUploadList={false}
            maxCount={1}
            name="fileLogo"
            accept=".png,.jpeg,.jpg,.svg"
            beforeUpload={(file) => {
              return false
            }}
            file={fileOwnWallpaper?.file}
            // onPreview={this.handlePreview}
            onChange={(file) => {
              setFileOwnWallpaper({
                ...file,
                x: 104,
                y: 360,
                width: 600,
              })
            }}
          >
            <DivUpdateFileLogo>
              <img src={AppResource.taxDeucationDocuments.keentddEdit} />
            </DivUpdateFileLogo>
          </UploadCustom>
          {fileOwnWallpaper?.url && (
            <DivRemmoveFileLogo
              onClick={() => {
                setFileOwnWallpaper({
                  x: 0,
                  y: 0,
                  width: 0,
                  height: 0,
                })
              }}
            >
              <img src={AppResource.taxDeucationDocuments.keentddDelete} />
            </DivRemmoveFileLogo>
          )}
        </FormCustom.Item>
        <div>
          <LabelForm marginLeft="22px">
            {t('taxDeductionDocuments.opactityImg')}
          </LabelForm>
          <DivFlex paddingRightV1="20px">
            <FormCustom.Item name={'opacityOwnWallpaper'}>
              <SliderCumstom
                min={0}
                max={100}
                step={1}
                width={'300px'}
                paddingLeft={'22px'}
                onChange={(value) => {
                  setPercentTextOwnWallpaper(value)
                  form.setFieldsValue({
                    opacityOwnWallpaper: value,
                  })
                  setFormState({
                    ...formState,
                    opacityOwnWallpaper: value,
                  })
                }}
              />
            </FormCustom.Item>
            <LabelForm paddingLeft="22px">
              {formState?.opacityOwnWallpaper}%
            </LabelForm>
          </DivFlex>
        </div>
      </DivCustomForm>
      <>
        <DivFlex
          paddingRightV1="20px"
          style={{ display: formState.ownWallpaper == 2 ? '' : 'none' }}
        >
          <DivCustomForm paddingLeft={'22px'}>
            <FormCustom.Item name={'fontTextOwnWallpaper'}>
              <SelectCustom
                placeholder={t('taxDeductionDocuments.fontTextSelectPl')}
                options={SelectOptions.fontText}
                style={{ width: 180 }}
              ></SelectCustom>
            </FormCustom.Item>
          </DivCustomForm>
          <DivCustomForm>
            <FormCustom.Item name={'fontSizeTextOwnWallpaper'}>
              <SelectCustom
                placeholder={t('taxDeductionDocuments.fontSizeSelectPl')}
                options={options}
                style={{ width: 100 }}
              ></SelectCustom>
            </FormCustom.Item>
          </DivCustomForm>
          <div>
            <LabelForm
              lineHeight="30px"
              padding="5px"
              style={{
                backgroundColor: formState?.boldOwnWallpaper && '#a7c9f1',
              }}
              onClick={() => {
                setBoldOwnWallpaper(!formState?.boldOwnWallpaper)
                form.setFieldsValue({
                  boldOwnWallpaper: !formState?.boldOwnWallpaper,
                })
                setFormState({
                  ...formState,
                  boldOwnWallpaper: !formState?.boldOwnWallpaper,
                })
              }}
            >
              B
            </LabelForm>
            <LabelForm
              lineHeight="30px"
              padding="5px"
              style={{ paddingLeft: 15 }}
              pointer="pointer"
            >
              /
            </LabelForm>
            <FormCustom.Item
              name={'boldOwnWallpaper'}
              style={{ display: 'none' }}
            ></FormCustom.Item>
            <ColorPickerCustom
              defaultFormat="hex"
              onChange={(value, hex) => {
                form.setFieldsValue({
                  ownWallpaperColor: hex,
                })
                setFormState({ ...formState, ownWallpaperColor: hex })
                setOwnWallpaperColor(hex)
              }}
            >
              <LabelForm
                lineHeight="30px"
                borderBottom="3px solid"
                style={{ marginLeft: 15 }}
                pointer="pointer"
                color={formState.ownWallpaperColor}
              >
                A
              </LabelForm>
            </ColorPickerCustom>
            <FormCustom.Item name={'ownWallpaperColor'}></FormCustom.Item>
          </div>
        </DivFlex>
        <FormCustom.Item
          name={'describeownWallpaper'}
          style={{
            padding: '0px 22px',
            display: formState.ownWallpaper == 2 ? '' : 'none',
          }}
        >
          <InputCustom.TextArea
            rows={3}
            style={{
              color: formState.ownWallpaperColor,
              fontWeight: !boldOwnWallpaper ? '400' : '700',
            }}
          />
        </FormCustom.Item>
        <div
          className={'pt-3'}
          style={{ display: formState.ownWallpaper == 2 ? '' : 'none' }}
        >
          <LabelForm marginLeft="22px">
            {t('taxDeductionDocuments.opactityText')}
          </LabelForm>
          <DivFlex paddingRightV1="20px">
            <FormCustom.Item name={'opacityOwnWallpaper'}>
              <SliderCumstom
                min={0}
                max={100}
                step={1}
                width={'300px'}
                paddingLeft={'22px'}
                onChange={(value) => {
                  form.setFieldsValue({ opacityOwnWallpaper: value })
                  setFormState({
                    ...formState,
                    opacityTextOwnWallpaper: value,
                  })
                }}
              />
            </FormCustom.Item>
            <LabelForm paddingLeft="22px">
              {formState.opacityOwnWallpaper ?? 0}%
            </LabelForm>
          </DivFlex>
        </div>
      </>
      {/* <FormCustom.Item name={'opacityContentBackground'}>
        <InputCustom />
        <DivFlex className="pt-3">
          <LabelForm paddingLeft="22px">
            {t('taxDeductionDocuments.opactity')}
          </LabelForm>
          <SliderCumstom min={0} max={100} step={0.5} width={'300px'} />
        </DivFlex>
      </FormCustom.Item> */}
      {/* modal */}
      <ModalBorderBackground
        form={form}
        visibleModalBorder={visibleModalBorder}
        setVisibleModalBorder={(value) => {
          setVisibleModalBorder(value)
        }}
        setChonseBorder={(value) => {
          const data = findKeyInBorderBackground(
            AppResource.borderBackground,
            value,
          )
          setChonseBorder(data)
          setFileBorderBackground(data?.key)
        }}
        chonseBorder={chonseBorder}
      />
      <ModalContentBackground
        form={form}
        visibleModalContent={visibleModalContent}
        setVisibleModalContent={(value) => {
          setVisibleModalContent(value)
        }}
        setChonseContent={(value) => {
          const data = findKeyInBorderBackground(
            AppResource.contentBackground,
            value,
          )
          setChonseContent({ ...data, x: 150, y: 320, height: 500, width: 500 })
          setFileContentBackground(data?.key)
        }}
        chonseContent={chonseContent}
      />
    </>
  )
}
export default TabBackground
