import TabProcedure from 'features/TaxDeduction/component/TabInfor/procedure'
import { thunkFindDeductionDocuments } from 'features/TaxDeduction/taxDeductionSlice'
import { useAppDispatch } from 'hooks/useRedux'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ContainerDivTab, TabCustom, ContainerTab } from '../../styled'
import { unwrapResult } from '@reduxjs/toolkit'
import Maintenance from 'features/TaxDeduction/component/Maintenance'
import AccountingDocuments from 'features/TaxDeduction/component/TabInfor/AccountingDocuments'
import { getParam } from 'general/utils/Utils'

function HomeTaxDeduction(props) {
  const tab = getParam('tab')
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [tabActiveKey, setTabActiveKey] = useState(tab ? tab : '2')
  const [openModalFind, setOpenModalFind] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [panningDataSource, setPanningDataSource] = useState({
    page: 1,
    limit: 20,
    totalItems: 0,
    totalPages: 0,
  })

  const onChangeTab = (value) => {
    setTabActiveKey(value)
  }

  const changeOpenModalFind = (value) => {
    setOpenModalFind(value)
  }

  const findDataSource = async () => {
    try {
      const data = unwrapResult(
        await dispatch(
          thunkFindDeductionDocuments({
            status: 1,
            page: panningDataSource.page,
            limit: panningDataSource.limit,
          }),
        ),
      )
      setDataSource(data.items)
      setPanningDataSource({
        ...panningDataSource,
        totalItems: data.totalItems,
        totalPages: data.totalPages,
      })
    } catch (error) {}
  }

  useEffect(() => {
    if (openModalFind) {
      findDataSource()
    }
  }, [openModalFind, panningDataSource.page, panningDataSource.limit])

  const changePaniningFind = (page, limit) => {
    setPanningDataSource({
      ...panningDataSource,
      page,
      limit,
    })
  }

  return (
    <ContainerTab className="pt-6">
      <div className="card card-custom w-100">
        <div className="card-header border-0 pt-6 pb-6">
          <ContainerDivTab className="w-100">
            <TabCustom
              activeKey={tabActiveKey != null ? tabActiveKey : '1'}
              onChange={onChangeTab}
            >
              {/* <TabCustom.TabPane tab={t('taxDeduction.procedure')} key="1">
                <TabProcedure
                  setTabActiveKey={(value) => {
                    onChangeTab(value)
                  }}
                  openModalFind={openModalFind}
                  changeOpenModalFind={changeOpenModalFind}
                  //tableFind
                  dataSource={dataSource}
                  panningDataSource={panningDataSource}
                  reloadFind={findDataSource}
                  changePaniningFind={changePaniningFind}
                />
              </TabCustom.TabPane> */}
              <TabCustom.TabPane tab={t('taxDeduction.evidence')} key="2">
                <AccountingDocuments tabActive={tabActiveKey} />
              </TabCustom.TabPane>
              {/* <TabCustom.TabPane
                tab={t('taxDeduction.voucherUsageStatement')}
                key="3"
              >
                <Maintenance />
              </TabCustom.TabPane>
              <TabCustom.TabPane tab={t('taxDeduction.notification')} key="4">
                <Maintenance />
              </TabCustom.TabPane> */}
            </TabCustom>
          </ContainerDivTab>
        </div>
      </div>
    </ContainerTab>
  )
}
HomeTaxDeduction.propTypes = {
  tabActive: PropTypes.string,
  onChangeTab: PropTypes.func,
}
export default HomeTaxDeduction
