import './style.scss'

import {
  setActivePanel,
  setInstanceInfo,
  setSpecifiedDataFieldProperty,
} from 'features/TemplateInvoice/templateInvoiceSlice'
import { useDispatch, useSelector } from 'react-redux'

function RouteControl(props) {
  const dispatch = useDispatch()
  const { style } = props

  const { routes } = useSelector((state) => state.templateInvoice)
  const { routeType, routeId, notDraftTickets } = useSelector(
    (state) => state.templateInvoice.instance,
  )

  const handleChangeRouteTicket = ({
    routeId,
    nameRoute,
    routeStart,
    routeEnd,
    // noCar,
  }) => {
    dispatch(
      setInstanceInfo({
        routeId: routeId,
      }),
    )

    dispatch(
      setSpecifiedDataFieldProperty({
        regionName: 'buyerInfo',
        dataFieldName: 'route',
        propertyName: 'value',
        propertyData: {
          value: nameRoute,
        },
      }),
    )

    dispatch(
      setSpecifiedDataFieldProperty({
        regionName: 'buyerInfo',
        dataFieldName: 'from',
        propertyName: 'value',
        propertyData: {
          value: routeStart,
        },
      }),
    )

    dispatch(
      setSpecifiedDataFieldProperty({
        regionName: 'buyerInfo',
        dataFieldName: 'destination',
        propertyName: 'value',
        propertyData: {
          value: routeEnd,
        },
      }),
    )

    // dispatch(
    //   setSpecifiedDataFieldProperty({
    //     regionName: 'buyerInfo',
    //     dataFieldName: 'vehicleNo',
    //     propertyName: 'value',
    //     propertyData: {
    //       value: noCar,
    //     },
    //   }),
    // )
  }

  return (
    <div
      className="RouteControl d-flex flex-column overflow-hidden"
      style={style}
    >
      <div
        className="d-flex flex-row align-items-center mb-3"
        style={{ flex: '0 1 auto' }}
      >
        <button
          type="button"
          className="btn btn-icon mr-2"
          onClick={() => dispatch(setActivePanel('main_ctrl'))}
        >
          <i className="fas fa-arrow-left" style={{ color: '#000000' }}></i>
        </button>
        <h3 className="static-title">Thiết lập tuyến đường</h3>
      </div>

      <div
        className="overflow-scroll"
        style={{ flex: '1 1 auto', fontSize: '0.95em' }}
      >
        <div className="row mx-0 g-2 mb-2">
          <div className="col-12 px-0">
            <p className="mb-0 font-weight-bold">Hình thức nhập liệu</p>
          </div>

          <div className="col-12 px-0">
            <div className="d-flex flex-row align-items-start cursor-pointer">
              <input
                className="mr-3 cursor-pointer"
                type="radio"
                name="routeType"
                id="single-route-type"
                checked={routeType == 'SINGLE'}
                value={'SINGLE'}
                onChange={(e) =>
                  dispatch(
                    setInstanceInfo({
                      routeType: e.target.value,
                    }),
                  )
                }
                style={{ width: '1.2rem', height: '1.2rem' }}
                disabled={notDraftTickets?.length}
              />
              <label
                className="m-0 cursor-pointer d-inline-flex flex-column"
                htmlFor="single-route-type"
              >
                <span className="font-weight-bold">
                  Sử dụng cho một tuyến đường
                </span>
                <span className="">
                  Thông tin tuyến đường được in sẵn trên mẫu vé
                </span>
              </label>
            </div>
          </div>

          <div className="col-12 px-0">
            <div className="d-flex flex-row align-items-start cursor-pointer mb-2">
              <input
                className="mr-3 cursor-pointer"
                type="radio"
                name="routeType"
                id="multiple-route-type"
                checked={routeType == 'MULTIPLE'}
                value={'MULTIPLE'}
                onChange={(e) => {
                  dispatch(
                    setInstanceInfo({
                      routeType: e.target.value,
                    }),
                  )

                  handleChangeRouteTicket({
                    routeId: null,
                    nameRoute: null,
                    routeStart: null,
                    routeEnd: null,
                    // noCar: null,
                  })
                }}
                style={{ width: '1.2rem', height: '1.2rem' }}
                disabled={notDraftTickets?.length}
              />
              <label
                className="m-0 cursor-pointer d-inline-flex flex-column"
                htmlFor="multiple-route-type"
              >
                <span className="font-weight-bold">
                  Sử dụng cho nhiều tuyến đường
                </span>
                <span className="">
                  Trên mẫu vé để trống tuyến đường, bạn sẽ chọn tuyến đường khi
                  xuất vé
                </span>
              </label>
            </div>
          </div>
        </div>

        {routeType == 'SINGLE' && (
          <div className="d-flex flex-column">
            <div className="mb-2">
              <p className="mb-0 font-weight-bold">
                Chọn tuyến đường in lên vé
              </p>
            </div>

            <div>
              {routes.map((route, index) => (
                <div
                  key={index}
                  className={`d-flex align-items-center route-item px-6 py-3 cursor-pointer ${
                    route.routeId == routeId ? 'picked' : ''
                  }`}
                  onClick={() =>
                    handleChangeRouteTicket({
                      routeId: route.routeId,
                      nameRoute: route.nameRoute,
                      routeStart: route.routeStart,
                      routeEnd: route.routeEnd,
                      // noCar: route.noCar,
                    })
                  }
                  style={{
                    pointerEvents: notDraftTickets?.length ? 'none' : 'auto',
                  }}
                >
                  <i className="fa-solid fa-circle-check mr-2"></i>
                  {route.nameRoute}
                </div>
              ))}
            </div>

            <div
              className="d-flex flex-column align-items-center p-5 mt-2"
              style={{ border: '1px dashed #ccc' }}
            >
              <i className="fa-light fa-circle-info text-primary mb-1"></i>
              <p className="mb-2">Hãy thêm tuyến đường để in sẵn lên mẫu vé</p>
              {/* <button className="btn btn-add-route w-100">
                <i
                  className="fa-regular fa-plus mb-1"
                  style={{ color: '#5099ef' }}
                ></i>
                Thêm tuyến đường
              </button> */}
            </div>
          </div>
        )}

        {routeType == 'MULTIPLE' && (
          <div
            className="d-flex flex-column align-items-center p-5"
            style={{ border: '1px dashed #ccc' }}
          >
            <i className="fa-light fa-circle-info text-primary mb-1"></i>
            <p className="mb-2">
              Quản lý danh sách tuyến đường, thời gian khởi hành tại
            </p>
            <a className="text-decoration-none">"Danh mục/Tuyến đường"</a>
          </div>
        )}
      </div>
    </div>
  )
}

export default RouteControl
