import invoiceApi from 'api/invoiceApi'
import { setAppSpinning, showNotification } from 'app/appSlice'
import { useMutation, useQuery } from '@tanstack/react-query'
import { List, Space, Tooltip } from 'antd'
import {
  setClearRows,
  setModalsState,
  setSelectedInvoices,
} from 'features/Invoice/invoiceSlice'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import TableAction from 'general/components/GroupButton/TableAction'
import { IconAction } from 'general/components/GroupButton/styles'
import ModalUnauthorized from 'general/components/Modal/ModalUnauthorized'
import {
  INVOICE_STATUS,
  INVOICE_TYPE,
  MESSAGE_SUCCESS,
} from 'general/constants/AppConstants'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import SelectOptions from 'general/constants/SelectOptions'
import DownloadInvoicesHelper from 'general/helpers/DownloadInvoicesHelper'
import InvoiceHelper from 'general/helpers/InvoiceHelper'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useGetSerials from 'hooks/Queries/useGetSerials'
import useAppDnD from 'hooks/useAppDnD'
import useFilter from 'hooks/useFilter'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import useRouter from 'hooks/useRouter'
import useSearchDebounce from 'hooks/useSearchDebounce'
import { get, includes, isArray, isEmpty, map, padStart, uniq } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual } from 'react-redux'
import './style.scss'
import ModalSelectReportingPeriod from './components/ModalSelectReporttingPeriod'
import dayjs from 'dayjs'

function DataSummaryTable() {
  // MARK --- Parmas: ---
  const { navigate } = useRouter()
  const dispatch = useAppDispatch()
  const { listFunctions, userFunctions } = useAppSelector(
    (state) => state?.function,
    shallowEqual,
  )
  const { activeSigningMethod } = useAppSelector(
    (state) => state.system.company,
  )
  const [
    isViewDataSummaryTable,
    isAddDataSummaryTable,
    isEditDataSummaryTable,
    isDeleteDataSummaryTable,
  ] = useMemo(() => {
    return Utils.checkFunction(
      [
        { listFunctionCode: 'LIST_INVOICE_SEND_AUT_VIEW_LIST' },
        { listFunctionCode: 'LIST_INVOICE_SEND_AUT_ADD' },
        { listFunctionCode: 'LIST_INVOICE_SEND_AUT_EDIT' },
        { listFunctionCode: 'LIST_INVOICE_SEND_AUT_DELETE' },
      ],
      userFunctions,
      listFunctions,
    )
  }, [userFunctions, listFunctions])

  const [filterParams, setFilterParams] = useFilter(
    'invoiceList',
    'gFiltersInvoiceList',
  )
  const { company } = useAppSelector((state) => state?.system)
  const { toggledClearRows, modalStates } = useAppSelector(
    (state) => state.invoice,
    shallowEqual,
  )

  const [activeInvoice, setActiveInvoice] = useState(null)
  const [handleMultipleInvoices, setHandleMultipleInvoices] = useState(false)
  const [isInvoiceAdjust, setIsInvoiceAdjust] = useState(false)
  const [showModalSelectReportingPeriod, setShowModalSelectReportingPeriod] =
    useState(false)

  const { data: serials } = useGetSerials()
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const {
    data: invoiceListData,
    isLoading: gettingListData,
    refetch: fetchInvoiceList,
  } = useQuery({
    queryKey: ['invoiceListData', filterParams, debouncedSearch],
    queryFn: () =>
      invoiceApi.listAll({
        ...filterParams,
        q: debouncedSearch,
      }),
    select: (res) => {
      const dataInvoice = res?.rows?.map((row, index) => ({
        ...row,
        id: index + 1,
        no: row.no ? InvoiceHelper.padNumber(row.no, 8) : null,
      }))
      return {
        total: get(res, 'count', 0),
        tableData: dataInvoice,
        statistics: get(res, 'statistics', {}),
      }
    },
  })

  const { mutate: updateInvoicePaidStatus, isPending } = useMutation({
    mutationKey: ['updateInvoicePaidStatus'],
    mutationFn: ({ invoiceId, isPaid }) =>
      invoiceApi.update(invoiceId, { isPayment: isPaid }),
    onSuccess: () => {
      ToastHelper.showSuccess('Cập nhật trạng thái thanh toán thành công')
      void fetchInvoiceList()
    },
  })

  const hsmSignInvoiceMutation = useMutation({
    mutationKey: ['hsmSignInvoice'],
    mutationFn: (params) =>
      invoiceApi.hsmInvoicesSign({
        invoiceIds: isArray(params) ? params : [params],
      }),
    onMutate: () => dispatch(setAppSpinning(true)),
    onError: () => dispatch(setAppSpinning(false)),
    onSuccess: (res, params) => {
      dispatch(setAppSpinning(false))
      if (res.data) res = res.data

      // Kí hàng loạt
      if (isArray(params)) {
        fetchInvoiceList()

        let { errorSignInvoices, successSignInvoices } = res
        let describeText = `Hóa đơn kí thành công: <ul class="mb-0">${successSignInvoices
          .map(
            ({ no, serial }) =>
              `<li>Hóa đơn ký hiệu: ${serial}, số: ${no}</li>`,
          )
          .join('')}</ul>
                            Hóa đơn ký thất bại: <ul class="mb-0">${errorSignInvoices
                              .map(
                                ({ no, serial, reason }) =>
                                  `<li>Hóa đơn ký hiệu: ${serial}, số: ${no}.</br> Lí do ký lỗi: ${reason}</li>`,
                              )
                              .join('')}</ul>`
        dispatch(
          showNotification({
            title: 'Thông báo',
            describeText,
            icon: 'fa-light fa-circle-info text-primary',
          }),
        )
      } else {
        /* Kí một hóa đơn */
        // Kí thành công
        if (
          !!res?.successSignInvoices?.find(
            ({ invoiceId }) => invoiceId == params,
          )
        ) {
          ToastHelper.showSuccess(MESSAGE_SUCCESS.ISSUED_INVOICE)
          // reload page
          fetchInvoiceList()
          return
        }
        // Kí thất bại
        ToastHelper.showError(res?.errorSignInvoices?.at(0)?.reason)
      }
    },
  })

  useEffect(() => {
    fetchInvoiceList()
  }, [fetchInvoiceList])

  const downloadInvoices = useCallback(
    async (passedInvoices) => {
      dispatch(setAppSpinning(true))
      let invoicesToDownLoad = passedInvoices

      invoicesToDownLoad = Array.isArray(invoicesToDownLoad)
        ? invoicesToDownLoad
        : [invoicesToDownLoad]
      try {
        const invoiceIdsToDownload = invoicesToDownLoad
          .filter(
            (invoice) =>
              (invoice.issueStatus === INVOICE_STATUS.AUTHORITY_ACCEPT ||
                invoice.issueStatus === INVOICE_STATUS.AUTHORITY_CODE_GIVEN) &&
              invoice.type !== INVOICE_TYPE.CANCELLED &&
              invoice.type !== INVOICE_TYPE.REPLACED,
          )
          .map((invoice) => invoice.invoiceId)
        if (invoiceIdsToDownload.length === 0) {
          ToastHelper.showError('Các hoá đơn đã chọn không thể download')

          dispatch(setAppSpinning(false))
          return
        }
        await DownloadInvoicesHelper({ invoiceIds: invoiceIdsToDownload })
      } catch (err) {
        console.log(err)
        ToastHelper.showError('Có lỗi xảy ra khi tải')
      } finally {
        dispatch(setAppSpinning(false))
        dispatch(
          setModalsState({
            modalName: 'download',
            status: false,
          }),
        )
      }
    },
    [dispatch],
  )

  const signInvoices = async (passedInvoices) => {
    dispatch(setAppSpinning(true))
    let invoicesToSign = passedInvoices

    invoicesToSign = Array.isArray(invoicesToSign)
      ? invoicesToSign
      : [invoicesToSign]
    try {
      const invoiceIdsToSign = invoicesToSign
        .filter(
          (invoice) =>
            invoice.issueStatus === INVOICE_STATUS.NOT_SENT_TO_AUTHORITY,
        )
        .map((invoice) => invoice.invoiceId)
        .sort((a, b) => a - b)
      if (invoiceIdsToSign.length === 0) {
        ToastHelper.showError('Các hoá đơn đã chọn không thể phát hành')

        dispatch(setAppSpinning(false))
        return
      }

      if (activeSigningMethod == 'HSM') {
        hsmSignInvoiceMutation.mutate(invoiceIdsToSign)
      } else {
        let accessToken = localStorage.getItem(PreferenceKeys.accessToken)
        let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},${company?.taxCode},`
        invoiceIdsToSign.map((invoiceId, index) => {
          if (index + 1 === invoiceIdsToSign.length) {
            urlSchema = urlSchema + `${invoiceId}`
          } else {
            urlSchema = urlSchema + `${invoiceId};`
          }
        })
        Utils.openToolSignInvoice(urlSchema, accessToken)
      }
    } catch (err) {
      console.log(err)
      ToastHelper.showError('Có lỗi xảy ra khi tải')
    } finally {
      dispatch(setAppSpinning(false))
      dispatch(
        setModalsState({
          modalName: 'sign',
          status: false,
        }),
      )
    }
  }

  const fetchTaxAuthorityMessage = useCallback(
    async (messageId) => {
      try {
        const res = await invoiceApi.checkMessage(messageId)
        if (res?.result === 'success') {
          fetchInvoiceList()
        }
      } catch (err) {
        console.error(err)
      }
    },
    [fetchInvoiceList],
  )

  const renderInvoiceType = (type) => {
    switch (type) {
      case 1:
        return <div className="badge badge-light-success">Thay thế</div>
      case 2:
        return <div className="badge badge-light-success">Điều chỉnh</div>
      case 3:
        return <div className="badge badge-light-danger">Đã huỷ</div>
      case 4:
        return <div className="badge badge-light-danger">Đã bị thay thế</div>
      case 5:
        return <div className="badge badge-light-danger">Đã bị điều chỉnh</div>

      default:
        return <div className="badge badge-light-primary">Hoá đơn gốc</div>
    }
  }

  const renderIssueStatus = useCallback(
    (invoice) => {
      let { issueStatus } = invoice
      switch (issueStatus) {
        case INVOICE_STATUS.AUTHORITY_CODE_GIVEN:
          return <div className="badge badge-light-primary">Đã cấp mã</div>
        case INVOICE_STATUS.AUTHORITY_CODE_NOT_GIVEN:
          return (
            <Tooltip title="Xem lý do từ chối">
              <div
                className="badge badge-light-danger"
                onClick={() => {
                  setActiveInvoice(invoice)
                  dispatch(
                    setModalsState({
                      modalName: 'transmission',
                      status: true,
                    }),
                  )
                }}
              >
                <u>Từ chối cấp mã</u>
              </div>
            </Tooltip>
          )
        case INVOICE_STATUS.NOT_SENT_TO_AUTHORITY:
          return <div className="badge badge-light-success">Chưa phát hành</div>
        case INVOICE_STATUS.WAITING_FOR_AUTHORITY:
          return <div className="badge badge-light-warning">Chờ CQT xử lý</div>
        case INVOICE_STATUS.AUTHORITY_ACCEPT:
          return (
            <div className="badge badge-light-primary">CQT xác nhận hợp lệ</div>
          )
        case INVOICE_STATUS.AUTHORITY_DENY:
          return <div className="badge badge-light-danger">CQT từ chối</div>
        default:
        // return <div className="label label-lg label-outline-primary label-inline">Hoá đơn gốc</div>;
      }
    },
    [dispatch],
  )
  const renderPayment = (isPayment) => {
    switch (isPayment) {
      case null:
        return <div className="badge badge-light-danger">Chưa thanh toán</div>
      case false:
        return <div className="badge badge-light-danger">Chưa thanh toán</div>
      case true:
        return <div className="badge badge-light-success">Đã thanh toán</div>
      default:
    }
  }

  const viewPdfInvoice = useCallback(
    async (row) => {
      let invoiceId = row?.invoiceId
      if (!invoiceId) return

      dispatch(setAppSpinning(true))
      try {
        var res = await invoiceApi.exportInvoiceById(invoiceId)
        if (!res) throw new Error('Response is undefined or null')
        let newFile = new File([res], 'Hóa đơn.pdf', {
          type: 'application/pdf',
        })
        var fileURL = URL.createObjectURL(newFile)
        window.open(fileURL, '_blank')
      } catch (e) {
        console.log(e)
      } finally {
        dispatch(setAppSpinning(false))
      }
    },
    [dispatch],
  )

  const defaultColumns = useMemo(
    () => [
      {
        name: 'Ký hiệu',
        width: '110px',
        center: true,
        omit: false,
        cell: (row) => {
          return (
            <p
              style={{
                textAlign: 'center',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
            >
              {row?.serial}
            </p>
          )
        },
      },
      {
        name: 'Số',
        width: '110px',
        center: true,
        omit: false,
        cell: (row) => {
          return (
            <p
              style={{
                textAlign: 'center',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
            >
              {row?.no}
            </p>
          )
        },
      },
      {
        name: 'Ngày hoá đơn',
        width: '150px',
        center: true,
        omit: false,
        cell: (row) => {
          const date = dayjs(row?.date)
          return (
            <p
              style={{
                textAlign: 'center',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
            >
              {date.isValid() ? date.format('L') : ''}
            </p>
          )
        },
      },
      {
        name: 'HĐ liên kết',
        grow: 1,
        center: true,
        cell: (row) =>
          isEmpty(row?.Related) ? null : (
            <List
              itemLayout="vertical"
              dataSource={row?.Related}
              style={{
                padding: 0,
              }}
              renderItem={(item) =>
                item?.issueStatus !== INVOICE_STATUS.AUTHORITY_ACCEPT ||
                item?.issueStatus !==
                  INVOICE_STATUS.AUTHORITY_CODE_GIVEN ? null : (
                  <List.Item onClick={() => viewPdfInvoice(item)}>
                    <Tooltip title={'Xem hoá đơn số ' + item?.no}>
                      <p className="m-0">
                        <a href="#">{padStart(item?.no, 8, '0')}</a>
                      </p>
                    </Tooltip>
                  </List.Item>
                )
              }
            />
          ),
      },
      {
        name: 'Khách hàng',
        center: true,
        width: '200px',
        omit: false,
        cell: (row) => {
          return (
            <p
              style={{
                textAlign: 'left',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 w-100 font-weight-normal m-0 text-maxline-3 "
            >
              {row?.customerCompanyName}
            </p>
          )
        },
      },
      {
        name: 'Thanh toán',
        width: '150px',
        center: true,
        omit: false,
        cell: (row) => {
          return (
            <div className="w-100 align-items-center justify-content-center">
              {renderPayment(row?.isPayment)}
            </div>
          )
        },
      },
      {
        name: 'Tiền thuế',
        omit: false,
        width: '120px',
        center: true,
        cell: (row) => (
          <p
            data-tag="allowRowEvents"
            style={{
              textAlign: 'right',
            }}
            className="text-dark-75 m-0 text-maxline-3 w-100"
          >
            {Utils.formatNumber(row?.taxMoney)}
          </p>
        ),
      },
      {
        name: 'Tổng tiền trước thuế',
        omit: false,
        width: '120px',
        center: true,

        cell: (row) => (
          <p
            style={{
              textAlign: 'right',
            }}
            data-tag="allowRowEvents"
            className="text-dark-75 m-0 text-maxline-3 w-100"
          >
            {Utils.formatNumber(row?.total)}
          </p>
        ),
      },
      {
        name: 'Tổng tiền thanh toán',
        center: true,
        omit: false,
        width: '120px',
        cell: (row) => {
          return (
            <p
              style={{
                textAlign: 'right',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 m-0 text-maxline-3 w-100"
            >
              {Utils.formatNumber(row?.totalAfterTax)}
              {/* {row?.currency} */}
            </p>
          )
        },
      },
      {
        name: 'Loại hoá đơn',
        center: true,
        omit: false,
        width: '120px',
        cell: (row) => {
          return (
            <div className="w-100 align-items-center justify-content-center">
              {renderInvoiceType(row?.type)}
            </div>
          )
        },
      },
      {
        name: 'Trạng thái phát hành',
        width: '150px',
        center: true,
        omit: false,
        cell: (row) => {
          return (
            <div className="w-100 align-items-center justify-content-center">
              {renderIssueStatus(row)}
            </div>
          )
        },
      },
      {
        name: 'Mã CQT',
        center: true,
        omit: false,
        width: '24.25rem',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 "
            >
              {row?.taxAuthorityCode}
            </p>
          )
        },
      },
      {
        name: 'Mã tra cứu',
        center: true,
        omit: false,
        width: '150px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 "
            >
              {row?.lookupCode}
            </p>
          )
        },
      },
      {
        name: 'Trạng thái gửi mail',
        center: true,
        cell: (row) => (
          <IconAction
            className={
              row?.isSent === 0
                ? 'fa-duotone fa-do-not-enter text-light-dark'
                : 'fa-duotone fa-circle-check'
            }
            style={
              row?.isSent !== 0
                ? {
                    '--fa-primary-color': '#fff',
                    '--fa-secondary-color': '#50cd89',
                    '--fa-secondary-opacity': 1,
                  }
                : {}
            }
          />
        ),
      },
      {
        name: 'Hành động',
        center: true,
        compact: true,
        disabled: true,
        grow: 3,
        style: {
          justifyContent: 'flex-end',
        },
        cell: (row) => (
          <Space size={'3px'} align="center">
            <TableAction
              titleActionText="Xem"
              icon={<IconAction className="fa-regular fa-eye" />}
              onClick={() => viewPdfInvoice(row)}
            />
            {get(row, 'issueStatus') ===
            INVOICE_STATUS.NOT_SENT_TO_AUTHORITY ? (
              <TableAction
                titleActionText="Cập nhật"
                icon={<IconAction className="fa-regular fa-pen-to-square" />}
                onClick={() => {
                  !isEditDataSummaryTable
                    ? dispatch(
                        setModalsState({
                          modalName: 'noRight',
                          status: true,
                        }),
                      )
                    : navigate(
                        `/hoa-don-dau-ra/hoa-don/cap-nhat/${row?.invoiceId}`,
                      )
                }}
              />
            ) : null}

            {/* Dropdown */}
            <TableAction
              type="dropdown"
              icon={<IconAction className="fa-regular fa-ellipsis" />}
              items={[
                row?.issueStatus === INVOICE_STATUS.WAITING_FOR_AUTHORITY && {
                  label: 'Kiểm tra trạng thái',
                  icon: <IconAction className="fa-solid fa-sync" />,
                  onClick: () => fetchTaxAuthorityMessage(row?.messageId),
                },

                { type: 'divider' },
              ]}
            />
          </Space>
        ),
      },
    ],
    [
      company?.taxCode,
      dispatch,
      downloadInvoices,
      fetchTaxAuthorityMessage,
      isAddDataSummaryTable,
      isEditDataSummaryTable,

      navigate,
      renderIssueStatus,
      updateInvoicePaidStatus,
      viewPdfInvoice,
    ],
  )
  const defaultValues = [
    {
      label: 'Ký hiệu',
      alias: 'Ký hiệu',
      formName: 'serials',
      options: serials,
      value: isEmpty(serials) ? null : serials[0]?.value ?? null,
      colSpan: 24,
      multiselect: true,
    },
    {
      label: ['Hoá đơn từ số', 'Đến số'],
      formName: ['fromNumberInvoice', 'toNumberInvoice'],
      alias: 'Số hoá đơn: Từ',
      value: [null, null],
      tips: 'Chỉ sử dụng được bộ lọc số hóa đơn khi chọn một Ký hiệu. Vui lòng nhập số hóa đơn sau khi đã loại bỏ số 0 ở đầu (VD: 1, 2, 3...) để lọc.',
      restrict: {
        type: 'number',
        min: 1,
        max: 99999999,
      },
    },
    {
      label: 'Trạng thái phát hành',
      alias: 'Trạng thái HĐ',
      formName: 'issueStatus',
      options: SelectOptions.issueStatus,
      value: SelectOptions.issueStatus[0].value,
    },
    {
      label: 'Loại hoá đơn',
      alias: 'Loại HĐ',
      formName: 'types',
      options: SelectOptions.invoiceType,
      value: SelectOptions.invoiceType[0].value,
    },
    {
      label: 'Trạng thái gửi hoá đơn',
      alias: 'Trạng thái gửi HĐ',
      formName: 'isSent',
      options: SelectOptions.isSent,
      value: SelectOptions.isSent[0].value,
      colSpan: 24,
    },
  ]

  const dynamicColumns = useAppDnD(defaultColumns)

  const [selected, setSelected] = useState([])

  useEffect(() => {
    dispatch(setSelectedInvoices(selected))
  }, [dispatch, selected])

  return (
    <ContentContainer
      wrapperClassName="list-invoice"
      //   tabs={<InvoiceDashboard statistics={invoiceListData?.statistics} />}
    >
      <ContentContainer.Header
        titleContent={
          'Báo cáo tổng hợp dữ liệu hóa đơn điện tử gửi cơ quan thuế'
        }
        toolBar={
          <Space>
            <CustomAntButton
              text="Lập bảng tổng hợp"
              antProps={{
                type: 'primary',
                onClick: () =>
                  isAddDataSummaryTable
                    ? setShowModalSelectReportingPeriod(true)
                    : dispatch(
                        setModalsState({
                          modalName: 'noRight',
                          status: true,
                        }),
                      ),
              }}
              iconCn={'fa-regular fa-plus text-white'}
            />
            <AppDnD
              defaultColumns={defaultColumns}
              localStorageName={PreferenceKeys.savedColumnsInvoice}
            />
          </Space>
        }
      >
        <Space align="center" size={'middle'}>
          <FilterPopover
            menuPosition="bottomLeft"
            defaultValues={defaultValues}
            savedKey={'gFiltersInvoiceList'}
            onApply={setFilterParams}
          />
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={dynamicColumns}
          dataSource={invoiceListData?.tableData}
          isLoading={gettingListData || isPending}
          isClickable
          handleDoubleClickRow={(row) => viewPdfInvoice(row)}
          pagination={{
            total: invoiceListData?.total,
            rowsPerPage: filterParams.limit,
            currentPage: filterParams.page,
            onChangePage: (page) => {
              setFilterParams({
                page,
              })
            },
            onChangeRowsPerPage: (limit) => {
              setFilterParams({
                limit,
                page: 1,
              })
            },
          }}
          selectedRows={{
            clearSelectedRows: toggledClearRows,
            handleSelectedRows: ({ selectedRows }) => setSelected(selectedRows),
          }}
        />
      </ContentContainer.Body>

      <ModalSelectReportingPeriod
        show={showModalSelectReportingPeriod}
        setShow={setShowModalSelectReportingPeriod}
      />

      <ModalUnauthorized
        show={modalStates.noRight}
        setShow={(status) =>
          dispatch(
            setModalsState({
              modalName: 'noRight',
              status,
            }),
          )
        }
      />
    </ContentContainer>
  )
}

export default DataSummaryTable
