import { Button, Table } from 'antd'
import Utils from 'general/utils/Utils'
import { isNil } from 'lodash'
/**
 *
 * @param {Object} props
 * @param {import('antd/es/table').ColumnsType<any>} props.columns - Column of the table
 * @param {Array} props.dataSource - Data of the table
 * @param {boolean} [props.isLoading] - Loading state of the table
 * @param {import('antd').TableProps['scroll']} [props.scroll] - Fixed header height
 * @param {{total: number,
 *  rowsPerPage: number,
 *  currentPage: number,
 *  onChangePage: (page: number) => void,
 *  onChangeRowsPerPage: (rowsPerPage: number) => void
 * }} props.pagination - Pagination setting for the table
 *
 * @param {string} [props.rowClassName]
 * @param {string} [props.rootClassName]
 * @param
 */
export default function CustomAntTable(props) {
  const {
    columns,
    dataSource,
    isLoading,
    scroll,
    pagination,
    rowClassName,
    rootClassName,
  } = props

  return (
    <>
      <Table
        sticky
        rowClassName={rowClassName}
        rootClassName={Utils.cn('h-100 flex-grow-1', rootClassName)}
        columns={columns}
        scroll={scroll}
        dataSource={dataSource}
        bordered
        loading={{
          spinning: isLoading,
          delay: 200,
          indicator: (
            <span className="spinner spinner-loader spinner-primary"></span>
          ),
          tip: 'Loading...',
        }}
        pagination={
          isNil(pagination)
            ? false
            : {
                pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
                position: ['bottomRight'],
                responsive: true,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  Utils.cn(
                    'Hiển thị từ',
                    range[0],
                    'đến',
                    range[1],
                    ' của',
                    total,
                  ),
                nextIcon: (
                  <NavigateButton
                    icon={<i className="fa-duotone fa-angle-right"></i>}
                  />
                ),
                prevIcon: (
                  <NavigateButton
                    icon={<i className="fa-duotone fa-angle-left"></i>}
                  />
                ),
                jumpNextIcon: (
                  <NavigateButton
                    icon={<i className="fa-duotone fa-angle-double-right"></i>}
                  />
                ),
                jumpPrevIcon: (
                  <NavigateButton
                    icon={<i className="fa-duotone fa-angle-double-left"></i>}
                  />
                ),

                current: pagination?.page,
                pageSize: pagination?.limit,
                total: pagination?.total,
                onShowSizeChange: (_current, size) =>
                  pagination?.onChangeRowsPerPage(size),
                onChange: (page) => pagination?.onChangePage(page),
              }
        }
      />
      {/* {pagination?.total > 0 && (
        <div className="d-flex align-items-center justify-content-center">
          <Pagination
            rowsPerPage={pagination.limit}
            rowCount={pagination?.total}
            currentPage={pagination.page}
            onChangePage={pagination.onChangePage}
            onChangeRowsPerPage={pagination.onChangeRowsPerPage}
          />
        </div>
      )} */}
    </>
  )
}

const NavigateButton = ({ icon }) => {
  return (
    <Button
      type="default"
      icon={icon}
      className="btn btn-icon btn-light btn-hover-primary btn-sm"
    />
  )
}
