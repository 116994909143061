import { useLocalStorage } from '@uidotdev/usehooks'
import { Typography } from 'antd'
import { Popover, Space, theme } from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { PERIOD } from 'general/constants/AppConstants'
import SelectOptions from 'general/constants/SelectOptions'
import { transformToStartAndEnd } from 'general/helpers/DateHelper'
import Utils from 'general/utils/Utils'
import useKeyboardPress from 'hooks/useKeyboardPress'
import { assign, flatMap, isArray, isEmpty, map, omit } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import CustomAntButton from '../Button/CustomAntButton'
import PopoverContent from './Components/FilterContent'
import FilterSummaryList from './Components/FilterSummaryList'
import { FilterTitleWrapper } from './styles'
import { useAppSelector } from 'hooks/useRedux'
import { shallowEqual } from 'react-redux'

const dateOptions = SelectOptions.periodFilterOptions

/**
 * @param {Object} props - The component props.
 * @param {FilterDefaultValueProp[]} [props.defaultValues] - An array of objects representing default values for a form.
 * @param {Function} props.onApply - The function to be called when the user applies the filters.
 * @param {import('antd').PopoverProps['placement']} [props.menuPosition] - The position of the menu.
 * @param {HTMLElement} [props.summaryListNode] - The node to render the summary list.
 * @param {string} props.savedKey - The key which will be saved in localstorage.
 * @param {string | number} [props.menuWidth] - The width of the menu.
 * @param {boolean} [props.canSelectTime] - The flag to enable the time selection. Default is false.
 * @returns {JSX.Element}
 */
export default function FilterPopover(props) {
  const {
    defaultValues,
    onApply,
    menuPosition,
    summaryListNode,
    savedKey,
    menuWidth,
    canSelectTime = false,
    title,
  } = props
  const { token } = theme.useToken()
  const [popoverIsOpen, setPopoverIsOpen] = useState(false)
  const [savedItems] = useLocalStorage(savedKey, null)
  const [defaultInit] = useState(() => {
    if (isEmpty(defaultValues)) return []
    return flatMap(defaultValues, ({ formName, value }) => {
      if (isArray(formName)) {
        return map(formName, (name, idx) => ({
          [name]: value[idx],
        }))
      }
      return { [formName]: value }
    })
  })

  const [initialValues, setInitValues] = useState(() => {
    let result = savedItems ?? assign({}, ...defaultInit)
    result = omit(result, 'q')

    if (result.period === PERIOD.CUSTOM) {
      return {
        ...result,
        startDate: result.startDate
          ? dayjs(result.startDate).startOf('d')
          : transformToStartAndEnd('THIS_MONTH')[0],
        endDate: result.endDate
          ? dayjs(result.endDate).endOf('d')
          : transformToStartAndEnd('THIS_MONTH')[1],
      }
    }
    const period = result.period ?? PERIOD.THIS_MONTH
    const dateRange = transformToStartAndEnd(period)
    return {
      ...result,
      startDate: dateRange[0],
      endDate: dateRange[1],
      period,
    }
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      onApply({
        ...values,
        startDate: values.startDate?.format(),
        endDate: values.endDate?.format(),
        page: 1,
      })
      setClonedValues(values)
      setPopoverIsOpen(false)
    },
  })

  const { submitForm } = formik

  const [clonedValues, setClonedValues] = useState(formik.values) // lưu giá trị thay đôi trước khi lưu

  const handleTogglePopover = () => {
    setPopoverIsOpen((prev) => !prev)
    //Cancel thay đổi khi đóng popover
    formik.resetForm({
      values: clonedValues,
    })
  }

  const handleCloseTag = (tagName, tagValue) => {
    const hasMultiple = isArray(tagName)
    if (hasMultiple) {
      map(tagName, (name) => formik.setFieldValue(name, tagValue))
    } else {
      formik.setFieldValue(tagName, tagValue)
    }
    formik.submitForm()
  }

  const resetDefault = useCallback(() => {
    const dateRange = transformToStartAndEnd(PERIOD.THIS_MONTH)
    setInitValues({
      ...assign({}, ...defaultInit),
      startDate: dateRange[0],
      endDate: dateRange[1],
      period: PERIOD.THIS_MONTH,
    })
  }, [defaultInit])

  useKeyboardPress('escape', () => setPopoverIsOpen(false))

  useEffect(
    function submitSavedFormAtFirstTime() {
      submitForm()
    },
    [submitForm],
  )

  // Reset dữ liệu các ô input khi mới vào hoặc thoát component
  // useEffect(() => {
  //   resetDefault()
  //   return () => {
  //     resetDefault()
  //   }
  // }, [defaultValues, resetDefault])

  const systemType = useAppSelector((s) => s.auth.activeInvoice, shallowEqual)

  return (
    <Space>
      <Popover
        trigger="click"
        getPopupContainer={(trigger) => trigger.parentElement}
        open={popoverIsOpen}
        onOpenChange={handleTogglePopover}
        placement={menuPosition ?? 'bottom'}
        arrow={false}
        overlayInnerStyle={{
          width: menuWidth ?? '40rem',
          borderRadius: token.borderRadiusLG,
        }}
        title={
          <FilterTitleWrapper>
            <Typography.Title
              level={4}
              style={{ fontWeight: 600, marginBottom: 0 }}
            >
              {title ?? (systemType?.ticket ? 'Lọc vé' : 'Lọc hóa đơn')}
            </Typography.Title>
            <CustomAntButton
              iconCn="fa-regular fa-times"
              antProps={{
                type: 'text',
                onClick: handleTogglePopover,
              }}
              customStyle={{
                minWidth: 'unset',
                borderRadius: '99999px',
              }}
            />
          </FilterTitleWrapper>
        }
        content={
          <PopoverContent
            defaultValues={defaultValues}
            onReset={resetDefault}
            canSelectTime={canSelectTime}
            formikProps={formik}
          />
        }
      >
        <CustomAntButton
          text="Lọc"
          iconCn={Utils.cn('fa-duotone fa-filter-list fa-lg')}
          antProps={{
            type: 'default',
            onClick: handleTogglePopover,
          }}
          customStyle={{
            boxShadow: 'none',
            gap: '5px',
            minWidth: 'fit-content',
            color: popoverIsOpen && token.colorPrimary,
            borderColor: popoverIsOpen && token.colorPrimaryBorder,
          }}
        />
      </Popover>
      {summaryListNode ? (
        createPortal(
          <FilterSummaryList
            popoverIsOpen={popoverIsOpen}
            defaultValues={defaultValues}
            values={clonedValues}
            handleCloseTag={handleCloseTag}
            canSelectTime={canSelectTime}
          />,
          summaryListNode,
        )
      ) : (
        <FilterSummaryList
          popoverIsOpen={popoverIsOpen}
          defaultValues={defaultValues}
          values={clonedValues}
          handleCloseTag={handleCloseTag}
          canSelectTime={canSelectTime}
        />
      )}
    </Space>
  )
}
