import { useQuery } from '@tanstack/react-query'
import { useLocalStorage } from '@uidotdev/usehooks'
import { Col, DatePicker, Popover, Row, Tag, Tooltip, Typography } from 'antd'
import localeVi from 'antd/lib/date-picker/locale/vi_VN'
import dayjs from 'dayjs'
import { goodsQueries } from 'features/Category/screens/GoodsScreen/queries'
import { useFormik } from 'formik'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import CustomAntSelect from 'general/components/CustomAntSelect'
import {
  FilterContentWrapper,
  FilterFooter,
  FilterTitleWrapper,
} from 'general/components/CustomPopover/styles'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import { PERIOD } from 'general/constants/AppConstants'
import SelectOptions from 'general/constants/SelectOptions'
import { transformToStartAndEnd } from 'general/helpers/DateHelper'
import Utils, { findObjectInArray } from 'general/utils/Utils'
import ValidationSchema from 'general/utils/ValidationSchema'
import useFilter from 'hooks/useFilter'
import useKeyboardPress from 'hooks/useKeyboardPress'
import useToken from 'hooks/useToken'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { transactionSelect } from './constant'
// import { transactionQueries } from '../queries'
import { useAppSelector } from 'hooks/useRedux'
import { shallowEqual } from 'react-redux'
import { CloseOutlined } from '@ant-design/icons'

export default function CustomFilter({ filterParams, setFilterParams }) {
  const { t } = useTranslation()
  const { token } = useToken()
  const [isOpen, setIsOpen] = useState(false)
  const [status, setStatus] = useState(null)
  const [savedItems] = useLocalStorage('gFilterListTransactions', null)
  const [initialValues] = useState(() => {
    let result = savedItems ?? filterParams
    result = _.omit(result, 'q')
    if (result.period === PERIOD.CUSTOM) {
      return {
        ...result,
        startDate: result.startDate
          ? dayjs(result.startDate).startOf('d')
          : transformToStartAndEnd('THIS_MONTH')[0],
        endDate: result.endDate
          ? dayjs(result.endDate).endOf('d')
          : transformToStartAndEnd('THIS_MONTH')[1],
      }
    }
    const period = result.period ?? PERIOD.THIS_MONTH
    const dateRange = transformToStartAndEnd(period)
    return {
      ...result,
      startDate: dateRange[0],
      endDate: dateRange[1],
      period: period,
    }
  })

  useEffect(() => {
    if (isOpen) {
      setStatus(filterParams.status)
    }
  }, [isOpen])

  const { submitForm, ...formik } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      setFilterParams({
        ...values,
        startDate: values.startDate?.format(),
        endDate: values.endDate?.format(),
        page: 1,
      })
      setClonedValues(values)
      setIsOpen(false)
    },
    validationSchema: ValidationSchema.transactionFilterSchema,
  })

  const [clonedValues, setClonedValues] = useState(formik.values) // lưu giá trị thay đôi trước khi lưu

  const { data: goodsOptions } = useQuery(
    goodsQueries.dataLogGoods({
      limit: 50,
      page: 0,
    }),
  )

  const handleTogglePopover = () => {
    setIsOpen((prev) => !prev)
    //Cancel thay đổi khi đóng popover
    formik.resetForm({
      values: clonedValues,
    })
  }

  const resetDefault = () => {
    formik.resetForm()
  }

  const renderTag = (alias, tagName, label, value) => {
    if (_.isNil(value)) return null
    if (_.every(value, _.isNil)) return null
    const handleCloseTag = (_tagName, tagValue) => {
      formik.setFieldValue(_tagName, tagValue)
      submitForm()
    }

    return (
      <Tag
        prefixCls="summary-filter"
        closable
        onClose={() => {
          if (tagName === 'stationCode') {
            formik.setFieldValue('nozzleCode', null)
          }
          if (_.isArray(tagName)) {
            _.forEach(tagName, (tag) => {
              handleCloseTag(tag, initialValues[tag])
            })
          } else handleCloseTag(tagName, initialValues[tagName])
        }}
        bordered={false}
        closeIcon={<i className="fa-solid fa-times text-danger fa-lg ml-2"></i>}
        className="badge badge-light text-info w-100 d-inline-flex align-items-baseline"
      >
        <Typography.Text strong type="secondary" className="fs-6 mr-1">
          {_.upperFirst(alias)}:
        </Typography.Text>
        <Typography.Text
          style={{
            color: 'inherit',
            fontSize: '12px',
            maxWidth: '12rem',
          }}
          ellipsis={{
            tooltip: {
              destroyTooltipOnHide: true,
              title: label,
              placement: 'topRight',
            },
          }}
        >
          {label}
        </Typography.Text>
      </Tag>
    )
  }

  useKeyboardPress('escape', () => setIsOpen(false))

  useEffect(() => {
    submitForm()
  }, [submitForm])

  const systemType = useAppSelector((s) => s.auth.activeInvoice, shallowEqual)

  return (
    <div
      className="d-flex align-items-center gap-3 w-100"
      id="summary-filter-container"
    >
      <Popover
        trigger="click"
        placement="bottom"
        open={isOpen}
        onOpenChange={handleTogglePopover}
        getPopupContainer={(trigger) => trigger.parentElement}
        destroyTooltipOnHide
        arrow={false}
        overlayInnerStyle={{
          width: '50rem',
          borderRadius: token.borderRadiusLG,
        }}
        content={
          <section>
            <FilterTitleWrapper>
              <Typography.Title
                level={4}
                style={{ fontWeight: 600, marginBottom: 0 }}
              >
                Lọc chứng từ
              </Typography.Title>
              <CustomAntButton
                iconCn="far fa-times"
                antProps={{
                  type: 'text',
                  onClick: handleTogglePopover,
                }}
                customStyle={{
                  minWidth: 'unset',
                  borderRadius: '99999px',
                }}
              />
            </FilterTitleWrapper>
            <FilterContentWrapper onSubmit={formik.handleSubmit}>
              <Row gutter={[13, 13]} align="middle">
                <Col span={10}>
                  <Typography.Text strong>{t('Period')}</Typography.Text>
                  <CustomAntSelect
                    notFoundContent="Không tìm thấy kỳ cần tìm"
                    placeholder="Chọn hoặc tìm kiếm"
                    options={SelectOptions.periodFilterOptions}
                    value={formik.values.period}
                    onChange={(value) => {
                      const dateRange = transformToStartAndEnd(value)
                      formik.setFieldValue('period', value)
                      if (_.isEmpty(dateRange)) return
                      formik.setFieldValue('startDate', dateRange[0])
                      formik.setFieldValue('endDate', dateRange[1])
                    }}
                  />
                </Col>
                {_.range(0, 2).map((idx) => (
                  <Col span={7} key={idx}>
                    <Typography.Text strong>
                      {idx === 0 ? t('FromDate') : t('ToDate')}
                    </Typography.Text>
                    <DatePicker
                      className="w-100"
                      placement="bottomLeft"
                      allowClear={false}
                      changeOnBlur
                      name={idx === 0 ? 'startDate' : 'endDate'}
                      value={formik.values[idx === 0 ? 'startDate' : 'endDate']}
                      onChange={(date) => {
                        formik.setFieldValue(
                          idx === 0 ? 'startDate' : 'endDate',
                          date,
                        )
                        formik.setFieldValue('period', 'CUSTOM')
                      }}
                      locale={localeVi}
                      format={'DD/MM/YYYY'} // Định dạng ngày tháng năm
                      suffixIcon={<i className="fa-light fa-calendar"></i>}
                      nextIcon={<i className="fa-regular fa-angle-right"></i>}
                      prevIcon={<i className="fa-regular fa-angle-left"></i>}
                      superNextIcon={
                        <i className="fa-regular fa-angle-double-right"></i>
                      }
                      superPrevIcon={
                        <i className="fa-regular fa-angle-double-left"></i>
                      }
                    />
                  </Col>
                ))}
              </Row>
              <Row gutter={[13, 13]} align="middle">
                {/* Trạng thái chứng từ */}
                <Col span={12} className="mx-0">
                  <Typography.Text strong className="mr-2">
                    Trạng thái chứng từ
                  </Typography.Text>
                  <CustomAntSelect
                    placeholder="Chọn trạng thái chứng từ"
                    options={SelectOptions.listStatusAccountingDocumentsAndAll}
                    value={status}
                    onChange={(value) => {
                      setStatus(value)
                      formik.setFieldValue('status', value)
                    }}
                    listHeight={150}
                  />
                </Col>
              </Row>
            </FilterContentWrapper>
            <FilterFooter
              style={{
                backgroundColor: token.colorBgBase,
              }}
            >
              <CustomAntButton
                antProps={{
                  type: 'text',
                  className: 'text-primary',
                  onClick: resetDefault,
                }}
                text={t('ResetFilter')}
              />
              <CustomAntButton
                antProps={{
                  type: 'primary',
                  onClick: submitForm,
                }}
                text={t('ApplyFilter')}
              />
            </FilterFooter>
          </section>
        }
      >
        <CustomAntButton
          text="Lọc"
          iconCn={Utils.cn('fa-duotone fa-filter-list fa-lg')}
          antProps={{
            type: 'default',
            onClick: handleTogglePopover,
          }}
          customStyle={{
            boxShadow: 'none',
            gap: '5px',
            minWidth: 'fit-content',
            color: isOpen && token.colorPrimary,
            borderColor: isOpen && token.colorPrimaryBorder,
          }}
        />
      </Popover>
      <section className="overflow-hidden max-w-100">
        <div className="d-flex gap-2">
          <Tag
            prefixCls="summary-filter"
            bordered={false}
            className="badge badge-light text-info w-100 d-inline-flex align-items-baseline"
          >
            <Typography.Text strong type="secondary" className="fs-6 mr-1">
              Ngày HĐ:
            </Typography.Text>
            <Tooltip
              title={`Từ ${clonedValues.startDate?.format(
                'L HH:mm',
              )} đến ${clonedValues.endDate?.format('L HH:mm')}`}
            >
              {
                _.find(SelectOptions.periodFilterOptions, {
                  value: clonedValues?.period,
                })?.label
              }
            </Tooltip>
          </Tag>
          {filterParams.status != null && filterParams.status != undefined ? (
            <Tag
              prefixCls="summary-filter"
              bordered={false}
              className="badge badge-light text-info w-100 d-inline-flex align-items-baseline"
            >
              <Typography.Text strong type="secondary" className="fs-6 mr-1">
                Trạng thái:
              </Typography.Text>
              <Tooltip
                title={
                  findObjectInArray(
                    SelectOptions.listStatusAccountingDocumentsAndAll,
                    'value',
                    filterParams.status,
                  )?.label
                }
              >
                {
                  findObjectInArray(
                    SelectOptions.listStatusAccountingDocumentsAndAll,
                    'value',
                    filterParams.status,
                  )?.label
                }
                <CloseOutlined
                  style={{ color: 'red', cursor: 'pointer', marginLeft: 4 }}
                  onClick={() => {
                    formik.setFieldValue('status', null)
                    setFilterParams({ ...filterParams, status: null })
                  }}
                />
              </Tooltip>
            </Tag>
          ) : (
            ''
          )}
        </div>
      </section>
    </div>
  )
}
