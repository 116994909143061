import { queryOptions } from '@tanstack/react-query'
import invoiceApi from 'api/invoiceApi'
import ticketApi from 'api/ticketApi'
import _ from 'lodash'

export const queryKeys = {
  base: {
    scope: ['general', 'findInvoiceModal'],
    entity: 'invoice',
  },
  lists: (params, type, isInvoice) =>
    queryOptions({
      queryKey: [queryKeys.base, params, type, isInvoice],
      queryFn: ({ signal }) => {
        console.log('type: ' + type)
        if (isInvoice) {
          return invoiceApi.listAll(
            {
              ...params,
              invoiceType: type,
            },
            signal,
          )
        } else {
          return ticketApi.list(
            {
              ...params,
              ticketType: type,
            },
            signal,
          )
        }
      },
      staleTime: 1000 * 60,
      refetchOnWindowFocus: true,
      retry: false,
      select: (res) => ({
        total: _.get(res, 'count', 0),
        rows: _.get(res, 'rows', []),
      }),
    }),
}
