import { isBoolean } from 'lodash'
import { memo } from 'react'
import LoadingCustom from '../LoadingCustom'
import UnauthorizedScreen from '../UnauthorizedScreen'

const ProtectedRoute = ({ children, permission, noPermission, isLoading }) => {
  if (!isBoolean(permission)) return <></>
  return isLoading ? (
    <LoadingCustom size="large" variant="primary" />
  ) : permission ? (
    children
  ) : (
    noPermission ?? <UnauthorizedScreen />
  )
}

export default memo(ProtectedRoute)
