import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Flex, Space, Tooltip } from 'antd'
import errAnnouceApi from 'api/errAnnouceApi'
import invoiceApi from 'api/invoiceApi'
import taxAuthorityMessageApi from 'api/taxAuthorityMessageApi'
import { setAppSpinning } from 'app/appSlice'
import { hasPinnedActionCol } from 'assets/styles/DatatableStyles/styles'
import dayjs from 'dayjs'
import ExportTicketModal from 'features/HandleTicket/Components/Modals/ExportTicketModal'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import CustomSegmented from 'general/components/CustomSegmented'
import CustomTag from 'general/components/CustomTag'
import Empty from 'general/components/Empty'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import TableAction from 'general/components/GroupButton/TableAction'
import { IconAction } from 'general/components/GroupButton/styles'
import Loading from 'general/components/Loading'
import ModalDataTransmissionLog from 'general/components/ModalDataTransmissionLog'
import Pagination from 'general/components/Pagination'
import {
  ERR_ANNOUCE_STATUS,
  ERROR_ANNOUCEMENTS,
  // TICKET_TYPE_ERR_ANNOUCEMENT,
} from 'general/constants/AppConstants'
import AppResource from 'general/constants/AppResource'
import SelectOptions from 'general/constants/SelectOptions'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useErrorTicketQuery from 'hooks/Queries/useErrorTicketQuery'
import useGetTicketSerial from 'hooks/Queries/useGetTicketSerial'
import useFilter from 'hooks/useFilter'
import { useAppSelector } from 'hooks/useRedux'
import useRouter from 'hooks/useRouter'
import useSearchDebounce from 'hooks/useSearchDebounce'
import { assign, find, isEmpty, keys, map } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
const { Body, Header } = ContentContainer

const prefixPath = '/hoa-don-dau-ra/tem-ve/xu-ly-ve/thong-bao-sai-sot'
export const tabOptions = [
  {
    id: 0,
    title: 'Thông báo VSS',
    name: 'Danh sách vé sai sót',
    filterTitle: 'Lọc thông báo',
    path: prefixPath,
  },
  {
    id: 1,
    title: 'Vé đã lập TBSS',
    name: 'Danh sách vé đã lập TBSS',
    filterTitle: 'Lọc vé',
    path: prefixPath + '/hoa-don',
  },
  {
    id: 2,
    title: 'Vé chưa lập TBSS',
    name: 'Danh sách vé chưa lập TBSS',
    filterTitle: 'Lọc vé',
    path: prefixPath + '/hoa-don-chua-lap-thong-bao-sai-sot',
  },
]
export default function ErrorTicketAnnoucement({ currentTab }) {
  const { t } = useTranslation()
  const clientQuery = useQueryClient()
  const { data: serials } = useGetTicketSerial({
    includeAllOption: true,
    disabled: false,
  })
  const dispatch = useDispatch()
  const [modalState, setModalState] = useState({
    create: false,
    transmissionLog: false,
  })
  const [file, setFile] = useState(null)
  const [filterParam, setFilterParam] = useFilter(
    'errorTicketAnnoucement',
    'gFilterErrorTicketAnnoucement',
  )
  const [activeErrAnnouce, setActiveErrAnnouce] = useState(null)

  const { company } = useAppSelector((state) => state.system)
  const { navigate } = useRouter()
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')

  const { mutate: viewPdfMutate } = useMutation({
    mutationKey: 'viewPdf',
    mutationFn: (tax_authority_messages) => {
      const messageId = find(tax_authority_messages, {
        typeCode: 301,
      })?.messageId
      if (!messageId) return ToastHelper.showError('Không tìm thấy thông báo')
      return taxAuthorityMessageApi.exportPdfMessage301(messageId)
    },
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: (res) => {
      const newFile = new File([res], 'Thông báo 01/TB-HĐSS', {
        type: 'application/pdf',
      })
      setFile(newFile)
    },
  })

  const {
    data: errorTicketAnnouncementsData,
    isFetching: gettingErrorAnnouncement,
    refetch: refetchingList,
  } = useErrorTicketQuery(
    {
      ...filterParam,
      ...(currentTab === 2 && { statusNotIn: true }),
    },
    currentTab,
    debouncedSearch,
  )

  const switchTab = (value) => {
    const path = find(tabOptions, (v) => v.title === value).path
    navigate(path)
    setSearch('')
  }

  /**
   *
   * @param {keyof modalState} modalName
   * @param {boolean} status
   */
  const toggleModalState = (modalName, status) => {
    setModalState((prev) => {
      const notModalName = keys(prev).filter((key) => key !== modalName)
      const turnOffOthers = assign(
        {},
        ...map(notModalName, (key) => ({
          [key]: false,
        })),
      )
      return {
        ...turnOffOthers,
        [modalName]: status,
      }
    })
  }

  const checkTaxAuthorityMessage = useMutation({
    mutationKey: 'checkTaxAuthorityMessage',
    mutationFn: async (messageId) => {
      const res = await invoiceApi.checkMessage(messageId)
      console.log('Tax authority message', res)
      clientQuery.invalidateQueries(['listErrorAnnoucement', currentTab])
    },
  })

  const defaultValue = useMemo(() => {
    /**
     * @type {FilterDefaultValueProp[]}
     */
    const arr =
      currentTab === 0
        ? [
            {
              label: 'Trạng thái phát hành',
              alias: 'Trạng thái phát hành',
              formName: 'issueStatus',
              options: SelectOptions.issueStatus,
              value: null,
            },
            {
              label: 'Loại thông báo',
              alias: 'Loại',
              formName: 'error_annoucements',
              options: SelectOptions.error_annoucements,
              value: null,
            },
          ]
        : currentTab === 1
          ? [
              {
                label: 'Hình thức xử lý',
                alias: 'Hình thức xử lý',
                formName: 'errAnnouceType',
                options: SelectOptions._ticketTypeErrAnnouce,
                value: null,
              },
              {
                label: 'Ký hiệu',
                alias: 'Ký hiệu',
                formName: 'serials',
                options: serials,
                value: null,
                multiselect: true,
              },
              {
                label: 'Trạng thái thông báo sai sót',
                alias: 'Trạng thái TBSS',
                formName: 'ticketErrAnnouceStatus',
                options: SelectOptions.ticketErrAnnouceStatus,
                value: null,
              },
            ]
          : [
              {
                label: 'Ký hiệu',
                alias: 'Ký hiệu',
                formName: 'serials',
                options: serials,
                value: null,
                multiselect: true,
              },
              {
                label: 'Trạng thái vé',
                alias: 'Trạng thái HĐ',
                formName: 'typeTickets',
                options: SelectOptions.invoiceTypeHDChuaLapTBSS,
                value: null,
                multiselect: true,
              },
            ]
    return arr
  }, [currentTab, serials])
  const renderIssueStatus = (issueStatus) => {
    switch (issueStatus) {
      case ERR_ANNOUCE_STATUS.NOT_SENT_TO_AUTHORITY:
        return <div className="badge badge-light-success">Chưa phát hành</div>
      case ERR_ANNOUCE_STATUS.WAITING_FOR_AUTHORITY:
        return <div className="badge badge-light-success">Chờ CQT xử lý</div>
      case ERR_ANNOUCE_STATUS.AUTHORITY_ACCEPT:
        return <div className="badge badge-light-primary">CQT đã tiếp nhận</div>
      case ERR_ANNOUCE_STATUS.AUTHORITY_DENY:
        return (
          <div className="badge badge-light-danger">CQT không tiếp nhận</div>
        )
      default:
      // return <div class="label label-lg label-outline-primary label-inline">Hoá đơn gốc</div>;
    }
  }

  const defaultColumns = useMemo(() => {
    /**
     * @type {import('react-data-table-component').TableColumn<ErrorTicketDataType>[]}
     */
    const arr =
      currentTab === 0
        ? [
            {
              name: 'Số thông báo',
              center: true,
              cell: ({ customerNo }) => (
                <p style={{ textAlign: 'center' }} className="w-100 m-0">
                  {customerNo}
                </p>
              ),
            },
            {
              name: 'Số thông báo của CQT',
              center: true,
              cell: ({ no, tax_authority_messages }) => (
                <p style={{ textAlign: 'center' }} className="w-100 m-0">
                  <Tooltip title="Nhấn để xem chi tiết">
                    <a
                      href="#"
                      onClick={() => viewPdfMutate(tax_authority_messages)}
                    >
                      {no}
                    </a>
                  </Tooltip>
                </p>
              ),
            },
            {
              name: 'Ngày lập',
              center: true,
              cell: ({ date }) => (
                <p style={{ textAlign: 'center' }} className="w-100 m-0">
                  {dayjs(date).format('L')}
                </p>
              ),
            },
            {
              name: 'Ngày thông báo',
              center: true,
              cell: ({ tax_authority_messages }) => {
                if (isEmpty(tax_authority_messages)) return null
                const hasDates = tax_authority_messages
                  .filter((message) =>
                    ['301', '204'].includes(
                      message?.contentRaw?.TDiep?.TTChung?.MLTDiep,
                    ),
                  )
                  .map((v) => v.contentRaw?.TDiep?.DLieu?.TBao?.STBao?.NTBao)
                return (
                  <p style={{ textAlign: 'center' }} className="w-100 m-0">
                    {hasDates.map((d) => dayjs(d).format('L'))}
                  </p>
                )
              },
            },
            {
              name: 'Nơi lập',
              center: true,
              cell: ({ place }) => (
                <p style={{ textAlign: 'center' }} className="w-100 m-0">
                  {place}
                </p>
              ),
            },
            {
              name: 'Trạng thái phát hành',
              center: true,
              minWidth: '140px',
              cell: ({ issueStatus }) =>
                // <p style={{ textAlign: 'left' }} className="w-100 m-0">
                //   {issueStatus !== null && (
                //     <CustomTag
                //       text={
                //         find(SelectOptions.taxMessageInvoiceStatus, {
                //           value: issueStatus,
                //         })?.label
                //       }
                //       tagProps={{
                //         className: `badge badge-light-${
                //           issueStatus === 'NOT_SENT_TO_AUTHORITY'
                //             ? 'warning'
                //             : issueStatus === 'WAITING_FOR_AUTHORITY'
                //               ? 'info'
                //               : issueStatus === 'AUTHORITY_DENY'
                //                 ? 'danger'
                //                 : issueStatus === 'AUTHORITY_CODE_NOT_GIVEN'
                //                   ? 'danger'
                //                   : issueStatus === 'AUTHORITY_CODE_GIVEN'
                //                     ? 'success'
                //                     : issueStatus === 'AUTHORITY_ACCEPT'
                //                       ? 'success'
                //                       : 'primary'
                //         }`,
                //       }}
                //     />
                //   )}
                // </p>
                renderIssueStatus(issueStatus),
            },
            {
              name: 'Loại thông báo',
              center: true,
              width: '260px',
              cell: ({ type }) => (
                <p style={{ textAlign: 'center' }} className="w-100 m-0">
                  {type !== null && (
                    <CustomTag
                      text={
                        find(SelectOptions.error_annoucements, {
                          value: type,
                        })?.label
                      }
                      tagProps={{
                        className: `badge badge-light-${
                          type === ERROR_ANNOUCEMENTS.TAX_AUTHORITIES_RELATED
                            ? 'primary'
                            : type === ERROR_ANNOUCEMENTS.TAX_PAYER_RELATED
                              ? 'info'
                              : 'primary'
                        }`,
                      }}
                    />
                  )}
                </p>
              ),
            },
            {
              name: 'Hành động',
              center: true,
              compact: true,
              cell: ({
                issueStatus,
                errorAnnoucementId,
                messageId,
                ...row
              }) => (
                <Flex justify="flex-end" gap={'5px'} className="w-100">
                  {issueStatus === 'WAITING_FOR_AUTHORITY' && (
                    <TableAction
                      titleActionText="Kiểm tra trạng thái"
                      icon={<IconAction className="fa-light fa-file-alt" />}
                      onClick={() => checkTaxAuthorityMessage.mutate(messageId)}
                    />
                  )}
                  {issueStatus === 'NOT_SENT_TO_AUTHORITY' && (
                    <>
                      <TableAction
                        titleActionText="Phát hành thông báo"
                        icon={
                          <IconAction className="fa-light fa-paper-plane" />
                        }
                        onClick={async () => {
                          if (company.activeSigningMethod == 'HSM') {
                            try {
                              dispatch(setAppSpinning(true))
                              await errAnnouceApi.hsmSignErrAnnouce({
                                errorAnnoucementId,
                              })
                              dispatch(setAppSpinning(false))

                              ToastHelper.showSuccess(
                                'Ký thông báo sai sót thành công',
                              )

                              refetchingList()
                            } catch (e) {
                              console.log(e)
                              dispatch(setAppSpinning(false))
                            }
                          } else {
                            const urlSchema = `${
                              process.env.REACT_APP_BASE_URL_SCHEMA
                            }${localStorage.getItem(
                              'eInvoice_accessToken',
                            )},message300,${errorAnnoucementId},${
                              company?.taxCode
                            }`
                            window.location.href = urlSchema
                          }
                        }}
                      />
                      <TableAction
                        titleActionText="Xoá thông báo"
                        icon={<IconAction className="fa-light fa-trash-alt" />}
                        onClick={async () => {
                          try {
                            dispatch(setAppSpinning(true))
                            await errAnnouceApi.delete(errorAnnoucementId)
                            dispatch(setAppSpinning(false))

                            ToastHelper.showSuccess(
                              'Xóa thông báo sai sót thành công',
                            )

                            refetchingList()
                          } catch (e) {
                            console.log(e)
                            dispatch(setAppSpinning(false))
                          }
                        }}
                      />
                      <TableAction
                        titleActionText="Chỉnh sửa thông báo"
                        icon={
                          <IconAction className="fa-light fa-pen-to-square" />
                        }
                        onClick={() => {
                          // dispatch(setAppSpinning(true))
                          navigate(
                            `/hoa-don-dau-ra/tem-ve/xu-ly-ve/chinh-sua-thong-bao-sai-sot/${errorAnnoucementId}`,
                          )
                        }}
                      />
                    </>
                  )}
                  {issueStatus !== 'NOT_SENT_TO_AUTHORITY' && (
                    <TableAction
                      titleActionText="Xem lịch sử truyền nhận"
                      icon={
                        <IconAction className="fa-light fa-clock-rotate-left" />
                      }
                      onClick={() => {
                        toggleModalState('transmissionLog', true)
                        setActiveErrAnnouce(row)
                        // setMessages(tax_authority_messages)
                      }}
                    />
                  )}
                </Flex>
              ),
            },
          ]
        : currentTab === 1
          ? [
              {
                name: 'Ký hiệu',
                center: true,
                cell: ({ serial }) => (
                  <p style={{ textAlign: 'left' }} className="w-100 m-0">
                    {serial}
                  </p>
                ),
              },
              {
                name: 'Số',
                center: true,
                width: '100px',
                cell: ({ no }) => (
                  <p style={{ textAlign: 'left' }} className="w-100 m-0">
                    {no}
                  </p>
                ),
              },
              {
                name: 'Ngày HĐ',
                center: true,
                cell: ({ date }) => (
                  <p style={{ textAlign: 'center' }} className="w-100 m-0">
                    {dayjs(date).format('L')}
                  </p>
                ),
              },
              {
                name: 'Khách hàng',
                width: '200px',
                center: true,
                cell: ({ customerCompanyName }) => (
                  <p style={{ textAlign: 'left' }} className="w-100 m-0">
                    {customerCompanyName}
                  </p>
                ),
              },
              {
                name: 'Tổng tiền',
                minWidth: '150px',
                center: true,
                cell: ({ totalPrice }) => (
                  <p style={{ textAlign: 'right' }} className="w-100 m-0">
                    {Utils.formatCurrency(totalPrice, ' VNĐ')}
                  </p>
                ),
              },
              {
                name: 'Mã CQT',
                grow: 2,
                minWidth: '300px',
                center: true,
                cell: ({ taxAuthorityCode }) => (
                  <p style={{ textAlign: 'left' }} className="w-100 m-0">
                    {taxAuthorityCode}
                  </p>
                ),
              },
              {
                name: 'Hình thức xử lý',
                center: true,
                grow: 0,
                cell: ({ errorAnnounceInfo }) => (
                  <p style={{ textAlign: 'left' }} className="w-100 m-0">
                    {errorAnnounceInfo && (
                      <CustomTag
                        text={
                          find(SelectOptions._ticketTypeErrAnnouce, {
                            value: errorAnnounceInfo?.errAnnouceType,
                          })?.label
                        }
                        tagProps={{
                          className: `badge badge-light-${
                            errorAnnounceInfo?.errAnnouceType === 'ADJUST'
                              ? 'success'
                              : errorAnnounceInfo?.errAnnouceType === 'CANCEL'
                                ? 'danger'
                                : errorAnnounceInfo?.errAnnouceType ===
                                    'EXPLAIN'
                                  ? 'primary'
                                  : errorAnnounceInfo?.errAnnouceType ===
                                      'REPLACE'
                                    ? 'info'
                                    : ''
                          }`,
                        }}
                      />
                    )}
                  </p>
                ),
              },
              {
                name: 'Số thông báo CQT',
                center: true,
                width: '120px',
                cell: ({ errorAnnounceInfo }) => (
                  <p style={{ textAlign: 'left' }} className="w-100 m-0">
                    {errorAnnounceInfo?.no}
                  </p>
                ),
              },
              {
                name: 'Hành động',
                center: true,
                grow: 0,
                compact: true,
                cell: () => (
                  <Space>
                    {/* <TableAction
                      titleActionText="Xem chi tiết"
                      icon={<IconAction className="fa-light fa-eye" />}
                    /> */}
                  </Space>
                ),
              },
            ]
          : [
              {
                name: 'Ký hiệu',
                center: true,
                cell: ({ serial }) => (
                  <p style={{ textAlign: 'left' }} className="w-100 m-0">
                    {serial}
                  </p>
                ),
              },
              {
                name: 'Ngày HĐ',
                center: true,
                cell: ({ date }) => (
                  <p style={{ textAlign: 'center' }} className="w-100 m-0">
                    {dayjs(date).format('L')}
                  </p>
                ),
              },
              {
                name: 'Số HĐ',
                center: true,
                cell: ({ no }) => (
                  <p style={{ textAlign: 'left' }} className="w-100 m-0">
                    {no}
                  </p>
                ),
              },
              {
                name: 'Mã CQT',
                center: true,
                cell: ({ taxAuthorityCode }) => (
                  <p style={{ textAlign: 'left' }} className="w-100 m-0">
                    {taxAuthorityCode}
                  </p>
                ),
              },
              {
                name: 'Mã số thuế',
                center: true,
                cell: ({ customerTaxCode }) => (
                  <p style={{ textAlign: 'center' }} className="w-100 m-0">
                    {customerTaxCode}
                  </p>
                ),
              },
              {
                name: 'Tổng tiền',
                center: true,
                cell: ({ totalPrice }) => (
                  <p style={{ textAlign: 'right' }} className="w-100 m-0">
                    {Utils.formatCurrency(totalPrice, ' VNĐ')}
                  </p>
                ),
              },
            ]
    return arr
  }, [
    checkTaxAuthorityMessage,
    company.activeSigningMethod,
    company?.taxCode,
    currentTab,
    dispatch,
    refetchingList,
    viewPdfMutate,
  ])

  // ------------------ Effects ------------------
  useEffect(() => {
    file && open(URL.createObjectURL(file), '_blank')
    return () => {
      URL.revokeObjectURL(file)
      setFile(null)
    }
  }, [file])

  return (
    <ContentContainer
      tabs={
        <CustomSegmented
          defaultValue={tabOptions[currentTab].title}
          options={tabOptions.map((v) => v.title)}
          onChange={switchTab}
        />
      }
    >
      <Header
        titleContent={find(tabOptions, { id: currentTab }).name}
        toolBar={
          <Space>
            <CustomAntButton
              text="Lập thông báo"
              iconCn="fa-regular fa-plus"
              fontSizeText={13}
              antProps={{
                type: 'primary',
                onClick: () => toggleModalState('create', true),
              }}
            />
          </Space>
        }
      >
        <Space align="center" className="w-100 m-0">
          <CustomAntInput
            borderRadius="sm"
            type="search"
            inputProps={{
              value: search,
              onChange: (e) => setSearch(e.target.value),
            }}
          />
          <FilterPopover
            menuPosition="bottom"
            savedKey="gFilterErrorTicketAnnoucement"
            onApply={setFilterParam}
            defaultValues={defaultValue}
            title={tabOptions[currentTab].filterTitle}
          />
        </Space>
      </Header>
      <Body>
        <DataTable
          className="h-100 flex-grow-1"
          columns={defaultColumns}
          data={errorTicketAnnouncementsData?.rows}
          noHeader
          responsive
          fixedHeader
          striped
          highlightOnHover
          noDataComponent={
            <div className="pt-12">
              <Empty
                text={t('NoData')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
                imageEmptyPercentWidth={170}
              />
            </div>
          }
          progressPending={gettingErrorAnnouncement}
          progressComponent={
            <Loading showBackground={false} message={`${t('Loading')}...`} />
          }
          customStyles={hasPinnedActionCol}
        />
        {errorTicketAnnouncementsData?.total > 0 && (
          <Pagination
            rowsPerPage={filterParam?.limit}
            rowCount={errorTicketAnnouncementsData?.total}
            currentPage={filterParam?.page}
            onChangePage={(newPage) => {
              setFilterParam({
                page: newPage,
              })
            }}
            onChangeRowsPerPage={(newPerPage) => {
              setFilterParam({
                limit: newPerPage,
                page: 1,
              })
            }}
          />
        )}
      </Body>
      <ExportTicketModal
        title="Chọn vé sai sót"
        open={modalState.create}
        onOpenChange={(state) => toggleModalState('create', state)}
        ticketType={'CAN_ERR_ANNOUCED'}
        handleApplyChanges={(activeRows, classify) => {
          if (activeRows?.length) {
            navigate(
              `/hoa-don-dau-ra/tem-ve/xu-ly-ve/thong-bao-sai-sot/${activeRows.join()}?invoiceClassify=${classify}`,
            )
          } else {
            ToastHelper.showError('Chưa chọn vé để thông báo sai sót')
          }
        }}
        hasTabs={false}
        currentTab={currentTab}
      />
      {/* <TranmissionLogModal
        open={modalState.transmissionLog}
        onOpenChange={(state) => toggleModalState('transmissionLog', state)}
        messages={messages}
      /> */}
      <ModalDataTransmissionLog
        show={modalState.transmissionLog}
        onHide={() => toggleModalState('transmissionLog', false)}
        errAnnouce={activeErrAnnouce}
      />
    </ContentContainer>
  )
}
