import dayjs from 'dayjs'
import _ from 'lodash'

/**
 * @param {import('general/constants/AppConstants').Period} value
 * @returns {[dayjs.Dayjs, dayjs.Dayjs] | []}
 */
export const transformToStartAndEnd = (value) => {
  let dateRange = []
  switch (value) {
    case 'RECENT_30_DAYS':
      dateRange = [
        dayjs().subtract(29, 'days').startOf('d'),
        dayjs().endOf('d'),
      ]
      break
    case 'RECENT_7_DAYS':
      dateRange = [dayjs().subtract(6, 'days').startOf('d'), dayjs().endOf('d')]
      break
    case 'YESTERDAY':
      dateRange = [
        dayjs().subtract(1, 'day').startOf('d'),
        dayjs().subtract(1, 'day').endOf('d'),
      ]
      break
    case 'TODAY':
      dateRange = [dayjs().startOf('d'), dayjs().endOf('d')]
      break
    case 'THIS_MONTH':
      dateRange = [
        dayjs().startOf('month').startOf('d'),
        dayjs().endOf('month').endOf('d'),
      ]
      break
    case 'THIS_QUARTER':
      dateRange = [
        dayjs().startOf('quarter').startOf('d'),
        dayjs().endOf('quarter').endOf('d'),
      ]
      break
    case 'THIS_WEEK':
      dateRange = [
        dayjs().startOf('week').startOf('d'),
        dayjs().endOf('week').endOf('d'),
      ]
      break
    case 'THIS_YEAR':
      dateRange = [
        dayjs().startOf('year').startOf('d'),
        dayjs().endOf('year').endOf('d'),
      ]
      break
    case 'LAST_MONTH':
      dateRange = [
        dayjs().subtract(1, 'month').startOf('month').startOf('d'),
        dayjs().subtract(1, 'month').endOf('month').endOf('d'),
      ]
      break
    case 'LAST_QUARTER':
      dateRange = [
        dayjs().subtract(1, 'quarter').startOf('quarter').startOf('d'),
        dayjs().subtract(1, 'quarter').endOf('quarter').endOf('d'),
      ]
      break
    case 'LAST_WEEK':
      dateRange = [
        dayjs().subtract(1, 'week').startOf('week').startOf('d'),
        dayjs().subtract(1, 'week').endOf('week').endOf('d'),
      ]
      break
    case 'LAST_YEAR':
      dateRange = [
        dayjs().subtract(1, 'year').startOf('year').startOf('d'),
        dayjs().subtract(1, 'year').endOf('year').endOf('d'),
      ]
      break
    case 'QUARTER_1':
    case 'QUARTER_2':
    case 'QUARTER_3':
    case 'QUARTER_4':
      const quarter = parseInt(_.last(value))
      dateRange = [
        dayjs().quarter(quarter).startOf('quarter').startOf('d'),
        dayjs().quarter(quarter).endOf('quarter').endOf('d'),
      ]
      break
    case 'MONTH_1':
    case 'MONTH_2':
    case 'MONTH_3':
    case 'MONTH_4':
    case 'MONTH_5':
    case 'MONTH_6':
    case 'MONTH_7':
    case 'MONTH_8':
    case 'MONTH_9':
    case 'MONTH_10':
    case 'MONTH_11':
    case 'MONTH_12':
      let month
      if (value == 'MONTH_10' || value == 'MONTH_11' || value == 'MONTH_12') {
        month = parseInt(value.replace('MONTH_', ''))
      } else {
        month = parseInt(_.last(value))
      }

      console.log('month', month)
      dateRange = [
        dayjs()
          .month(month - 1)
          .startOf('month')
          .startOf('d'),
        dayjs()
          .month(month - 1)
          .endOf('month')
          .endOf('d'),
      ]
      break
    case 'FIRST_HALF_YEAR':
      dateRange = [
        dayjs().startOf('year').startOf('d'),
        dayjs().month(5).endOf('month').endOf('d'),
      ]
      break
    case 'SECOND_HALF_YEAR':
      dateRange = [
        dayjs().month(6).startOf('month').startOf('d'),
        dayjs().endOf('year').endOf('d'),
      ]
      break
    case 'CUSTOM':
    default:
      dateRange = []
      break
  }
  return dateRange
}
