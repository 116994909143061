import { useQuery } from '@tanstack/react-query'
import { Card, Col, Flex, Row, Space, Typography } from 'antd'
import resourceApi from 'api/resourceApi'
import chroma from 'chroma-js'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import Utils from 'general/utils/Utils'
import useRouter from 'hooks/useRouter'
import useToken from 'hooks/useToken'
import { useMemo } from 'react'
import '../style.scss'

export default function ResourcePlans() {
  const { navigate } = useRouter()
  const { token } = useToken()

  //=========== QUery n mutation ===========

  const { data: syntheticData, isLoading: isGettingSyntheticData } = useQuery({
    queryKey: ['resource', 'synthetic'],
    queryFn: () => resourceApi.getSynthetic(),
  })

  const plans = useMemo(
    /** @returns {PlanType[]}  */
    () => [
      {
        id: 'hddt',
        title: 'Hoá đơn điện tử',
        description:
          'Tài nguyên sử dụng để phát hành Hóa đơn, Phiếu xuất kho, Biên lai',
        hasPurchased: syntheticData?.invoice && {
          total: syntheticData?.invoice?.resourceTotalQuantityInvoices,
          used: syntheticData?.invoice?.resourceUsedQuantityInvoices,
        },
        style: {
          color: 'hsl(214, 90%, 90%)',
          background: `linear-gradient(to top right,hsl(214, 90%, 45%),hsl(200, 93%, 65%))`,
        },
        onClick: () => navigate('/hoa-don-dau-ra/he-thong/mua-them'),
      },
      {
        id: 'hd-ve-tu-may-tinh-tien',
        title: 'HĐ/Vé từ máy tính tiền',
        description:
          'Tài nguyên sử dụng để phát hành Hóa đơn/Vé điện tử từ máy tính tiền',
        onClick: () => navigate('/hoa-don-dau-ra/he-thong/mua-them'),

        hasPurchased: false,
        style: {
          color: 'hsl(175, 50%, 90%)',
          background: `linear-gradient(to top right,hsl(175, 50%, 40%) , hsl(175, 70%, 70%))`,
        },
      },
      {
        id: 've-dien-tu',
        title: 'Vé điện tử',
        description: 'Tài nguyên sử dụng để phát hành Vé điện tử',
        onClick: () => navigate('/hoa-don-dau-ra/he-thong/mua-them'),
        hasPurchased: syntheticData?.ticket && {
          total: syntheticData?.ticket?.resourceTotalQuantityTickets,
          used: syntheticData?.ticket?.resourceUsedQuantityTickets,
        },
        style: {
          color: 'hsl(272, 76%, 90%)',
          background: `linear-gradient(to top right,hsl(260, 100%, 44%), hsl(272, 76%, 63%))`,
        },
      },
    ],
    [navigate, syntheticData?.invoice, syntheticData?.ticket],
  )
  return (
    <Row gutter={[8, 8]} className="w-100">
      {plans.map((plan) => (
        <Col
          lg={Math.round(24 / plans.length)}
          md={24 / 2}
          sm={24}
          key={plan.id}
        >
          <Card
            loading={isGettingSyntheticData}
            bordered={false}
            className="h-100 min-w-300px customCard "
            style={{
              ...plan.style,
              borderRadius: token.borderRadiusSM,
            }}
            title={
              <Typography.Title level={4} className="text-white fw-bold m-0">
                {plan.title}
              </Typography.Title>
            }
          >
            <div className="h-100px">
              {plan.hasPurchased ? (
                <>
                  <Space direction="vertical">
                    <Typography.Text
                      className="fw-semibold"
                      style={{ color: 'inherit' }}
                    >
                      Còn được sử dụng
                    </Typography.Text>
                    <span className="fs-2 fw-bolder">
                      {Utils.formatNumber(
                        plan.hasPurchased.total - plan.hasPurchased.used,
                      )}
                    </span>
                  </Space>
                  <Flex justify="space-between" align="center">
                    <div className="d-block">
                      <Typography.Text
                        className="fw-medium mr-2"
                        style={{ color: 'inherit' }}
                      >
                        Đã mua:
                      </Typography.Text>
                      <span className="fw-bold">
                        {Utils.formatNumber(plan.hasPurchased.total)}
                      </span>
                    </div>
                    <div className="d-block">
                      <Typography.Text
                        className="fw-medium mr-2"
                        style={{ color: 'inherit' }}
                      >
                        Đã sử dụng:
                      </Typography.Text>
                      <span className="fw-bold">
                        {Utils.formatNumber(plan.hasPurchased.used)}
                      </span>
                    </div>
                  </Flex>
                </>
              ) : (
                <div className="h-100 w-100 flex-column d-flex justify-content-center align-items-center ">
                  <Typography.Text
                    className="fw-bold fs-5 text-white text-center"
                    ellipsis={{
                      tooltip: 'VietInvoice - ' + plan.title,
                    }}
                  >
                    Bạn chưa có tài nguyên
                    <br />
                    VietInvoice - {plan.title}
                  </Typography.Text>
                </div>
              )}
            </div>
            {/* ====== */}
            <CustomAntButton
              text={plan.hasPurchased ? 'Mua thêm' : 'Mua ngay'}
              fontSizeText={15}
              customStyle={{
                color: chroma(plan.style.color).set('hsl.l', 0.4),
              }}
              antProps={{
                onClick: plan.onClick,
                block: true,
                className: Utils.cn(
                  plan.id === 'hddt'
                    ? 'blueBtn'
                    : plan.id === 'hd-ve-tu-may-tinh-tien'
                      ? 'greenBtn'
                      : 'purpleBtn',
                  'mt-3',
                ),
              }}
            />
            <Typography.Text
              style={{
                color: 'inherit',
              }}
              className="font-italic font-size-sm"
            >
              {plan.description}
            </Typography.Text>
          </Card>
        </Col>
      ))}
    </Row>
  )
}
