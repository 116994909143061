import { Checkbox, Flex } from 'antd'
import { onShowColumn } from 'app/tableSettingSlice'
import Utils from 'general/utils/Utils'
import { useAppDispatch } from 'hooks/useRedux'

/**
 *
 * @param {Object} props
 * @param {import('react-beautiful-dnd').DraggableProvided} props.draggableProvided
 * @param {import('react-beautiful-dnd').DraggableStateSnapshot} props.draggableSnapshot
 * @param {number} props.idx
 * @param {Object} props.item
 */
export default function DraggableRow(props) {
  const { draggableProvided, draggableSnapshot, item } = props
  const dispatch = useAppDispatch()
  return (
    <li
      {...draggableProvided.draggableProps}
      {...draggableProvided.dragHandleProps}
      className={Utils.cn(
        'snapshot-drag user-select-none list-group-item list-group-item-action py-1 px-3',
        draggableSnapshot.isDragging && 'list-group-item-light',
      )}
      ref={draggableProvided.innerRef}
    >
      <Flex
        align="center"
        justify="space-between"
        style={{
          cursor: draggableSnapshot.isDragging ? 'grabbing' : 'grab',
          pointerEvents: item.disabled ? 'none' : 'auto',
        }}
      >
        <div className="d-flex align-items-center">
          <Checkbox
            checked={!item.hidden}
            id={item.id}
            onChange={(event) => dispatch(onShowColumn(event))}
          >
            {item.name}
          </Checkbox>
        </div>
        <span className="p-3">
          <i className="fa-solid fa-bars"></i>
        </span>
      </Flex>
    </li>
  )
}
