import invoiceApi from 'api/invoiceApi'
import { setAppSpinning } from 'app/appSlice'
import AppResource from 'general/constants/AppResource'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import ModalViewInvoice from './components/ModalViewInvoice'
import NotFoundError from './components/NotFoundError'
import './style.scss'

function Search() {
  const dispatch = useDispatch()
  const [searchParam] = useSearchParams()
  const initialLookupCode = searchParam.get('lookupCode')
  const [invoiceLookupCode, setInvoiceLookupCode] = useState('')
  const [invoice, setInvoice] = useState()
  const [showModalViewInvoice, setShowModalViewInvoice] = useState(false)

  const [notFoundInvoiceLookupCode, setNotFoundInvoiceLookupCode] = useState()

  const [invoiceUrl, setInvoiceUrl] = useState()

  const handleSearchInvoice = async (lookupCode) => {
    dispatch(setAppSpinning(true))
    try {
      let res = await invoiceApi.findByLookupCode(lookupCode)
      let resPdf = await invoiceApi.exportInvoiceByLookupCode(lookupCode)
      dispatch(setAppSpinning(false))

      let newFile = new File([resPdf], 'Mẫu hóa đơn.pdf', {
        type: 'application/pdf',
      })
      var fileURL = URL.createObjectURL(newFile)
      setInvoice(res.data)
      setInvoiceUrl(fileURL)
      setNotFoundInvoiceLookupCode(null)

      setShowModalViewInvoice(true)
    } catch (e) {
      console.log(e)
      dispatch(setAppSpinning(false))
      setNotFoundInvoiceLookupCode(lookupCode)
    }
  }

  useEffect(() => {
    if (initialLookupCode) {
      setInvoiceLookupCode(initialLookupCode)
      handleSearchInvoice(initialLookupCode)
    }
  }, [initialLookupCode])

  return (
    <>
      <div className="Search">
        <div className="top-bar">
          <a
            href="#"
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <img src={AppResource.images.img_VIETINVOICE_logo} width={200} />
          </a>
        </div>

        <div>
          <div
            className="layout-tra-cuu d-flex justify-content-center"
            style={{ marginBottom: '4em' }}
          >
            <div className="d-flex align-items-center justify-content-center col-12">
              <div className="row" style={{ width: '41%' }}>
                <div className="col-12 mb-5">
                  <div className="text-white text-center">
                    <h4 className="font-medium text-2xl m-0">
                      TRA CỨU HÓA ĐƠN ĐIỆN TỬ
                      <br />
                      VIET-INVOICE
                    </h4>
                  </div>
                </div>
                <div className="col-12 mb-7">
                  <input
                    placeholder="Nhập mã tra cứu"
                    className="w-100 search-input"
                    required
                    value={invoiceLookupCode}
                    onChange={(e) => {
                      setInvoiceLookupCode(e.target.value)
                    }}
                    onKeyDown={(e) => {
                      if (e.key == 'Enter') {
                        handleSearchInvoice(invoiceLookupCode)
                      }
                    }}
                  />
                </div>
                <div className="col-12">
                  <div className="text-center">
                    <button
                      className="btn btn-success d-inline-flex align-items-center"
                      onClick={() => handleSearchInvoice(invoiceLookupCode)}
                    >
                      <i className="fa-regular fa-magnifying-glass"></i>
                      <span style={{ fontSize: '16px' }}>Tra cứu</span>
                    </button>
                  </div>
                </div>

                {notFoundInvoiceLookupCode && (
                  <div className="col-12">
                    <NotFoundError
                      text={`Không tìm thấy hóa đơn có mã tra cứu: ${notFoundInvoiceLookupCode}`}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="layout-loi-ich mb-5">
            <h2 className="text-center mt-7 mb-0">Lợi ích hóa đơn điện tử</h2>

            <div className="p-3">
              <div className="row">
                <div className="col-12 col-md-3 col-lg-3">
                  <div className="item">
                    <div className="item-img">
                      <img src={AppResource.images.img_nghiep_vu} alt="" />
                    </div>
                    <div className="item-title text-center m-3">
                      ĐÁP ỨNG NGHIỆP VỤ
                    </div>
                    <div className="item-text text-center">
                      Đảm bảo đáp ứng toàn bộ nghiệp vụ theo quy định của Tổng
                      cục thuế
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3">
                  <div className="item">
                    <div className="item-img">
                      <img src={AppResource.images.img_hieu_qua} alt="" />
                    </div>
                    <div className="item-title text-center m-3">
                      TIẾT KIỆM - HIỆU QUẢ
                    </div>
                    <div className="item-text text-center">
                      Tiết kiệm 90% chi phí in ấn, vận chuyển, lưu trữ và bảo
                      quản hóa đơn
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3">
                  <div className="item">
                    <div className="item-img">
                      <img src={AppResource.images.img_an_toan} alt="" />
                    </div>
                    <div className="item-title text-center m-3">
                      AN TOÀN - BẢO MẬT
                    </div>
                    <div className="item-text text-center">
                      Sử dụng công nghệ bảo mật nhiều lớp, an toàn tuyệt đối
                      trong việc quản lý và lưu trữ.
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3">
                  <div className="item">
                    <div className="item-img">
                      <img src={AppResource.images.img_tu_van} alt="" />
                    </div>
                    <div className="item-title text-center m-3">
                      TƯ VẤN - HỖ TRỢ
                    </div>
                    <div className="item-text text-center">
                      Triển khai nhanh, hỗ trợ thông báo phát hành hóa đơn, tư
                      vấn nghiệp vụ trong việc sử dụng hóa đơn.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="layout-footer text-center text-white p-8">
            <b>CÔNG TY CỔ PHẦN iCORP</b>
            <p>
              <i className="fa-solid fa-location-dot mr-2"></i>Địa chỉ: Số 32/21
              Phố Trương Công Giai, Phường Dịch Vọng, Quận Cầu Giấy, Thành phố
              Hà Nội.
            </p>
            <p>
              <i className="fa-solid fa-location-dot mr-2"></i>VPGD: Tầng 6, Số
              82 Trần Thái Tông, Dịch Vọng Hậu, Cầu Giấy, Hà Nội
            </p>
            <p>
              <i className="fa-solid fa-phone mr-2"></i>
              <b>Hotline:</b> 1900.4751
            </p>
            <p>
              <i className="fa-regular fa-envelope mr-2"></i>
              <b>Email:</b> vietinvoice@icorp.vn
            </p>
          </div>
        </div>
      </div>

      {invoice && (
        <ModalViewInvoice
          invoiceUrl={invoiceUrl}
          lookupCode={invoiceLookupCode}
          invoice={invoice}
          showModalViewInvoice={showModalViewInvoice}
          setShowModalViewInvoice={setShowModalViewInvoice}
        />
      )}
    </>
  )
}

export default Search
