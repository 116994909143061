import dayjs from 'dayjs'
import { TRANSACTION_STATUS } from 'general/constants/AppConstants'

/** @type {import('antd').TimeRangePickerProps['presets']} */
export const rangePresets = [
  {
    label: 'Tất cả',
    value: [
      dayjs('01/01/2000').startOf('day'),
      dayjs().endOf('year').endOf('day'),
    ],
  },
  {
    label: 'Hôm nay',
    value: [dayjs().startOf('day'), dayjs().endOf('day')],
  },
  {
    label: 'Hôm qua',
    value: [
      dayjs().subtract(1, 'day').startOf('day'),
      dayjs().subtract(1, 'day').endOf('day'),
    ],
  },
  {
    label: '7 ngày trước',
    value: [dayjs().subtract(6, 'day').startOf('day'), dayjs()],
  },
  {
    label: '30 ngày trước',
    value: [dayjs().subtract(29, 'day').startOf('day'), dayjs()],
  },
  {
    label: 'Tháng này',
    value: [dayjs().startOf('month'), dayjs().endOf('month')],
  },
  {
    label: 'Tháng trước',
    value: [
      dayjs().subtract(1, 'month').startOf('month'),
      dayjs().endOf('month'),
    ],
  },
  {
    label: 'Tùy chọn',
    value: [],
  },
]

export const transactionSelect = [
  {
    value: null,
    label: 'Tất cả',
  },
  {
    value: TRANSACTION_STATUS['NOT_CREATE_INVOICE'],
    label: 'Chưa xuất hóa đơn',
  },
  {
    value: TRANSACTION_STATUS['CREATED_INVOICE'],
    label: 'Chưa phát hành',
  },
  {
    value: TRANSACTION_STATUS['RELEASED_INVOICE'],
    label: 'Đã phát hành',
  },
  {
    value: TRANSACTION_STATUS['MARKED'],
    label: 'Đã đánh dấu',
  },
  {
    value: TRANSACTION_STATUS['RELEASING_INVOICE'],
    label: 'Đang xuất hóa đơn',
  },
  {
    value: TRANSACTION_STATUS['RELEASE_ERROR'],
    label: 'Lỗi phát hành',
  },
  {
    value: TRANSACTION_STATUS['CANCELLED'],
    label: 'Đã hủy',
  },
]
