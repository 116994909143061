import ModalConfirm from 'general/components/Modal/ModalConfirm'
import AppResource from 'general/constants/AppResource'
import {
  prefixPath,
  SIDEBAR_MENU_ITEMS,
  SIDEBAR_PERSONAL_INCOME_TAX,
  SIDEBAR_TICKET_CONTENT,
} from 'general/constants/SidebarContent'
import Utils from 'general/utils/Utils'
import useRouter from 'hooks/useRouter'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import '../style.scss'

let pathnameToNavigate = ''
const SideMenuContent = () => {
  const { t } = useTranslation()
  const { pathname, navigate } = useRouter()
  const partsName = pathname.split('/')
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const isCreateInvoicePage = useMemo(() => {
    return (
      partsName.includes('hoa-don') &&
      (partsName.includes('them-moi') ||
        partsName.includes('dieu-chinh') ||
        partsName.includes('thay-the') ||
        partsName.includes('dieu-chinh') ||
        partsName.includes('cap-nhat'))
    )
  }, [partsName])

  const { invoice, ticket, personalIncomeTax } = useSelector(
    (s) => s.auth.activeInvoice,
  )

  useEffect(() => {}, [invoice, ticket, personalIncomeTax])

  /**
   * @param {SidebarContent} menu
   * @param {number} idx
   */
  const renderMenuItems = (menu, idx) => {
    if (menu?.type === 'item') {
      const hasSubMenuItemsTemp = !!menu?.subMenuItems
      // setHasSubMenuItems(hasSubMenuItemsTemp)
      const tabIsActive = _.isEqual(pathname, menu.path)
      return (
        <li
          key={`menu_${menu?.text}_item_${idx}`}
          className={`menu-item ${hasSubMenuItemsTemp ? 'menu-item-submenu' : ''}  ${
            tabIsActive ||
            window.location.pathname.replace(prefixPath, '') === menu.path
              ? 'menu-item-active'
              : ''
          }  
          `
            .trim()
            .replace(/\s/g, ' ')}
        >
          <a
            href={menu?.path}
            onClick={(e) => {
              if (Utils.isObjectEmpty(menu.subMenuItems)) {
                if (menu.isDownload) {
                  e.preventDefault()
                  window.open(menu?.path, '_blank')
                  return
                }
                if (menu?.path) {
                  e.preventDefault()
                  if (isCreateInvoicePage) {
                    setShowModalConfirm(true)
                    pathnameToNavigate = menu?.path
                    return
                  }
                  navigate(menu?.path)
                }
              }
            }}
            className={`menu-link ${hasSubMenuItemsTemp ? 'menu-toggle' : ''}`}
          >
            {menu.icon ? (
              <span className="sgv-icon menu-icon">
                <i className={menu?.icon}></i>
              </span>
            ) : (
              <i className="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
            )}

            <span className="menu-text">{t(menu?.text)}</span>

            {menu?.label && (
              <span className="menu-label">
                <span className="label label-rounded label-danger label-inline">
                  {menu?.label}
                </span>
              </span>
            )}

            {hasSubMenuItemsTemp && <i className="menu-arrow" />}
          </a>
          {hasSubMenuItemsTemp ? (
            <div className="menu-submenu">
              <ul className="menu-subnav">
                {/* Using flatMap */}
                {menu.subMenuItems
                  .filter((root) => !!root)
                  .map(renderMenuItems)}

                {/* {menu.subMenuItems
                  .filter((item) => item !== null)
                  .map((root, rootIdx) => renderMenuItems(root, rootIdx))} */}
              </ul>
            </div>
          ) : null}
        </li>
      )
    } else {
      return (
        <li
          key={`menu_${menu?.text}_item_${idx}`}
          className="menu-section mt-0"
        >
          <h4
            className="menu-text"
            style={{
              color: AppResource.colors.feature,
            }}
          >
            {t(menu?.text)}
          </h4>
        </li>
      )
    }
  }
  return (
    <>
      <ul className="menu-nav pt-0">
        {invoice
          ? SIDEBAR_MENU_ITEMS.map(renderMenuItems)
          : ticket
            ? SIDEBAR_TICKET_CONTENT.map(renderMenuItems)
            : personalIncomeTax
              ? SIDEBAR_PERSONAL_INCOME_TAX.map(renderMenuItems)
              : null}
      </ul>
      <ModalConfirm
        header="Xác nhận"
        content="Bạn chưa lưu dữ liệu. Bạn có muốn tiếp tục không"
        show={showModalConfirm}
        setShow={setShowModalConfirm}
        handleConfirm={(e) => {
          e.preventDefault()
          setShowModalConfirm(false)
          if (pathnameToNavigate === '/hoa-don-dau-ra/hoa-don/them-moi') {
            window.location.href = '/hoa-don-dau-ra/hoa-don/them-moi'
          } else {
            navigate(pathnameToNavigate)
          }
        }}
      />
    </>
  )
}

export default SideMenuContent
