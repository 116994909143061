import CustomButton from 'general/components/Button/CustomButton'
import PropTypes from 'prop-types'
import { IMPORT_TICKET_STEPS } from 'general/constants/AppConstants'
import { useAppSelector } from 'hooks/useRedux'
import { useMemo } from 'react'

ImportTicketFooter.propTypes = {
  handleIncreaseStep: PropTypes.func,
  handleDecreaseStep: PropTypes.func,
  handleCheckRowsData: PropTypes.func,
}

ImportTicketFooter.defaultProps = {
  handleIncreaseStep: () => {},
  handleDecreaseStep: () => {},
  handleCheckRowsData: () => {},
}
function ImportTicketFooter(props) {
  const {
    currentStep,
    handleDecreaseStep,
    handleIncreaseStep,
    handleCheckRowsData,
    handleDownloadFileCheck,
    handleUploadRows,
    notViewCancel,
  } = props

  const { checkRowsInfo } = useAppSelector((state) => state.importTicket)

  const validRowNum = useMemo(
    () =>
      checkRowsInfo?.rowWithUploadErrors?.filter(
        ({ uploadErrors }) => !uploadErrors,
      )?.length,
    [checkRowsInfo?.rowWithUploadErrors],
  )
  const rowNum = useMemo(
    () => checkRowsInfo?.rowWithUploadErrors?.length,
    [checkRowsInfo?.rowWithUploadErrors],
  )

  return (
    <div
      className="ImportTicketFooter w-100 pt-5 pb-1"
      style={{ minHeight: '60px' }}
    >
      <div
        className="d-flex flex-row justify-content-between align-items-center"
        style={{ gap: '10px' }}
      >
        {!notViewCancel ? (
          <CustomButton
            text="Hủy bỏ"
            backgroundColor="white"
            color="#041847"
            width="120px"
            borderColor="#ccc"
            onClick={handleDecreaseStep}
            height="36px"
          />
        ) : (
          <div></div>
        )}

        <div
          className="d-flex flex-row align-items-center"
          style={{ gap: '10px' }}
        >
          {currentStep == 2 && (
            <>
              {checkRowsInfo && (
                <div
                  className="d-flex flex-row bg-white border border-primary p-3"
                  style={{ borderRadius: '8px' }}
                >
                  <div className="mr-2">
                    <i
                      className="fa-solid fa-circle-check"
                      style={{ color: '#00B416' }}
                    ></i>
                  </div>

                  <div>
                    <p className="text-dark font-weight-bold mb-1">
                      {`${validRowNum}/${rowNum} dòng dữ liệu hợp lệ`}
                    </p>
                    <p className="mb-0">
                      Tải tệp kết quả kiểm tra dữ liệu{' '}
                      <a
                        className="text-primary"
                        onClick={(e) => {
                          e.preventDefault()
                          handleDownloadFileCheck()
                        }}
                      >
                        tại đây
                      </a>
                    </p>
                  </div>
                </div>
              )}

              <CustomButton
                text="Kiểm tra dữ liệu"
                backgroundColor="white"
                showBorder={false}
                color="#1877f2"
                onClick={handleCheckRowsData}
                height="36px"
              />
            </>
          )}

          {currentStep > 1 && (
            <CustomButton
              text="Quay lại"
              backgroundColor="white"
              // showBorder={false}
              color="#041847"
              width="120px"
              borderColor="#ccc"
              onClick={handleDecreaseStep}
              height="36px"
            />
          )}

          {currentStep < IMPORT_TICKET_STEPS.length - 1 && (
            <CustomButton
              text="Tiếp theo"
              backgroundColor="#1877f2"
              showBorder={false}
              color="white"
              width="120px"
              onClick={handleIncreaseStep}
              height="36px"
            />
          )}

          {currentStep == IMPORT_TICKET_STEPS.length - 1 && (
            <CustomButton
              text="Nhập khẩu"
              backgroundColor="#1877f2"
              showBorder={false}
              color="white"
              width="120px"
              onClick={handleUploadRows}
              height="36px"
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ImportTicketFooter
