import Empty from 'general/components/Empty'
import ProtectedRoute from 'general/components/Routes/ProtectedRoute'
import AppResource from 'general/constants/AppResource'
import useCheckPermission from 'hooks/useCheckPermission'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useNavigate } from 'react-router-dom'
import DashboardTab from './screens/DashboardTab'
import { useEffect } from 'react'
import TaxDeduction from 'features/TaxDeduction'

Dashboard.propTypes = {}

function Dashboard({ personalIncomeTax }) {
  const navigate = useNavigate()
  // MARK: --- Params ---
  const { t } = useTranslation()

  const { isLoadingPermission, hasAccess } = useCheckPermission([
    {
      keyAction: 'hasAccess',
      permissionCode: 'TABLE_WORK_VIEW_STATISTICS_REPORT',
    },
  ])

  useEffect(() => {
    if (personalIncomeTax) {
      navigate(
        // '/hoa-don-dau-ra/chung-tu-tncn/khau-tru-thue-thu-nhap-ca-nhan?tab=2',
        '/hoa-don-dau-ra/chung-tu-tncn/khau-tru-thue-thu-nhap-ca-nhan',
      )
    }
  }, [personalIncomeTax])

  return (
    <Routes>
      <Route
        path=""
        element={
          <ProtectedRoute
            isLoading={isLoadingPermission}
            permission={hasAccess}
          >
            {personalIncomeTax ? <TaxDeduction /> : <DashboardTab />}
          </ProtectedRoute>
        }
      />

      <Route
        path="*"
        element={
          <Container fluid className="h-100 d-flex">
            <Row className="justify-content-center align-items-center flex-grow-1">
              <Empty
                text={t('PageNotFound')}
                buttonText={t('Refresh')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
              />
            </Row>
          </Container>
        }
      />
    </Routes>
  )
}

export default Dashboard
