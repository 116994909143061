import { Space } from 'antd'
import dayjs from 'dayjs'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import { IconAction } from 'general/components/GroupButton/styles'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import SelectOptions from 'general/constants/SelectOptions'
import InvoiceHelper from 'general/helpers/InvoiceHelper'
import Utils from 'general/utils/Utils'
import useGetSerials from 'hooks/Queries/useGetSerials'
import {
  setModalsState,
  setSelectedInvoices,
} from 'features/Invoice/invoiceSlice'
import {
  useQueryListInvoice,
  useMutationCancelInvoice,
  useMutationGetBulkMessage,
  useMutationDeleteInvoice,
  useMutationSendExplanationAnnounce,
} from '../ListInvoice/queries'
import useAlertMessage from 'hooks/useAlertMessage'
import useAppDnD from 'hooks/useAppDnD'
import useCheckPermission from 'hooks/useCheckPermission'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import useFilter from 'hooks/useFilter'
import useRouter from 'hooks/useRouter'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _ from 'lodash'
import { shallowEqual } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import SendInvoiceModal from 'general/components/Modal/SendInvoiceModal'
import CancelInvoiceModal from 'general/components/Modal/CancelInvoiceModal'
import ModalConfirm from 'general/components/Modal/ModalConfirm'
import ModalDataTransmissionLog from 'general/components/ModalDataTransmissionLog'
import { RenderInvoiceActionButtons, TableActions } from './Component'
import ExplanationInvoiceModal from 'general/components/Modal/ExplanationInvoiceModal'
import ModalSelectAdjustInvoiceMethod from 'general/components/Modal/ModalSelectAdjustInvoiceMethod'
import { INVOICE_STATUS } from 'general/constants/AppConstants'
import ModalSelectCancellationMethod from 'general/components/Modal/ModalSelectCancellationMethod'
import { CloneMultiInvoiceModal } from 'general/components/Modal/CloneMultiInvoiceModal'

export default function ListGoodsDeliveryNote() {
  //#region ------------State---------------------
  const { canCreateInvoice } = useCheckPermission([
    {
      keyAction: 'canCreateInvoice',
      permissionCode: 'INVOICE_ADD_ORIGINAL_INVOICE',
    },
    {
      keyAction: 'isDeleteInvoice',
      permissionCode: 'INVOICE_DELETE_ORIGINAL_INVOICE',
    },
    {
      keyAction: 'isSendInvoiceToCustomer',
      permissionCode: 'INVOICE_SEND_INVOICE_TO_CUSTOMER_ORIGINAL',
    },
    {
      keyAction: 'isCreateReplacementInvoice',
      permissionCode: 'INVOICE_CREATE_REPLACEMENT_INVOICE',
    },
  ])

  const { navigate } = useRouter()
  const { openAlert, contextHolder } = useAlertMessage()
  // const { fireError } = useFireSwal()
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const [filterParams, setFilterParams] = useFilter(
    'goodsDeliveryNoteList',
    'gFilterGoodsDeliveryNoteList',
  )
  const { data: serials } = useGetSerials('PXK')
  const { data: deleveryBill } = useQueryListInvoice(
    debouncedSearch,
    filterParams,
  )
  const [activeInvoice, setActiveInvoice] = useState(null)
  const { selectedInvoices, toggledClearRows, modalStates } = useAppSelector(
    (state) => state.invoice,
    shallowEqual,
  )

  const [cloneMultiInvoiceModal, setCloneMultiInvoiceModal] = useState({
    show: false,
    invoiceId: null,
  })

  const { refetchListInvoice } = useQueryListInvoice(
    debouncedSearch,
    filterParams,
  )
  // const dataSource = useMemo(() => {
  //   return deleveryBill?.tableData
  // }, [filterParams.limit, filterParams.page])

  const filterDefault = useMemo(() => {
    /** @type {FilterDefaultValueProp[]} */
    const arr = [
      {
        alias: 'Ký hiệu',
        label: 'Ký hiệu hóa đơn',
        formName: 'serials',
        multiselect: true,
        options: serials,
        value: null,
        colSpan: 'FULL',
      },
      {
        alias: 'Số',
        label: ['Hoá đơn từ số', 'Đến số'],
        separator: 'đến',
        formName: ['fromNumberInvoice', 'toNumberInvoice'],
        value: [null, null],
        restrict: {
          type: 'number',
          min: 1,
          max: 99999999,
        },
      },
      {
        label: 'Trạng thái HĐ',
        alias: 'TT HĐ',
        formName: 'type',
        options: SelectOptions.invoiceType,
        value: null,
      },
      {
        label: 'Trạng thái phát hành',
        alias: 'Loại HĐ',
        formName: 'issueStatus',
        options: SelectOptions.issueStatus,
        value: null,
      },
      {
        label: 'Trạng thái gửi hoá đơn',
        alias: 'TT gửi HĐ',
        formName: 'isSent',
        options: SelectOptions.isSent,
        value: null,
      },
      {
        label: 'Trạng thái phê duyệt',
        alias: 'TT phê duyệt',
        formName: 'confirmed',
        options: SelectOptions.confirmStatus,
      },
    ]
    return arr
  }, [serials])

  const defaultColumns = useMemo(() => {
    /** @type {CustomeTableColumn<GoodsDeliveryNotes>[]} */
    const arr = [
      // {
      //   id: 'sellerName',
      //   name: 'Đơn vị',
      //   minWidth: '210px',
      //   center: true,
      //   hidden: true,
      //   selector: (row) => row?.sellerName,
      // },
      {
        id: 'serial',
        name: 'Ký hiệu',
        minWidth: '110px',
        center: true,
        selector: (row) => row?.serial,
      },
      {
        id: 'invoiceNo',
        name: 'Số hoá đơn',
        center: true,
        style: {
          justifyContent: 'flex-start',
        },
        selector: (row) => row?.no,
      },
      {
        id: 'date',
        name: 'Ngày HĐ',
        center: true,
        format: (row) =>
          dayjs(row?.date).isValid()
            ? dayjs(row?.date).format('DD/MM/YYYY')
            : '',
        selector: (row) => row.date,
      },
      {
        id: 'buyerName',
        name: 'Số lệnh điều động hoặc HĐ kinh tế',
        formName: 'buyername',
        minWidth: '200px',
        center: true,
        hidden: true,
        selector: (row) => row?.buyerName,
      },
      {
        id: 'deliveryOrderDate',
        name: 'Ngày lệnh điều động hoặc HĐ kinh tế',
        center: true,
        minWidth: '200px',
        format: (row) =>
          dayjs(row?.deliveryOrderDate).isValid()
            ? dayjs(row?.deliveryOrderDate).format('DD/MM/YYYY')
            : '',
        selector: (row) => row.deliveryOrderDate,
      },
      {
        id: 'consigneeUnitName',
        name: 'Đại lý/Đơn vị nhận hàng',
        minWidth: '220px',
        formName: 'consigneeUnitName',
        center: true,
        selector: (row) => row?.consigneeUnitName || row?.invoiceNote,
      },
      {
        id: 'consigneeUnitTaxcode',
        name: 'Mã số thuế đại lý/đơn vị nhận hàng',
        formName: 'consigneeUnitTaxcode',
        minWidth: '200px',
        center: true,
        selector: (row) => row?.consigneeUnitTaxcode ?? row?.buyerTaxCode,
      },
      {
        id: 'deliveryBy',
        name: 'Tên người vận chuyển',
        formName: 'deliveryBy',
        center: true,
        hidden: true,
        selector: (row) => row?.deliveryBy,
      },
      {
        id: 'containerNumber',
        name: 'Hợp đồng vận chuyển',
        formName: 'containerNumber',
        hidden: true,
        center: true,
        selector: (row) => row?.containerNumber,
      },
      {
        id: 'transportationMethod',
        name: 'Phương tiện vận chuyển',
        formName: 'transportationMethod',
        center: true,
        hidden: true,
        selector: (row) => row?.transportationMethod,
      },
      {
        id: 'stockInFullName',
        name: 'Tên người nhận hàng',
        formName: 'stockInFullName',
        center: true,
        hidden: true,
        selector: (row) => row?.stockInFullName || row?.importerName,
      },
      {
        id: 'addressWarehouse',
        name: 'Địa chỉ kho xuất hàng',
        formName: 'addressWarehouse',
        minWidth: '200px',
        hidden: true,
        center: true,
        selector: (row) => row?.addressWarehouse ?? row?.fromWarehouseName,
      },
      {
        id: 'consigneeUnitAddress',
        name: 'Địa điểm nhận hàng',
        minWidth: '200px',
        formName: 'consigneeUnitAddress',
        center: true,
        hidden: true,
        selector: (row) => row?.consigneeUnitAddress ?? row?.toWarehouseName,
      },
      {
        id: 'isPayment',
        name: 'Thanh toán',
        center: true,
        minWidth: '150px',
        allowOverflow: true,
        hidden: true,
        format: (row) => InvoiceHelper.renderIsPaid(row.isPayment),
        selector: (row) => row.isPayment,
      },
      {
        id: 'total',
        name: 'Tổng tiền',
        center: true,
        style: {
          justifyContent: 'flex-end',
        },
        format: (row) => Utils.formatNumber(row?.total),
        selector: (row) => row?.total,
      },
      {
        id: 'type',
        name: 'Trạng thái HĐ',
        center: true,
        minWidth: '150px',
        style: {
          justifyContent: 'flex-start',
        },
        format: (row) => {
          const { color, text } = InvoiceHelper.renderInvoiceType(row?.type)
          return (
            <div className={`badge badge-light-${color}`}>
              {_.upperFirst(text)}
            </div>
          )
        },
        selector: (row) => row?.type,
      },
      {
        id: 'issueStatus',
        name: 'Trạng thái phát hành',
        minWidth: '150px',
        center: true,
        format: (row) => {
          const { color, text } = InvoiceHelper.renderIssueStatus(
            row.issueStatus,
          )
          return <div className={`badge badge-light-${color}`}>{text}</div>
        },
        selector: (row) => row.issueStatus,
      },
      {
        id: 'dateSellerSign',
        name: 'Thời gian phát hành',
        center: true,
        hidden: true,
        format: (row) =>
          dayjs(row?.dateSellerSign).format('DD/MM/YYYY') !== '01/01/3000'
            ? dayjs(row?.dateSellerSign).format('DD/MM/YYYY')
            : '',
        selector: (row) => {
          return dayjs(row?.dateSellerSign).format('DD/MM/YYYY') !==
            '01/01/3000'
            ? row.dateSellerSign
            : ''
        },
      },
      {
        id: 'lookupCode',
        name: 'Mã tra cứu',
        center: true,
        hidden: true,
        omit: false,
        maxWidth: '150px',
        selector: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 "
            >
              {row?.lookupCode}
            </p>
          )
        },
      },
      {
        id: 'isSent',
        name: 'Gửi mail',
        width: '100px',
        center: true,
        cell: (row) => (
          <IconAction
            className={
              row?.isSent === 0
                ? 'fa-duotone fa-do-not-enter text-light-dark'
                : 'fa-duotone fa-circle-check'
            }
            style={
              row?.isSent !== 0
                ? {
                    '--fa-primary-color': '#fff',
                    '--fa-secondary-color': '#50cd89',
                    '--fa-secondary-opacity': 1,
                  }
                : {}
            }
          />
        ),
      },
      {
        name: 'Hành động',
        center: true,
        disabled: true,
        compact: true,
        minWidth: '200px',
        style: {
          justifyContent: 'flex-end',
        },
        cell: (row) => (
          <TableActions
            row={row}
            navigate={navigate}
            activeInvoice={activeInvoice}
            setActiveInvoice={setActiveInvoice}
            filterParams={filterParams}
            modalStates={modalStates}
            selectedInvoices={selectedInvoices}
            setCloneMultiInvoiceModal={setCloneMultiInvoiceModal}
          />
        ),
      },
    ]
    return arr
  }, [navigate, activeInvoice, filterParams, modalStates, selectedInvoices])

  const dynamicColumns = useAppDnD(defaultColumns)

  const dispatch = useAppDispatch()

  const { mutate: checkMessages, isPending: checkingBulkMessage } =
    useMutationGetBulkMessage()
  const { mutate: deleteInvoiceMutation } = useMutationDeleteInvoice()
  const { mutate: sendExplanationAnnounceToCustomer } =
    useMutationSendExplanationAnnounce()
  //#endregion ------------State---------------------

  //#region ------------Effects---------------------
  useEffect(() => {
    setFilterParams({
      q: debouncedSearch,
      page: 1,
    })
  }, [debouncedSearch, setFilterParams])
  //#endregion

  const { mutate: cancelInvoice } = useMutationCancelInvoice()

  return (
    <ContentContainer wrapperClassName="list-goods-delivery-note">
      <ContentContainer.Header
        titleContent="Phiếu xuất kho"
        toolBar={
          <Space>
            <CustomAntButton
              text="Tạo phiếu xuất kho"
              antProps={{
                type: 'primary',
                onClick: () =>
                  canCreateInvoice
                    ? navigate(
                        '/hoa-don-dau-ra/hoa-don/phieu-xuat-kho/them-moi',
                      )
                    : openAlert.error('Bạn không có quyền tạo phiếu xuất kho'),
                // fireError('Lỗi', 'Bạn không có quyền tạo phiếu xuất kho'),
              }}
              iconCn={'fa-regular fa-plus text-white'}
            />

            {/* tạm ẩn tính năng upload excel */}
            {/* <CustomAntButton
              text="Upload excel"
              variant="success"
              iconCn="fa-regular fa-file-arrow-up"
              antProps={{
                type: 'primary',
              }}
            /> */}
            <CustomAntButton
              iconCn={Utils.cn(
                'far fa-rotate',
                checkingBulkMessage && 'fa-spin',
              )}
              antProps={{
                type: 'default',
                title: 'Kiểm tra',
                className: 'p-3',
                shape: 'round',
                disabled: checkingBulkMessage,
                onClick: () =>
                  checkMessages(_.pick(filterParams, ['startDate', 'endDate'])),
              }}
              customStyle={{
                minWidth: 'fit-content',
              }}
            />
            <AppDnD
              defaultColumns={defaultColumns}
              localStorageName={PreferenceKeys.savedColumnsPxk}
            />
          </Space>
        }
      >
        <RenderInvoiceActionButtons
          selectedInvoices={selectedInvoices}
          refetchListInvoice={refetchListInvoice}
        />
        <Space
          wrap
          className={Utils.cn(selectedInvoices.length > 0 ? 'd-none' : '')}
        >
          <CustomAntInput
            type="search"
            borderRadius="sm"
            width={150}
            inputProps={{
              value: search,
              onChange: (e) => setSearch(e.target.value),
            }}
          />
          <FilterPopover
            menuPosition="bottom"
            defaultValues={filterDefault}
            savedKey={'gFilterGoodsDeliveryNoteList'}
            onApply={setFilterParams}
          />
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={dynamicColumns}
          dataSource={deleveryBill?.tableData}
          pagination={{
            currentPage: filterParams.page,
            rowsPerPage: filterParams.limit,
            total: deleveryBill?.total,
            onChangePage: (page) => setFilterParams({ page: _.toNumber(page) }),
            onChangeRowsPerPage: (limit) =>
              setFilterParams({ limit: _.toNumber(limit), page: 1 }),
          }}
          isSelectable
          selectedRows={{
            clearSelectedRows: toggledClearRows,
            handleSelectedRows: ({ selectedRows }) =>
              dispatch(setSelectedInvoices(selectedRows)),
            selectableRowSelected: (row) => _.includes(selectedInvoices, row),
          }}
        />
      </ContentContainer.Body>
      {contextHolder}

      <CancelInvoiceModal
        show={modalStates.cancel}
        onHide={() =>
          dispatch(
            setModalsState({
              modalName: 'cancel',
              status: false,
            }),
          )
        }
        title="Huỷ hoá đơn"
        // invoiceId={selectedInvoiceId}
        invoice={activeInvoice}
        buttonAgreeOnClick={(value) =>
          cancelInvoice({ ...value, invoiceId: activeInvoice.invoiceId })
        }
      />
      <ModalDataTransmissionLog
        show={modalStates.transmission}
        onHide={() =>
          dispatch(
            setModalsState({
              modalName: 'transmission',
              status: false,
            }),
          )
        }
        invoice={activeInvoice}
      />

      <SendInvoiceModal
        invoiceId={activeInvoice?.invoiceId}
        invoice={activeInvoice}
        show={modalStates.sendDraftInvoice}
        onHide={() =>
          dispatch(
            setModalsState({
              modalName: 'sendDraftInvoice',
              status: false,
            }),
          )
        }
      />

      <ModalConfirm
        header="Xác nhận"
        content="Chương trình chỉ thực hiện xóa các hóa đơn chưa cấp số và có trạng thái chưa phát hành. Bạn có muốn tiếp tục không?"
        show={modalStates.delete}
        setShow={(status) => {
          dispatch(
            setModalsState({
              modalName: 'delete',
              status,
            }),
          )
        }}
        handleConfirm={(e) => {
          e.preventDefault()
          deleteInvoiceMutation({
            invoices:
              selectedInvoices.length > 0 ? selectedInvoices : [activeInvoice],
          })
        }}
      />

      <ExplanationInvoiceModal
        show={modalStates.explanation}
        onHide={() =>
          dispatch(
            setModalsState({
              modalName: 'explanation',
              status: false,
            }),
          )
        }
        title="Gửi thông báo giải trình cho khách hàng"
        invoice={activeInvoice}
        buttonAgreeOnClick={sendExplanationAnnounceToCustomer}
      />

      <ModalSelectAdjustInvoiceMethod
        show={modalStates.selectAdjustInvoiceMethod}
        setShow={(status) =>
          dispatch(
            setModalsState({
              modalName: 'selectAdjustInvoiceMethod',
              status,
            }),
          )
        }
        header="Xác nhận"
        content="Bạn có thể lập biên bản điện tử để thỏa thuận với người mua trước khi lập hóa đơn điều chỉnh."
        createAdjustedReportSelect={
          (activeInvoice?.issueStatus === INVOICE_STATUS.AUTHORITY_ACCEPT ||
            activeInvoice?.issueStatus ===
              INVOICE_STATUS.AUTHORITY_CODE_GIVEN) &&
          [0, 5].includes(activeInvoice?.type) &&
          activeInvoice?.reportStatus == 1
            ? () =>
                navigate(
                  `/hoa-don-dau-ra/hoa-don/bien-ban-dieu-chinh/them-moi/${activeInvoice?.invoiceId}?invoiceClassify=PXK`,
                )
            : null
        }
        createAdjustAnnounceSelect={
          [1, 4, 6].includes(activeInvoice?.invoiceErrAnnouceStatus)
            ? () =>
                navigate(
                  `/hoa-don-dau-ra/xu-ly-hoa-don/thong-bao-sai-sot/${activeInvoice?.invoiceId}?invoiceClassify=PXK`,
                )
            : null
        }
        adjustInvoiceSelect={
          [0, 5].includes(activeInvoice?.type)
            ? () => {
                navigate(
                  `/hoa-don-dau-ra/xu-ly-hoa-don/phieu-xuat-kho/dieu-chinh/${activeInvoice?.invoiceId}`,
                )
              }
            : null
        }
      />
      <ModalConfirm
        header="Xác nhận"
        content="Bạn cần hủy hóa đơn bị sai sót trước khi lập hóa đơn thay thế. Bạn có muốn hủy hóa đơn này không?"
        show={modalStates.selectReplacementMethod}
        setShow={(status) =>
          dispatch(
            setModalsState({ modalName: 'selectReplacementMethod', status }),
          )
        }
        handleConfirm={(e) => {
          e.preventDefault()
          dispatch(
            setModalsState({
              modalName: 'selectReplacementMethod',
              status: false,
            }),
          )
          dispatch(
            setModalsState({
              modalName: 'selectCancelationMethod',
              status: true,
            }),
          )
        }}
      />
      <ModalSelectCancellationMethod
        show={modalStates.selectCancelationMethod}
        setShow={(status) =>
          dispatch(
            setModalsState({
              modalName: 'selectCancelationMethod',
              status,
            }),
          )
        }
        header="Xác nhận"
        content="Bạn có thể lập thông báo hóa đơn điện tử có sai sót gửi cơ quan thuế và lập biên bản điện tử để thỏa thuận với người mua trước khi thực hiện hủy hóa đơn."
        createCancellationReportSelect={
          (activeInvoice?.issueStatus === INVOICE_STATUS.AUTHORITY_ACCEPT ||
            activeInvoice?.issueStatus ===
              INVOICE_STATUS.AUTHORITY_CODE_GIVEN) &&
          [0, 1, 3].includes(activeInvoice?.type) &&
          activeInvoice?.reportStatus == 1
            ? () =>
                navigate(
                  `/hoa-don-dau-ra/hoa-don/bien-ban-huy/them-moi/${activeInvoice?.invoiceId}`,
                )
            : null
        }
        createErrorAnnounceSelect={
          [1, 4, 6].includes(activeInvoice?.invoiceErrAnnouceStatus)
            ? () =>
                navigate(
                  `/hoa-don-dau-ra/xu-ly-hoa-don/thong-bao-sai-sot/${activeInvoice?.invoiceId}`,
                )
            : null
        }
        cancelInvoiceSelect={
          [0, 1].includes(activeInvoice?.type)
            ? () => {
                dispatch(
                  setModalsState({
                    modalName: 'selectCancelationMethod',
                    status: false,
                  }),
                )
                dispatch(
                  setModalsState({
                    modalName: 'cancel',
                    status: true,
                  }),
                )
              }
            : null
        }
      />
      <CloneMultiInvoiceModal
        show={modalStates.cloneMultiInvoiceModal}
        type="hd"
        invoiceId={cloneMultiInvoiceModal.invoiceId}
        onOpenChange={(value) =>
          dispatch(
            setModalsState({
              modalName: 'cloneMultiInvoiceModal',
              status: value,
            }),
            setCloneMultiInvoiceModal({
              ...cloneMultiInvoiceModal,
              show: value,
            }),
          )
        }
        reloadList={refetchListInvoice}
      ></CloneMultiInvoiceModal>
    </ContentContainer>
  )
}
