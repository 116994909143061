import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import CardAreaChartV2 from 'features/Dashboard/components/CardAreaChartV2'
import CardDonutChartV2 from 'features/Dashboard/components/CardDonutChartV2'
import CardInvoiceStatus from 'features/Dashboard/components/CardInvoiceStatus'
import CardPieChartV2 from 'features/Dashboard/components/CardPieChartV2'
import { queriesKeys } from 'features/Dashboard/queries'
import CustomAntRangePicker from 'general/components/CustomAntRangePicker'
import SelectOptions from 'general/constants/SelectOptions'
import Global from 'general/utils/Global'
import Utils from 'general/utils/Utils'
import { useAppSelector } from 'hooks/useRedux'
import _ from 'lodash'
import { useState } from 'react'
import { shallowEqual } from 'react-redux'

export default function DashboardCharts() {
  const [filterParams_usedInvoiceByDate, setFilterParams_usedInvoiceByDate] =
    useState(Global.gFiltersUsedInvoicesByDay)
  const [filterParams_statisticInvoice, setFilterParams_statisticInvoice] =
    useState(Global['gFiltersStatisticInvoice'])

  const [
    filterParams_invoiceValueByCustomer,
    setFilterParams_invoiceValueByCustomer,
  ] = useState(Global['gFiltersInvoiceValue'])

  const [filterParams_allInvoice, setFilterParams_allInvoice] = useState(
    Global['gFiltersAllInvoice'],
  )

  //Queries

  const {
    data: statusOfUsingInvoiceByDate,
    isLoading: isLoadingStatusOfUsingInvoiceByDate,
  } = useQuery(
    queriesKeys.createdInvoicesByDay(
      _.omit(filterParams_usedInvoiceByDate, ['period']),
    ),
  )

  const { data: statisticInvoice, isLoading: isLoadingStatisticInvoice } =
    useQuery(
      queriesKeys.statisticInvoice(
        _.omit(filterParams_statisticInvoice, ['period']),
      ),
    )

  const { data: invoiceValueByBuyer, isLoading: isLoadingInvoiceValueByBuyer } =
    useQuery(
      queriesKeys.totalInvoiceValueByCustomer(
        _.omit(filterParams_invoiceValueByCustomer, ['period']),
      ),
    )

  const { data: allInvoiceByStatus, isLoading: isLoadingAllInvoiceByStatus } =
    useQuery(
      queriesKeys.allInvoiceByStatus(
        _.omit(filterParams_allInvoice, ['period']),
      ),
    )

  const systemType = useAppSelector((s) => s.auth.activeInvoice, shallowEqual)

  return (
    <div className="row m-0">
      <div className="col-lg-8 my-4">
        <CardAreaChartV2
          fill
          fullChartLabels={Utils.getNewRangeDate(
            filterParams_usedInvoiceByDate,
          )}
          loading={isLoadingStatusOfUsingInvoiceByDate}
          title={
            systemType?.ticket
              ? 'Tình hình sử dụng vé theo thời gian'
              : 'Tình hình sử dụng hóa đơn theo thời gian'
          }
          chartLabels={statusOfUsingInvoiceByDate?.map((item) => item.date)}
          chartSeries={[
            {
              name: systemType?.ticket ? 'Vé' : 'Hóa đơn',
              data: statusOfUsingInvoiceByDate?.map((item) =>
                _.get(item, 'count', 0),
              ),
            },
          ]}
          chartColors={['#18CDBD', '#FF9427']}
          headerSidebar={
            <CustomAntRangePicker
              className="max-w-250px min-w-150px"
              dateFormat="L"
              defaultValue={[
                dayjs(filterParams_usedInvoiceByDate.startDate),
                dayjs(filterParams_usedInvoiceByDate.endDate),
              ]}
              defaultPeriod={filterParams_usedInvoiceByDate.period}
              onChange={(range, period) => {
                setFilterParams_usedInvoiceByDate({
                  period,
                  startDate: range[0]?.format(),
                  endDate: range[1]?.format(),
                })
              }}
            />
          }
        />
      </div>
      <div className="col-lg-4 my-4">
        <CardDonutChartV2
          loading={isLoadingStatisticInvoice}
          title={
            systemType?.ticket
              ? 'Tình hình phát hành vé'
              : 'Tình hình phát hành hóa đơn'
          }
          chartLabels={
            systemType?.ticket
              ? ['Đã phát hành', 'Chưa phát hành']
              : ['CQT chấp nhận', 'CQT từ chối', 'chờ CQT']
          }
          chartSeries={
            systemType?.ticket
              ? [
                  statisticInvoice?.total_issued,
                  statisticInvoice?.total_not_issued,
                ]
              : [
                  statisticInvoice?.accepted,
                  statisticInvoice?.rejected,
                  statisticInvoice?.waiting,
                ]
          }
          chartColors={['#32BBFF', 'red', '#FFBA26']}
          headerSidebar={
            <CustomAntRangePicker
              className="flex-fill"
              dateFormat="L"
              defaultValue={[
                dayjs(filterParams_statisticInvoice.startDate),
                dayjs(filterParams_statisticInvoice.endDate),
              ]}
              defaultPeriod={filterParams_statisticInvoice.period}
              onChange={(range, period) => {
                setFilterParams_statisticInvoice({
                  period,
                  startDate: range[0]?.format(),
                  endDate: range[1]?.format(),
                })
              }}
            />
          }
        />
      </div>
      <div className="col-lg-6 my-4">
        <CardPieChartV2
          loading={isLoadingInvoiceValueByBuyer}
          title={
            systemType?.ticket
              ? 'Giá trị vé theo người bán'
              : 'Giá trị hóa đơn theo người mua'
          }
          chartLabels={invoiceValueByBuyer?.map((item) => item.customer)}
          chartSeries={invoiceValueByBuyer?.map((item) => item.totalMoney)}
          headerSidebar={
            <CustomAntRangePicker
              dateFormat="L"
              className="max-w-250px min-w-150px"
              defaultValue={[
                dayjs(filterParams_invoiceValueByCustomer.startDate),
                dayjs(filterParams_invoiceValueByCustomer.endDate),
              ]}
              defaultPeriod={filterParams_invoiceValueByCustomer.period}
              onChange={(range, period) => {
                setFilterParams_invoiceValueByCustomer({
                  period,
                  startDate: range[0]?.format(),
                  endDate: range[1]?.format(),
                })
              }}
            />
          }
        />
      </div>
      <div className="col-lg-6 my-4">
        <CardInvoiceStatus
          title={systemType?.ticket ? 'Trạng thái vé' : 'Trạng thái hóa đơn'}
          isLoading={isLoadingAllInvoiceByStatus}
          data={allInvoiceByStatus}
          datePickerData={filterParams_allInvoice}
          headerSidebar={
            <CustomAntRangePicker
              className="max-w-250px min-w-150px"
              dateFormat="L"
              defaultValue={[
                dayjs(filterParams_allInvoice.startDate),
                dayjs(filterParams_allInvoice.endDate),
              ]}
              additionalFilter={[
                {
                  label: systemType?.ticket ? 'Loại vé' : 'Loại hóa đơn',
                  formName: 'invoiceClassify',
                  value: filterParams_allInvoice.invoiceClassify,
                  options: systemType?.ticket
                    ? SelectOptions.ticketClassify
                    : SelectOptions.invoiceClassify,
                  onAdditionalFilterChange: (value) => {
                    setFilterParams_allInvoice({
                      ...filterParams_allInvoice,
                      invoiceClassify: value,
                    })
                  },
                },
              ]}
              defaultPeriod={filterParams_allInvoice.period}
              onChange={(range, period, additionalFilter) => {
                setFilterParams_allInvoice({
                  ...filterParams_allInvoice,
                  period,
                  startDate: range[0]?.format(),
                  endDate: range[1]?.format(),
                  ...additionalFilter,
                })
              }}
            />
          }
        />
      </div>
    </div>
  )
}
