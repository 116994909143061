import { useQuery } from '@tanstack/react-query'
import { Space, Tooltip, theme } from 'antd'
import { setAppSpinning } from 'app/appSlice'
import customDataTableStyle from 'assets/styles/customDataTableStyle'
import dayjs from 'dayjs'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import FindInvoiceModal from 'general/components/Modal/FindInvoiceModal'
import ModalUnauthorized from 'general/components/Modal/ModalUnauthorized'
import { INVOICE_TYPE_TO_QUERY } from 'general/constants/AppConstants'
import SelectOptions from 'general/constants/SelectOptions'
import Utils from 'general/utils/Utils'
import useFilter from 'hooks/useFilter'
import useRouter from 'hooks/useRouter'
import useSearchDebounce from 'hooks/useSearchDebounce'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { queries } from './queries'
import './style.scss'
import InvoiceReportHelper from 'general/helpers/InvoiceReportHelper'
import invoiceReportApi from 'api/invoiceReportApi'
import InvoiceHelper from 'general/helpers/InvoiceHelper'
import ToastHelper from 'general/helpers/ToastHelper'
import invoiceApi from 'api/invoiceApi'

function ListReplacedInvoice() {
  // MARK --- Parmas: ---
  const { token } = theme.useToken()
  const [FindInvoiceModalShow, setFindInvoiceModalShow] = useState(false)
  const dispatch = useDispatch()

  const [filters, setFilters] = useFilter(
    'replacedInvoiceList',
    'gFilterListReplacedInvoice',
  )
  const [showNoRight, setShowNoRight] = useState(false)
  const listFunction = useSelector((state) => state?.function?.listFunctions)
  const userFunction = useSelector((state) => state?.function?.userFunctions)
  const [isCreateReplacementInvoice] = useMemo(() => {
    return Utils.checkFunction(
      [
        {
          listFunctionCode: 'INVOICE_CREATE_REPLACEMENT_INVOICE',
        },
      ],
      userFunction,
      listFunction,
    )
  }, [userFunction, listFunction])

  const { navigate } = useRouter()
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const {
    data: listReplacedData,
    isLoading: gettingListData,
    isFetching,
  } = useQuery(queries.list(filters))

  const defaultValues = useMemo(() => {
    /** @type {FilterDefaultValueProp} */
    const arr = [
      {
        label: 'Trạng thái phát hành',
        alias: 'Trạng thái phát hành',
        formName: 'statusReleaseInvoiceReplace',
        options: SelectOptions.statusReleaseInvoiceReplace,
        value: SelectOptions.statusReleaseInvoiceReplace[0].value,
      },
      {
        label: 'Tình trạng gửi hoá đơn',
        alias: 'Tình trạng gửi HĐ',
        formName: 'isSent',
        options: SelectOptions.isSent,
        value: SelectOptions.isSent[0].value,
      },
      {
        label: 'Trạng thái thông báo hoá đơn sai sót',
        alias: 'Trạng thái TBHĐ sai sót',
        formName: 'errAnnouceIssueStatus',
        options: SelectOptions.errAnnouceIssueStatus,
        value: SelectOptions.errAnnouceIssueStatus[0].value,
        colSpan: 'FULL',
      },
    ]
    return arr
  }, [])

  const columns = [
    {
      name: <span className="red-header">Đơn vị lập hoá đơn</span>,
      // renderHeadCell: () => <span style={{ color: 'red' }}>Name</span> ,
      width: '300px',
      center: true,
      className: 'red-header',
      cell: (row) => {
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
          >
            {row?.sellerName}
          </p>
        )
      },
    },
    {
      name: 'Biên bản điện tử',
      center: true,
      width: '160px',
      style: { justifyContent: 'space-between' },
      cell: (row) => {
        const no = row?.report?.no && Utils.padString(row?.report?.no, 8, '0')
        return (
          <>
            <Tooltip
              title={InvoiceReportHelper.renderInvoiceReportStatus(
                row?.reportStatus,
              )}
              placement="bottom"
            >
              <p
                data-tag="allowRowEvents"
                className={`text-hover-decoration font-weight-normal m-0 text-maxline-3 mr-4 ${
                  no ? 'text-danger' : 'text-primary'
                }`}
                onClick={() => {
                  // if (no) {
                  let invoiceId = row.invoiceId
                  if (row.reportStatus == 1) {
                    navigate(
                      `/hoa-don-dau-ra/hoa-don/bien-ban-thay-the/them-moi/${invoiceId}`,
                    )
                  }

                  if (row.reportStatus == 2) {
                    navigate(
                      `/hoa-don-dau-ra/hoa-don/bien-ban-thay-the/cap-nhat/${invoiceId}`,
                    )
                  }

                  if (![1, 2].includes(row.reportStatus)) {
                    navigate(
                      `/hoa-don-dau-ra/hoa-don/bien-ban-thay-the/xem/${invoiceId}`,
                    )
                  }
                  // }
                }}
              >
                {no ?? 'Lập biên bản'}
              </p>
            </Tooltip>
            {![1, 2].includes(row.reportStatus) && (
              <i
                className="fa-light fa-eye"
                style={{ color: 'inherit' }}
                title="Xem biên bản điện tử"
                onClick={async () => {
                  let res

                  dispatch(setAppSpinning(true))
                  try {
                    res = await invoiceReportApi.preview({
                      reportId: row.report?.reportId,
                    })
                    dispatch(setAppSpinning(false))

                    let _newFile = new File([res], 'Biên bản hủy.pdf', {
                      type: 'application/pdf',
                    })
                    var _fileURL = URL.createObjectURL(_newFile)
                    window.open(_fileURL, '_blank')
                  } catch (e) {
                    dispatch(setAppSpinning(false))
                  }
                }}
              ></i>
            )}
          </>
        )
      },
    },
    {
      name: <span className="red-header">Ký hiệu</span>,
      // sortable: false,
      // right: true,
      width: '110px',
      center: true,
      cell: (row) => {
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
          >
            {row?.serial}
          </p>
        )
      },
    },
    {
      name: <span className="red-header">Số</span>,
      width: '110px',
      center: true,
      cell: (row) => {
        const no = row?.no && Utils.padString(row?.no, 8, '0')
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
          >
            {no}
          </p>
        )
      },
    },
    {
      name: <span className="red-header">Ngày HĐ</span>,
      // sortable: false,
      width: '120px',
      center: true,
      cell: (row) => {
        const date = dayjs(row?.date)
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
          >
            {date.isValid() ? date.format('L') : ''}
          </p>
        )
      },
    },
    {
      name: <span className="red-header">Mã CQT</span>,
      width: '200px',
      center: true,
      cell: (row) => {
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
          >
            {row?.taxAuthorityCode}
          </p>
        )
      },
    },
    {
      name: <span className="text-primary">Khách hàng</span>,
      width: '300px',
      center: true,
      cell: (row) => {
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
          >
            {row?.Related[0]?.customerCompanyName}
          </p>
        )
      },
    },
    {
      name: <span className="text-primary">Ký hiệu</span>,
      width: '110px',
      center: true,
      cell: (row) => {
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
          >
            {row?.Related[0]?.serial}
          </p>
        )
      },
    },
    {
      name: <span className="text-primary">Ngày HĐ</span>,
      width: '120px',
      center: true,
      cell: (row) => {
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
          >
            {dayjs(row?.Related[0]?.date).isValid()
              ? dayjs(row?.Related[0]?.date).format('L')
              : ''}
          </p>
        )
      },
    },
    {
      name: <span className="text-primary">Số</span>,
      width: '110px',
      center: true,
      cell: (row) => {
        const no =
          row?.Related[0]?.no && Utils.padString(row?.Related[0]?.no, 8, '0')
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
          >
            {no}
          </p>
        )
      },
    },
    {
      name: <span className="text-primary">Mã CQT</span>,
      width: '200px',
      center: true,
      cell: (row) => {
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
          >
            {row?.Related[0]?.taxAuthorityCode}
          </p>
        )
      },
    },
    {
      name: <span className="text-primary">Lý do thay thế</span>,
      width: '200px',
      center: true,
      cell: (row) => {
        return (
          <p
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
          >
            {row?.Related[0]?.description}
          </p>
        )
      },
    },
    {
      name: '',
      minWidth: '100px',
      center: true,
      compact: true,
      cell: (row) => {
        return (
          <Space align="center">
            {row.isInternal && (
              <TableAction
                icon={
                  <IconAction
                    className="fa-light fa-eye"
                    style={{
                      color: token.colorError,
                    }}
                  />
                }
                titleActionText="Xem hoá đơn bị thay thế"
                onClick={() => {
                  Utils.viewPdfInvoice(row, dispatch, setAppSpinning)
                }}
              />
            )}
            <TableAction
              titleActionText="Xem hoá đơn thay thế"
              icon={
                <IconAction
                  className="fa-light fa-eye"
                  style={{
                    color: token.colorPrimary,
                  }}
                />
              }
              onClick={() => {
                Utils.viewPdfInvoice(row?.Related[0], dispatch, setAppSpinning)
              }}
            />
          </Space>
        )
      },
    },
  ]

  // MARK --- Effect: ---

  useEffect(() => {
    setFilters({
      page: 1,
      q: debouncedSearch,
    })
  }, [debouncedSearch, setFilters])

  useEffect(() => {
    const startColumnFirstHeader = 1
    const startColumnSecondHeader = 6
    const columnsSpanFirstHeader = 5
    const columnsSpanSecondHeader = 12
    const addHeaderTable = () => {
      const firstDivToRemove = document.querySelector('div.table-red-header')
      const secondDivToRemove = document.querySelector(
        'div.table-header-normal',
      )

      if (firstDivToRemove) {
        firstDivToRemove.remove()
      }
      if (secondDivToRemove) {
        secondDivToRemove.remove()
      }
      let firstHeaderWidth = 0
      let secondHeaderWidth = 0

      for (let i = startColumnFirstHeader; i <= columnsSpanFirstHeader; i++) {
        const div = document.querySelector(`div[data-column-id="${i}"]`)
        if (div) {
          firstHeaderWidth += div.getBoundingClientRect().width
        }
      }
      for (let i = startColumnSecondHeader; i <= columnsSpanSecondHeader; i++) {
        const div = document.querySelector(`div[data-column-id="${i}"]`)
        if (div) {
          secondHeaderWidth += div.getBoundingClientRect().width
        }
      }
      const rowDiv = document.querySelector('div[role="rowgroup"]')
      if (rowDiv) {
        const hasRowGroup =
          rowDiv.parentNode.querySelector('#rowGroupContainer')
        let containerDiv
        if (!hasRowGroup) {
          containerDiv = document.createElement('div')
          containerDiv.classList.add('d-flex')
          containerDiv.id = 'rowGroupContainer'
        } else
          containerDiv = rowDiv.parentNode.querySelector('#rowGroupContainer')
        const firstHeaderDiv = document.createElement('div')
        firstHeaderDiv.classList.add('table-red-header')
        firstHeaderDiv.style.width = `${Math.floor(firstHeaderWidth)}px`
        const spanElementFirstHeader = document.createElement('span')
        spanElementFirstHeader.textContent = 'Hoá đơn bị thay thế'
        firstHeaderDiv.appendChild(spanElementFirstHeader)

        const secondHeaderDiv = document.createElement('div')
        secondHeaderDiv.classList.add('table-header-normal')
        secondHeaderDiv.style.width = `${Math.ceil(secondHeaderWidth)}px`
        const spanElementSecondHeader = document.createElement('span')
        spanElementSecondHeader.textContent = 'Hoá đơn thay thế'
        secondHeaderDiv.appendChild(spanElementSecondHeader)

        containerDiv.appendChild(firstHeaderDiv)
        containerDiv.appendChild(secondHeaderDiv)
        // Insert the newDiv before the rowDiv
        rowDiv.parentNode.insertBefore(containerDiv, rowDiv)
      }
    }
    !gettingListData && addHeaderTable()
  }, [gettingListData])

  const navigateHandler = (row, classify) => {
    if (classify === 'PXK')
      return navigate(
        `/hoa-don-dau-ra/xu-ly-hoa-don/phieu-xuat-kho/thay-the/${row?.invoiceId}`,
      )
    else if (classify === 'MTT') {
      return navigate(
        `/hoa-don-dau-ra/xu-ly-hoa-don/may-tinh-tien/thay-the/${row?.invoiceId}`,
      )
    } else
      return navigate(
        `/hoa-don-dau-ra/xu-ly-hoa-don/thay-the/${row?.invoiceId}`,
      )
  }

  return (
    <ContentContainer wrapperClassName="list-replaced-invoice">
      <ContentContainer.Header
        titleContent={'Bảng kê hoá đơn đã thay thế'}
        toolBar={
          <Space>
            <CustomAntButton
              text="Lập hoá đơn thay thế"
              iconCn="fa-regular fa-plus"
              antProps={{
                type: 'primary',
                onClick: () => {
                  if (isCreateReplacementInvoice) setFindInvoiceModalShow(true)
                  if (!isCreateReplacementInvoice) setShowNoRight(true)
                },
              }}
            />
          </Space>
        }
      >
        <Space>
          <CustomAntInput
            borderRadius="sm"
            type="search"
            loading={isFetching}
            inputProps={{
              value: search,
              onChange: (e) => setSearch(e.target.value),
            }}
          />
          <FilterPopover
            defaultValues={defaultValues}
            onApply={setFilters}
            savedKey="gFilterListReplacedInvoice"
          />
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          wrapperClassName="table-replaced-invoice"
          columns={columns}
          dataSource={listReplacedData?.rows}
          isLoading={gettingListData}
          pagination={{
            currentPage: filters?.page,
            rowsPerPage: filters?.limit,
            total: listReplacedData?.total,
            onChangePage: (newPage) => {
              setFilters({
                page: parseInt(newPage),
              })
            },
            onChangeRowsPerPage: (newPerPage) => {
              setFilters({
                limit: parseInt(newPerPage),
                page: 1,
              })
            },
          }}
        />
      </ContentContainer.Body>
      <FindInvoiceModal
        checkBoxShow={false}
        show={FindInvoiceModalShow}
        onHide={() => setFindInvoiceModalShow(false)}
        title="Chọn hoá đơn thay thế"
        onRowClicked={(row, classify) => {
          navigateHandler(row, classify)
        }}
        invoiceType={INVOICE_TYPE_TO_QUERY.CAN_REPLACED}
        buttonAgreeVisibility={true}
        buttonAgreeOnlick={() => {}}
        buttonAgreeTab2Onlick={async (invoice) => {
          invoice = InvoiceHelper.validateInvoiceFromExternal(invoice)
          if (!invoice) return

          /* Check external */
          try {
            dispatch(setAppSpinning(true))
            let res = await invoiceApi.checkExternal(invoice)
            dispatch(setAppSpinning(false))

            if (!res?.data) return

            if (res.data == true) {
              const queryParams = new URLSearchParams(invoice)
              navigate(
                `/hoa-don-dau-ra/xu-ly-hoa-don/thay-the-khac-he-thong/${queryParams.toString()}`,
              )
              return
            }

            navigate(`/hoa-don-dau-ra/xu-ly-hoa-don/thay-the/${res.data}`)
          } catch (error) {
            console.log(error.message)
            dispatch(setAppSpinning(false))
            ToastHelper.showError(error.message)
          }
        }}
      />
      <ModalUnauthorized show={showNoRight} setShow={setShowNoRight} />
    </ContentContainer>
  )
}

export default ListReplacedInvoice
