import { useQuery } from '@tanstack/react-query'
import { Flex, Radio } from 'antd'
import ticketApi from 'api/ticketApi'
import dayjs from 'dayjs'
import CustomDataTable from 'general/components/CustomDataTable'
import CustomDatepicker from 'general/components/CustomDatepicker/CustomDatepicker'
import CustomSegmented from 'general/components/CustomSegmented'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import Utils from 'general/utils/Utils'
import useSearchDebounce from 'hooks/useSearchDebounce'
import { get, map } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import ExportTicketForm from '../Forms/ExportTicketForm'

const radioGroups = [
  { label: 'Vé theo NĐ123 (Có mã)', value: 'HD' },
  { label: 'Vé theo NĐ123 (Khởi tạo từ máy tính tiền)', value: 'MTT' },
]

const tabs = [
  { text: 'Vé trên VietInvoice', value: 'VIETINVOICE' },
  { text: 'Vé trên hệ thống khác', value: 'OTHER' },
]

export default function ExportTicketModal({
  open,
  onOpenChange,
  ticketType,
  title,
  hasTabs = true,
  handleApplyChanges,
  isClickable = false,
  hasApplyButton = true,
  currentTab,
  statusNotInCanReplaced,
}) {
  const initialParams = {
    period: 'RECENT_30_DAYS',
    limit: 20,
    page: 1,
    startDate: dayjs().subtract(29, 'days'),
    endDate: dayjs(),
    classify: 'HD',
    //Thêm điều kiện lọc vé tbss
    ...(currentTab === 1 ? { statusNotIn: true } : {}),
    statusNotInCanReplaced,
  }

  const modalRef = useRef(null)

  const [activeTab, setActiveTab] = useState(tabs[0].value)
  const [filterParam, setFilterParam] = useState({ initialParams })
  const [clearSelectedRows, setToggleClearRows] = useState(false)
  const [activedRows, setActivedRows] = useState([])
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const {
    data: dataSource,
    isFetching,
    refetch,
  } = useQuery({
    enabled: open,
    queryKey: ['exportTicket', filterParam, debouncedSearch, ticketType],
    queryFn: () =>
      ticketApi.list({
        ...filterParam,
        q: debouncedSearch,
        ticketType,
      }),
    select: (res) => ({
      total: get(res, 'count', 0),
      rows: get(res, 'rows', []),
    }),
  })
  const columns = useMemo(() => {
    /**
     *
     * @type {import('react-data-table-component').TableColumn<ExportTicketDataType>[]}
     */
    const arr = [
      {
        name: 'Ký hiệu',
        selector: ({ serial }) => serial,
        center: true,
      },
      {
        name: 'Số',
        selector: ({ no }) => no,
      },
      {
        name: 'Ngày xuất vé',
        selector: ({ dateRelease }) => dayjs(dateRelease).format('L'),
      },
      {
        name: 'Khách hàng',
        selector: ({ customerCompanyName }) => customerCompanyName,
      },
      {
        name: 'Tổng tiền',
        selector: ({ totalPrice }) => Utils.formatCurrency(totalPrice, 'VNĐ'),
      },
    ]
    return arr
  }, [])

  /**
   * @type {import('react-data-table-component').TableProps<ReturnTicketData>['onSelectedRowsChange']}
   */
  const handleSelectRow = ({ selectedRows }) => {
    const ticketIds = map(selectedRows, (row) => row.ticketId)
    setActivedRows(ticketIds)
  }

  /**
   *
   * @type {(row: ReturnTicketData) => boolean}
   */
  const rowsSelected = ({ ticketId }) => activedRows.includes(ticketId)

  useEffect(() => {
    if (open) {
      setActivedRows([])
      setToggleClearRows((prev) => !prev)
      setFilterParam(initialParams)
      refetch()
    }
  }, [open, refetch])

  return (
    <DraggableModal
      title={title}
      ref={modalRef}
      isOpen={open}
      // applyText="Đồng ý"
      onOpenChange={onOpenChange}
      width={1000}
      height={500}
      styles={{
        body: {
          padding: 0,
        },
      }}
      hasApplyButton={hasApplyButton}
      handleApplyChanges={() =>
        handleApplyChanges(activedRows, filterParam.classify)
      }
    >
      {hasTabs && (
        <Flex className="bg-light w-100" justify="center" align="center">
          <div className="p-3 w-50">
            <CustomSegmented
              fullWidth
              options={tabs.map((tab) => tab.text)}
              defaultValue={tabs.find((tab) => tab.value === activeTab).text}
              onChange={(value) =>
                setActiveTab(tabs.find((tab) => tab.text === value).value)
              }
            />
          </div>
        </Flex>
      )}
      {activeTab === 'VIETINVOICE' || !hasTabs ? (
        <>
          <div className="bg-light">
            <div
              className="d-flex flex-column"
              style={{
                backgroundColor: '#f3f5f7',
                fontSize: '14px',
              }}
            >
              <Flex justify="flex-start" className="p-3 fw-light">
                <Radio.Group
                  options={radioGroups}
                  onChange={(e) => {
                    setFilterParam((prev) => ({
                      ...prev,
                      classify: e.target.value,
                    }))
                  }}
                  value={filterParam.classify}
                />
              </Flex>
            </div>

            <Flex gap={'5px'} className="p-3 w-100">
              <CustomDatepicker
                initialPeriod={filterParam.period}
                initialEndDate={filterParam.endDate}
                initialStartDate={filterParam.startDate}
                menuPosition="bottomLeft"
                onApply={(values) =>
                  setFilterParam((prev) => ({
                    ...prev,
                    ...values,
                  }))
                }
              />
              <CustomAntInput
                type="search"
                borderRadius="sm"
                inputProps={{
                  value: search,
                  onChange: (e) => setSearch(e.target.value),
                }}
                width={'100%'}
              />
            </Flex>
          </div>
          <div
            className="p-3 d-flex flex-column flex-grow-1"
            style={{
              overflowX: 'auto',
              overflowY: 'hidden',
            }}
          >
            <CustomDataTable
              columns={columns}
              dataSource={dataSource?.rows}
              // Nếu cho phép click thì không cho phép chọn
              isSelectable={!isClickable}
              handleDoubleClickRow={(value) => {
                handleApplyChanges(value, filterParam?.classify)
              }}
              isClickable={isClickable}
              isLoading={isFetching}
              selectedRows={{
                clearSelectedRows,
                handleSelectedRows: handleSelectRow,
                selectableRowSelected: rowsSelected,
              }}
              pagination={{
                currentPage: filterParam.page,
                rowsPerPage: filterParam.limit,
                total: dataSource?.total,
                onChangePage: (newPage) =>
                  setFilterParam({
                    ...filterParam,
                    page: newPage,
                  }),
                onChangeRowsPerPage: (newPerPage) => {
                  setFilterParam({
                    ...filterParam,
                    limit: newPerPage,
                    page: 1,
                  })
                },
              }}
            />
          </div>
        </>
      ) : (
        <ExportTicketForm />
      )}
    </DraggableModal>
  )
}
