import { Segmented } from 'antd'
import ProcessHomeScreen from 'features/Process/screens/ProcessHomeScreen'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DashboardHomeScreen from '../DashboardHomeScreen'
import './styles.scss'

function DashboardTab() {
  // MARK --- Params ---
  const { t } = useTranslation()
  // const tabs = [t('Statistic'), t('Process')]
  const tabs = [t('Statistic')]
  const [activatedTab, setActivatedTab] = useState(t('Statistic'))

  return (
    <>
      <div className="d-flex justify-content-center align-items-center my-3">
        <Segmented
          prefixCls="tab-dashboard"
          options={tabs}
          value={activatedTab}
          onChange={(value) => setActivatedTab(value)}
          size="large"
          style={{
            padding: 0,
          }}
        />
      </div>
      <div className="my-3">
        {activatedTab === t('Statistic') ? (
          <DashboardHomeScreen />
        ) : (
          <ProcessHomeScreen />
        )}
      </div>
    </>
  )
}

export default DashboardTab
