import { Space } from 'antd'
import customDataTableStyle from 'assets/styles/customDataTableStyle'
import reportHelper from 'features/Report/reportHelper'
import { setPaginationPerPage } from 'features/Report/reportSlice'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import SelectOptions from 'general/constants/SelectOptions'
import Utils from 'general/utils/Utils'
import useGetSerials from 'hooks/Queries/useGetSerials'
import useAppDnD from 'hooks/useAppDnD'
import useFilter from 'hooks/useFilter'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useExportMutation, useGetInvoiceStatistic } from './queries'
import $ from 'jquery'
import dayjs from 'dayjs'
import { useQuery } from '@tanstack/react-query'
import { goodsQueries } from 'features/Category/screens/GoodsScreen/queries'

function UsedInvoiceStatisticScreen() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [filters, setFilters] = useFilter(
    'usedInvoiceList',
    'gFiltersUsedInvoices',
  )
  const [goodsFilterParams] = useState({
    q: '',
    page: 0,
    limit: 50,
    status: 'ACTIVE',
  })
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const { data: serials } = useGetSerials('ALL')
  const { accounts } = useAppSelector((state) => state?.userList?.users)
  const sysConfig = useAppSelector((s) => s.systemConfig.formatNumber)

  const { data: goodsOptions } = useQuery({
    ...goodsQueries.listOfGoods(goodsFilterParams),
    select: (res) => {
      return _.concat(
        [{ value: null, label: 'Tất cả hàng hóa' }],
        res.goods,
      ).map((v) => ({
        value: v.name ?? v.value,
        label: v.label ?? v.name,
      }))
    },
  })

  const defaultValues = useMemo(() => {
    /** @type {Array<FilterDefaultValueProp>} */
    const arr = [
      {
        label: 'Ký hiệu',
        alias: 'Ký hiệu',
        formName: 'serials',
        options: serials,
        value: _.isEmpty(serials) ? null : (serials[0]?.value ?? null),
        colSpan: 24,
        multiselect: true,
      },
      {
        label: ['Hoá đơn từ số', 'Đến số'],
        formName: ['fromNumberInvoice', 'toNumberInvoice'],
        alias: 'Số hoá đơn: Từ',
        separator: 'Đến',
        value: [null, null],
        restrict: {
          type: 'number',
          min: 1,
          max: 99999999,
        },
      },
      {
        label: 'Người lập hoá đơn',
        alias: 'Người lập',
        formName: 'accountIds',
        options: _.reduce(
          accounts,
          (prev, acc) => [
            ...prev,
            {
              value: acc.accountId,
              label: acc.fullname,
            },
          ],
          [{ value: null, label: 'Tất cả' }],
        ),
        value: null,
      },
      {
        label: 'Trạng thái phát hành',
        alias: 'Trạng thái phát hành',
        formName: 'statusReleaseInvoiceReports',
        options: SelectOptions.statusReleaseInvoiceReplace,
        value: SelectOptions.statusReleaseInvoiceReplace[0].value,
      },
      {
        label: 'Trạng thái hoá đơn',
        alias: 'Trạng thái HĐ',
        formName: 'types',
        options: SelectOptions.invoiceType,
        value: SelectOptions.invoiceType[0].value,
      },
      {
        label: 'Trạng thái gửi hoá đơn',
        alias: 'Trạng thái HĐ',
        formName: 'isSent',
        options: SelectOptions.isSent,
        value: SelectOptions.isSent[0].value,
      },
      {
        label: 'Tên hàng hóa',
        alias: 'Hàng hóa',
        formName: 'goodsName',
        options: goodsOptions,
        value: null,
        colSpan: 'FULL',
      },
    ]
    return arr
  }, [accounts, goodsOptions, serials])

  const fullColumns = useMemo(
    () => [
      {
        name: 'Số',
        id: 'no',
        sortable: true,
        center: true,
        width: '100px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(row?.xmlString)
          const invoiceNo = xml ? $(xml).find('SHDon').text() : row?.no
          return (
            <div
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-bold m-0 text-maxline-3 d-flex align-items-center"
            >
              {invoiceNo ? Utils.padString(invoiceNo, 8, '0') : ''}
            </div>
          )
        },
      },
      {
        name: 'Mẫu số',
        id: 'denominator',
        center: true,
        width: '100px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(row?.xmlString)
          const denominator = xml
            ? $(xml).find('KHMSHDon').text()
            : row?.serial[0]
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 m-0 text-maxline-3"
            >
              {denominator}
            </p>
          )
        },
      },

      {
        name: 'Ký hiệu',
        id: 'serial',
        center: true,
        width: '100px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(row?.xmlString)
          const serial = xml
            ? $(xml).find('KHMSHDon').text() + $(xml).find('KHHDon').text()
            : row?.serial
          return <span className="m-0 text-maxline-2">{serial}</span>
        },
      },
      {
        name: 'Ngày HĐ',
        id: 'date',
        center: true,
        width: '120px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(row?.xmlString)
          const ngayLap = xml ? $(xml).find('NLap').text() : row?.date
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75  m-0 text-maxline-3"
            >
              {dayjs(ngayLap).isValid()
                ? dayjs(ngayLap).format('DD/MM/YYYY')
                : ''}
            </p>
          )
        },
      },
      {
        name: 'Mã khách hàng',
        id: 'customerTaxCode',
        center: true,
        minWidth: '150px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(row?.xmlString)
          const customerTaxCode = xml
            ? $(xml).find('NMua > MST').text()
            : row?.customerTaxCode
          return (
            <p
              style={{
                textAlign: 'left',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75  m-0 text-maxline-3 w-100"
            >
              {customerTaxCode}
            </p>
          )
        },
      },
      {
        name: 'Tên khách hàng',
        id: 'customerCompanyName',
        center: true,
        minWidth: '250px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(row?.xmlString)
          const customerCompanyName = xml
            ? $(xml).find('NMua > Ten').text()
            : row?.customerCompanyName
          return (
            <p
              style={{
                textAlign: 'left',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 m-0 text-maxline-3 w-100"
            >
              {customerCompanyName}
            </p>
          )
        },
      },
      {
        name: 'Địa chỉ',
        id: 'customerFullAddress',
        center: true,
        minWidth: '250px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(row?.xmlString)
          const customerFullAddress = xml
            ? $(xml).find('NMua > DChi').text()
            : row?.customerFullAddress
          return (
            <p
              style={{
                textAlign: 'left',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 m-0 text-maxline-3"
            >
              {customerFullAddress}
            </p>
          )
        },
      },
      {
        name: 'Mã số thuế',
        id: 'taxCode',
        center: true,
        width: '140px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(row?.xmlString)
          const customerTaxCode = xml
            ? $(xml).find('NMua > MST').text()
            : row?.customerTaxCode
          return (
            <p
              style={{
                textAlign: 'left',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75  m-0 text-maxline-3"
            >
              {customerTaxCode}
            </p>
          )
        },
      },
      {
        name: 'Người mua hàng',
        id: 'customerName',
        center: true,
        minWidth: '200px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(row?.xmlString)
          const customerName = xml ? $(xml).find('NMua > HVTNMHang').text() : ''
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75  m-0 text-maxline-3"
            >
              {customerName}
            </p>
          )
        },
      },
      {
        name: 'Tổng tiền hàng',
        id: 'total',
        center: true,
        minWidth: '130px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(row?.xmlString)
          const denominator = xml
            ? $(xml).find('KHMSHDon').text()
            : row?.serial[0]
          const totalPrice = xml
            ? denominator === '2'
              ? $(xml).find('TToan > TGTKhac').text() //chốt nếu xml không có trường này là lỗi, phải hiện theo xml thì đọc luôn k check null
                ? Number($(xml).find('TToan > TgTTTBSo').text()) +
                  Number($(xml).find('TToan > TGTKhac').text())
                : row?.total
              : $(xml).find('TToan > TgTCThue').text()
            : row?.total

          return (
            <p
              style={{
                textAlign: 'right',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 m-0 text-maxline-3 w-100"
            >
              {Utils.valueFormatted(
                totalPrice,
                row?.numberFormat,
                sysConfig,
                'totalCost',
              )}
            </p>
          )
        },
      },
      {
        name: 'Tiền chiết khấu',
        id: 'discountMoney',
        center: true,
        minWidth: '130px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(row?.xmlString)
          const discountMoney = xml
            ? $(xml).find('TToan > TTCKTMai').text()
            : row?.discountMoney
          return (
            <p
              style={{
                textAlign: 'right',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 m-0 text-maxline-3 w-100"
            >
              {Utils.valueFormatted(
                discountMoney,
                row?.numberFormat,
                sysConfig,
                'currencyExchange',
              )}
            </p>
          )
        },
      },
      {
        name: 'Tiền thuế GTGT',
        id: 'taxMoney',
        center: true,
        minWidth: '130px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(row?.xmlString)
          const taxMoney = xml
            ? $(xml).find('TToan > TgTThue').text()
            : row?.taxMoney
          return (
            <p
              style={{
                textAlign: 'right',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 m-0 text-maxline-3 w-100"
            >
              {Utils.valueFormatted(
                taxMoney,
                row?.numberFormat,
                sysConfig,
                'totalCost',
              )}
            </p>
          )
        },
      },
      {
        name: 'Tiền thuế giảm trừ',
        id: 'taxReduction',
        center: true,
        minWidth: '130px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(row?.xmlString)
          const denominator = xml
            ? $(xml).find('KHMSHDon').text()
            : row?.serial[0]
          const taxReduction =
            xml && denominator === '2'
              ? $(xml).find('TToan > TGTKhac').text() //chốt nếu xml không có trường này là lỗi, phải hiện theo xml thì đọc luôn k check null
                ? $(xml).find('TToan > TGTKhac').text()
                : row?.taxReduction
              : row?.taxReduction
          return (
            <p
              style={{
                textAlign: 'right',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 m-0 text-maxline-3 w-100"
            >
              {Utils.valueFormatted(
                taxReduction,
                row?.numberFormat,
                sysConfig,
                'totalCost',
              )}
            </p>
          )
        },
      },
      {
        name: 'Tổng tiền thanh toán',
        id: 'totalAfterTax',
        center: true,
        minWidth: '130px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(row?.xmlString)
          const totalAfterTax = xml
            ? $(xml).find('TToan > TgTTTBSo').text()
            : row?.totalAfterTax
          return (
            <p
              style={{
                textAlign: 'right',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 m-0 text-maxline-3 w-100"
            >
              {Utils.valueFormatted(
                totalAfterTax,
                row?.numberFormat,
                sysConfig,
                'totalCost',
              )}
            </p>
          )
        },
      },
      {
        name: 'Mã CQT',
        id: 'taxAuthorityCode',
        center: true,
        minWidth: '130px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75  m-0 text-maxline-3"
            >
              {row?.taxAuthorityCode}
            </p>
          )
        },
      },
      {
        name: 'Mã tra cứu',
        id: 'searchCode',
        center: true,
        minWidth: '130px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 m-0 text-maxline-3"
            >
              {reportHelper.getSearchCode(row?.invoiceCode, row?.lookupCode)}
            </p>
          )
        },
      },
      {
        name: 'Loại hóa đơn',
        id: 'type',
        center: true,
        minWidth: '140px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className={`m-0 badge badge-light-${reportHelper.getInvoiceTypeColor(
                row?.type,
              )}`}
              style={{ whiteSpace: 'nowrap' }}
            >
              {_.capitalize(reportHelper.getInvoiceTypes(row?.type))}
            </p>
          )
        },
      },
      {
        name: 'Trạng thái',
        id: 'issueStatus',
        center: true,
        minWidth: '140px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className={`m-0 badge badge-light-${reportHelper.getInvoiceStatusColor(
                row?.issueStatus,
              )}`}
              style={{ whiteSpace: 'nowrap' }}
            >
              {reportHelper.getInvoiceStatus(row?.issueStatus)}
            </p>
          )
        },
      },
    ],
    [sysConfig],
  )

  const dynamicColumns = useAppDnD(fullColumns)

  // MARK --- Functions ---
  const { data: usedInvoiceStatistic, isFetching } = useGetInvoiceStatistic(
    debouncedSearch,
    filters,
  )

  const { mutate: handleExport, isPending } = useExportMutation(dynamicColumns)

  useEffect(() => {
    setFilters({
      q: debouncedSearch,
      page: 1,
    })
  }, [debouncedSearch, setFilters])

  return (
    <ContentContainer>
      <ContentContainer.Header
        titleContent={t('Bảng kê hóa đơn đã sử dụng')}
        description={`${t('Tổng')}: ${Utils.formatNumber(
          usedInvoiceStatistic?.total,
        )} ${t('Hóa đơn')}`}
        toolBar={
          <Space>
            <CustomAntButton
              text={t('ExportExcel')}
              iconCn={Utils.cn(
                'fa-solid mr-2 p-0',
                isPending ? 'fa-spinner-third fa-spin' : 'fa-down-to-line',
              )}
              antProps={{
                disabled: isPending,
                className: 'btn btn-success',
                onClick: () =>
                  handleExport(
                    _.omit({ ...filters, classify: 'ALL' }, ['page', 'limit']),
                  ),
              }}
            />
            <AppDnD
              defaultColumns={fullColumns}
              localStorageName={PreferenceKeys.savedColumnsUsedInvoice}
            />
          </Space>
        }
      >
        <Space wrap>
          <CustomAntInput
            borderRadius="sm"
            type="search"
            inputProps={{
              value: search,
              onChange: (e) => setSearch(e.target.value),
            }}
          />
          <FilterPopover
            menuPosition="bottomRight"
            onApply={setFilters}
            defaultValues={defaultValues}
            savedKey="gFiltersUsedInvoices"
          />
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          customTableStyle={customDataTableStyle}
          columns={dynamicColumns}
          dataSource={usedInvoiceStatistic?.rows}
          isLoading={isFetching}
          pagination={{
            currentPage: filters?.page,
            onChangePage: (page) => setFilters({ page }),
            onChangeRowsPerPage: (limit) => {
              dispatch(setPaginationPerPage(limit))
              setFilters({
                limit,
                page: 1,
              })
            },
            rowsPerPage: filters?.limit,
            total: usedInvoiceStatistic?.total,
          }}
        />
      </ContentContainer.Body>
    </ContentContainer>
  )
}

export default UsedInvoiceStatisticScreen
