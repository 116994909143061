import { useQuery } from '@tanstack/react-query'
import { Space } from 'antd'
import checkPointApi from 'api/checkPointApi'
import { setAppSpinning } from 'app/appSlice'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import TableAction from 'general/components/GroupButton/TableAction'
import { IconAction } from 'general/components/GroupButton/styles'
import Utils from 'general/utils/Utils'
import useFilter from 'hooks/useFilter'
import useSearchDebounce from 'hooks/useSearchDebounce'
import { assign, get, includes, keys, map } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { CreateNewModal } from './Components/Modals/CreateNewModal'
import DeleteModal from './Components/Modals/DeleteModal'
import EditModal from './Components/Modals/EditModal'

/**
 * Represents a location check ticket.
 * @typedef {Object} LocationCheckTicket
 * @property {number} locationCheckTicketId - The ID of the location check ticket.
 * @property {number} companyId - The ID of the company.
 * @property {string} nameLocationCheckTicket - The name of the location check ticket.
 * @property {string} description - The description of the location check ticket.
 * @property {boolean} availability - The availability of the location check ticket.
 * @property {string} createdAt - The creation time of the location check ticket.
 * @property {string} updatedAt - The update time of the location check ticket.
 */
export default function CheckPointScreen() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [modalState, setModalState] = useState({
    createNew: false,
    edit: false,
    delete: false,
    duplicate: false,
  })

  const [filter, setFilter] = useFilter(
    'checkPointTicketList',
    'gFilterTicketCheckPoint',
  )

  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const [activedRowId, setActiveRowId] = useState('')
  const [activedRowIds, setActiveRowIds] = useState([])
  const [clearSelectedRows, setClearSelectedRows] = useState(false)
  /**
   * Toggles the state of a modal.
   * @param {Object} options - The options object.
   * @param {keyof modalState} options.modalName - The name of the modal to toggle.
   * @param {boolean} options.status - The status to set for the modal.
   */
  const toggleModalState = ({ modalName, status }) =>
    setModalState((prev) => {
      const notModalName = keys(prev).filter((key) => key !== modalName)
      const turnEverythingOff = map(notModalName, (key) => ({ [key]: false }))
      return {
        ...assign({}, ...turnEverythingOff),
        [modalName]: status,
      }
    })

  const {
    data: checkPointData,
    isFetching: gettingCheckPoint,
    refetch: refetchTicketCheckPoint,
  } = useQuery({
    queryKey: ['getTicketCheckPoint', debouncedSearch, filter],
    queryFn: () => checkPointApi.find({ ...filter, q: debouncedSearch }),
    select: (res) => ({
      total: get(res, 'total', 0),
      rows: get(res, 'nameLocationCheckTickets', []),
    }),
  })
  const {
    data: defaultFormData,
    isFetching: gettingFormData,
    refetch: refreshTicketCheckPointById,
  } = useQuery({
    enabled: activedRowId !== '',
    queryKey: ['getTicketCheckPointById', activedRowId],
    queryFn: () =>
      checkPointApi.getDetail({ locationCheckTicketId: activedRowId }),
    select: (res) => get(res, 'locationCheckTicket', {}),
  })

  useEffect(() => {
    gettingFormData
      ? dispatch(setAppSpinning(true))
      : dispatch(setAppSpinning(false))
  }, [dispatch, gettingFormData])

  const columns = useMemo(() => {
    /**
     * An array of table columns.
     * @type {import('react-data-table-component').TableColumn<LocationCheckTicket>[]}
     */
    const arr = [
      {
        name: 'Tên điểm soát vé',
        center: true,
        selector: (row) => row.nameLocationCheckTicket,
      },
      {
        name: 'Mô tả',
        center: true,
        selector: (row) => row.description,
      },

      {
        name: 'Trạng thái',
        center: true,
        selector: (row) => (
          <div
            data-tag="allowRowEvents"
            className={`badge badge-light-${
              row?.availability ? 'success' : 'danger'
            } `}
          >
            {row.availability ? 'Đang sử dụng' : 'Ngừng sử dụng'}
          </div>
        ),
      },
      {
        name: 'Hành động',
        center: true,
        compact: true,
        disabled: true,
        cell: (row) => (
          <>
            <TableAction
              titleActionText="Sửa"
              icon={<IconAction className="fa-light fa-pen-to-square" />}
              onClick={() => {
                setActiveRowId(row.locationCheckTicketId)
                toggleModalState({ modalName: 'edit', status: true })
              }}
            />
            <TableAction
              titleActionText="Xoá"
              icon={<IconAction className="fa-light fa-trash" />}
              onClick={() => {
                setActiveRowId(row.locationCheckTicketId)
                toggleModalState({ modalName: 'delete', status: true })
              }}
            />
            <TableAction
              titleActionText="Nhân bản"
              icon={<IconAction className="fa-light fa-copy" />}
              onClick={() => {
                setActiveRowId(row.locationCheckTicketId)
                toggleModalState({ modalName: 'duplicate', status: true })
              }}
            />
          </>
        ),
      },
    ]
    return arr
  }, [])

  return (
    <ContentContainer>
      <ContentContainer.Header
        title="Danh sách điểm soát vé"
        description={Utils.cn(
          t('Total'),
          checkPointData?.total || 0,
          'điểm soát vé',
        )}
        toolBar={
          <CustomAntButton
            text={t('AddNew')}
            iconCn="fa-regular fa-plus text-white"
            antProps={{
              type: 'primary',
              onClick: () =>
                toggleModalState({
                  modalName: 'createNew',
                  status: true,
                }),
            }}
          />
        }
      >
        <Space wrap>
          {activedRowIds.length > 0 ? (
            <CustomAntButton
              antProps={{
                type: 'primary',
                danger: true,
                onClick: () =>
                  toggleModalState({ modalName: 'deleteModal', status: true }),
              }}
              text={'Xoá ' + activedRowIds.length}
              iconCn="fa-light fa-trash"
            />
          ) : (
            <CustomAntInput
              type="search"
              inputProps={{
                placeholder: t('Search') + ' tên điểm soát vé...',
                value: search,
                onChange: (e) => setSearch(e.target.value),
              }}
              borderRadius="sm"
            />
          )}
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={columns}
          dataSource={checkPointData?.rows}
          isLoading={gettingCheckPoint}
          isClickable
          isSelectable
          pagination={{
            currentPage: filter.page !== 0 ? filter.page : 1,
            rowsPerPage: filter.limit,
            total: checkPointData?.total,
            onChangePage: (newPage) => {
              let iNewPage = parseInt(newPage)
              if (iNewPage > 0) {
                iNewPage = iNewPage - 1
              }
              setFilter({
                page: iNewPage,
              })
            },
            onChangeRowsPerPage: (newPerPage) => {
              const iNewPerPage = parseInt(newPerPage)
              setFilter({
                limit: iNewPerPage,
                page: 0,
              })
            },
          }}
          selectedRows={{
            clearSelectedRows,
            handleSelectedRows: ({ selectedRows }) => {
              setActiveRowIds(
                selectedRows.map((row) => row.locationCheckTicketId),
              )
            },
            selectableRowSelected: (row) =>
              includes(activedRowIds, row.locationCheckTicketId),
          }}
          handleClickRow={(row) => {
            setActiveRowId(row.locationCheckTicketId)
            toggleModalState({ modalName: 'edit', status: true })
          }}
        />
      </ContentContainer.Body>

      <CreateNewModal
        open={modalState.createNew}
        toggleModalState={(status) =>
          toggleModalState({ modalName: 'createNew', status })
        }
        defaultFormData={{
          nameLocationCheckTicket: '',
          description: '',
          availability: true,
        }}
        refetchData={refetchTicketCheckPoint}
      />
      <EditModal
        open={modalState.edit}
        toggleModalState={(status) =>
          toggleModalState({ modalName: 'edit', status })
        }
        defaultFormData={defaultFormData}
        refetchData={() => {
          refetchTicketCheckPoint()
          refreshTicketCheckPointById()
        }}
      />
      <DeleteModal
        open={modalState.delete}
        toggleModalState={(status) =>
          toggleModalState({ modalName: 'delete', status })
        }
        selected={activedRowIds.length > 0 ? activedRowIds : activedRowId}
      />

      <CreateNewModal
        open={modalState.duplicate}
        toggleModalState={(status) =>
          toggleModalState({ modalName: 'duplicate', status })
        }
        defaultFormData={defaultFormData}
        refetchData={refetchTicketCheckPoint}
      />
    </ContentContainer>
  )
}
