import React, { useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import CreateTemplateTicketHeader from './components/CreateTemplateTicketHeader'

import './style.scss'
import RightControl from './components/RightControl'
import LeftContent from './components/LeftContent'
import {
  clearTemplateInvoiceState,
  setInstanceInfo,
  thunkGetExtendFields,
  thunkGetRoutes,
} from 'features/TemplateInvoice/templateInvoiceSlice'
import { useDispatch } from 'react-redux'
import templateInvoiceApi from 'api/templateInvoiceApi'
import { useSelector } from 'react-redux'
import Utils from 'general/utils/Utils'
import ModalExportTicket from '../../components/ModalExportTicket'
// import { useSubColumns, useSubColumnsDispatch } from './SubColumnsContext'
import {
  useSubColumns,
  useSubColumnsDispatch,
} from 'features/TemplateInvoice/screens/CreateTemplateInvoice/SubColumnsContext'
// import { useColumns, useColumnsDispatch } from './ColumnsContext'
import { setAppSpinning } from 'app/appSlice'
import { useRef } from 'react'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import ToastHelper from 'general/helpers/ToastHelper'
import {
  useColumns,
  useColumnsDispatch,
} from 'features/TemplateInvoice/screens/CreateTemplateInvoice/ColumnsContext'
import templateTicketApi from 'api/templateTicketApi'

const INVOICE_INVOICE_TEMPLATE_DICT = {
  sellerName: 'companyName',
  sellerTaxCode: 'taxCode',
  sellerAddress: 'businessPermitAddress',
  sellerEmail: 'contactPersonEmail',
  sellerPhone: 'contactPersonPhone',
  sellerWeb: 'website',
  sellerFax: 'fax',
}

const genTemplateInvoiceInstance = (
  templateInvoice,
  setAllSubColumns,
  sellerInfoData,
  extendFields,
) => {
  let returnValue = {
    templateInvoiceName: templateInvoice.name,
    // templateInvoiceDecscription :templateInvoice.decscription,
    templateInvoiceForm: templateInvoice.hasTaCode === 'C' ? 1 : 0,
    templateInvoiceSeries:
      templateInvoice.form +
      templateInvoice.hasTaCode +
      templateInvoice.year +
      templateInvoice.type +
      templateInvoice.managementCode,

    dataTransferMethod: templateInvoice.directTranfer + '',
    sellerInfoPosition: templateInvoice.sellerInfoPosition,
    fontFamily: templateInvoice.metadata?.fontFamily,
    fontSize: templateInvoice.metadata?.fontSize,
    color: templateInvoice.metadata?.color,
    lineType: templateInvoice.metadata?.lineType,
    lineHeight: templateInvoice.metadata?.lineHeight,
    isShowQRCode: !!templateInvoice.isShowQRCode,
    duplicateInfoMulPages: !!templateInvoice.duplicateInfoMulPages,
    isShowLabelEn: !!templateInvoice.isShowLabelEn,
    isShowWatermarkInAdjustedInvoice:
      !!templateInvoice.isShowWatermarkInAdjustedInvoice,
    isNonTariffArea: !!templateInvoice.isNonTariffArea,

    logoPosition: templateInvoice.logoPosition,
    paperSize: templateInvoice.paperSize,
    // mulTaxRateSupport: templateInvoice.mulTaxRateSupport,
    category: templateInvoice.category,
    category_ticket: templateInvoice.category_ticket,
    calcConfig: templateInvoice.calcConfig,

    templateInvoiceServiceName: templateInvoice.serviceName,
    templateInvoiceTicketPrice: templateInvoice.ticketPrice,
    templateInvoiceCurrencyUnit: templateInvoice.currencyUnit,
    ticketPricePosition: templateInvoice.ticketPricePosition,
    QRCodePosition: templateInvoice.QRCodePosition,

    isShowCurrencyUnit: templateInvoice.isShowCurrencyUnit,
    isTaxReduction: templateInvoice.isTaxReduction,
    templateInvoiceTicketPriceWithoutReduce:
      templateInvoice.ticketPriceWithoutReduce,
    templateInvoiceTicketPriceReduce: templateInvoice.ticketPriceReduce,
    rateVatInAmount: templateInvoice.rateVatInAmount,
    vatRateTicket: templateInvoice.vatRateTicket,

    routeId: templateInvoice.routeId,
    routeType: templateInvoice.routeType,

    extendFields: templateInvoice.extend_fields,
    notDraftTickets: templateInvoice.notDraftTickets,
  }

  if (templateInvoice.logo && templateInvoice.metadata?.logo) {
    returnValue.logoInfo = {
      logoFile: templateInvoice.logo,
      ...templateInvoice.metadata?.logo,
    }
  }

  if (templateInvoice.border) {
    returnValue.surroundInfo = {
      surroundData: templateInvoice.border,
      ...templateInvoice.metadata?.surround,
    }
  }

  if (templateInvoice.background) {
    returnValue.backgroundInfo = {
      backgroundData: templateInvoice.background,
      ...templateInvoice.metadata?.background,
    }
  }

  if (
    templateInvoice.customBackground &&
    templateInvoice.metadata?.customBackground
  ) {
    returnValue.customBackgroundInfo = {
      file: templateInvoice.customBackground,
      ...templateInvoice.metadata?.customBackground,
    }
  }

  if (templateInvoice.metadata?.watermark) {
    returnValue.watermarkInfo = {
      ...templateInvoice.metadata?.watermark,
    }
  }

  if (templateInvoice.metadata?.subFields) {
    setAllSubColumns(templateInvoice.metadata.subFields)
  }

  extendFields = extendFields
    /* lọc những trường đã có rồi */
    .filter(
      ({ name }) => !templateInvoice.fields.find((item) => item.name == name),
    )
    .map((field) => ({
      ...field,
      label: { value: field.label, editable: false },
      labelEn: { value: field.labelEn, editable: false },
      value: { value: '', editable: false },
      extendField: true,
    }))

  let groupByRegionName = templateInvoice.fields
    .concat(extendFields)
    .reduce((group, field) => {
      const { regionName } = field
      group[regionName] = group[regionName] ?? []
      group[regionName].push(field)

      return group
    }, {})

  returnValue.RegionConfigs = Object.entries(groupByRegionName).map(
    ([regionName, fields]) => ({
      regionName,
      regionConfigs:
        regionName == 'sellerInfo' && sellerInfoData
          ? fields.map((field) => ({
              dataField: field.name,
              label: field.label,
              labelEn: field.labelEn,
              value: {
                ...field.value,
                value:
                  field.name == 'sellerBankAccount'
                    ? `${sellerInfoData['bankAccountNumber']} - ${sellerInfoData['bankName']}`
                    : (sellerInfoData[
                        INVOICE_INVOICE_TEMPLATE_DICT[field.name]
                      ] ?? field.value.value),
              },
              show: field.active,
              children: field.children,

              ...field.metadata,
            }))
          : fields.map((field) => ({
              dataField: field.name,
              label: field.label,
              labelEn: field.labelEn,
              value: field.value,
              show: field.active,
              children: field.children?.map((fieldC) => ({
                dataField: fieldC.name,
                label: fieldC.label,
                labelEn: fieldC.labelEn,
                value: fieldC.value,
                show: fieldC.active,

                ...fieldC.metadata,
              })),

              dataType: field.dataType,
              defaultValue: field.defaultValue,

              ...field.metadata,
            })),
      ...(templateInvoice.metadata?.region[regionName] || {}),
    }),
  )
  return returnValue
}

function CreateTemplateTicket() {
  const dispatch = useDispatch()
  const columnsDispatch = useColumnsDispatch()
  const subColumnsDispatch = useSubColumnsDispatch()
  let { templateInvoiceId } = useParams()
  const { pathname } = useLocation()

  const { company } = useSelector((state) => state.system)
  const { currentAccount } = useSelector((state) => state.auth)
  const { instance: templateInvoiceInstance, extendFields } = useSelector(
    (state) => state.templateInvoice,
  )
  const {
    templateInvoiceName,
    templateInvoiceServiceName,
    templateInvoiceTicketPrice,
    templateInvoiceCurrencyUnit,
    templateInvoiceSeries,
    dataTransferMethod,
    sellerInfoPosition,
    ticketPricePosition,
    QRCodePosition,
    isShowQRCode,
    isShowCurrencyUnit,
    duplicateInfoMulPages,
    isShowLabelEn,
    isTaxReduction,
    isShowWatermarkInAdjustedInvoice,
    logoPosition,
    paperSize,
    // mulTaxRateSupport,
    category,
    category_ticket,
    calcConfig,

    fontFamily,
    fontSize,
    color,
    lineType,
    lineHeight,
    logoInfo,

    backgroundInfo,
    customBackgroundInfo,
    surroundInfo,
    watermarkInfo,

    RegionConfigs,

    templateInvoiceTicketPriceWithoutReduce,
    templateInvoiceTicketPriceReduce,
    rateVatInAmount,
    vatRateTicket,
    routeType,
    routeId,

    extendFields: instanceExtendFields,
  } = useSelector((state) => state.templateInvoice.instance)

  const invoiceInfoColumns = useColumns('invoiceInfoColumns')
  const setInvoiceInfoColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'invoiceInfoColumns',
      payload,
    })
  }

  const firstInvoiceColumns = useColumns('firstInvoiceColumns')
  const setFirstInvoiceColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'firstInvoiceColumns',
      payload,
    })
  }

  const buyerInfoColumns = useColumns('buyerInfoColumns')
  const setBuyerInfoColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'buyerInfoColumns',
      payload,
    })
  }

  const signXmlColumns = useColumns('signXmlColumns')
  const setSignXmlColumns = (payload) => {
    columnsDispatch({
      type: 'set',
      colsName: 'signXmlColumns',
      payload,
    })
  }
  const subColumns = useSubColumns()

  const userFunction = useSelector((state) => state?.function?.userFunctions)
  const listFunction = useSelector((state) => state?.function?.listFunctions)

  const [themFunction, suaFunction] = useMemo(() => {
    return Utils.checkFunction(
      [
        {
          listFunctionCode: 'TEMPLATE_INVOICE_ADD',
        },
        {
          listFunctionCode: 'TEMPLATE_INVOICE_EDIT',
        },
      ],
      userFunction,
      listFunction,
    )
  }, [userFunction, listFunction])

  useEffect(() => {
    dispatch(clearTemplateInvoiceState())
    dispatch(thunkGetExtendFields())
    dispatch(thunkGetRoutes())

    return () => {
      dispatch(clearTemplateInvoiceState())
    }
  }, [])

  const gotDetailTemplateInvoice = useRef(false)
  useEffect(() => {
    if (
      templateInvoiceId &&
      !Utils.isObjectEmpty(company) &&
      extendFields &&
      userFunction?.length &&
      listFunction?.length
    ) {
      if (!gotDetailTemplateInvoice.current)
        (async function () {
          if (pathname.includes('/tao/') && !themFunction) {
            ToastHelper.showError('Bạn không có quyền thêm mẫu hóa đơn')
            gotDetailTemplateInvoice.current = true
            return
          }

          if (pathname.includes('/sua/') && !suaFunction) {
            ToastHelper.showError('Bạn không có quyền sửa mẫu hóa đơn')
            gotDetailTemplateInvoice.current = true
            return
          }

          try {
            let templateInvoice =
              await templateInvoiceApi.getDetail(templateInvoiceId)

            if (!templateInvoice) return

            gotDetailTemplateInvoice.current = true
            let sellerInfoData = {
              ...company,
              contactPersonEmail:
                company?.invoiceDisplayedInfo?.email ||
                company?.contactPersonEmail,
              contactPersonPhone:
                company?.invoiceDisplayedInfo?.phone ||
                company?.contactPersonPhone,
              website:
                company?.invoiceDisplayedInfo?.website || company?.website,
              sellerFax: company?.invoiceDisplayedInfo?.fax || company?.fax,
            }
            dispatch(
              setInstanceInfo({
                ...genTemplateInvoiceInstance(
                  templateInvoice,
                  setAllSubColumns,
                  sellerInfoData,
                  extendFields,
                ),
              }),
            )

            // if (templateInvoice.category.taxType == 2) {
            //   dispatch(setMulTaxRate(true))
            // }

            let firstInvoiceRegion = templateInvoice.region?.find(
              ({ regionName }) => regionName == 'firstInvoice',
            )
            if (firstInvoiceRegion.widths?.length) {
              setFirstInvoiceColumns([
                ...firstInvoiceRegion.widths.map((width, index) =>
                  width != '*'
                    ? {
                        width,
                        dataIndex: 'col' + (index + 1),
                        key: 'col' + (index + 1),
                        id:
                          templateInvoice?.logoPosition == 1
                            ? 'logo-container'
                            : undefined,
                      }
                    : {
                        dataIndex: 'col' + (index + 1),
                        key: 'col' + (index + 1),
                        id:
                          templateInvoice?.logoPosition == 0
                            ? 'logo-container'
                            : undefined,
                      },
                ),
              ])
            }

            let invoiceInfoRegion = templateInvoice.region?.find(
              ({ regionName }) => regionName == 'invoiceInfo',
            )
            if (invoiceInfoRegion.widths?.length) {
              setInvoiceInfoColumns([
                ...invoiceInfoRegion.widths.map((width, index) =>
                  width != '*'
                    ? {
                        width,
                        dataIndex: 'col' + (index + 1),
                        key: 'col' + (index + 1),
                      }
                    : {
                        dataIndex: 'col' + (index + 1),
                        key: 'col' + (index + 1),
                      },
                ),
              ])
            }

            let buyerInfoRegion = templateInvoice.region?.find(
              ({ regionName }) => regionName == 'buyerInfo',
            )
            if (buyerInfoRegion.widths?.length) {
              setBuyerInfoColumns([
                ...buyerInfoRegion.widths.map((width, index) =>
                  width != '*'
                    ? {
                        width,
                        dataIndex: 'col' + (index + 1),
                        key: 'col' + (index + 1),
                      }
                    : {
                        dataIndex: 'col' + (index + 1),
                        key: 'col' + (index + 1),
                      },
                ),
              ])
            }

            let signXmlRegion = templateInvoice.region?.find(
              ({ regionName }) => regionName == 'signXml',
            )
            if (signXmlRegion.widths?.length) {
              setSignXmlColumns([
                ...signXmlRegion.widths.map(({ width, dataIndex }) =>
                  width != '*'
                    ? { width, dataIndex, key: dataIndex }
                    : { dataIndex, key: dataIndex },
                ),
              ])
            }
          } catch (e) {
            console.log(e)
          }
        })()
    }
    // else {
    //   dispatch(setInstanceInfo({ ...initialInstance }));
    // }
  }, [company, extendFields, userFunction, listFunction])

  const prepareTemplateInvoiceParams = async (toExport = false) => {
    let metadata = {
      fontFamily,
      fontSize,
      color,
      lineType,
      lineHeight,
    }

    let region = [
      {
        regionName: 'firstInvoice',
        widths: firstInvoiceColumns.map(({ width }) => width ?? '*'),
      },
      {
        regionName: 'invoiceInfo',
        widths: invoiceInfoColumns.map(({ width }) => width ?? '*'),
      },
      {
        regionName: 'buyerInfo',
        widths: buyerInfoColumns.map(({ width }) => width ?? '*'),
      },
      {
        regionName: 'signXml',
        widths: signXmlColumns.map(({ width, dataIndex }) => ({
          width: width ?? '*',
          dataIndex,
        })),
      },
    ]

    let params = {
      categoryId: category?.categoryId,
      categoryTicketId: category_ticket?.categoryTicketId,
    }

    if (logoInfo?.logoFile) {
      let { logoFile, ...rest } = logoInfo
      if (toExport && logoFile instanceof File) {
        params.logo = await Utils.toBase64(logoFile)
      } else {
        params.logo = logoFile
      }
      metadata.logo = { ...rest }
    } else {
      metadata.logo = null
    }

    if (backgroundInfo?.backgroundData) {
      let { backgroundData, ...rest } = backgroundInfo
      params.backgroundId = backgroundData.backgroundId
      metadata.background = { ...rest }
    } else {
      metadata.background = null
    }

    if (surroundInfo?.surroundData) {
      let { surroundData, ...rest } = surroundInfo
      params.borderId = surroundData.borderId
      metadata.surround = { ...rest }
    } else {
      metadata.surround = null
    }

    if (customBackgroundInfo?.file) {
      let { file, ...rest } = customBackgroundInfo
      if (toExport && file instanceof File) {
        params.customBackground = await Utils.toBase64(file)
      } else {
        params.customBackground = file
      }
      metadata.customBackground = { ...rest }
    } else {
      metadata.customBackground = null
    }

    if (!Utils.isObjectNull(watermarkInfo)) {
      metadata.watermark = { ...watermarkInfo }
    } else {
      metadata.watermark = {}
    }

    if (!toExport) {
      params.companyId = currentAccount?.companyId
    }

    if (!toExport) {
      params.name = templateInvoiceName
    }
    // params.decscription = ""
    params.form = templateInvoiceSeries[0]
    params.hasTaCode = templateInvoiceSeries[1]
    params.type = templateInvoiceSeries[4]
    params.managementCode = templateInvoiceSeries.slice(5)
    if (!toExport) {
      params.directTranfer = parseInt(dataTransferMethod)
    }
    params.paperSize = paperSize
    // params.mulTaxRateSupport = mulTaxRateSupport;
    params.calcConfig = calcConfig
    params.extendFields = instanceExtendFields

    params.extendFields = instanceExtendFields

    params.region = region

    params.sellerInfoPosition = sellerInfoPosition
    params.isShowQRCode = isShowQRCode ? 1 : 0
    params.duplicateInfoMulPages = duplicateInfoMulPages ? 1 : 0
    params.isShowLabelEn = isShowLabelEn ? 1 : 0
    params.isShowWatermarkInAdjustedInvoice = isShowWatermarkInAdjustedInvoice
      ? 1
      : 0
    // params.isNonTariffArea = isNonTariffArea ? 1 : 0
    params.logoPosition = logoPosition ? 1 : 0

    params.serviceName = templateInvoiceServiceName
    params.ticketPrice = templateInvoiceTicketPrice
    params.currencyUnit = templateInvoiceCurrencyUnit
    params.ticketPricePosition = ticketPricePosition
    params.QRCodePosition = QRCodePosition

    params.isShowCurrencyUnit = isShowCurrencyUnit ? 1 : 0
    params.isTaxReduction = isTaxReduction ? 1 : 0
    params.ticketPriceWithoutReduce = templateInvoiceTicketPriceWithoutReduce
    params.ticketPriceReduce = templateInvoiceTicketPriceReduce
    params.rateVatInAmount = rateVatInAmount
    params.vatRateTicket = vatRateTicket

    params.routeType = routeType
    params.routeId = routeId

    let index = 0
    let regionMetadata = {}
    for (let i = 0; i < RegionConfigs.length; i++) {
      RegionConfigs[i].regionConfigs.forEach((dataField, dfi) => {
        if (
          dataField.dataField.startsWith('extendField') &&
          !instanceExtendFields.find((item) => item.name == dataField.dataField)
        ) {
          return
        }

        let dfMetadata = {}
        if (dataField.mergeField) {
          dfMetadata.mergeField = dataField.mergeField
        }
        if (dataField.displayType) {
          dfMetadata.displayType = dataField.displayType
        }
        if (dataField.borderType) {
          dfMetadata.borderType = dataField.borderType
        }
        // if (dataField.mstSplitCells) {
        dfMetadata.mstSplitCells = dataField.mstSplitCells
        // }
        if (dataField.typeShow) {
          dfMetadata.typeShow = dataField.typeShow
        }
        // if (dataField.canHide) {
        dfMetadata.canHide = dataField.canHide
        // }
        // if (dataField.previewField) {
        dfMetadata.previewField = dataField.previewField
        // }
        dfMetadata.mrRank = dataField.mrRank

        dfMetadata.fieldAlign = dataField.fieldAlign

        params[`fields[${index}][name]`] = dataField.dataField
        params[`fields[${index}][regionName]`] = RegionConfigs[i].regionName
        params[`fields[${index}][type]`] = 'STATIC'
        params[`fields[${index}][label]`] = JSON.stringify(dataField.label)
        params[`fields[${index}][labelEn]`] = JSON.stringify(dataField.labelEn)
        params[`fields[${index}][value]`] = JSON.stringify(dataField.value)
        params[`fields[${index}][metadata]`] = JSON.stringify(dfMetadata)
        params[`fields[${index}][children]`] = JSON.stringify(
          dataField.children?.map((item) => {
            let { fieldId, parentId, ...rest } = item
            return rest
          }) || [],
        )

        let hideToSave = 0
        /* Hóa đơn dạng chuyển đổi */
        if (
          RegionConfigs[i].regionName === 'invoiceInfo' &&
          dataField.dataField == 'descriptionInvoicePaperClient'
        ) {
          // if (!toExport) {
          hideToSave = 1
          // }
        }

        /* Phát sinh ngoại tệ */
        if (
          RegionConfigs[i].regionName === 'currencyBlock' &&
          ['exchangeRate', 'translationCurrency'].includes(dataField.dataField)
        ) {
          // if (!toExport) {
          hideToSave = 1
          // }
        }

        params[`fields[${index}][active]`] = hideToSave
          ? 0
          : dataField.show
            ? 1
            : 0

        index += 1
      })

      // region metadata
      let { regionConfigs, regionName, ...rest } = RegionConfigs[i]
      if (regionName == 'sellerInfo') {
        rest.maxWidth = parseInt(
          sessionStorage.getItem(PreferenceKeys.sellerInfoMaxWidth),
        )
      } else if (regionName == 'buyerInfo') {
        rest.maxWidth = parseInt(
          sessionStorage.getItem(PreferenceKeys.buyerInfoMaxWidth),
        )
      }

      regionMetadata[regionName] = rest
    }
    metadata.region = regionMetadata

    metadata.subFields = {}
    Object.entries(subColumns).forEach(([mergeField, columns]) => {
      metadata.subFields[mergeField] = columns.map(({ width }) =>
        width ? { width } : { width: '*' },
      )
    })

    params.metadata = JSON.stringify(metadata)

    return params
  }

  const exportTicketHandler = async (exportOptions) => {
    let params = await prepareTemplateInvoiceParams(true, exportOptions)
    params.exportOptions = JSON.stringify(exportOptions)

    dispatch(setAppSpinning(true))
    var res
    try {
      res = await templateTicketApi.exportTicketTemplate(params)
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(setAppSpinning(false))
    }

    let newFile = new File([res], 'Mẫu vé.pdf', {
      type: 'application/pdf',
    })
    var fileURL = URL.createObjectURL(newFile)
    window.open(fileURL, '_blank')
  }

  const setAllSubColumns = (param) => {
    subColumnsDispatch({
      type: 'set_all',
      payload: param,
    })
  }

  return (
    <div>
      {!Utils.isObjectEmpty(templateInvoiceInstance) ? (
        <div className="CreateTemplateTicket d-flex flex-column vh-100">
          <CreateTemplateTicketHeader style={{ flex: '0 1 auto' }} />

          <div
            className="mx-auto mt-1 d-flex flex-row"
            style={{ flex: '1 1 auto', overflow: 'hidden' }}
          >
            <LeftContent
              className="left-content h-100"
              invoiceInfoColumns={invoiceInfoColumns}
              setInvoiceInfoColumns={setInvoiceInfoColumns}
              firstInvoiceColumns={firstInvoiceColumns}
              setFirstInvoiceColumns={setFirstInvoiceColumns}
              buyerInfoColumns={buyerInfoColumns}
              setBuyerInfoColumns={setBuyerInfoColumns}
              signXmlColumns={signXmlColumns}
              setSignXmlColumns={setSignXmlColumns}
            />

            <RightControl
              className="right-control h-100 p-3"
              prepareTemplateInvoiceParams={prepareTemplateInvoiceParams}
            />
          </div>
        </div>
      ) : (
        <div>Loading</div>
      )}

      <ModalExportTicket exportTicketHandler={exportTicketHandler} />
    </div>
  )
}

export default CreateTemplateTicket
