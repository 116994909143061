import { useAppSelector } from 'hooks/useRedux'
import { memo } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import DraggableRow from './DraggableRow'

function DnDColumnList() {
  const items = useAppSelector((s) => s.table.visualAppears)
  return items.map((item, index) =>
    item.isAppear ? (
      <Draggable
        isDragDisabled={item.disabled}
        key={item.id}
        draggableId={item.id ?? item?.name}
        index={index}
      >
        {(draggableProvided, draggableSnapshot) => (
          <DraggableRow
            draggableProvided={draggableProvided}
            draggableSnapshot={draggableSnapshot}
            item={item}
            idx={index}
          />
        )}
      </Draggable>
    ) : null,
  )
}
DnDColumnList.displayName = 'DnDColumnList'
export default memo(DnDColumnList)
