import { createSlice } from '@reduxjs/toolkit'
import Global from 'general/utils/Global'
import merge from 'lodash/merge'

const initialState = {
  invoiceList: Global.gFiltersInvoiceList,
  adjustedInvoiceList: Global.gFilterListAdjustedInvoice,
  cancelledInvoiceList: Global.gFilterCancelledInvoice,
  errorInvoiceAnnouncement: Global.gFiltersErrAnnouceList,
  replacedInvoiceList: Global.gFilterListReplacedInvoice,
  detailInvoiceList: Global.gFiltersDetailInvoices,
  generalReport: Global.gFiltersGeneralReport,
  issuedInvoiceList: Global.gFiltersIssuedInvoices,
  ticketReportList: Global.gFilterTicketReport,
  usedInvoiceList: Global.gFiltersUsedInvoices,
  taxMessageRegistrationList: Global.gFilterTaxMessageRegistrationList,
  issuedTicketList: Global.gFilterListIssuedTicket,
  issuedTicketListFromPOS: Global.gFilterListIssuedTicketFromPOS,
  checkPointTicketList: Global.gFilterTicketCheckPoint,
  companyTitleList: Global.gFiltersCompanyTitleList,
  companyUnitList: Global.gFiltersCompanyUnitList,
  customerList: Global.gFiltersCustomerList,
  employeeList: Global.gFiltersEmployeeList,
  goodsGroupList: Global.gFiltersGoodsGroupList,
  goodsList: Global.gFiltersGoodsList,
  moneyTypeList: Global.gFiltersMoneyTypeList,
  ticketRoute: Global.gFilterTicketRoute,
  registrationList: Global.gFiltersRegistrationList,
  taxErrorAnnouceList: Global.gFiltersTaxErrorAnnouceList,
  templateInvoiceList: Global.gFiltersTemplateInvoiceList,
  taxMessageInvoiceList: Global.gFilterListTaxMessageInvoice,
  ticketPublished: Global.gFilterTicketPublished,
  ticketExportedList: Global.gFilterTicketExportedList,
  canceledTicketList: Global.gFilterCanceledTicket,
  templateTicketList: Global.gFiltersTemplateTicketList,
  replacedTicketList: Global.gFilterReplacedTicket,
  adjustedTicketList: Global.gFilterAdjustedTicketList,
  errorTicketAnnoucement: Global.gFilterErrorTicketAnnoucement,
  listTransactions: Global.gFilterListTransactions,
  listInvoiceFromPOS: Global.gFilterListInvoiceFromPOS,
  taxMessageInvoiceFromPos: Global.gFilterTaxMessageInvoiceFromPos,
  taxMessageTicketFromPos: Global.gFilterTaxMessageTicketFromPos,
  serialDevices: Global.gCheckTicketPortal,
  goodsDeliveryNoteList: Global.gFilterGoodsDeliveryNoteList,
}

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    /**
     * @param {import('@reduxjs/toolkit').PayloadAction<{storeObj: {storeName: string, localSavedName: string}, data: *}>} action
     */
    setFilterParams: (state, action) => {
      const storeObj = action.payload.storeObj,
        _data = action.payload.data
      state[storeObj.storeName] = merge(state[storeObj.storeName], _data)
      const stringified = JSON.stringify(state[storeObj.storeName])
      localStorage.setItem(storeObj.localSavedName, stringified)
    },
    /**
     *
     * @param {import('@reduxjs/toolkit').PayloadAction<{
     *  storeName: string,
     *  localSavedName: string,
     * }>} action
     */
    resetDefaultParams: (state, action) => {
      localStorage.removeItem(action.payload.localSavedName)
      state[action.payload.storeName] = Global[action.payload.localSavedName]
    },
  },
})

const { actions, reducer } = filterSlice
export const {
  setPaginationPerPage,
  setColumns,
  setFilterParams,
  resetDefaultParams,
} = actions
export default reducer

/**
 * @typedef {keyof initialState} FilterState
 * @typedef {typeof initialState[FilterState]} FilterStateValue
 */
