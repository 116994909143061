import { useTranslation } from 'react-i18next'
import { useMemo, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import HeaderTaxDectionDocumentsUpdate from '../../component/Layout/header'
import AlertTaxDectionDocumentsUpdate from '../../component/Alert'
import { ContainerContent } from 'features/TaxDeductionDocuments/styled'
import LeftContentTaxDeductionDocuments from 'features/TaxDeductionDocuments/component/ContentUpdate/leftContent'
import RightContentTaxDeductionDocuments from 'features/TaxDeductionDocuments/component/ContentUpdate/rightContent'
import { Form } from 'antd'
import dayjs from 'dayjs'
import { useSelector, useDispatch } from 'react-redux'
import { thunkGetAccountProfile } from 'app/authSlice'
import AppResource from 'general/constants/AppResource'
import Utils, {
  findKeyInBorderBackground,
  setCookie,
} from 'general/utils/Utils'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  thunkGetAllTaxDeductionDocumentsDefault,
  thunkGetIpTaxDeductionDocuments,
  thunkSaveTaxDeductionDocumentsDefault,
} from 'features/TaxDeductionDocuments/taxDeductionDocumentsSlice'
import ToastHelper from 'general/helpers/ToastHelper'
import { thunkGetCompanyProfile } from 'features/System/systemSlice'
import { DIGITAL_SIGN_METHOD } from 'general/constants/AppConstants'
import PreferenceKeys from 'general/constants/PreferenceKeys'
function AddTaxDeductionDocuments() {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [auth, setAuth] = useState({})
  const [listDataTemplateDefault, setListDataTemplateDefault] = useState([])
  const [templateDefault, setTemplateDefault] = useState({})
  const [imgLogoTemplateDefault, setImgLogoTemplateDefault] = useState({})
  const [imgLOwnWallpaper, setImgLOwnWallpaper] = useState({})

  const [openModalAlert, setOpenModalAlert] = useState(false)
  const [formState, setFormState] = useState(false)
  const [openModalAlertTemplate, setOpenModalAlertTemplate] = useState(false)
  const [openModalAlertTemplateSave, setOpenModalAlertTemplateSave] =
    useState(false)
  const typePaper = templateDefault?.typePaper ?? 'A4'
  const language = useState(JSON.parse(templateDefault?.language ?? '["vi"]'))
  const [openModalMaintenance, setOpenModalMaintenance] = useState(false)
  const [taxDeductionDocumentsTemplate, setTaxDeductionDocumentsTemplate] =
    useState({})
  const [backgroundImg, setBackgroundImg] = useState({
    x: 225,
    y: 200,
    width: 200,
    height: 200,
    location: null,
  })
  const [imgLogo, setImgLogo] = useState()
  const [fileOwnWallpaper, setFileOwnWallpaper] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })
  const [fileContentBackground, setFileContentBackground] = useState()
  const [chonseBorder, setChonseBorder] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })
  const [styleOwnWallpaper, setStyleOwnWallpaper] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })
  const [chonseContent, setChonseContent] = useState()
  const [contentBackgroundColor, setContentBackgroundColor] = useState()
  const [fileBorderBackground, setFileBorderBackground] = useState()
  const [inforCompany, setInforCompany] = useState()
  const [colorBorderBackground, setColorBorderBackground] = useState()
  const [tabActiveKeyRightContent, setTabActiveKeyRightContent] = useState('2')
  const [listContentFeild, setListContentFeild] = useState([])
  const [typeSign, setTypeSign] = useState()
  // const formKey = useTranslationForTemplate()
  const [activeFeild, setActiveFeild] = useState({})
  const [activeLayout, setActiveLayout] = useState({})
  const [activeTd, setActiveTd] = useState({})
  const resetForm = () => {
    if (form) {
      form.resetFields()
      let day = dayjs()
      const colorBorderBackgroundDefault = '#56A5FC'
      const dataReset = {
        taxDuctionDocumentsTemplateId: templateDefault?.templatePaperId,
        formNumber: templateDefault?.formNumber,
        symbolCode: null,
        symbolYear: day.set('year', templateDefault?.symbolYear),
        symbolType: templateDefault?.symbolType,
        number: templateDefault?.number,
        typeMoney: templateDefault?.typeMoney,
        organizationName: auth?.currentCompany?.companyName
          ? auth?.currentCompany?.companyName
          : auth?.companyName
            ? auth.companyName
            : '',
        positionLogo: templateDefault?.positionLogo,
        fontText: 'Times New Roman',
        fontSize: '16',
        textColor: '#000000',
        viewConversionTemplate: templateDefault?.viewConversionTemplate,
        borderBackgroundColor: templateDefault?.borderBackgroundColor,
        borderBackground: templateDefault?.boderBackground,
        contentBackgroundColor: templateDefault?.contentBackgroundColor,
        contentBackground: templateDefault?.contentBackground,
        opacityContentBackground: templateDefault?.contentBackgroundOpactity,
        ownWallpaper: 1,
        ownWallpaperColor: colorBorderBackgroundDefault,
        describeownWallpaper: templateDefault?.describeownWallpaper,
        boldOwnWallpaper: templateDefault?.boldOwnWallpaper,
        opacityOwnWallpaper: templateDefault?.opacityOwnWallpaper ?? 50,
        opacityTextOwnWallpaper: templateDefault?.opacityTextOwnWallpaper ?? 50,
        fontTextOwnWallpaper:
          templateDefault?.fontTextOwnWallpaper ?? 'Times New Roman',
        fontSizeTextOwnWallpaper: templateDefault?.fontSizeTextOwnWallpaper,
      }
      form.setFieldsValue(dataReset)
      setFormState(dataReset)
      setFileBorderBackground(templateDefault?.boderBackground)
      setChonseBorder(
        findKeyInBorderBackground(
          AppResource.borderBackground,
          templateDefault?.boderBackground,
        ),
      )
      setColorBorderBackground(templateDefault?.borderBackgroundColor)
      setContentBackgroundColor(templateDefault?.contentBackgroundColor)
      setFileContentBackground(templateDefault?.contentBackground)
      let locationContentBackground = JSON.parse(
        templateDefault?.contentBackgroundLocation
          ? templateDefault.contentBackgroundLocation
          : '{}',
      )
      setChonseContent({
        ...findKeyInBorderBackground(
          AppResource.contentBackground,
          templateDefault?.contentBackground,
        ),
        x: locationContentBackground?.x ?? 150,
        y: locationContentBackground?.y ?? 320,
        width: locationContentBackground?.width ?? 500,
        height: locationContentBackground?.width ?? 500,
        key: templateDefault?.contentBackground,
      })
    }
    setListContentFeild(
      JSON.parse(
        templateDefault?.data
          ? t(templateDefault.data, {
              nameCompany: inforCompany?.companyName,
              businessPermitAddress: inforCompany?.businessPermitAddress,
              companyTaxCode: inforCompany?.taxCode,
              nameCity: inforCompany?.province?.name
                ? `${inforCompany.province.name} ,`
                : ``,
              companyPhone: inforCompany?.companyPhone,
            })
          : '[]',
      ),
    )
  }

  const changeFile = (value, type) => {
    const file = value.fileList?.[0]?.originFileObj
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const img = new Image()
        img.src = e.target.result
        img.onload = () => {
          const { width, height } = img
          let newWidth, newHeight
          if (width > height) {
            newHeight = Math.round((height * 68) / width)
            newWidth = 68
          } else {
            newWidth = Math.round((width * 68) / height)
            newHeight = 68
          }
          if (type === 'imgLogo') {
            setImgLogo({
              ...imgLogo,
              height: newHeight,
              width: newWidth,
              file: value.fileList?.[0],
              url: e.target.result,
            })
          } else if (type === 'fileOwnWallpaper') {
            if (width > height) {
              newHeight = Math.round((height * 600) / width)
              newWidth = 600
            } else {
              newWidth = Math.round((width * 600) / height)
              newHeight = 600
            }
            setFileOwnWallpaper({
              ...fileOwnWallpaper,
              ...value,
              height: newHeight,
              width: newWidth,
              file: value.fileList?.[0],
              url: e.target.result,
            })
          }
        }
      }
      reader.readAsDataURL(file)
    } else {
      if (type === 'imgLogo') {
        setImgLogo({
          location: imgLogo.location,
          x: 0,
          y: 0,
          width: 0,
          height: 0,
        })
      } else if (type === 'fileOwnWallpaper') {
        setFileOwnWallpaper({
          x: 0,
          y: 0,
          width: 0,
          height: 0,
        })
      }
    }
  }

  const findDataSource = async () => {
    try {
      const data = unwrapResult(
        await dispatch(thunkGetAllTaxDeductionDocumentsDefault({})),
      )
      setListDataTemplateDefault(data?.items)
    } catch (error) {}
  }

  const getDataCompany = async (companyId) => {
    try {
      const data = unwrapResult(
        await dispatch(thunkGetCompanyProfile({ companyId })),
      )
      setInforCompany(data)
    } catch (error) {}
  }

  useEffect(() => {
    findDataSource()
  }, [])

  useEffect(() => {
    if (tabActiveKeyRightContent != '3') {
      setActiveFeild({})
      setActiveTd({})
      setActiveLayout({})
    }
  }, [tabActiveKeyRightContent])

  useEffect(() => {
    if (listDataTemplateDefault && listDataTemplateDefault.length > 0) {
      setTemplateDefault(listDataTemplateDefault[0])
    }
  }, [listDataTemplateDefault])

  useEffect(() => {
    if (templateDefault) {
      setImgLogoTemplateDefault(
        JSON.parse(templateDefault?.imglogo ? templateDefault.imglogo : '{}'),
      )
      setImgLOwnWallpaper(
        JSON.parse(
          templateDefault?.fileOwnWallpaper
            ? templateDefault.fileOwnWallpaper
            : '{}',
        ),
      )
    }
  }, [templateDefault])

  useMemo(() => {
    if (auth?.companyId) {
      getDataCompany(auth.companyId)
    }
  }, [auth])

  // useMemo(() => {
  //   form.setFieldsValue({
  //     organizationName: inforCompany?.companyName,
  //   })
  // }, [inforCompany])

  useEffect(() => {
    resetForm()
  }, [inforCompany, templateDefault, form, auth])

  useMemo(() => {
    setImgLogo({
      x: imgLogoTemplateDefault.x ?? 0,
      y: imgLogoTemplateDefault.y ?? 0,
      width: imgLogoTemplateDefault?.width ?? 75,
      height: imgLogoTemplateDefault?.height ?? 56,
      location: imgLogoTemplateDefault?.location,
      url: imgLogoTemplateDefault?.url
        ? `${process.env.REACT_APP_BASE_URL}${imgLogoTemplateDefault.url}`
        : null,
    })
  }, [imgLogoTemplateDefault])

  useMemo(() => {
    setFileOwnWallpaper({
      x: imgLOwnWallpaper?.x ?? 0,
      y: imgLOwnWallpaper?.y ?? 0,
      width: imgLOwnWallpaper?.width ?? 75,
      height: imgLOwnWallpaper?.height ?? 56,
      location: imgLOwnWallpaper?.location,
      url: imgLOwnWallpaper?.url
        ? `${process.env.REACT_APP_BASE_URL}${imgLOwnWallpaper.url}`
        : null,
    })
  }, [imgLOwnWallpaper])

  useMemo(() => {
    const fetchData = async () => {
      const data = await dispatch(thunkGetAccountProfile())
      if (data?.payload) {
        const dataCompany = await dispatch(
          thunkGetCompanyProfile({
            companyId: data?.payload?.company?.companyId,
          }),
        )
        setTypeSign(dataCompany?.payload?.activeSigningMethod)
      }
      setAuth(data?.payload?.company)
    }
    fetchData()
  }, [dispatch])

  const save = (values, sign) => {
    const formData = new FormData()
    formData.append('templateId', values.taxDuctionDocumentsTemplateId)
    formData.append('circular', templateDefault.circular)
    formData.append('templatePaperName', templateDefault.templatePaperName)
    formData.append('typePaper', templateDefault.typePaper)
    formData.append('imglogo', JSON.stringify({ ...imgLogo, file: null }))
    formData.append('language', templateDefault.language)
    formData.append('data', JSON.stringify(listContentFeild))
    formData.append('symbolCode', values.symbolCode)
    formData.append('symbolYear', dayjs(values.symbolYear).year())
    formData.append('symbolType', values.symbolType)
    formData.append('number', templateDefault.number)
    formData.append('typeMoney', values.typeMoney)
    formData.append('organizationName', values.organizationName)
    formData.append('positionLogo', values.positionLogo)
    formData.append('viewConversionTemplate', values.viewConversionTemplate)
    formData.append('boderBackground', fileBorderBackground)
    formData.append('borderBackgroundColor', colorBorderBackground)
    formData.append('contentBackground', fileContentBackground)
    formData.append('contentBackgroundColor', contentBackgroundColor)
    formData.append(
      'contentBackgroundLocation',
      JSON.stringify({ ...chonseContent, file: null, url: null }),
    )
    formData.append(
      'contentBackgroundOpactity',
      values.opacityContentBackground,
    )
    formData.append('fontTextOwnWallpaper', formState.fontTextOwnWallpaper)
    formData.append(
      'fontSizeTextOwnWallpaper',
      formState.fontSizeTextOwnWallpaper,
    )
    formData.append('boldOwnWallpaper', formState.boldOwnWallpaper)
    formData.append('ownWallpaperColor', formState.ownWallpaperColor)
    formData.append('opacityOwnWallpaper', formState.opacityOwnWallpaper)
    formData.append(
      'opacityTextOwnWallpaper',
      formState.opacityTextOwnWallpaper,
    )
    formData.append('styleOwnWallpaper', JSON.stringify(styleOwnWallpaper))
    formData.append('describeownWallpaper', formState.describeownWallpaper)
    formData.append('formNumber', templateDefault.formNumber)
    formData.append('templatePaperDetail', templateDefault.templatePaperDetail)
    formData.append('issueDate', templateDefault.issueDate)

    formData.append('taxCode', auth.taxCode)
    formData.append('address', auth.businessPermitAddress)
    formData.append('phone', auth.contactPersonPhone)
    formData.append('status', 0)
    if (imgLogo?.file?.originFileObj) {
      formData.append('fileLogo', imgLogo.file.originFileObj)
    }
    if (fileOwnWallpaper?.file?.originFileObj) {
      formData.append('fileOwnWallpaper', fileOwnWallpaper.file.originFileObj)
    }
    formData.append(
      'positionOwnWallpaper',
      JSON.stringify({
        ...fileOwnWallpaper,
        file: null,
        url: fileOwnWallpaper?.file
          ? null
          : fileOwnWallpaper?.url
            ? fileOwnWallpaper.url.replace(process.env.REACT_APP_BASE_URL, '')
            : null,
        fileList: null,
      }),
    )
    if (sign) {
      formData.append('sign', sign)
      formData.append('typeSign', typeSign)
      console.log('typeSign', typeSign)
      if (
        typeSign === DIGITAL_SIGN_METHOD.HSM ||
        typeSign === DIGITAL_SIGN_METHOD.USB
      ) {
        saveData(formData, sign, typeSign, values)
      } else {
        alert(t('taxDeductionDocuments.checkTypeSign'))
      }
    } else {
      saveData(formData)
    }
  }

  const saveData = async (formData, sign, typeSign, values) => {
    try {
      const dataSave = unwrapResult(
        await dispatch(thunkSaveTaxDeductionDocumentsDefault(formData)),
      )
      if (dataSave?.message === 'success') {
        if (sign) {
          if (typeSign === DIGITAL_SIGN_METHOD.HSM) {
            setOpenModalAlertTemplateSave(false)
            if (dataSave?.typeSignError) {
              ToastHelper.showWarning(`${dataSave?.data}`)
            } else {
              ToastHelper.showSuccess(
                `${t('taxDeductionDocuments.messageSuscessSaveTemplate')}`,
              )
            }

            navigate('/hoa-don-dau-ra/chung-tu-tncn/thiet-lap/mau')
          } else if (typeSign === DIGITAL_SIGN_METHOD.USB) {
            ToastHelper.showSuccess(
              `${t('taxDeductionDocuments.messageSuscessSaveTemplate')}`,
            )
            setOpenModalAlertTemplateSave(false)
            let accessToken = localStorage.getItem(PreferenceKeys.accessToken)
            let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},${auth.taxCode},${dataSave?.data?.templatePaperId},TemplatePaper`
            console.log('urlSchema', urlSchema)
            Utils.openToolSignTemplatePaper(urlSchema, accessToken)
          } else {
          }
        } else {
          setOpenModalAlertTemplateSave(false)
          ToastHelper.showSuccess(
            `${t('taxDeductionDocuments.messageSuscessSaveTemplate')}`,
          )
          navigate('/hoa-don-dau-ra/chung-tu-tncn/thiet-lap/mau')
        }
      } else if (dataSave?.message === 'error') {
        ToastHelper.showError(dataSave?.data)
      }
    } catch (error) {}
  }

  return (
    <>
      <HeaderTaxDectionDocumentsUpdate
        setOpenModalAlert={(value) => {
          setOpenModalAlert(value)
        }}
        setOpenModalAlertTemplate={(value) => {
          setOpenModalAlertTemplate(value)
        }}
        setOpenModalMaintenance={(value) => {
          setOpenModalMaintenance(value)
        }}
        openModalAlertTemplateSave={openModalAlertTemplateSave}
        setOpenModalAlertTemplateSave={(value) => {
          form
            .validateFields()
            .then((values) => {
              setOpenModalAlertTemplateSave(value)
            })
            .catch((errorInfo) => {
              setTabActiveKeyRightContent('1')
              console.error('Validation Failed:', errorInfo)
            })
        }}
        nameTemplate={t('taxDeductionDocuments.updateTitleRight', {
          name: templateDefault?.templatePaperDetail,
        })}
        saveSign={() => {
          form
            .validateFields()
            .then((values) => {
              save(values, 1, typeSign)
            })
            .catch((errorInfo) => {
              setTabActiveKeyRightContent('1')
              console.error('Validation Failed:', errorInfo)
            })
        }}
      />

      <ContainerContent>
        <LeftContentTaxDeductionDocuments
          typePaper={typePaper}
          taxDeductionDocumentsTemplate={taxDeductionDocumentsTemplate}
          backgroundImg={backgroundImg}
          setBackgroundImg={(value) => {
            setBackgroundImg(value)
          }}
          imgLogo={imgLogo}
          setImgLogo={(value) =>
            setImgLogo((imgLogo) => ({
              ...imgLogo,
              ...value,
            }))
          }
          fileOwnWallpaper={fileOwnWallpaper}
          setFileOwnWallpaper={(value) => {
            setFileOwnWallpaper({ ...fileOwnWallpaper, ...value })
          }}
          chonseBorder={chonseBorder}
          fileBorderBackground={fileBorderBackground}
          colorBorderBackground={colorBorderBackground}
          chonseContent={chonseContent}
          setChonseContent={(value) => {
            setChonseContent(value)
          }}
          setChonseBorder={(value) => {
            setChonseBorder(value)
          }}
          fileContentBackground={fileContentBackground}
          setFileContentBackground={(value) => {
            setFileContentBackground((fileContentBackground) => ({
              ...fileContentBackground,
              ...value,
            }))
          }}
          contentBackgroundColor={contentBackgroundColor}
          listContentFeild={listContentFeild}
          setListContentFeild={(value) => {
            setListContentFeild(value)
          }}
          formState={formState}
          tabActiveKey={tabActiveKeyRightContent}
          setTabActiveKey={(value) => {
            setTabActiveKeyRightContent(value)
          }}
          activeFeild={activeFeild}
          setActiveFeild={(value) => {
            setActiveFeild(value)
          }}
          activeLayout={activeLayout}
          setActiveLayout={(value) => {
            setActiveLayout(value)
          }}
          activeTd={activeTd}
          setActiveTd={(value) => {
            setActiveTd(value)
          }}
          language={language}
          setFormState={(value) => {
            setFormState(value)
          }}
          styleOwnWallpaper={styleOwnWallpaper}
          setStyleOwnWallpaper={(value) => {
            setStyleOwnWallpaper(value)
          }}
        />
        <RightContentTaxDeductionDocuments
          listContentFeild={listContentFeild}
          setFormState={(value) => {
            setFormState(value)
          }}
          formState={formState}
          tabActiveKey={tabActiveKeyRightContent}
          onChangeTab={(value) => {
            setTabActiveKeyRightContent(value)
          }}
          form={form}
          listDataTemplate={listDataTemplateDefault}
          resetForm={resetForm}
          imgLogo={imgLogo}
          setImgLogo={(value) => {
            changeFile(value, 'imgLogo')
          }}
          chonseBorder={chonseBorder}
          setChonseBorder={(value) => {
            setChonseBorder(value)
          }}
          fileBorderBackground={fileBorderBackground}
          setFileBorderBackground={(value) => {
            setFileBorderBackground(value)
          }}
          chonseContent={chonseContent}
          setChonseContent={(value) => {
            setChonseContent(value)
          }}
          fileContentBackground={fileContentBackground}
          setFileContentBackground={(value) => {
            setFileContentBackground(value)
          }}
          fileOwnWallpaper={fileOwnWallpaper}
          setFileOwnWallpaper={(value) => changeFile(value, 'fileOwnWallpaper')}
          colorBorderBackground={colorBorderBackground}
          setColorBorderBackground={(value) => {
            setColorBorderBackground(value)
          }}
          contentBackgroundColor={contentBackgroundColor}
          setContentBackgroundColor={(value) => {
            setContentBackgroundColor(value)
          }}
          backgroundImg={backgroundImg}
          setBackgroundImg={(value) => {
            setBackgroundImg(value)
          }}
          activeFeild={activeFeild}
          setActiveFeild={(value) => {
            setActiveFeild(value)
          }}
          activeLayout={activeLayout}
          setActiveLayout={(value) => {
            setActiveLayout(value)
          }}
          activeTd={activeTd}
          setActiveTd={(value) => {
            setActiveTd(value)
          }}
          setListContentFeild={(value) => {
            setListContentFeild(value)
          }}
          language={language}
        />
      </ContainerContent>
      <AlertTaxDectionDocumentsUpdate
        titlePrimary={t('taxDeductionDocuments.save')}
        openModalAlert={openModalAlert}
        titleAlert={t('taxDeductionDocuments.dataHasChanged')}
        messageAlert={t('taxDeductionDocuments.messageAlert')}
        setOpenModalAlert={(value) => {
          setOpenModalAlert(value)
        }}
        titleButtonNotRecommended={t('taxDeductionDocuments.notSave')}
        onClickButtonNotRecommended={() => {
          navigate('/hoa-don-dau-ra/chung-tu-tncn/thiet-lap/mau')
        }}
        onClickPrimary={() => {
          form
            .validateFields()
            .then((values) => {
              save(values)
            })
            .catch((errorInfo) => {
              setTabActiveKeyRightContent('1')
              console.error('Validation Failed:', errorInfo)
            })
        }}
      />
      <AlertTaxDectionDocumentsUpdate
        titleCancel={t('taxDeductionDocuments.no')}
        titlePrimary={t('taxDeductionDocuments.yes')}
        openModalAlert={openModalAlertTemplate}
        titleAlert={t('taxDeductionDocuments.cb')}
        messageAlert={t('taxDeductionDocuments.messageAlertcb')}
        setOpenModalAlert={(value) => {
          setOpenModalAlertTemplate(value)
        }}
        onClickPrimary={() => {
          resetForm()
          setOpenModalAlertTemplate(false)
        }}
      />
      <AlertTaxDectionDocumentsUpdate
        titleCancel={t('taxDeductionDocuments.no')}
        titlePrimary={t('taxDeductionDocuments.yes')}
        openModalAlert={openModalAlertTemplateSave}
        titleAlert={t('taxDeductionDocuments.titleSaveTemplate')}
        messageAlert={t('taxDeductionDocuments.messageSaveTemplate')}
        setOpenModalAlert={(value) => {
          setOpenModalAlertTemplateSave(value)
        }}
        onClickPrimary={() => {
          form
            .validateFields()
            .then((values) => {
              save(values)
            })
            .catch((errorInfo) => {
              console.error('Validation Failed:', errorInfo)
            })
        }}
      />
    </>
  )
}
export default AddTaxDeductionDocuments
