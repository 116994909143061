import { queryOptions } from '@tanstack/react-query'
import registrationApi from 'api/registrationApi'
import _ from 'lodash'

export const listRegistrationQueries = {
  base: {
    scope: ['registration', 'list'],
  },
  listRegistration: (params) =>
    queryOptions({
      queryKey: [
        listRegistrationQueries.base,
        { entity: 'listRegistration' },
        params,
      ],
      queryFn: ({ signal }) => registrationApi.getRegistrations(params, signal),
      select: (result) => {
        return {
          total: _.get(result, 'total', 0),
          registrations: result?.registrations
            ? _.map(result?.registrations, (reg, idx) => ({
                ...reg,
                id: idx + 1,
              }))
            : [],
        }
      },
    }),
}
