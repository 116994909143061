import Empty from 'general/components/Empty'
import ProtectedRoute from 'general/components/Routes/ProtectedRoute'
import AppResource from 'general/constants/AppResource'
import Utils from 'general/utils/Utils'
import { useAppSelector } from 'hooks/useRedux'
import { useMemo } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'
import CreateRegistration from './screens/CreateRegistration'
import ListRegistration from './screens/ListRegistration'

function Registration() {
  // MARK: --- Params ---
  const { t } = useTranslation()
  const userFunction = useAppSelector((state) => state?.function?.userFunctions)
  const listFunction = useAppSelector((state) => state?.function?.listFunctions)
  const [isViewListRegistration] = useMemo(() => {
    return Utils.checkFunction(
      [
        {
          listFunctionCode: 'TOKHAI_VIEW_LIST',
        },
      ],
      userFunction,
      listFunction,
    )
  }, [userFunction, listFunction])
  return (
    <Routes>
      <Route
        path=""
        element={
          <ProtectedRoute permission={isViewListRegistration}>
            <ListRegistration />
          </ProtectedRoute>
        }
      />
      <Route path="tao" element={<CreateRegistration />} />
      <Route path="xem/:registrationId" element={<CreateRegistration />} />
      <Route path="sua/:registrationId" element={<CreateRegistration />} />
      <Route
        path="*"
        element={
          <Container fluid className="h-100 d-flex">
            <Row className="justify-content-center align-items-center flex-grow-1">
              <Empty
                imageEmptyPercentWidth={30}
                text={t('PageNotFound')}
                buttonText={t('Refresh')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
              />
            </Row>
          </Container>
        }
      />
    </Routes>
  )
}

export default Registration
