import { queryOptions } from '@tanstack/react-query'
import dashboardApi from 'api/dashboardApi'
import _ from 'lodash'

const FRESH_TIME = 1000 * 1
export const queriesKeys = {
  base: {
    scope: ['dashboard'],
  },
  issuedInvoicesCount: (params) =>
    queryOptions({
      staleTime: FRESH_TIME,
      refetchOnWindowFocus: true,
      retry: false,
      queryKey: [queriesKeys.base, 'issuedInvoicesCount', params],
      queryFn: ({ signal }) =>
        dashboardApi.getOverallNew(
          '/dashboard/get-overall/issuedInvoicesCount',
          params,
          signal,
        ),
      select: (res) => {
        if (res.result !== 'success') {
          return 0
        }
        return res.metadata ?? 0
      },
    }),
  notIssuedInvoicesCount: (params) =>
    queryOptions({
      staleTime: FRESH_TIME,
      refetchOnWindowFocus: true,
      retry: false,
      queryKey: [queriesKeys.base, 'notIssuedInvoicesCount', params],
      queryFn: ({ signal }) =>
        dashboardApi.getOverallNew(
          '/dashboard/get-overall/notIssuedInvoicesCount',
          params,
          signal,
        ),
      select: (res) => {
        if (res.result !== 'success') {
          return 0
        }
        return res.metadata ?? 0
      },
    }),
  issuedInvoicesValue: (params) =>
    queryOptions({
      staleTime: FRESH_TIME,
      refetchOnWindowFocus: true,
      retry: false,
      queryKey: [queriesKeys.base, 'issuedInvoicesValue', params],
      queryFn: ({ signal }) =>
        dashboardApi.getOverallNew(
          '/dashboard/get-overall/issuedInvoicesValue',
          params,
          signal,
        ),
      select: (res) => {
        if (res.result !== 'success') {
          return 0
        }
        return res.metadata ?? 0
      },
    }),
  createdInvoicesByDay: (params) =>
    queryOptions({
      staleTime: FRESH_TIME,
      refetchOnWindowFocus: true,
      retry: false,
      queryKey: [queriesKeys.base, 'createdInvoicesByDay', params],
      queryFn: ({ signal }) =>
        dashboardApi.getOverallNew(
          '/dashboard/get-overall/createdInvoicesByDay',
          params,
          signal,
        ),
      select: (res) => {
        if (res.result !== 'success') {
          return []
        }
        return _.map(res.metadata, (item) => ({
          date: item.date,
          count: item.count,
        }))
      },
    }),
  statisticInvoice: (params) =>
    queryOptions({
      staleTime: FRESH_TIME,
      refetchOnWindowFocus: true,
      retry: false,
      queryKey: [queriesKeys.base, 'statisticInvoice', params],
      queryFn: ({ signal }) =>
        dashboardApi.getOverallNew(
          '/dashboard/get-overall/statisticInvoice',
          params,
          signal,
        ),
      select: (res) => {
        if (res.result !== 'success') {
          return {
            accepted: 0,
            rejected: 0,
            waiting: 0,
          }
        }
        return {
          accepted: _.get(res.metadata, 'accepted', 0),
          rejected: _.get(res.metadata, 'rejected', 0),
          waiting: _.get(res.metadata, 'waiting', 0),

          //ticket
          total_issued: _.get(res.metadata, 'total_issued', 0),
          total_not_issued: _.get(res.metadata, 'total_not_issued', 0),
        }
      },
    }),
  totalInvoiceValueByCustomer: (params) =>
    queryOptions({
      staleTime: FRESH_TIME,
      refetchOnWindowFocus: true,
      retry: false,
      queryKey: [queriesKeys.base, 'totalInvoiceValueByCustomer', params],
      queryFn: ({ signal }) =>
        dashboardApi.getOverallNew(
          '/dashboard/get-overall/totalInvoiceValueByCustomer',
          params,
          signal,
        ),
      select: (res) => {
        if (res.result !== 'success') {
          return []
        }
        return _.map(res.metadata, (item) => ({
          customer: item.customer,
          totalMoney: item.totalMoney,
        }))
      },
    }),
  allInvoiceByStatus: (params) =>
    queryOptions({
      staleTime: FRESH_TIME,
      refetchOnWindowFocus: true,
      retry: false,
      queryKey: [queriesKeys.base, 'allInvoiceByStatus', params],
      queryFn: ({ signal }) =>
        dashboardApi.getOverallNew(
          '/dashboard/get-overall/allInvoiceByStatus',
          params,
          signal,
        ),
      select: (res) => {
        if (res.result !== 'success') {
          return {
            canceledInvoicesCount: 0,
            adjustedInvoicesCount: 0,
            replacedInvoicesCount: 0,
            notIssuedInvoicesCount: 0,
          }
        }
        return {
          canceledInvoicesCount: _.get(
            res,
            'metadata.canceledInvoicesCount',
            0,
          ),
          adjustedInvoicesCount: _.get(
            res,
            'metadata.adjustedInvoicesCount',
            0,
          ),
          replacedInvoicesCount: _.get(
            res,
            'metadata.replacedInvoicesCount',
            0,
          ),
          notIssuedInvoicesCount: _.get(
            res,
            'metadata.notIssuedInvoicesCount',
            0,
          ),
        }
      },
    }),
}
