import {
  Modal,
  Button,
  Tabs,
  Form,
  Select,
  Input,
  DatePicker,
  Upload,
  Radio,
  ColorPicker,
  Checkbox,
  InputNumber,
  Slider,
  Space,
} from 'antd'
import styled from 'styled-components'
import {
  LeftOutlined,
  FileImageOutlined,
  RightOutlined,
  FormOutlined,
  DeleteOutlined,
  CopyOutlined,
  SendOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  PrinterOutlined,
  PlusOutlined,
  StopOutlined,
  MailOutlined,
  MinusOutlined,
  UndoOutlined,
} from '@ant-design/icons'
import { Rnd } from 'react-rnd'
const { Search, TextArea } = Input
const { RangePicker } = DatePicker
export const TitleRight = styled.span`
  color: black;
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 25px;
  display: block;
`

export const ContainerSearch = styled.div`
  padding: 0px 25px;
`

export const ContainerTable = styled.div`
  background-color: #ffffff;
`

export const DivRight = styled.div`
  text-align: right;
`

export const TitleLeft = styled.span`
  color: black;
  font-weight: 600;
  font-size: 18px;
  display: block;
`
export const ContainerHeader = styled.div`
  padding: 15px 25px;
  border-bottom: 1px solid #ebedf3;
`
export const ContainerContent = styled.div`
  padding: 10px 25px;
  display: flex;
  width: 100%;
`
export const IconLeftOutlined = styled(LeftOutlined)`
  position: absolute;
  left: -8px;
  top: 7px;
  font-size: 14px;
  cursor: pointer;
`
export const ButtonNotRecommended = styled(Button)`
  border-radius: 3px;
  margin-right: 5px;
  background-color: #f2f2f2;
  font-weight: 500;
  :hover {
    background-color: #dfe5e8;
    color: black !important;
    border-color: black !important;
  }
`

export const DivColFooterNotBorder = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent ?? 'unset'};
  padding-left: 0px;
  padding-right: 0px;
`
export const ModalWrapperAlert = styled(Modal)`
  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .ant-modal-body {
    height: 100%;
    overflow-y: overlay;
    margin: 4px 2px 4px 0;
    color: black;
  }

  .ant-modal-title {
    font-weight: bold;
    font-size: 16px;
  }

  .ant-modal-footer {
    border-top: ${(props) =>
      props.notBorderTopFoodter ? 'unset' : '1px solid #e6e6e6'};
    background-color: ${(props) =>
      props.notBorderTopFoodter ? 'unset' : '#f5f6f8'};
    margin: 0px -24px;
    padding: 10px 24px;
    height: 60px;
    margin-bottom: -20px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  button:focus {
    outline: none;
  }
  .ant-modal-title {
    font-size: 20px;
    color: #212121;
  }
  .ant-modal-close {
    right: 20px;
  }
`
export const SpanNotYetReleased = styled.span`
  font-size: 12px;
  padding: 3px 13px;
  background-color: #ffffff;
  border-radius: 12px;
  position: relative;
  left: 15px;
`
export const SpanNotYetReleasedCancel = styled.span`
  font-size: 12px;
  padding: 3px 13px;
  background-color: rgb(255, 205, 205);
  color: rgb(230, 29, 29);
  border-radius: 12px;
  position: relative;
  left: 15px;
`
export const LeftContent = styled.div`
  min-width: 801px;
  width: 100%;
  background-color: #eee;
  height: 100%;
  border: 0.5px solid #d9d9d9;
  margin-right: 9px;
`
export const RightContent = styled.div`
  background-color: #ffffff;
  padding-bottom: 20px;
  border: 0.5px solid #d9d9d9;
  flex-grow: 1;
  min-width: 350px;
  width: 800px;
`

export const DivFrame = styled.div`
  background-image: url('${(props) => props.urlBackGroupImage}');
  background-repeat: no-repeat;
  background-color: #ffffff;
  background-size: 100% 100%;
  opacity: 1;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: relative;
  box-shadow:
    0 2px 4px 0 rgba(14, 30, 37, 0.12),
    0 2px 16px 0 rgba(14, 30, 37, 0.32);
`

export const RndCustom = styled(Rnd)`
  position: absolute;
  user-select: auto;
  width: 521px;
  height: 535px;
  display: inline-block;
  top: 0px;
  left: 0px;
  cursor: move;
  z-index: 2;
  transform: translate(107.5px, 379.5px);
  max-width: 9.0072e15px;
  max-height: 9.0072e15px;
  box-sizing: border-box;
  border: ${(props) =>
    props.unSetBorder ? 'unset' : '1px solid #5180fb !important'};
  pointer-events: ${(props) => (props.unSetBorder ? 'none' : 'unset')};
  opacity: ${(props) => props.opacity};
`
export const ImgRnd = styled.img`
  z-index: 0;
  position: relative;
  pointer-events: none;
`

export const RndCustomTemplate = styled(Rnd)``
export const ImgRndTemplate = styled.img`
  z-index: 0;
  pointer-events: none;
`

export const FormCustom = styled(Form)`
  .ant-tabs-content-holder {
    padding: 0px 12px;
    padding-top: 20px;
  }
  .ant-form-item-label > label {
    font-weight: 600 !important;
    font-size: 13px !important;
    color: #454545 !important;
    line-height: 18px !important;
    cursor: default;
    letter-spacing: unset !important;
  }
  .ant-form-item-label {
    width: ${(props) => (props.widthLabel ? props.widthLabel : '120px;')};
    text-align: left;
  }
  .hideLabel .ant-form-item-label {
    width: 0px !important;
  }
  .hideLabel label {
    display: none !important;
  }
  .marginLeftLabel .ant-form-item-label {
    margin-right: 55px;
  }

  .ant-form-item-label > label::after {
    content: '';
  }
  .ant-form-item-label > label::after {
    content: '';
  }
  .ant-form-item {
    margin: ${(props) => props.margin};
    margin-bottom: 20px;
  }
  .ant-form-item-required::before {
    content: '' !important;
    margin-inline-end: 0px !important;
  }
  .ant-form-item-explain-error {
    font-size: 11px;
  }
`

export const SelectCustom = styled(Select)`
  .ant-select-selector {
    border-radius: 3px;
  }
`
export const InputCustom = styled(Input)`
  border-radius: 3px;
  .ant-input {
    border-radius: 3px;
  }
  input {
    border-radius: 3px !important;
    min-width: ${(props) => props.minWidth};
  }
  color: ${(props) => props.colorStyle};
  font-weight: ${(props) => props.fontWeight};
`
export const InputCustomGY = styled(Input)`
  .ant-input {
    border-radius: 3px;
  }
  color: ${(props) => props.colorStyle};
  font-weight: ${(props) => props.fontWeight};
  .ant-select-show-arrow {
    width: ${(props) => props.widthDropdown};
  }
  .ant-input-wrapper {
    position: relative;
  }
  .ant-input-wrapper input {
    position: absolute;
    width: 428px;
    z-index: 2;
  }
  .ant-input-group-addon {
    height: 32px;
    z-index: 0;
  }
`

export const SearchCustom = styled(Search)`
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  .ant-input {
    border-radius: 3px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  input {
    min-width: ${(props) => props.minWidth};
    border-right: unset;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  color: ${(props) => props.colorStyle};
  font-weight: ${(props) => props.fontWeight};
  .ant-input-search-button {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    border-left: unset;
  }
  :hover {
    .ant-input {
      border-color: #5978ff;
    }
    .ant-input-search-button {
      border-color: #5978ff;
    }
  }
  :where(.css-dev-only-do-not-override-10gtad1).ant-input:focus,
  :where(.css-dev-only-do-not-override-10gtad1).ant-input:focus-within {
    border-color: #304ffd;
    box-shadow: 0 0 0 0 rgba(5, 88, 255, 0.06) !important;
    outline: 0;
  }
`

export const DatePickerCustom = styled(DatePicker)`
  border-radius: 3px;
  .ant-input {
    border-radius: 3px;
  }
`
export const RedStar = styled.span`
  font-size: 16px;
  position: relative;
  left: 3px;
  top: 2px;
  color: red;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
`
export const DivTddCode = styled.div`
  justify-content: ${(props) => props.justifyContent};
  text-align: ${(props) => props.textAlign};
  padding: ${(props) => props.padding};
  display: ${(props) => props.display};
  width: ${(props) => props.width};
  font-family: ${(props) => props.fontFamily};
`
export const DivCenterImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  background-color: #f4f4f4;
  width: 100%;
  border: 0.5px solid #d9d9d9;
  border-radius: 3px;
  position: relative;
  pointer-events: none;
  background-image: url('${(props) => props.urlBackGroupImage}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`
export const UploadCustom = styled(Upload)`
  width: 100%;
  .ant-upload-select {
    width: 100%;
  }
`
export const RadioCustom = styled(Radio)`
  color: #000000;
`
export const IconFileImageOutlined = styled(FileImageOutlined)`
  font-size: 40px;
`
export const DivUpdateFileLogo = styled.div`
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 3px;
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0px;
  > img {
    pointer-events: none;
  }
`
export const DivRemmoveFileLogo = styled.div`
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 3px;
  position: absolute;
  cursor: pointer;
  top: 45px;
  right: 0px;
  > img {
    pointer-events: none;
  }
`
export const DivFlex = styled.div`
  display: flex;
  padding-left: ${(props) => props.paddingLeft};
  padding-top: ${(props) => props.paddingTop};
  background: ${(props) => props.backGround};
  img {
    padding-top: 7px;
  }
  > div {
    padding-right: ${(props) =>
      props.paddingRightV1 ? props.paddingRightV1 : '35px'};
  }
  > div:last-child {
    padding-right: 0px;
  }
`
export const DivListItem = styled.div`
  display: flex;
  padding-left: ${(props) => props.paddingLeft};
  padding-top: ${(props) => props.paddingTop};
  background: ${(props) => props.backGround};
  img {
    padding-top: 7px;
  }
  > div {
    display: flex;
    padding-right: ${(props) =>
      props.paddingRightV1 ? props.paddingRightV1 : '15px'};
  }
  > div:last-child {
    padding-right: 0px;
  }
  > div:not(:last-child)::after {
    content: '|';
    color: #e0e0e0;
  }
  > div span {
    margin-left: 10px;
    padding-right: ${(props) =>
      props.paddingRightV1 ? props.paddingRightV1 : '15px'};
  }
  b {
    width: auto;
    min-width: 55px;
  }
`

export const DivFlexCustom = styled.div`
  display: flex;
  > div {
    padding-right: ${(props) =>
      props.paddingRightV1 ? props.paddingRightV1 : '20px'};
  }
  > div:last-child {
    padding-right: 0px;
  }
`
export const DivBorder = styled.div`
  border-radius: 3px;
  padding-bottom: 0px !important;
`
export const TextlogoFont = styled.div`
  font-size: 24px;
  color: dodgerblue;
`
export const TextFont = styled.div`
  font-size: 13px;
  position: relative;
  top: 3px;
  font-weight: 500;
  color: #454545;
`
export const ButtonCustom = styled(Button)`
  border-radius: 3px;
  border-color: ${(props) => props.borderColor};
  border: ${(props) => props.border};
  color: ${(props) => props.color};
  width: ${(props) => props.width};
  background-color: ${(props) => props.backgroundColor};
  :hover {
    border: 1px solid #205cd6;
    color: #205cd6 !important;
    background-color: #d8ebff;
  }
`
export const ButtonCustomT = styled(Button)`
  border-radius: 3px;
  border-color: ${(props) => props.borderColor};
  border: ${(props) => props.border};
  color: ${(props) => props.color};
  width: ${(props) => props.width};
  background-color: ${(props) => props.backgroundColor};
  :hover {
    border: 0px solid #205cd6;
    color: #ffffff !important;
    background-color: #5166e0;
  }
`
export const ButtonCustomAddFeild = styled(Button)`
  margin: ${(props) => props.margin};
  border-radius: 3px;
  border-color: #007aff;
  color: #007aff;
  width: ${(props) => props.width};
  :hover {
    border: 1px solid #205cd6;
    color: #205cd6 !important;
    background-color: #d8ebff;
  }
`
export const DivfontS = styled.div`
  > button {
    margin-right: 5px;
  }
  > button:last-child {
    margin-right: 0px;
  }
`
export const ColorPickerCustom = styled(ColorPicker)`
  border-radius: 3px;
  .ant-color-picker-color-block {
    border-radius: 15px;
  }
`
export const CheckboxCustom = styled(Checkbox)`
  border-radius: 3px;
  .ant-checkbox {
    top: -7px;
    left: 30px;
  }
`

export const LabelForm = styled.span`
  font-size: 13px !important;
  color: ${(props) => (props.color ? props.color : '#454545')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '#500')};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '1px')};
  padding-right: ${(props) =>
    props.paddingRight ? props.paddingRight : '1px'};
  border-bottom: ${(props) => props.borderBottom};
  padding-bottom: ${(props) => props.paddingBottom};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => props.lineHeight};
  padding: ${(props) => props.padding};
  cursor: ${(props) => props.pointer};
  background: ${(props) => props.backGround};
  margin-left: ${(props) => props.marginLeft};
`
export const DivFlexRight = styled.div`
  display: flex;
  float: right;
  cursor: pointer;
  position: relative;
  bottom: 12px;
  > div {
    padding-right: 20px;
  }
  > div:last-child {
    padding-right: 0px;
  }
`
export const DivBackGroundTab = styled.div`
  border-radius: 3px;
  height: 20px;
  border: 0.5px solid #d9d9d9;
  margin-top: ${(props) => props.marginTop};
  padding-top: ${(props) => props.paddingTop};
  padding-bottom: ${(props) => props.paddingBottom};
  cursor: pointer;
  padding-left: 25px;
  padding-right: 25px;
  margin-right: 35px;
  margin-left: 35px;
`
export const IconRightOutlined = styled(RightOutlined)`
  position: relative;
  color: ${(props) => (props.color ? props.color : '#454545')};
  float: ${(props) => props.float};
`
export const InputNumberCumstom = styled(InputNumber)`
  border-radius: 3px;
  .ant-input-number {
    width: ${(props) => props.width};
  }
  input {
    text-align: ${(props) => props.textAlignNumber} !important;
  }
`
export const SliderCumstom = styled(Slider)`
  left: 35px;
  position: relative;
  bottom: 8px;
  width: ${(props) => props.width};
  margin-left: ${(props) => props.marginLeft};
`
export const DivCustom = styled.div`
  border-radius: 3px;
  height: 20px;
  border: 0.5px solid #d9d9d9;
  margin-top: ${(props) => props.marginTop};
  padding-top: ${(props) => props.paddingTop};
  padding-bottom: ${(props) => props.paddingBottom};
  cursor: pointer;
  padding-left: 25px;
  padding-right: 25px;
  margin-right: 35px;
  margin-left: 35px;
`
export const DivCustomForm = styled.div`
  padding-left: ${(props) => props.paddingLeft};
  padding-bottom: ${(props) => props.paddingBottom};
  width: ${(props) => props.width};
  background: ${(props) => props.backGround};
  padding: ${(props) => props.padding};
  text-align: ${(props) => props.textAlign};
`

export const ImgTextAglin = styled.img`
  background-color: #fff;
  height: 100%;
  padding-top: 0px;
  margin-top: 3px;
  padding: 1px;
  border: 1px solid #c0c0c0;
  padding: 1px;
  padding-top: 1px !important;
  margin-left: 10px;
  cursor: pointer;
`
export const DivRightContent = styled.div`
  :hover {
    background-color: ${(props) => props.hoverBackgroundColor};
    > .divFeildImg span {
      display: unset;
    }
  }
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '15px')};
  padding-bottom: ${(props) => props.paddingBottom};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '25px')};
  cursor: ${(props) => props.cursor};
  position: ${(props) => props.position};
`
export const ContainerLayout = styled.div`
  padding: 35px 35px 0px 35px;
`

export const TableLayout = styled.table`
  width: 100%;
  position: ${(props) => props.position};
  bottom: ${(props) => props.bottom};
`

export const TdTableLayout = styled.td`
  width: ${(props) => props.width};
  height: ${(props) => (props.height ? props.height : 'auto')};
  padding: ${(props) => props.padding};
`
export const TrTableLayout = styled.tr`
  cursor: ${(props) => (props.empty ? 'unset' : 'pointer')};
  background-color: ${(props) => (props.activeLayout ? '#f2fff1' : 'unset')};
  border-style: ${(props) =>
    props.activeLayout ? 'dotted !important' : 'unset'};
  border: ${(props) => (props.activeLayout ? '1px solid' : 'unset')};
`
export const DivTableLayout = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => (props.height ? props.height : 'auto')};
  justify-content: ${(props) => props.justifyContent};
  text-align: ${(props) => props.textAlign};
  position: relative;
`
export const DivFieldValue = styled.div`
  position: relative;
  text-align: ${(props) => props.textAlign};
  font-size: ${(props) => props.fontSize};
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-weight: ${(props) => props.fontWeight};
  text-decoration: ${(props) => props.textDecoration};
  text-transform: ${(props) => props.textTransform};
  line-height: ${(props) => props.lineHeight};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-family: ${(props) => props.fontFamily};
  cursor: pointer;
  background-color: ${(props) => (props.active ? '##EBEDF0' : 'unset')};
  border-style: ${(props) => (props.active ? 'solid !important' : 'unset')};
  border: ${(props) => (props.active ? '1px solid #2ca01c' : 'unset')};
`
export const SpanFieldValue = styled.span`
  position: relative;
  text-align: ${(props) => props.textAlign};
  font-size: ${(props) => props.fontSize};
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-weight: ${(props) => props.fontWeight};
  text-decoration: ${(props) => props.textDecoration};
  text-transform: ${(props) => props.textTransform};
  line-height: ${(props) => props.lineHeight};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  margin-left: ${(props) => props.marginLeft};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-family: ${(props) => props.fontFamily};
  cursor: pointer;
  background-color: ${(props) => (props.active ? '##EBEDF0' : 'unset')};
  border-style: ${(props) => (props.active ? 'solid !important' : 'unset')};
  border: ${(props) => (props.active ? '1px solid #2ca01c' : 'unset')};
  display: ${(props) => props.display};
`

export const DivFiledSingleLogo = styled.div`
  position: relative;
  text-align: center;
  font-size: 13px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  text-transform: capitalize;
  line-height: 20px;
  margin: 0px;
`
export const DivText = styled.div`
  padding: 10px;
  font-size: ${(props) => props.fontSize};
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-weight: ${(props) => props.fontWeight};
  font-family: ${(props) => props.fontFamily};
`
export const CheckboxCumstom = styled(Checkbox)`
  margin-left: ${(props) => props.marginLeft};
  color: '#000000';
`
export const DivImgFeild = styled.div`
  position: absolute;
  top: -3px;
  right: 0px;
  display: flex;
  margin-top: 8px;
`
export const FormOutlinedIcon = styled(FormOutlined)`
  font-size: 17px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '3px')};
  display: ${(props) => (props.display ? props.display : 'none')};
  border: ${(props) => props.border};
`
export const SendOutlinedIcon = styled(SendOutlined)`
  font-size: 17px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '3px')};
  display: ${(props) => (props.display ? props.display : 'none')};
  border: ${(props) => props.border};
  color: ${(props) => props.color};
`
export const StopOutlinedIcon = styled(StopOutlined)`
  font-size: 17px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '3px')};
  display: ${(props) => (props.display ? props.display : 'none')};
  border: ${(props) => props.border};
  color: ${(props) => props.color};
`
export const DownloadOutlinedIcon = styled(DownloadOutlined)`
  font-size: 17px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '3px')};
  display: ${(props) => (props.display ? props.display : 'none')};
  border: ${(props) => props.border};
  color: ${(props) => props.color};
`

export const FilePdfOutlinedIcon = styled(FilePdfOutlined)`
  font-size: 17px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '3px')};
  display: ${(props) => (props.display ? props.display : 'none')};
  border: ${(props) => props.border};
  color: ${(props) => props.color};
  margin-right: ${(props) => props.marginRight};
  top: ${(props) => props.top};
  position: ${(props) => props.position};
`

export const FileExcelOutlinedIcon = styled(FileExcelOutlined)`
  font-size: 17px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '3px')};
  display: ${(props) => (props.display ? props.display : 'none')};
  border: ${(props) => props.border};
  color: ${(props) => props.color};
  margin-right: ${(props) => props.marginRight};
  top: ${(props) => props.top};
  position: ${(props) => props.position};
`

export const DeleteOutlinedIcon = styled(DeleteOutlined)`
  font-size: 17px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '3px')};
  color: red;
  display: ${(props) => (props.display ? props.display : 'none')};
`
export const UndoOutlinedIcon = styled(UndoOutlined)`
  font-size: 17px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '3px')};
  color: '#5180fb';
  display: ${(props) => (props.display ? props.display : 'none')};
`
export const PrinterOutlinedIcon = styled(PrinterOutlined)`
  font-size: 17px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '3px')};
  display: ${(props) => (props.display ? props.display : 'none')};
`
export const SpanImgCN = styled.span`
  padding: 8px;
  border-radius: 20px;
  display: block;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 20px;
  background-color: #ffffff;
  :hover {
    border: 1px solid #007aff;
  }
`
export const ContainerFunction = styled.div`
  position: relative;
`
export const DivListFunction = styled.div`
  position: relative;
  display: none;
  span {
    font-size: 17px;
    padding: 5px;
    border-radius: 20px;
    border: 1px solid gray;
    background-color: #ffffff;
  }
  span:hover {
    border: 1px solid #007aff;
  }
  width: 100%;
`
export const DivListFunctionView = styled.div`
  position: relative;
  span {
    font-size: 17px;
    padding: 5px;
    border-radius: 20px;
    border: 1px solid gray;
    background-color: #ffffff;
  }
  span:hover {
    border: 1px solid #007aff;
  }
  width: 100%;
`
export const DivFunction = styled.div`
  right: ${(props) => props.right};
  position: absolute;
`
export const CopyOutlinedIcon = styled(CopyOutlined)`
  font-size: 17px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '3px')};
  display: ${(props) => (props.display ? props.display : 'none')};
  border: ${(props) => props.border};
`
export const MailOutlinedIcon = styled(MailOutlined)`
  font-size: 17px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '3px')};
  display: ${(props) => (props.display ? props.display : 'none')};
  border: ${(props) => props.border};
`
export const TextAreaCustom = styled(TextArea)`
  border-radius: 3px;
`

export const SpanNotYetReleasedTd = styled.span`
  font-size: 12px;
  padding: 3px 13px;
  background-color: ${(props) => props.backGroundColor};
  color: ${(props) => props.color};
  border-radius: 12px;
`
export const SpanAutoType = styled.span`
  display: inline-block;

  div {
    text-transform: none !important;
  }
`

export const RadioCustomT = styled(Radio)`
  .ant-radio-inner {
    border-radius: 0px;
  }
`

export const RangePickerCustom = styled(RangePicker)`
  border-radius: 3px;
  .ant-input {
    border-radius: 3px;
  }
`
export const DivBorderCustom = styled.div`
  border-radius: 3px;
  border: 1px solid #c0c0c0;
`
export const SpanAutoTypeBorder = styled.span`
  margin-left: 8px;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 3px;
  text-transform: none;
  border: 1px solid rgb(192, 192, 192);
  :hover {
    color: #5978ff;
    border: 1px solid #5978ff;
  }
`
export const PlusOutlinedCustom = styled(PlusOutlined)`
  :hover {
    color: rgb(89, 120, 255);
    border-color: rgb(89, 120, 255);
  }
`
export const MinusOutlinedCustom = styled(MinusOutlined)`
  :hover {
    color: rgb(89, 120, 255);
    border-color: rgb(89, 120, 255);
  }
`
export const LeftContentFoodter = styled.div`
  background-color: #f4f4f4;
  position: absolute;
  bottom: 25px;
  width: 100%;
  left: 0px;
`
export const SpaceCustom = styled(Space)`
  .ant-select-selector {
    border-radius: 3px;
  }
`
