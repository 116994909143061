import { clone } from 'lodash'
import axiosClient from './axiosClient'

const registrationApi = {
  getRegistrations: (params, signal) => {
    const clonedParams = clone(params)
    clonedParams.page =
      clonedParams.page === 0 ? clonedParams.page : clonedParams.page - 1
    const url = '/registration'

    return axiosClient.get(url, { params: clonedParams, signal })
  },

  getDetail: (registrationId) => {
    const url = `/registration/${registrationId}`

    return axiosClient.get(url)
  },

  getPrototype: () => {
    const url = '/registration/prototype'

    return axiosClient.get(url)
  },

  createRegistration: (params) => {
    const url = '/registration'

    return axiosClient.post(url, params)
  },

  updateRegistration: (registrationId, params) => {
    const url = `/registration/${registrationId}`

    return axiosClient.put(url, params)
  },

  exportRegistration: (params) => {
    const url = '/registration/export'

    return axiosClient.post(url, params, { responseType: 'blob' })
  },

  exportRegistrationById: (registrationId) => {
    const url = `/registration/export/${registrationId}`

    return axiosClient.get(url, { responseType: 'blob' })
  },

  deleteRegistration: (registrationId) => {
    const url = `/registration/${registrationId}`

    return axiosClient.delete(url)
  },

  hsmSignRegistration: (params) => {
    const url = '/registration/hsm-sign-registration'

    return axiosClient.post(url, params)
  },

  getCertificateDetailHSM: (params) => {
    const url = '/registration/get-certificate-detail-hsm'

    return axiosClient.post(url, params)
  },
}

export default registrationApi
