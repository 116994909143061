import { queryOptions } from '@tanstack/react-query'
import invoiceApi from 'api/invoiceApi'
import ticketApi from 'api/ticketApi'
import _ from 'lodash'

export const listTaxMessageInvoiceFromPosQueries = {
  base: {
    scope: ['taxMessage', 'listTaxMessageInvoiceFromPos'],
  },
  list: (params) =>
    queryOptions({
      queryKey: [
        listTaxMessageInvoiceFromPosQueries.base,
        { entity: 'listInvoice' },
        params,
      ],
      queryFn: ({ signal }) => invoiceApi.getMySummaryInvoice(params, signal),
      select: (response) => {
        console.log(response)
        return {
          total: _.get(response, 'total', 0),
          rows: !response
            ? []
            : _.map(response?.rows, (row) => ({
                invoiceQuantity:
                  params.invoiceType == 'TICKET'
                    ? row.summary_invoice_tickets?.length
                    : row.summary_invoice_invoices?.length,
                dateRelease: row.dateRelease,
                statusRelease: row.statusRelease,
                tax_authority_messages: row.tax_authority_messages,
                summaryDetail:
                  params.invoiceType == 'TICKET'
                    ? row?.summary_invoice_tickets.map(({ ticketId }) => ({
                        ticketId,
                        description: '',
                      }))
                    : row?.summary_invoice_invoices.map((v) => ({
                        invoiceId: v.invoiceId,
                        // serial: v.invoice.serial,
                        // dateInvoice: v.invoice.date,
                        // invoiceNo: v.invoice.no,
                        // taxAuthorityCode: v.invoice.taxAuthorityCode,
                        description: '',
                      })),
              })),
        }
      },
    }),
  detailInvoice: (params) =>
    queryOptions({
      // enabled: false,
      queryKey: [
        listTaxMessageInvoiceFromPosQueries.base,
        { entity: 'detailInvoice' },
        params,
      ],
      queryFn: ({ signal }) =>
        params.invoiceType == 'TICKET'
          ? ticketApi.list(params, signal)
          : invoiceApi.listAll(params, signal),
      select: (response) => ({
        total: _.get(response, 'total', 0),
        rows: _.get(response, 'rows', []),
      }),
    }),
}
