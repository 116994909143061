import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from 'hooks/useRedux'
import { useEffect, useState } from 'react'
import {
  ContainerFunction,
  ContainerSearch,
  ContainerTable,
  DeleteOutlinedIcon,
  DivCustom,
  DivFlex,
  DivFunction,
  DivListFunction,
  DivListItem,
  DivRight,
  FormOutlinedIcon,
  InputCustom,
  SearchCustom,
  SpanNotYetReleasedTd,
  TitleRight,
} from 'features/TaxDeductionDocuments/styled'
import {
  DivColRightTitle,
  DivRowTitle,
  EllipsisDiv,
} from 'features/Taxpayer/styled'
import {
  DirectoryTreeCustom,
  DivCustomCotent,
  DivHardLeft,
  DivHardRight,
  DivItemTree,
  DivTable,
  DivTitleTable,
  EditOutlinedIconCustom,
  SpanTitle,
  TreeCustom,
} from '../styled'
import { Empty, Tree } from 'antd'
import {
  thunkDepartment,
  thunkDepartmentById,
  thunkRemoveDepartmentById,
} from '../departmentSlice'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import { ButtonSave, TableCustom } from 'features/TaxDeduction/styled'
import { convertDate, findObjectInArray } from 'general/utils/Utils'
import SelectOptions from 'general/constants/SelectOptions'
import UpdateDepartment from '../component/Update'
import { thunkGetAccountProfile } from 'app/authSlice'
import AlertTaxDectionDocumentsUpdate from 'features/TaxDeductionDocuments/component/Alert'
import ToastHelper from 'general/helpers/ToastHelper'

function SearchDepartment() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [dataTree, setDataTree] = useState([])
  const [dataSearch, setDataSearch] = useState('')
  const [departmentActive, setDepartmentActive] = useState({})
  const [listDepartment, setListDepartment] = useState([])
  const [fullDepartmentTree, setFullDepartmentTree] = useState([]) // Để lưu trữ cây dữ liệu gốc
  const [selectedKey, setSelectedKey] = useState(null)
  const [visibleAdd, setVisibleAdd] = useState(false)
  const [visibleUpdate, setVisibleUpdate] = useState(false)
  const [maxDepartmentId, setMaxDepartmentId] = useState(0)
  const [auth, setAuth] = useState({})
  const [departmentUpdate, setDepartmentUpdate] = useState({})
  const [visibleRemove, setVisibleRemove] = useState(false)

  const fetchData = async () => {
    const data = await dispatch(thunkGetAccountProfile())
    setAuth(data?.payload?.company)
  }
  const buildDepartmentTree = (departments) => {
    if (departments) {
      // Tạo một bản sao của mảng departments trước khi sắp xếp
      const departmentsCopy = [...departments]

      // Sắp xếp danh sách departmentsCopy theo thứ tự tăng dần của thuộc tính orders,
      // đặt các phần tử có orders là null ở cuối cùng
      departmentsCopy.sort((a, b) => {
        if (a.orders === null && b.orders === null) return 0
        if (a.orders === null) return 1
        if (b.orders === null) return -1
        return a.orders - b.orders
      })

      const departmentMap = {}
      departmentsCopy.forEach((department) => {
        departmentMap[department.departmentId] = {
          title: <DivItemTree>{department.departmentName}</DivItemTree>,
          titleSearch: department.departmentName,
          key: department.departmentId,
          children: [],
          orders: department.orders,
          value: department.departmentId,
        }
      })

      const tree = []
      departmentsCopy.forEach((department) => {
        if (department.filiation === null) {
          // Nếu không có filiation, đây là một phần tử gốc
          tree.push(departmentMap[department.departmentId])
        } else {
          // Nếu có filiation, thêm nó vào danh sách con của phần tử cha
          if (departmentMap[department.filiation]) {
            departmentMap[department.filiation].children.push(
              departmentMap[department.departmentId],
            )
          }
        }
      })

      // Thiết lập isLeaf cho các phần tử không có children
      Object.values(departmentMap).forEach((node) => {
        if (node.children.length === 0) {
          node.isLeaf = true
        } else {
          // Sắp xếp các children của node theo thứ tự tăng dần của thuộc tính orders,
          // đặt các phần tử có orders là null ở cuối cùng
          node.children.sort((a, b) => {
            if (a.orders === null && b.orders === null) return 0
            if (a.orders === null) return 1
            if (b.orders === null) return -1
            return a.orders - b.orders
          })
        }
      })

      return tree
    } else {
      return []
    }
  }

  const getAllDepartment = async () => {
    const data = unwrapResult(
      await dispatch(thunkDepartment({ maxDepartmentId: 1 })),
    )
    const departments = data?.data ?? []
    setListDepartment(departments)
    setMaxDepartmentId(data?.maxDepartmentId ?? 0)
    setFullDepartmentTree(buildDepartmentTree(departments)) // Cập nhật cây dữ liệu gốc
  }

  const columns = [
    {
      title: t('department.namedv'),
      width: 200,
      className: 'center-header',
      // fixed: 'left',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={200}
          title={record?.departmentName}
        >
          {record?.departmentName}
        </EllipsisDiv>
      ),
    },
    {
      title: t('department.codedv'),
      width: 180,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={180}
          title={record?.departmentCode}
        >
          {record?.departmentCode}
        </EllipsisDiv>
      ),
    },
    {
      title: t('department.namet'),
      width: 200,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={200}
          title={record?.abbreviation}
        >
          {record?.abbreviation}
        </EllipsisDiv>
      ),
    },
    {
      title: t('department.levertc'),
      width: 200,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={200}
          title={record?.nameOrganizationallevel}
        >
          {record?.nameOrganizationallevel}
        </EllipsisDiv>
      ),
    },
    // {
    //   title: t('department.tdv'),
    //   width: 200,
    //   className: 'center-header',
    //   render: (text, record) => (
    //     <EllipsisDiv
    //       className="left-cell"
    //       width={200}
    //       title={record?.fieldOfOperation}
    //     >
    //       {record?.fieldOfOperation}
    //     </EllipsisDiv>
    //   ),
    // },
    {
      title: t('taxpayer.address'),
      width: 200,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv className="left-cell" width={200}>
          {record?.address}
        </EllipsisDiv>
      ),
    },
    {
      title: t('department.cnnv'),
      width: 240,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={240}
          title={record?.functionsAndDuties}
        >
          {record?.functionsAndDuties}
        </EllipsisDiv>
      ),
    },
    {
      title: t('department.status'),
      width: 200,
      className: 'center-header',
      render: (text, record) => {
        const status = findObjectInArray(
          SelectOptions.listStatusDepartment,
          'value',
          record.status,
        )
        return (
          <EllipsisDiv
            color={status?.color}
            className="left-cell"
            width={200}
            title={status?.label ? status.label : t('department.notdata')}
          >
            {status?.label ? status.label : t('department.notdata')}
          </EllipsisDiv>
        )
      },
    },

    {
      title: '',
      width: 0,
      className: 'center-header',
      fixed: 'right',
      render: (text, record, index) => {
        let listFunction = [
          {
            key: 'remove',
            title: t('taxDeductionDocuments.remove'),
            icon: (
              <DeleteOutlinedIcon
                display="inherit"
                onClick={() => {
                  setDepartmentUpdate(record)
                  setVisibleRemove(true)
                }}
              />
            ),
          },
          {
            key: 'edit',
            title: t('taxDeductionDocuments.editt'),
            icon: (
              <FormOutlinedIcon
                display="inherit"
                onClick={() => {
                  setDepartmentUpdate(record)
                  setVisibleUpdate(true)
                }}
              />
            ),
          },
        ]
        return (
          <ContainerFunction className="left-cell">
            <DivListFunction className="listFuntionFlex">
              {listFunction.map((item, indexl) => (
                <DivFunction
                  title={item.title}
                  key={indexl}
                  right={`${indexl * 40}px`}
                >
                  {item.icon}
                </DivFunction>
              ))}
            </DivListFunction>
          </ContainerFunction>
        )
      },
    },
  ]

  useEffect(() => {
    getAllDepartment()
    fetchData()
  }, [])

  useEffect(() => {
    setDataTree(searchTreeByTitle(dataSearch))
  }, [dataSearch, listDepartment])

  const searchTreeByTitle = (title) => {
    const searchNode = (node) => {
      const matchesTitle =
        node.titleSearch &&
        node.titleSearch.toLowerCase().includes(title.toLowerCase())
      const children = node.children
        .map((child) => searchNode(child))
        .filter((child) => child !== null)

      if (matchesTitle || children.length > 0) {
        return { ...node, children }
      }
      return null
    }

    const results = fullDepartmentTree
      .map(searchNode)
      .filter((node) => node !== null)

    return results
  }
  const onSelect = (selectedKeys) => {
    if (selectedKeys[0]) setSelectedKey(selectedKeys[0])
  }

  const getDepartmentById = async (departmentId) => {
    setDepartmentActive({})
    try {
      const result = unwrapResult(
        await dispatch(thunkDepartmentById({ departmentId })),
      )
      if (result?.message === 'success') {
        setDepartmentActive(result.data)
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (fullDepartmentTree && fullDepartmentTree.length > 0) {
      setSelectedKey(fullDepartmentTree[0]?.key)
    }
  }, [fullDepartmentTree])

  useEffect(() => {
    if (selectedKey) {
      getDepartmentById(selectedKey)
    } else {
      setDepartmentActive({})
    }
  }, [selectedKey])

  const remove = async () => {
    try {
      const result = unwrapResult(
        await dispatch(
          thunkRemoveDepartmentById({
            departmentId: departmentUpdate.departmentId,
          }),
        ),
      )
      if (result?.message == 'success') {
        setVisibleRemove(false)
        ToastHelper.showSuccess(result?.data)
        getDepartmentById(selectedKey)
        getAllDepartment()
        fetchData()
        // setDepartmentActive(selectedKey)
      } else {
        ToastHelper.showError(result?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <>
      <ContainerSearch>
        <DivRowTitle className="pt-4 row">
          <div className="col-sm-8">
            <TitleRight>{t('department.titleSearch')}</TitleRight>
          </div>
          <DivRight className="col-sm-4">
            <ButtonSave
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => {
                setVisibleAdd(true)
              }}
            >
              {t('department.add')}
            </ButtonSave>
          </DivRight>
        </DivRowTitle>
        <ContainerTable>
          <DivFlex>
            <DivHardLeft>
              <DivCustomCotent className="pb-3">
                <SearchCustom
                  placeholder={t('department.placehodersearch')}
                  style={{ width: 294 }}
                  onChange={(e) => setDataSearch(e.target.value)}
                />
              </DivCustomCotent>
              <DivCustomCotent>
                {dataTree.length > 0 && (
                  <TreeCustom
                    multiple={false}
                    defaultExpandAll
                    showLine
                    treeData={dataTree}
                    onCheck
                    onSelect={onSelect}
                    selectedKeys={[selectedKey]}
                  />
                )}
              </DivCustomCotent>
            </DivHardLeft>
            <DivHardRight>
              <DivCustomCotent className="pb-3">
                <SpanTitle>
                  {departmentActive?.departmentName ?? t('department.notdata')}
                </SpanTitle>
                {departmentActive?.departmentName && (
                  <EditOutlinedIconCustom
                    onClick={() => {
                      setDepartmentUpdate(departmentActive)
                      setVisibleUpdate(true)
                    }}
                  />
                )}
              </DivCustomCotent>
              <DivCustomCotent className="pb-3">
                <DivListItem>
                  <div>
                    <b>{t('department.codedv')}: </b>
                    <span>{departmentActive?.departmentCode}</span>
                  </div>
                  <div>
                    <b>{t('department.levertc')}: </b>
                    <span style={{ width: 140 }}>
                      {departmentActive?.nameOrganizationallevel ??
                        t('department.tct')}
                    </span>
                  </div>
                  <div>
                    <b>{t('department.namet')}: </b>
                    <span>{departmentActive?.abbreviation}</span>
                  </div>
                  <div>
                    <b>{t('taxpayer.address')}: </b>
                    <span>{departmentActive?.address}</span>
                  </div>
                </DivListItem>
              </DivCustomCotent>
              <DivTitleTable>{t('department.titleTable')}</DivTitleTable>
              <DivTable className="pt-4">
                {departmentActive?.children && (
                  <TableCustom
                    dataSource={departmentActive?.children ?? []}
                    columns={columns}
                    scroll={{ x: 1200 }}
                    locale={{
                      emptyText: (
                        <Empty description={t('taxDeduction.noData')} />
                      ),
                    }}
                    marginRight={'0px'}
                    marginLeft={'0px'}
                    pagination={false}
                  />
                )}
              </DivTable>
            </DivHardRight>
          </DivFlex>
        </ContainerTable>
      </ContainerSearch>
      {/* add */}
      <UpdateDepartment
        openModal={visibleAdd}
        changeOpenModal={(value) => {
          setVisibleAdd(value)
        }}
        getAllDepartment={() => {
          getAllDepartment()
        }}
        selectedKey={selectedKey}
        setSelectedKey={(value) => {
          setSelectedKey(value)
        }}
        departmentIdActive={departmentActive?.departmentId}
        // typeActive={departmentActive?.type}
        // departmentActive={departmentActive}
        companyId={auth?.companyId}
        maxId={maxDepartmentId + 1}
        dataTree={dataTree}
      ></UpdateDepartment>
      {/* edit */}
      <UpdateDepartment
        openModal={visibleUpdate}
        changeOpenModal={(value) => {
          setVisibleUpdate(value)
        }}
        departmentActive={departmentUpdate}
        getAllDepartment={() => {
          getAllDepartment()
        }}
        selectedKey={selectedKey}
        setSelectedKey={(value) => {
          setSelectedKey(value)
        }}
        dataTree={dataTree}
        departmentIdActive={departmentActive?.departmentId}
        typeActive={departmentActive?.type}
      ></UpdateDepartment>
      {/* remove */}
      <AlertTaxDectionDocumentsUpdate
        titleCancel={t('taxDeductionDocuments.no')}
        titlePrimary={t('taxDeductionDocuments.remove')}
        openModalAlert={visibleRemove}
        titleAlert={t('department.removedepartment')}
        // messageAlert={t('taxpayer.messagennt', dataActive)}
        setOpenModalAlert={(value) => {
          setVisibleRemove(value)
        }}
        onClickPrimary={() => {
          remove()
        }}
        colorButtonSave={'#ffffff'}
        backGroundButtonSave={'#e61d1d'}
        backGroundButtonSaveHover={'#cc0202!important'}
        messageAlert={t('department.messageRemove')}
      />
    </>
  )
}

export default SearchDepartment
