import { useTranslation } from 'react-i18next'
import { Result, Button, Empty, Space, Form, Table } from 'antd'
import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
  SmileOutlined,
  UndoOutlined,
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import {
  ButtonSave,
  DivColFooter,
  DivFooter,
  DivRowFooter,
  ModalCustom,
  StyleButtonClose,
  TableCustom,
} from 'features/TaxDeduction/styled'
import { ContainerContentModal } from 'features/Department/styled'
import {
  ContainerFunction,
  DeleteOutlinedIcon,
  DivFunction,
  DivListFunction,
  DivRight,
  FormOutlinedIcon,
  InputCustom,
  SpanNotYetReleasedTd,
  UndoOutlinedIcon,
} from 'features/TaxDeductionDocuments/styled'
import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from 'hooks/useRedux'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  thunkDeleteTaxpayers,
  thunkFindTaxpayer,
  thunkRestoreTaxpayers,
} from 'features/Taxpayer/taxpayerSlice'
import { debounce } from 'lodash'
import { EllipsisDiv } from 'features/Taxpayer/styled'
import PopupSaveTaxpayer from '../PopupSave'
import { convertDate, findObjectInArray } from 'general/utils/Utils'
import SelectOptions from 'general/constants/SelectOptions'
import ToastHelper from 'general/helpers/ToastHelper'
function PopupTranshCan({ visible, setVisible, taxpayerSelect, reload }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [dataSource, setDataSource] = useState([])
  const [panningDataSource, setPanningDataSource] = useState({
    page: 1,
    limit: 20,
    totalItems: 0,
    totalPages: 0,
    key: '',
    status: 0,
  })

  const findDataSource = async () => {
    try {
      const data = unwrapResult(
        await dispatch(thunkFindTaxpayer(panningDataSource)),
      )
      const dattaSource = data.items.map((item) => ({
        ...item,
        key: item.taxpayersId, // Thêm trường key theo id
      }))
      setDataSource(dattaSource)
      setPanningDataSource({
        ...panningDataSource,
        totalItems: data.totalItems,
        totalPages: data.totalPages,
      })
    } catch (error) {}
  }

  const deleteTaxpayers = async (data) => {
    try {
      const result = unwrapResult(
        await dispatch(thunkDeleteTaxpayers({ taxpayersIds: data.dataids })),
      )
      if (result?.message == 'success') {
        ToastHelper.showSuccess(`${result.data}`)
        if (data.type) {
          setVisible(false)
          reload()
        } else {
          findDataSource()
        }
      } else {
        ToastHelper.showError(`${result.data}`)
      }
    } catch (error) {}
  }

  const restoreTaxpayers = async (data) => {
    try {
      const result = unwrapResult(
        await dispatch(thunkRestoreTaxpayers({ taxpayersIds: data.dataids })),
      )
      if (result?.message == 'success') {
        ToastHelper.showSuccess(`${result.data}`)
        if (data.type) {
          setVisible(false)
        } else {
          findDataSource()
        }
        reload()
      } else {
        ToastHelper.showError(`${result.data}`)
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (visible) findDataSource()
  }, [
    panningDataSource.page,
    panningDataSource.limit,
    panningDataSource.key,
    visible,
  ])

  useEffect(() => {
    if (visible) setSelectedRowKeys([])
  }, [visible])

  const debouncedSetFormState = useCallback(
    debounce((keyValue, value) => {
      // Gọi API tại đây với keyValue và value
      setPanningDataSource((prevPanningDataSource) => ({
        ...prevPanningDataSource,
        [keyValue]: value,
      }))
    }, 300), // Thời gian chờ sau khi kết thúc nhập liệu, ví dụ: 500ms
    [], // Empty dependency array means this callback will only be created once
  )
  let timeoutId = null // Biến lưu trữ timeout ID
  const handleChange = (key, value) => {
    // Xóa timeout hiện tại để tránh gọi API nếu người dùng vẫn đang nhập
    clearTimeout(timeoutId)

    // Thiết lập timeout mới với debounce
    timeoutId = setTimeout(() => {
      debouncedSetFormState(key, value)
    }, 500) // Đợi 500ms sau khi kết thúc nhập liệu
  }

  const columns = [
    {
      title: t('taxpayer.employeeName'),
      width: 150,
      className: 'center-header',
      // fixed: 'left',
      render: (text, record) => (
        <div className="left-cell">{record?.employeeCode}</div>
      ),
    },
    {
      title: t('taxpayer.taxpaperName'),
      width: 180,
      className: 'center-header',
      render: (text, record) => (
        <div className="left-cell">{record?.taxpayersName}</div>
      ),
    },
    {
      title: t('taxpayer.employeeBirthDate'),
      width: 110,
      className: 'center-header',
      render: (text, record) => (
        <div className="left-cell">
          {record.employeeBirthDate
            ? convertDate(record.employeeBirthDate, 'DD/MM/YYYY')
            : ''}
        </div>
      ),
    },
    {
      title: t('taxpayer.gender'),
      width: 100,
      className: 'center-header',
      render: (text, record) => (
        <div className="left-cell">{record.gender}</div>
      ),
    },
    {
      title: t('taxpayer.departmentName'),
      width: 180,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={180}
          title={record?.department?.departmentName}
        >
          {record?.department?.departmentName}
        </EllipsisDiv>
      ),
    },
    {
      title: t('taxpayer.job'),
      width: 200,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={200}
          title={`${record?.jobPosition?.jobPositionName ?? ''} ${record?.jobPosition?.jobPositionName && record?.jobTitle?.jobTitleName ? '/' : ''} ${record?.jobTitle?.jobTitleName ?? ''}`}
        >{`${record?.jobPosition?.jobPositionName ?? ''} ${record?.jobPosition?.jobPositionName && record?.jobTitle?.jobTitleName ? '/' : ''} ${record?.jobTitle?.jobTitleName ?? ''}`}</EllipsisDiv>
      ),
    },
    {
      title: t('taxpayer.contractType'),
      width: 240,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={240}
          title={record?.contractType}
        >
          {record?.contractType}
        </EllipsisDiv>
      ),
    },
    {
      title: t('taxpayer.taxCode'),
      width: 130,
      className: 'center-header',
      render: (text, record) => (
        <EllipsisDiv
          className="left-cell"
          width={130}
          title={record?.employeeTaxCode}
        >
          {record?.employeeTaxCode}
        </EllipsisDiv>
      ),
    },
    {
      title: t('taxpayer.internshipStartDate'),
      width: 150,
      className: 'center-header',
      render: (text, record) => (
        <div className="left-cell">
          {record.internshipStartDate
            ? convertDate(record.internshipStartDate, 'DD/MM/YYYY')
            : ''}
        </div>
      ),
    },
    {
      title: t('taxpayer.probationStartDate'),
      width: 150,
      className: 'center-header',
      render: (text, record) => (
        <div className="left-cell">
          {record.probationStartDate
            ? convertDate(record.probationStartDate, 'DD/MM/YYYY')
            : ''}
        </div>
      ),
    },
    {
      title: t('taxpayer.phone'),
      width: 150,
      className: 'center-header',
      render: (text, record) => (
        <div className="left-cell">{record?.employeePhone}</div>
      ),
    },
    {
      title: t('taxpayer.email'),
      width: 200,
      className: 'center-header',
      render: (text, record) => (
        <div className="left-cell">
          <EllipsisDiv className="left-cell" width={200}>
            {record?.employeeEmail}
          </EllipsisDiv>
        </div>
      ),
    },
    {
      title: t('taxpayer.identification'),
      width: 200,
      className: 'center-header',
      render: (text, record) => (
        <div className="left-cell">{record?.employeeIdentification}</div>
      ),
    },
    {
      title: t('taxpayer.workingStatus'),
      width: 150,
      className: 'center-header',
      render: (text, record) => {
        const workingStatus = findObjectInArray(
          SelectOptions.listWorkingStatus,
          'value',
          record.workingStatus,
        )
        return (
          <div className="left-cell">
            <SpanNotYetReleasedTd
              color={workingStatus?.color}
              backGroundColor={workingStatus?.backGroundColor}
            >
              {workingStatus?.value}
            </SpanNotYetReleasedTd>
          </div>
        )
      },
    },
    {
      title: '',
      width: 0,
      className: 'center-header',
      fixed: 'right',
      render: (text, record, index) => {
        let listFunction = [
          {
            key: 'remove',
            title: t('taxDeductionDocuments.remove'),
            icon: (
              <DeleteOutlinedIcon
                display="inherit"
                onClick={() => {
                  deleteTaxpayers({ dataids: [record.taxpayersId] })
                }}
              />
            ),
          },
          {
            key: 'restore',
            title: t('taxpayer.kp'),
            icon: (
              <UndoOutlinedIcon
                display="inherit"
                onClick={() => {
                  restoreTaxpayers({ dataids: [record.taxpayersId] })
                }}
              />
            ),
          },
        ]
        // if (record.status === 1) {
        //   listFunction = removeItemByKeys(
        //     [...listFunction],
        //     ['edit', 'remove', 'release'],
        //   )
        // }
        return (
          <ContainerFunction className="left-cell">
            <DivListFunction className="listFuntionFlex">
              {listFunction.map((item, indexl) => (
                <DivFunction
                  title={item.title}
                  key={indexl}
                  right={`${indexl * 40}px`}
                >
                  {item.icon}
                </DivFunction>
              ))}
            </DivListFunction>
          </ContainerFunction>
        )
      },
    },
  ]

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  return (
    <>
      <ModalCustom
        title={t('taxpayer.trash')}
        open={visible}
        onCancel={() => {
          setVisible(false)
        }}
        width={1400}
        footer={
          <DivFooter>
            <DivRowFooter className="row">
              <DivColFooter className="col-6"></DivColFooter>
              <DivColFooter className="col-6" justifyContent={'right'}>
                <StyleButtonClose
                  onClick={() => {
                    setVisible(false)
                  }}
                >
                  {t('taxDeductionDocuments.cancelt')}
                </StyleButtonClose>
                <ButtonSave
                  style={{ marginRight: 5 }}
                  disabled={!selectedRowKeys.length}
                  type="primary"
                  icon={<UndoOutlined />}
                  onClick={() => {
                    restoreTaxpayers({ type: 1, dataids: selectedRowKeys })
                  }}
                >
                  {t('taxpayer.kp')}
                </ButtonSave>
                <ButtonSave
                  type="primary"
                  backGroundButtonSave={'red'}
                  backGroundButtonSaveHover={'#d47171 !important'}
                  disabled={!selectedRowKeys.length}
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    deleteTaxpayers({ type: 1, dataids: selectedRowKeys })
                  }}
                >
                  {t('taxpayer.rm')}
                </ButtonSave>
              </DivColFooter>
            </DivRowFooter>
          </DivFooter>
        }
      >
        <ContainerContentModal padding="20px 20px 0px 20px">
          <div className=" pb-5 row">
            <div className="col-sm-6">
              <Space.Compact>
                <InputCustom
                  onChange={(e) => {
                    handleChange('key', e.target.value)
                  }}
                  minWidth="300px"
                  addonBefore={
                    <SearchOutlined
                      onClick={() => {
                        findDataSource()
                      }}
                    />
                  }
                  placeholder={t('taxpayer.keySearch')}
                  allowClear
                />
              </Space.Compact>
            </div>
            <DivRight className="col-sm-6"></DivRight>
          </div>
          <TableCustom
            rowSelection={rowSelection}
            rowClassName={(record) =>
              taxpayerSelect &&
              record.taxpayersId === taxpayerSelect.taxpayersId
                ? 'active-row'
                : ''
            }
            dataSource={dataSource}
            columns={columns}
            locale={{
              emptyText: <Empty description={t('taxDeduction.noData')} />,
            }}
            marginRight={'0px'}
            marginLeft={'0px'}
            scroll={{ x: 1400 }}
            pagination={
              panningDataSource.totalPages > 1
                ? {
                    current: panningDataSource.page,
                    pageSize: panningDataSource.limit,
                    total: panningDataSource.totalItems,
                  }
                : false
            }
            onChange={(pagination) => {
              setPanningDataSource({
                ...panningDataSource,
                page: pagination.current,
                limit: pagination.pageSize,
              })
            }}
          />
        </ContainerContentModal>
      </ModalCustom>
    </>
  )
}
export default PopupTranshCan
