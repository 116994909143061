import CustomSelect from 'general/components/CustomSelect'
import PropTypes from 'prop-types'
import ViewImportFile from 'features/Category/components/ViewImportFile'
import ImportTicketChooseSheetAndLine from '../ImportTicketChooseSheetAndLine'
import { useAppSelector } from 'hooks/useRedux'
import { useEffect } from 'react'
import {
  ALPHABET_CHARACTERS,
  TICKET_UPLOAD_DATA_SAMPLES,
  TICKET_UPLOAD_GUIDES,
  TICKET_UPLOAD_HEADER_COLUMNS,
} from 'general/constants/AppConstants'
import ExcelJs from 'exceljs'
import './styles.scss'
import {
  setExcelData,
  setSelectedFile,
  setSelectedSerial,
  setSelectedSheetName,
  setSheetNamesOption,
} from 'features/Ticket/screens/ImportTicket/importTicketSlice'
import { useDispatch } from 'react-redux'
import * as XLSX from 'xlsx'
import _, { set } from 'lodash'
import {
  filterColumnsBaseOnTicketTemplate,
  getRefObjFromHeaderBaseOnMappingKeyword,
} from 'general/helpers/ImportTicketHelper'
import dayjs from 'dayjs'

ImportTicketStepOne.propTypes = {
  tableData: PropTypes.array,
  setTableData: PropTypes.func,
}

ImportTicketStepOne.defaultProps = {}

function ImportTicketStepOne(props) {
  const { ticketSerials } = props
  const dispatch = useDispatch()

  const { selectedFile, selectedSerial, headerIndex, sheetNamesOption } =
    useAppSelector((state) => state.importTicket)

  const handleChangeFile = async (file) => {
    dispatch(setSelectedFile(file))
    parseExcel(file)
    await parseExcelJs(file)
  }

  const parseExcel = async (file) => {
    if (!file) return
    const reader = new FileReader()

    reader.onload = (event) => {
      const binaryStr = event.target.result
      const workBook = XLSX.read(binaryStr, { type: 'binary' })
      const { SheetNames } = workBook
      // const worksheetName = workBook.SheetNames[0]
      // const worksheet = workBook.Sheets[worksheetName]
      // const jsonData = XLSX.utils.sheet_to_json(worksheet)
      const sheetNamesArray = _.map(SheetNames, (sheet) => {
        return { label: sheet, value: sheet }
      })
      dispatch(setSheetNamesOption(sheetNamesArray))
      dispatch(setSelectedSheetName(sheetNamesArray[0].value))
    }

    reader.readAsBinaryString(file)
  }

  const parseExcelJs = async (file) => {
    const workbook = new ExcelJs.Workbook()
    const reader = new FileReader()

    reader.readAsArrayBuffer(file)
    reader.onload = async () => {
      const buffer = reader.result

      try {
        await workbook.xlsx.load(buffer)
        const sheet = workbook.getWorksheet('Vé')

        const excel_headers = sheet
          .getRow(headerIndex)
          .values.slice(1)
          .map((item, index) => ({
            text: item,
            textWithColName: ALPHABET_CHARACTERS[index] + ' - ' + item,
            key: getRefObjFromHeaderBaseOnMappingKeyword(item)?.key,
          }))

        const excel_rows_data = []
        for (let i = headerIndex + 1; i < sheet.lastRow.number + 1; i++) {
          let row = sheet
            .getRow(i)
            .values.slice(1)
            .map((item, itemIndex) => {
              let textValue

              if (item && typeof item === 'object') {
                if (item instanceof Date) {
                  textValue = dayjs(item).format('DD/MM/YYYY')
                } else {
                  if (item.result instanceof Date) {
                    textValue = dayjs(item.result).format('DD/MM/YYYY')
                  } else {
                    textValue = item.result || '' // Trả về giá trị
                  }
                }
              } else {
                textValue = item // Trả về giá trị bình thường
              }
              return {
                text: textValue,
                key: excel_headers[itemIndex].key,
              }
            })
            .reduce(
              (result, item) => ({ ...result, [item.key]: item.text }),
              {},
            )

          row.rowNum = i
          excel_rows_data.push(row)
        }

        dispatch(
          setExcelData({ headers: excel_headers, rows: excel_rows_data }),
        )
      } catch (e) {
        console.log(e)
      }
    }
  }

  const handleDownloadSampleExcel = async () => {
    let serial = ticketSerials.find(({ value }) => value == selectedSerial)
    if (!serial) {
      alert('Lỗi khi tìm kiếm serial')
      return
    }

    const fileName = serial.name + '.xlsx'
    let sheetName = 'Vé'

    const wb = new ExcelJs.Workbook()
    const ws = wb.addWorksheet(sheetName)

    const commonStyle = {
      name: 'Times New Roman',
      family: 4,
      size: 13,
    }

    const commonBorder = {
      top: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } },
    }

    /* gen excel columns */
    let excelColumns = TICKET_UPLOAD_HEADER_COLUMNS.filter(
      ({ onlyWeb }) => onlyWeb !== true,
    )
    excelColumns = filterColumnsBaseOnTicketTemplate({
      baseColumns: excelColumns,
      serial,
    })

    let columnsLength = excelColumns.length

    // uploadGuides
    ws.mergeCells(`A1:${ALPHABET_CHARACTERS[columnsLength - 1]}1`)
    ws.getCell('A1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '99CCFF' },
    }
    ws.getCell('A1').value = TICKET_UPLOAD_GUIDES.title
    ws.getCell('A1').font = {
      ...commonStyle,
      size: 16,
      bold: true,
    }

    ws.mergeCells(`A2:${ALPHABET_CHARACTERS[columnsLength - 1]}2`)
    ws.getCell('A2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCC99' },
    }
    ws.getCell('A2').value = TICKET_UPLOAD_GUIDES.subtitle
    ws.getCell('A2').font = {
      ...commonStyle,
      color: { argb: 'FF0000' },
    }

    let startIndex = 3
    TICKET_UPLOAD_GUIDES.guides.forEach((guide) => {
      ws.mergeCells(
        `A${startIndex}:${ALPHABET_CHARACTERS[columnsLength - 1]}${startIndex}`,
      )
      ws.getCell(`A${startIndex}`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFCC99' },
      }
      ws.getCell(`A${startIndex}`).value = guide
      ws.getCell(`A${startIndex}`).font = {
        ...commonStyle,
      }
      startIndex++
    })

    // sampleData
    /* header */
    startIndex = 8
    ws.getRow(startIndex).font = {
      ...commonStyle,
      bold: true,
    }
    ws.getRow(startIndex).values = excelColumns.map(({ header }) => header)
    excelColumns.forEach((_, index) => {
      let cellName = `${ALPHABET_CHARACTERS[index]}${startIndex}}`
      ws.getCell(cellName).border = commonBorder
      ws.getCell(cellName).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'CCCCFF' },
      }
      ws.getCell(cellName).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
    })
    ws.columns = excelColumns.map(({ key, width }) => {
      return { key, width }
    })

    /* data */
    startIndex = 9
    ws.addRows(TICKET_UPLOAD_DATA_SAMPLES)
    ws.eachRow((row, rowNumber) => {
      if (rowNumber < startIndex) return

      row.eachCell((cell) => {
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center',
          wrapText: true,
        }
        cell.border = commonBorder
        cell.font = commonStyle
      })
    })

    await wb.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = fileName
      anchor.click()
      window.URL.revokeObjectURL(url)
    })
  }

  useEffect(() => {
    if (!ticketSerials?.length) return

    dispatch(setSelectedSerial(ticketSerials[0].value))
  }, [ticketSerials])

  return (
    <div
      className="importTicketStepOne"
      style={{ marginLeft: '18%', marginRight: '18%' }}
    >
      <span className="" style={{ fontSize: '14px', fontWeight: 700 }}>
        Chọn mẫu vé muốn nhập khẩu
      </span>

      <CustomSelect
        autoSize
        width="max-content"
        selectOptions={ticketSerials || []}
        currentValue={selectedSerial}
        onChange={(v) => dispatch(setSelectedSerial(v.value))}
      />

      <ViewImportFile
        _file={selectedFile}
        showFooterButton={false}
        handleChangeFile={handleChangeFile}
        onDownloadSampleFile={handleDownloadSampleExcel}
      />

      {selectedFile && (
        <ImportTicketChooseSheetAndLine sheetNamesOption={sheetNamesOption} />
      )}
    </div>
  )
}

export default ImportTicketStepOne
