import { Space } from 'antd'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import useFilter from 'hooks/useFilter'
import { useAppDispatch } from 'hooks/useRedux'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _ from 'lodash'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useInvalidateQuery, useMutationExportExcel } from '../queries'
import { transactionActions, useTransactionSelector } from '../slice'
import '../styles.scss'
import CustomFilter from './CustomFilter'

export default function TransactionHeader({ defaultColumns }) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { debouncedSearch, search, setSearch } = useSearchDebounce()
  const [filterParams, setFilterParams] = useFilter(
    'listTransactions',
    'gFilterListTransactions',
  )
  const { invalidate } = useInvalidateQuery()
  const { mutate: handleExport, isPending: isExportingExcel } =
    useMutationExportExcel(defaultColumns)
  const { selectedRows } = useTransactionSelector()

  function handleCombineExport() {
    dispatch(
      transactionActions.toggleModalState({
        name: 'combineExportInvoice',
        status: true,
      }),
    )
  }
  function handleMultipleExport() {
    dispatch(
      transactionActions.toggleModalState({
        name: 'multipleExportInvoice',
        status: true,
      }),
    )
  }
  // ============ EFFECTS ============

  useEffect(() => {
    if (filterParams.page)
      setFilterParams({
        q: debouncedSearch,
      })
  }, [debouncedSearch, filterParams.page, setFilterParams])

  return (
    <ContentContainer.Header
      titleContent={t('listTransaction.name')}
      toolBar={
        <Space>
          {selectedRows.length > 0 && (
            <Space>
              <CustomAntButton
                iconCn="far fa-file-invoice"
                text={t('listTransaction.singleExport', {
                  quantity: selectedRows.length,
                })}
                antProps={{
                  type: 'default',

                  onClick: () => {
                    handleMultipleExport()
                  },
                }}
              />

              <CustomAntButton
                iconCn="far fa-files"
                text={t('listTransaction.mergedExport', {
                  quantity: selectedRows.length,
                })}
                antProps={{
                  type: 'default',

                  onClick: () => {
                    handleCombineExport()
                  },
                }}
              />
            </Space>
          )}

          <CustomAntButton
            iconCn="fas fa-file-arrow-down"
            text={t('listTransaction.exportToExcel')}
            isLoading={isExportingExcel}
            antProps={{
              type: 'primary',
              className: 'btn btn-success',
              onClick: () => {
                handleExport(_.omit(filterParams, ['page', 'limit']))
              },
            }}
          />
          <CustomAntButton
            iconCn="far fa-rotate"
            text="Làm mới dữ liệu"
            customStyle={{
              minWidth: 'auto',
            }}
            antProps={{
              type: 'default',
              onClick: () => {
                invalidate()
              },
            }}
          />
          <AppDnD
            defaultColumns={defaultColumns}
            localStorageName="gFilterListTransactions"
          />
        </Space>
      }
    >
      <Space wrap className="max-w-100" id="filter-content-wrapper">
        <CustomAntInput
          type="search"
          borderRadius="sm"
          width={150}
          inputProps={{
            value: search,
            onChange: (e) => setSearch(e.target.value),
          }}
        />
        <CustomFilter />
      </Space>
    </ContentContainer.Header>
  )
}
