import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { FormLabel, Modal } from 'react-bootstrap'
import invoiceApi from 'api/invoiceApi'
import { setAppSpinning } from 'app/appSlice'
import CustomSelect from 'general/components/CustomSelect'
import Option from 'general/components/CustomSelect/CustomOption'
import MenuList from 'general/components/CustomSelect/MenuList'
import ToastHelper from 'general/helpers/ToastHelper'
import { useAppSelector } from 'hooks/useRedux'
import { useDispatch } from 'react-redux'
import './style.scss'
import ExcelJs from 'exceljs'
import {
  ALPHABET_CHARACTERS,
  INVOICE_UPLOAD_DATA_MULTIPLE_TAX_RATE_SAMPLES,
  INVOICE_UPLOAD_DATA_SINGLE_TAX_RATE_SAMPLES,
  INVOICE_UPLOAD_GUIDES,
  INVOICE_UPLOAD_HEADER_COLUMNS,
} from 'general/constants/AppConstants'
import { filterColumnsBaseOnInvoiceTemplate } from 'general/helpers/ImportInvoiceHelper'
import useRouter from 'hooks/useRouter'
import useGetSerials from 'hooks/Queries/useGetSerials'
import { useMap } from '@uidotdev/usehooks'

ModalUploadInvoiceExcel.prototype = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  content: PropTypes.string,
  handleConfirm: PropTypes.func,
}

function ModalUploadInvoiceExcel(props) {
  const dispatch = useDispatch()
  const { pathname } = useRouter()

  const { show, setShow } = props

  const { data } = useGetSerials(
    pathname.includes('may-tinh-tien') ? 'POS' : undefined,
  )
  const templateInvoiceSerials = useMemo(
    () => data?.filter(({ value }) => value) || [],
    [data],
  )

  const [selectedInvoiceTemplate, setSelectedInvoiceTemplate] = useState()
  const [selectedFile, setSelectedFile] = useState()
  const [resultText, setResultText] = useState('')

  useEffect(() => {
    if (!templateInvoiceSerials?.length) return

    let firstInvoiceTemplate = templateInvoiceSerials.at(0)
    setSelectedInvoiceTemplate({
      invoiceTemplateId: firstInvoiceTemplate.invoiceTemplateId,
      label: firstInvoiceTemplate.serial,
      value: firstInvoiceTemplate.invoiceTemplateId,
      name: firstInvoiceTemplate.name,
      category: firstInvoiceTemplate.category,
    })
  }, [templateInvoiceSerials])

  const handleClose = () => {
    setShow(false)
    setResultText('')
    setSelectedInvoiceTemplate()
    setSelectedFile()
  }

  const handleSelectInvoiceSerial = (invoiceTemplate) => {
    setSelectedInvoiceTemplate(invoiceTemplate)
  }

  const handleUploadExcel = async () => {
    if (!selectedFile) {
      ToastHelper.showError('Chưa chọn file excel')
      return
    }

    if (!selectedInvoiceTemplate) {
      ToastHelper.showError('Chưa chọn mẫu hóa đơn')
      return
    }

    let params = {
      invoiceTemplateId: selectedInvoiceTemplate.invoiceTemplateId,
      file: selectedFile,
    }

    dispatch(setAppSpinning(true))

    try {
      let res = await invoiceApi.upload(params)
      dispatch(setAppSpinning(false))
      let { successInvoiceSequences, failedInvoiceSequences } = res

      successInvoiceSequences = successInvoiceSequences.map((item) => ({
        ...item,
        success: 'Y',
      }))

      failedInvoiceSequences = failedInvoiceSequences.map((item) => ({
        ...item,
        success: 'N',
      }))

      let combineResult = successInvoiceSequences
        .concat(failedInvoiceSequences)
        .sort((a, b) => a.invoiceSequence - b.invoiceSequence)
        .map((item) => {
          let { index, ...rest } = item
          return rest
        })

      ToastHelper.showSuccess('Upload excel hóa đơn thành công')
      setResultText(
        combineResult.map((item) => JSON.stringify(item)).join(',<br/>'),
      )
    } catch (e) {
      console.log(e)

      setResultText('')
      dispatch(setAppSpinning(false))
    } finally {
      if (typeof props?.refetchListInvoice == 'function') {
        props.refetchListInvoice()
      }
    }
  }

  const handleDownloadSampleExcel = async () => {
    if (!selectedInvoiceTemplate) {
      alert('Lỗi khi tìm kiếm serial')
      return
    }

    const fileName = selectedInvoiceTemplate.name + '.xlsx'
    const commonStyle = {
      name: 'Times New Roman',
      family: 4,
      size: 13,
    }
    const commonBorder = {
      top: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } },
    }

    const wb = new ExcelJs.Workbook()
    function addWorkSheet({ invoiceTemplate, sheetName = 'Hóa đơn' }) {
      const ws = wb.addWorksheet(sheetName)

      /* gen excel columns */
      let excelColumns = INVOICE_UPLOAD_HEADER_COLUMNS.filter(
        ({ onlyWeb }) => onlyWeb !== true,
      )
      excelColumns = filterColumnsBaseOnInvoiceTemplate({
        baseColumns: excelColumns,
        serial: invoiceTemplate,
      })

      let columnsLength = excelColumns.length

      // uploadGuides
      ws.mergeCells(`A1:${ALPHABET_CHARACTERS[columnsLength - 1]}1`)
      ws.getCell('A1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '99CCFF' },
      }
      ws.getCell('A1').value = INVOICE_UPLOAD_GUIDES.title
      ws.getCell('A1').font = {
        ...commonStyle,
        size: 16,
        bold: true,
      }

      ws.mergeCells(`A2:${ALPHABET_CHARACTERS[columnsLength - 1]}2`)
      ws.getCell('A2').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFCC99' },
      }
      ws.getCell('A2').value = INVOICE_UPLOAD_GUIDES.subtitle
      ws.getCell('A2').font = {
        ...commonStyle,
        color: { argb: 'FF0000' },
      }

      let startIndex = 3
      INVOICE_UPLOAD_GUIDES.guides.forEach((guide) => {
        ws.mergeCells(
          `A${startIndex}:${
            ALPHABET_CHARACTERS[columnsLength - 1]
          }${startIndex}`,
        )
        ws.getCell(`A${startIndex}`).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFCC99' },
        }
        ws.getCell(`A${startIndex}`).value = guide
        ws.getCell(`A${startIndex}`).font = {
          ...commonStyle,
        }
        startIndex++
      })

      // sampleData
      /* header */
      startIndex = 12
      ws.getRow(startIndex).font = {
        ...commonStyle,
        bold: true,
      }
      ws.getRow(startIndex).values = excelColumns.map(({ header }) => header)

      let startProductIndex = ws
        .getRow(startIndex)
        .values.findIndex((item) => item == 'Tên hàng hóa/dịch vụ (*)')

      excelColumns.forEach((_, index) => {
        let cellName = `${ALPHABET_CHARACTERS[index]}${startIndex}}`
        ws.getCell(cellName).border = commonBorder
        ws.getCell(cellName).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: index < startProductIndex - 1 ? 'CCCCFF' : 'FFFF00',
          },
        }
        ws.getCell(cellName).alignment = {
          vertical: 'middle',
          horizontal: 'center',
          wrapText: true,
        }
      })
      ws.columns = excelColumns.map(({ key, width }) => {
        return { key, width }
      })

      let INVOICE_UPLOAD_DATA_SAMPLES =
        invoiceTemplate.category.taxType == 1
          ? INVOICE_UPLOAD_DATA_SINGLE_TAX_RATE_SAMPLES()
          : INVOICE_UPLOAD_DATA_MULTIPLE_TAX_RATE_SAMPLES()
      /* data */
      startIndex = 13
      ws.addRows(INVOICE_UPLOAD_DATA_SAMPLES)
      ws.eachRow((row, rowNumber) => {
        if (rowNumber < startIndex) return

        row.eachCell((cell) => {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          }
          cell.border = commonBorder
          cell.font = commonStyle
        })
      })
    }

    if (selectedInvoiceTemplate.category.taxType == 3) {
      addWorkSheet({
        invoiceTemplate: {
          ...selectedInvoiceTemplate,
          category: { ...selectedInvoiceTemplate.category, taxType: 1 },
        },
        sheetName: 'HĐ một thuế suất',
      })
      addWorkSheet({
        invoiceTemplate: {
          ...selectedInvoiceTemplate,
          category: { ...selectedInvoiceTemplate.category, taxType: 2 },
        },
        sheetName: 'HĐ nhiều thuế suất',
      })
    } else {
      addWorkSheet({ invoiceTemplate: selectedInvoiceTemplate })
    }

    await wb.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = fileName
      anchor.click()
      window.URL.revokeObjectURL(url)
    })
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="ModalUploadInvoiceExcel"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span>Upload excel hóa đơn</span>
          <br />
          {/* <span
            className="text-danger fst-italic"
            style={{ fontSize: '0.9rem' }}
          >
            <i className="fa-regular fa-circle-exclamation text-danger mr-1"></i>
            Chú ý: Mẫu hóa đơn gộp thuế xuất chưa khả dụng với chức năng này
          </span> */}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div /* className="create-invoice-right-col" */>
          <div className="d-flex flex-row align-items-center w-100 mb-5">
            <FormLabel style={{ flexBasis: '80px' }} className="mb-1">
              Ký hiệu:
            </FormLabel>

            <CustomSelect
              classNamePrefix="rs-kh"
              placeholder={'Chọn ký hiệu'}
              currentValue={selectedInvoiceTemplate?.value}
              autoSize
              selectOptions={
                templateInvoiceSerials?.map((item) => ({
                  invoiceTemplateId: item.invoiceTemplateId,
                  label: item.serial,
                  value: item.invoiceTemplateId,
                  name: item.name,
                  category: item.category,
                })) ?? []
              }
              customFilterFn={(option, searchText) => {
                return (
                  option.label
                    ?.toLowerCase()
                    ?.includes(searchText.toLowerCase()) ||
                  option.data.name
                    ?.toLowerCase()
                    ?.includes(searchText.toLowerCase())
                )
              }}
              onChange={(selectedOption) =>
                handleSelectInvoiceSerial(selectedOption)
              }
              components={{
                MenuList,

                Option,
              }}
              menuParentRef={document.querySelector('.ModalUploadInvoiceExcel')}
            />
          </div>

          <div className="d-flex flex-row align-items-center w-100 mb-5">
            <FormLabel style={{ flexBasis: '80px' }} className="mb-1">
              Chọn file:
            </FormLabel>

            <input
              className="form-control flex-fill"
              type="file"
              style={{ width: 'auto' }}
              onChange={(e) => {
                setSelectedFile(e?.target?.files[0])
              }}
            ></input>
          </div>

          {resultText && <UploadInvoiceNotifi text={resultText} />}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="">
          <button
            className="btn btn-outline-warning mr-4"
            onClick={handleDownloadSampleExcel}
            disabled={!selectedInvoiceTemplate}
          >
            Tải file excel mẫu
          </button>

          <button
            className="btn btn-success px-8 mr-4"
            onClick={handleUploadExcel}
            disabled={!selectedInvoiceTemplate || !selectedFile}
          >
            Tải lên
          </button>

          <button
            className="btn btn-outline-secondary px-8"
            onClick={handleClose}
          >
            Đóng
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

function UploadInvoiceNotifi({ text }) {
  return (
    <div className="uin-container mt-5">
      <div className="uin-wrapper d-flex flex-row align-items-center">
        <i
          className="fa-sharp fa-2x fa-light fa-circle-info mr-2"
          style={{ color: '#044868' }}
        ></i>

        <span
          className="uin-text"
          dangerouslySetInnerHTML={{ __html: text }}
        ></span>
      </div>
    </div>
  )
}

export default ModalUploadInvoiceExcel
