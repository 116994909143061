import { Space } from 'antd'
import customDataTableStyle from 'assets/styles/customDataTableStyle'
import reportHelper from 'features/Report/reportHelper'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import SelectOptions from 'general/constants/SelectOptions'
import Utils, {
  cutOrRoundNumber,
  processInvoice,
  processTaxMoney,
} from 'general/utils/Utils'
import useGetSerials from 'hooks/Queries/useGetSerials'
import useAppDnD from 'hooks/useAppDnD'
import useFilter from 'hooks/useFilter'
import { useAppSelector } from 'hooks/useRedux'
import useSearchDebounce from 'hooks/useSearchDebounce'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useExportMutation, useGetDetailInvoiceReport } from './queries'
import _ from 'lodash'
import $ from 'jquery'
import { goodsQueries } from 'features/Category/screens/GoodsScreen/queries'
import { useQuery } from '@tanstack/react-query'

function DetailInvoiceReportScreen() {
  // MARK --- Parmas: ---
  const { t } = useTranslation()
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')

  const sysConfig = useAppSelector((s) => s.systemConfig.formatNumber)
  const [filters, setFilters] = useFilter(
    'detailInvoiceList',
    'gFiltersDetailInvoices',
  )
  const [goodsFilterParams] = useState({
    q: '',
    page: 0,
    limit: 50,
    status: 'ACTIVE',
  })

  const { data: goodsOptions } = useQuery({
    ...goodsQueries.listOfGoods(goodsFilterParams),
    select: (res) => {
      return _.concat(
        [{ value: null, label: 'Tất cả hàng hóa' }],
        res.goods,
      ).map((v) => ({
        value: v.name ?? v.value,
        label: v.label ?? v.name,
      }))
    },
  })

  const { data: serials } = useGetSerials('ALL')
  const fullColumns = useMemo(
    () => [
      {
        name: 'Số hoá đơn',
        id: 'invoiceOfInvoiceProduct.no',
        center: true,
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const invoiceNo = xml
            ? $(xml).find('SHDon').text()
            : row?.invoiceOfInvoiceProduct?.no
          return (
            <div
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-bold m-0 text-maxline-3 d-flex align-items-center"
            >
              {invoiceNo ? Utils.padString(invoiceNo, 8, '0') : ''}
            </div>
          )
        },
      },
      {
        center: true,
        id: 'invoiceOfInvoiceProduct.type',
        name: 'Loại hóa đơn',
        minWidth: '140px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className={`m-0 badge badge-light-${reportHelper.getInvoiceTypeColor(
                row?.invoiceOfInvoiceProduct?.type,
              )}`}
              style={{ whiteSpace: 'nowrap' }}
            >
              {reportHelper.getInvoiceTypes(row?.invoiceOfInvoiceProduct?.type)}
            </p>
          )
        },
      },
      {
        center: true,
        id: 'invoiceOfInvoiceProduct.serial',
        name: t('Symbol'),
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const invoiceSerial = xml
            ? $(xml).find('KHMSHDon').text() + $(xml).find('KHHDon').text()
            : row?.invoiceOfInvoiceProduct?.serial
          return (
            <p className="font-weight-normal m-0 text-maxline-2">
              {invoiceSerial}
            </p>
          )
        },
      },
      {
        center: true,
        id: 'invoiceOfInvoiceProduct.date',
        name: t('ContractDay'),
        width: '120px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {Utils.formatDateTime(
                row?.invoiceOfInvoiceProduct?.date,
                'DD/MM/YYYY',
              )}
            </p>
          )
        },
      },
      {
        id: 'invoiceOfInvoiceProduct.customerCompanyName',
        name: t('CustomerName'),
        center: true,
        minWidth: '250px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const customerName = xml
            ? $(xml).find('NMua > Ten').text()
            : row?.invoiceOfInvoiceProduct?.customerCompanyName
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {customerName}
            </p>
          )
        },
      },
      {
        id: 'invoiceOfInvoiceProduct.customerFullAddress',
        name: t('Address'),
        center: true,
        minWidth: '250px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const customerAddress = xml
            ? $(xml).find('NMua > DChi').text()
            : row?.invoiceOfInvoiceProduct?.customerFullAddress
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {customerAddress}
            </p>
          )
        },
      },
      {
        id: 'invoiceOfInvoiceProduct.customerTaxCode',
        name: t('TaxCode'),
        center: true,
        width: '140px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const customerTaxCode = xml
            ? $(xml).find('NMua > MST').text()
            : row?.invoiceOfInvoiceProduct?.customerTaxCode
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {customerTaxCode}
            </p>
          )
        },
      },
      {
        id: 'invoiceOfInvoiceProduct.customerName',
        name: t('Buyer'),
        center: true,
        minWidth: '250px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const customerName = xml
            ? $(xml).find('NMua > HVTNMHang').text()
            : row?.invoiceOfInvoiceProduct?.customerName
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {customerName}
            </p>
          )
        },
      },
      {
        id: 'invoiceOfInvoiceProduct.paymentMethod',
        name: t('PaymentMethod'),
        center: true,
        minWidth: '150px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const paymentMethod = xml
            ? $(xml).find('HTTToan').text()
            : reportHelper.getPaymentMethod(
                row?.invoiceOfInvoiceProduct?.paymentMethod,
              )
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {paymentMethod}
            </p>
          )
        },
      },
      {
        id: 'invoiceOfInvoiceProduct.currency',
        name: t('MoneyType'),
        center: true,
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const currency = xml
            ? $(xml).find('DVTTe').text()
            : row?.invoiceOfInvoiceProduct?.currency
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {currency}
            </p>
          )
        },
      },
      {
        id: 'invoiceOfInvoiceProduct.exchangeRate',
        name: t('ExchangeRate'),
        center: true,
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const exchangeRate = xml
            ? ($(xml).find('TGia')?.text() ??
              row?.invoiceOfInvoiceProduct?.exchangeRate)
            : row?.invoiceOfInvoiceProduct?.exchangeRate
          return (
            <p
              style={{
                textAlign: 'right',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 w-100 font-weight-normal m-0 text-maxline-3"
            >
              {Utils.valueFormatted(
                exchangeRate,
                row?.numberFormat,
                sysConfig,
                'exchangeRate',
              )}
            </p>
          )
        },
      },
      {
        id: 'name',
        name: t('CommodityName'),
        center: true,
        minWidth: '300px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const xmlProduct = xml
            ? $(xml)
                .find('DSHHDVu > HHDVu')
                .eq(+row?.no - 1)
            : null
          const productName = xmlProduct
            ? $(xmlProduct).find('THHDVu').text()
            : row?.name
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {productName}
            </p>
          )
        },
      },
      {
        name: t('Unit'),
        center: true,
        id: 'unit',
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const xmlProduct = xml
            ? $(xml)
                .find('DSHHDVu > HHDVu')
                .eq(+row?.no - 1)
            : null
          const unit = xmlProduct
            ? $(xmlProduct).find('DVTinh').text()
            : row?.unit
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {unit}
            </p>
          )
        },
      },
      {
        id: 'quantity',
        name: t('Quantity'),
        center: true,
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const xmlProduct = xml
            ? $(xml)
                .find('DSHHDVu > HHDVu')
                .eq(+row?.no - 1)
            : null
          const quantity = xmlProduct
            ? $(xmlProduct).find('SLuong').text()
            : row?.quantity
          return (
            <p
              style={{
                textAlign: 'right',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 w-100 font-weight-normal m-0 text-maxline-3"
            >
              {Utils.valueFormatted(
                quantity,
                row?.numberFormat,
                sysConfig,
                'quantity',
              )}
            </p>
          )
        },
      },
      {
        id: 'unitPrice',
        name: t('UnitPrice'),
        minWidth: '110px',
        center: true,
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const xmlProduct = xml
            ? $(xml)
                .find('DSHHDVu > HHDVu')
                .eq(+row?.no - 1)
            : null
          const unitPrice = xmlProduct
            ? $(xmlProduct).find('DGia').text()
            : row?.unitPrice
          return (
            <p
              style={{
                textAlign: 'right',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 w-100 font-weight-normal m-0 text-maxline-3"
            >
              {Utils.valueFormatted(
                unitPrice,
                row?.numberFormat,
                sysConfig,
                'unitPrice',
              )}
            </p>
          )
        },
      },
      {
        id: 'totalUnitPrice',
        name: t('IntoMoney'),
        minWidth: '110px',
        center: true,
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const xmlProduct = xml
            ? $(xml)
                .find('DSHHDVu > HHDVu')
                .eq(+row?.no - 1)
            : null
          const totalUnitPrice = xmlProduct
            ? $(xmlProduct).find('ThTien').text()
            : row?.totalUnitPrice
          return (
            <p
              style={{
                textAlign: 'right',
              }}
              data-tag="allowRowEvents"
              className="text-dark-75 w-100 font-weight-normal m-0 text-maxline-3"
            >
              {Utils.valueFormatted(
                totalUnitPrice,
                row?.numberFormat,
                sysConfig,
                'totalAmount',
              )}
            </p>
          )
        },
      },
      {
        id: 'totalUnitPriceConvert',
        name: t('IntoMoneyConvert'),
        center: true,
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          let exchangeRate
          if (xml) {
            const TGiaText = $(xml).find('TGia')?.text()
            exchangeRate = TGiaText
              ? TGiaText
              : row?.invoiceOfInvoiceProduct?.exchangeRate
          } else {
            exchangeRate = row?.invoiceOfInvoiceProduct?.exchangeRate
          }
          const xmlProduct = xml
            ? $(xml)
                .find('DSHHDVu > HHDVu')
                .eq(+row?.no - 1)
            : null
          const totalUnitPriceConvert = xmlProduct
            ? Number($(xmlProduct).find('ThTien').text()) * Number(exchangeRate)
            : row?.totalUnitPrice * row?.invoiceOfInvoiceProduct?.exchangeRate
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {Utils.valueFormatted(
                totalUnitPriceConvert,
                row?.numberFormat,
                sysConfig,
                'totalAmount',
              )}
            </p>
          )
        },
      },
      {
        id: 'discount',
        name: t('DiscountRate') + '(%)',
        center: true,
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const xmlProduct = xml
            ? $(xml)
                .find('DSHHDVu > HHDVu')
                .eq(+row?.no - 1)
            : null
          const discount = xml
            ? $(xmlProduct).find('TLCKhau').text()
            : row?.discount
          return (
            <p
              style={{
                textAlign: 'right',
              }}
              data-tag="allowRowEvents"
              className="w-100 text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {Utils.valueFormatted(
                discount,
                row?.numberFormat,
                sysConfig,
                'ratio',
              )}
            </p>
          )
        },
      },
      {
        id: 'discountMoney',
        name: t('DiscountMoney'),
        center: true,
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const xmlProduct = xml
            ? $(xml)
                .find('DSHHDVu > HHDVu')
                .eq(+row?.no - 1)
            : null
          const discountMoney = xmlProduct
            ? $(xmlProduct).find('STCKhau').text()
            : row?.discountMoney
          return (
            <p
              style={{
                textAlign: 'right',
              }}
              data-tag="allowRowEvents"
              className="w-100 text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {Utils.valueFormatted(
                discountMoney,
                row?.numberFormat,
                sysConfig,
                'currencyExchange',
              )}
            </p>
          )
        },
      },
      {
        id: 'discountMoneyConvert',
        name: t('DiscountMoneyConvert'),
        center: true,
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const xmlProduct = xml
            ? $(xml)
                .find('DSHHDVu > HHDVu')
                .eq(+row?.no - 1)
            : null
          let exchangeRate
          if (xml) {
            const TGiaText = $(xml).find('TGia')?.text()
            exchangeRate = TGiaText
              ? TGiaText
              : row?.invoiceOfInvoiceProduct?.exchangeRate
          } else {
            exchangeRate = row?.invoiceOfInvoiceProduct?.exchangeRate
          }
          const discountMoneyConvert = xmlProduct
            ? Number($(xmlProduct).find('STCKhau').text()) *
              Number(exchangeRate)
            : row?.discountMoney * row?.invoiceOfInvoiceProduct?.exchangeRate
          return (
            <p
              style={{
                textAlign: 'right',
              }}
              data-tag="allowRowEvents"
              className="w-100 text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {Utils.valueFormatted(
                discountMoneyConvert,
                row?.numberFormat,
                sysConfig,
                'currencyExchange',
              )}
            </p>
          )
        },
      },
      {
        id: 'taxRate',
        name: t('Tax') + '(%)',
        center: true,
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const xmlProduct = xml
            ? $(xml)
                .find('DSHHDVu > HHDVu')
                .eq(+row?.no - 1)
            : null
          const taxRate = xmlProduct
            ? $(xmlProduct).find('TSuat').text()
            : row?.taxRate
          return (
            <p
              style={{
                textAlign: 'right',
              }}
              data-tag="allowRowEvents"
              className="w-100 text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {taxRate}
            </p>
          )
        },
      },
      {
        id: 'taxMoney',
        name: t('VAT'),
        center: true,
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          let taxMoney
          if (xml) {
            const xmlProduct = $(xml).find('TToan')
            taxMoney = $(xmlProduct).find('TgTThue').text()
          } else {
            taxMoney = row?.taxMoney
          }
          return (
            <p
              style={{ textAlign: 'right' }}
              data-tag="allowRowEvents"
              className="w-100 text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {Utils.valueFormatted(
                taxMoney,
                row?.numberFormat,
                sysConfig,
                'totalCost',
              )}
            </p>
          )
        },
      },
      {
        id: 'taxMoneyConvert',
        name: t('VATConvert'),
        center: true,
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          let exchangeRate
          if (xml) {
            const TGiaText = $(xml).find('TGia')?.text()
            exchangeRate = TGiaText
              ? TGiaText
              : row?.invoiceOfInvoiceProduct?.exchangeRate
          } else {
            exchangeRate = row?.invoiceOfInvoiceProduct?.exchangeRate
          }
          const taxMoneyConvert = xml
            ? row?.taxMoney * Number(exchangeRate)
            : row?.taxMoney * row?.invoiceOfInvoiceProduct?.exchangeRate
          return (
            <p
              style={{ textAlign: 'right' }}
              data-tag="allowRowEvents"
              className="w-100 text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {Utils.valueFormatted(
                taxMoneyConvert,
                row?.numberFormat,
                sysConfig,
                'totalCost',
              )}
            </p>
          )
        },
      },
      {
        id: 'totalPayment',
        name: t('TotalPayment'),
        center: true,
        minWidth: '150px',
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const xmlProduct = xml
            ? $(xml)
                .find('DSHHDVu > HHDVu')
                .eq(+row?.no - 1)
            : null
          const totalPayment = xmlProduct
            ? row?.taxMoney + Number($(xmlProduct).find('ThTien').text())
            : row?.taxMoney + row?.totalUnitPrice
          return (
            <p
              style={{ textAlign: 'right' }}
              data-tag="allowRowEvents"
              className="text-dark-75 w-100 font-weight-normal m-0 text-maxline-3"
            >
              {Utils.valueFormatted(
                totalPayment,
                row?.numberFormat,
                sysConfig,
                'totalCost',
              )}
            </p>
          )
        },
      },
      {
        id: 'totalPaymentConvert',
        name: t('TotalPaymentConvert'),
        center: true,
        cell: (row) => {
          const xml = reportHelper.readXmlString(
            row?.invoiceOfInvoiceProduct?.xmlString,
          )
          const xmlProduct = xml
            ? $(xml)
                .find('DSHHDVu > HHDVu')
                .eq(+row?.no - 1)
            : null
          let exchangeRate
          if (xml) {
            const TGiaText = $(xml).find('TGia')?.text()
            exchangeRate = TGiaText
              ? TGiaText
              : row?.invoiceOfInvoiceProduct?.exchangeRate
          } else {
            exchangeRate = row?.invoiceOfInvoiceProduct?.exchangeRate
          }
          const totalPaymentConvert = xmlProduct
            ? (row?.taxMoney + Number($(xmlProduct).find('ThTien').text())) *
              Number(exchangeRate)
            : (row?.taxMoney + row?.totalUnitPrice) *
              row?.invoiceOfInvoiceProduct?.exchangeRate
          return (
            <p
              style={{ textAlign: 'right' }}
              data-tag="allowRowEvents"
              className="text-dark-75 w-100 font-weight-normal m-0 text-maxline-3"
            >
              {Utils.valueFormatted(
                totalPaymentConvert,
                row?.numberFormat,
                sysConfig,
                'totalCost',
              )}
            </p>
          )
        },
      },
      {
        id: 'invoiceOfInvoiceProduct.issueStatus',
        name: t('IssueStatus'),
        minWidth: '130px',
        center: true,
        cell: (row) => {
          const issueStatus = row?.invoiceOfInvoiceProduct?.issueStatus
          return (
            <p
              data-tag="allowRowEvents"
              className={`m-0 badge badge-light-${reportHelper.getInvoiceStatusColor(
                issueStatus,
              )}`}
              style={{ whiteSpace: 'nowrap' }}
            >
              {reportHelper.getInvoiceStatus(issueStatus)}
            </p>
          )
        },
      },
    ],
    [sysConfig, t],
  )

  const dynamicColumns = useAppDnD(fullColumns)

  // MARK --- Functions ---

  const { mutate: handleExportExcel, isPending } = useExportMutation()
  const { data: detailInvoices, isFetching: isGettingDetailInvoice } =
    useGetDetailInvoiceReport(debouncedSearch, filters)

  // MARK --- Hooks ---
  useEffect(() => {
    setFilters({
      q: debouncedSearch,
      page: 1,
    })
  }, [debouncedSearch, setFilters])

  return (
    <ContentContainer>
      <ContentContainer.Header
        titleContent={t('DetailedListOfUsedInvoices')}
        description={`${t('Tổng')}: ${Utils.formatNumber(
          detailInvoices?.total,
        )} ${t('Hóa đơn')}`}
        toolBar={
          <Space>
            <CustomAntButton
              text="Xuất excel"
              iconCn={Utils.cn(
                'fa-solid mr-2 p-0',
                isPending ? 'fa-spinner-third fa-spin' : 'fa-down-to-line',
              )}
              antProps={{
                disabled: isPending,
                className: 'btn btn-success',
                onClick: () =>
                  handleExportExcel(_.omit(filters, ['page', 'limit'])),
              }}
            />
            <AppDnD
              defaultColumns={fullColumns}
              localStorageName={PreferenceKeys.savedColumnsDetailInvoice}
            />
          </Space>
        }
      >
        <Space wrap>
          <CustomAntInput
            type="search"
            borderRadius="base"
            inputProps={{
              value: search,
              onChange: (e) => setSearch(e.target.value),
            }}
          />
          <FilterPopover
            menuPosition="bottomRight"
            defaultValues={[
              {
                alias: 'Kí hiệu',
                label: 'Ký hiệu',
                formName: 'serials',
                options: serials,
                value: null,
                colSpan: 24,
                multiselect: true,
              },
              {
                label: ['Hoá đơn từ số', 'Đến số'],
                formName: ['fromNumberInvoice', 'toNumberInvoice'],
                alias: 'Số hoá đơn: Từ',
                separator: 'Đến',
                value: [null, null],
                restrict: {
                  type: 'number',
                  min: 1,
                  max: 99999999,
                },
              },
              {
                label: 'Trạng thái phát hành',
                alias: 'Trạng thái phát hành',
                formName: 'statusReleaseInvoiceReports',
                options: SelectOptions.statusReleaseInvoiceReplace,
                value: SelectOptions.statusReleaseInvoiceReplace[0].value,
              },
              {
                label: 'Tên hàng hóa',
                alias: 'Hàng hóa',
                formName: 'goodsName',
                options: goodsOptions,
                value: null,
              },
            ]}
            onApply={setFilters}
            savedKey="gFiltersDetailInvoices"
          />
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={dynamicColumns}
          dataSource={detailInvoices?.rows}
          customTableStyle={customDataTableStyle}
          isLoading={isGettingDetailInvoice}
          pagination={{
            currentPage: filters?.page,
            rowsPerPage: filters?.limit,
            total: detailInvoices?.total,
            onChangePage: (newPage) => {
              let iNewPage = parseInt(newPage)
              setFilters({
                page: iNewPage,
              })
            },
            onChangeRowsPerPage: (limit) => {
              const iNewPerPage = parseInt(limit)
              setFilters({
                limit: iNewPerPage,
                page: 1,
              })
            },
          }}
        />
      </ContentContainer.Body>
    </ContentContainer>
  )
}

export default DetailInvoiceReportScreen
