import { createSlice } from '@reduxjs/toolkit'
import Global from 'general/utils/Global'
import { useAppSelector } from 'hooks/useRedux'
import { assign, cloneDeep, filter, keys, map, merge } from 'lodash'
import { shallowEqual } from 'react-redux'

/**
 * @typedef {{
 * modalName: keyof initialState['modalStates'],
 * status: boolean
 * }} SetModalType
 *
 */

const initialState = {
  columns: [],
  pagination: { perPage: Global.gDefaultPagination, currentPage: 0 },
  modalStates: {
    cancel: false,
    explanation: false,
    sign: false,
    delete: false,
    download: false,
    sentMail: false,
    transmission: false,
    sendDraftInvoice: false,
    selectCancelationMethod: false,
    selectReplacementMethod: false,
    selectAdjustInvoiceMethod: false,
    noRight: false,
    detectMultipleCustomer: false,
    upload: false,
    sentFromPos: false,
    listInvoiceFromPOS: false,
    issueInvoiceFromPos: false,
    issueInvoicesFromPos: false,
    convertPaperModal: false,
    cloneMultiInvoiceModal: false,
  },
  /** @type {InvoiceFromPos[]} */
  selectedInvoices: [],
  /** @type {InvoiceFromPos} */
  selectedInvoice: null,
  toggledClearRows: false,
}

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    /**
     * @param {import('@reduxjs/toolkit').PayloadAction<SetModalType>} action
     */
    setModalsState: (state, action) => {
      const previousModalState = cloneDeep(state.modalStates)
      const notCurrentModal = filter(
        keys(previousModalState),
        (m) => m !== action.payload.modalName,
      )
      const turnOthersOff = assign(
        {},
        ...map(notCurrentModal, (m) => ({ [m]: false })),
      )
      state.modalStates = merge(turnOthersOff, {
        [action.payload.modalName]: action.payload.status,
      })
    },
    /**
     * @param {import('@reduxjs/toolkit').PayloadAction<any[]>} action
     */
    setSelectedInvoices: (state, action) => {
      state.selectedInvoices = action.payload
      // state.toggledClearRows = false
    },
    setSelectedInvoice: (state, action) => {
      state.selectedInvoice = action.payload
    },
    setClearRows: (state) => {
      state.toggledClearRows = !state.toggledClearRows
    },
    setColumns: (s, a) => {
      s.columns = a.payload.columns
    },
    setPaginationPerPage: (state, action) => {
      const previousState = cloneDeep(state.pagination)
      state.pagination = merge(previousState, {
        perPage: action.payload,
      })
    },
    resetModals: (s) => {
      s.modalStates = initialState.modalStates
    },
  },
})
const { actions, reducer } = invoiceSlice

export const useInvoiceSelector = () =>
  useAppSelector((state) => state.invoice, shallowEqual)

export const {
  setPaginationPerPage,
  setColumns,
  setModalsState,
  setSelectedInvoices,
  setSelectedInvoice,
  setClearRows,
  resetModals,
} = actions
export default reducer
