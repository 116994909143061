import { useMutation, useQuery } from '@tanstack/react-query'
import { Space } from 'antd'
import templateInvoiceApi from 'api/templateInvoiceApi'
import templateTicketApi from 'api/templateTicketApi'
import { setAppSpinning } from 'app/appSlice'
import dayjs from 'dayjs'
import ModalExportTemplate from 'features/TemplateInvoice/components/ModalExportTemplate'
import { thunkGetTemplates } from 'features/TemplateInvoice/templateInvoiceSlice'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import ModalConfirm from 'general/components/Modal/ModalConfirm'
import ModalUnauthorized from 'general/components/Modal/ModalUnauthorized'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import TemplateInvoiceHelper from 'general/helpers/TemplateInvoiceHelper'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useAppDnD from 'hooks/useAppDnD'
import useFilter from 'hooks/useFilter'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import useRouter from 'hooks/useRouter'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import ModalViewPdf from './components/ModalViewPdf'
import './style.scss'

function ListTemplateTicket() {
  // MARK --- Parmas: ---
  const dispatch = useAppDispatch()
  const { navigate } = useRouter()
  const { t } = useTranslation()
  const [showNoRight, setShowNoRight] = useState(false)
  const { listFunctions, userFunctions } = useAppSelector(
    (state) => state?.function,
    shallowEqual,
  )
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const [filters, setFilters] = useFilter(
    'templateTicketList',
    'gFiltersTemplateTicketList',
  )

  const [showModalViewPdf, setShowModalViewPdf] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [pdfUrl, setPdfUrl] = useState(false)

  const [activeInvoiceTemplateId, setActiveInvoiceTemplateId] = useState(null)

  const {
    data: templatesData,
    isFetching: gettingTemplatesData,
    refetch: refetchTemplateData,
  } = useQuery({
    queryKey: ['templateInvoice', filters, debouncedSearch],
    queryFn: () =>
      dispatch(thunkGetTemplates({ ...filters, q: debouncedSearch })).unwrap(),
    select: (res) => {
      return {
        total: _.get(res, 'total', 0),
        templates:
          _.isNull(res?.templates) || _.isUndefined(res?.templates)
            ? []
            : res.templates.map((template, index) => ({
                id: index + 1,
                invoiceTemplateId: template.invoiceTemplateId,
                name: template.name,
                form: TemplateInvoiceHelper.displayForm(template.form),
                categoryTitle: template.category?.title,
                taxTypeTitle: TemplateInvoiceHelper.displayTaxType(
                  template.category?.taxType,
                ),
                serial:
                  template.form +
                  template.hasTaCode +
                  template.year +
                  template.type +
                  template.managementCode,
                description: template.description,
                directTranfer: TemplateInvoiceHelper.displayDirectTranfer(
                  template.directTranfer,
                ),
                createdByAccount: template.createdByAccount?.email,
                updatedAt: template.updatedAt,
                active: template.active,
              })),
      }
    },
  })

  const openStuffInPdf = (blobData) => {
    const newFile = new File([blobData], 'Mẫu vé.pdf', {
      type: 'application/pdf',
    })
    setShowModalViewPdf(true)
    setPdfUrl(URL.createObjectURL(newFile))
    URL.revokeObjectURL(newFile)
  }

  const { mutate: viewPdfTemplateInvoice } = useMutation({
    mutationKey: ['templateInvoice', 'viewPdfTemplateInvoice'],
    mutationFn: () =>
      templateTicketApi.exportTicketTemplateById(activeInvoiceTemplateId),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: (res) => openStuffInPdf(res),
  })

  const { mutate: exportTemplateByIdHandler } = useMutation({
    mutationKey: ['templateInvoice', 'exportTemplateById'],
    mutationFn: (exportOptions) => {
      if (!activeInvoiceTemplateId) return
      return templateInvoiceApi.exportTemplateById(
        activeInvoiceTemplateId,
        exportOptions,
      )
    },
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: (res) => openStuffInPdf(res),
  })

  const { mutate: handleConfirmDeleteTemplate } = useMutation({
    mutationKey: ['templateInvoice', 'deleteTemplate'],
    mutationFn: () =>
      templateInvoiceApi.deleteTemplate(activeInvoiceTemplateId),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: () => {
      ToastHelper.showSuccess('Xóa mẫu hóa đơn thành công')
      setShowModalConfirm(false)
      void refetchTemplateData()
    },
  })

  const { mutate: handleChangeActive } = useMutation({
    mutationKey: ['templateInvoice', 'updateTemplate'],
    mutationFn: (active) =>
      templateInvoiceApi.updateTemplate(activeInvoiceTemplateId, { active }),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: () => {
      ToastHelper.showSuccess('Cập nhật trạng thái thành công')
      void refetchTemplateData()
    },
  })

  const [
    isViewTemplateInvoice,
    isCreateTemplateInvoice,
    isEditTemplateInvoice,
    isDeleteTemplateInvoice,
  ] = useMemo(() => {
    return Utils.checkFunction(
      [
        {
          listFunctionCode: 'TEMPLATE_INVOICE_VIEW_LIST',
        },
        {
          listFunctionCode: 'TEMPLATE_INVOICE_ADD',
        },
        {
          listFunctionCode: 'TEMPLATE_INVOICE_EDIT',
        },
        {
          listFunctionCode: 'TEMPLATE_INVOICE_DELETE',
        },
      ],
      userFunctions,
      listFunctions,
    )
  }, [listFunctions, userFunctions])

  const columns = useMemo(() => {
    return [
      {
        name: 'Tên mẫu vé',
        sortable: true,
        sortFunction: (a, b) => {
          if (!a.name || !b.name) return 0
          return a.name.localeCompare(b.name)
        },
        // width: 'auto',s
        minWidth: '250px',
        cell: (row) => {
          return (
            <div
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-bold m-0 text-maxline-3 d-flex align-items-center"
            >
              {row?.name}
            </div>
          )
        },
      },
      {
        name: 'Ký hiệu',
        sortable: true,
        sortFunction: (a, b) => {
          if (!a.serial || !b.serial) return 0
          return a.serial.localeCompare(b.serial)
        },
        minWidth: '100px',
        // width: 'auto',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {row?.serial}
            </p>
          )
        },
      },
      {
        name: 'Phương thức chuyển dữ liệu',
        sortable: true,
        sortFunction: (a, b) => {
          if (!a.directTranfer || !b.directTranfer) return 0
          return a.directTranfer.localeCompare(b.directTranfer)
        },
        width: '300px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {row?.directTranfer}
            </p>
          )
        },
      },
      {
        name: 'Người tạo',
        sortable: true,
        sortFunction: (a, b) => {
          if (!a.createdByAccount || !b.createdByAccount) return 0
          return a.createdByAccount.localeCompare(b.createdByAccount)
        },
        // width: 'auto',
        minWidth: '250px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {row?.createdByAccount}
            </p>
          )
        },
      },
      {
        name: 'Ngày cập nhật',
        sortable: true,
        sortFunction: (a, b) => {
          const dateA = dayjs(a.updatedAt)
          const dateB = dayjs(b.updatedAt)
          if (dateA.isBefore(dateB)) {
            return -1
          } else if (dateA.isAfter(dateB, 's')) {
            return 1
          }
          return 0
        },
        // width: 'auto',
        minWidth: '180px',
        center: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {row?.updatedAt && dayjs(row.updatedAt).format('DD/MM/YYYY')}
            </p>
          )
        },
      },
      {
        name: 'Sử dụng',
        sortable: false,
        // width: 'auto',
        minWidth: '130px',
        center: true,
        cell: (row) => {
          return (
            <span
              data-tag="allowRowEvents"
              // className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {row?.active ? (
                <i
                  className="fa-sharp fa-solid fa-circle-check"
                  style={{ color: '#00B416' }}
                ></i>
              ) : (
                <i className="fa-solid fa-circle-minus"></i>
              )}
            </span>
          )
        },
      },
      {
        name: '',

        center: true,
        cell: (row) => {
          return (
            <Space
              onClick={() => setActiveInvoiceTemplateId(row.invoiceTemplateId)}
            >
              <TableAction
                titleActionText="Xem"
                icon={<IconAction className="fa-regular fa-eye" />}
                onClick={() => {
                  if (isViewTemplateInvoice) {
                    viewPdfTemplateInvoice(row.invoiceTemplateId)
                  } else setShowNoRight(true)
                }}
              />
              <TableAction
                titleActionText="Sửa"
                icon={<IconAction className="fa-regular fa-pen-to-square" />}
                onClick={() => {
                  if (isEditTemplateInvoice)
                    navigate(
                      `/hoa-don-dau-ra/tem-ve/mau-tem-ve/sua/${row.invoiceTemplateId}`,
                    )
                  if (!isEditTemplateInvoice) setShowNoRight(true)
                }}
              />
              <TableAction
                type="dropdown"
                items={[
                  {
                    label: 'Nhân bản',
                    icon: <IconAction className="fa-regular fa-copy" />,
                    onClick: () => {
                      if (isCreateTemplateInvoice)
                        navigate(
                          // `/hoa-don-dau-ra/mau-hoa-don/tao/${row.invoiceTemplateId}`,
                          `/hoa-don-dau-ra/tem-ve/mau-tem-ve/tao/${row.invoiceTemplateId}?type=copy`,
                        )
                      else setShowNoRight(true)
                    },
                  },
                  {
                    label: 'Xóa',
                    icon: <IconAction className="fa-regular fa-trash" />,
                    onClick: () => {
                      if (isDeleteTemplateInvoice) setShowModalConfirm(true)
                      else setShowNoRight(true)
                    },
                  },
                  {
                    label: row?.active
                      ? 'Ngừng sử dụng'
                      : 'Sử dụng mẫu hoá đơn',
                    icon: row?.active ? (
                      <IconAction className="fa-regular fa-file-slash" />
                    ) : (
                      <IconAction className="fa-regular fa-file" />
                    ),
                    onClick: () => {
                      if (isEditTemplateInvoice)
                        handleChangeActive(!row.active ? 1 : 0)
                      else setShowNoRight(true)
                    },
                  },
                ]}
              />
            </Space>
          )
        },
      },
    ]
  }, [
    handleChangeActive,
    isCreateTemplateInvoice,
    isDeleteTemplateInvoice,
    isEditTemplateInvoice,
    isViewTemplateInvoice,
    navigate,
    viewPdfTemplateInvoice,
  ])

  // MARK --- Effects: ---

  const dynamicColumns = useAppDnD(columns)

  return (
    <ContentContainer wrapperClassName="ListTemplateTicket ">
      <ContentContainer.Header
        titleContent={t('Danh sách mẫu vé')}
        description={`${t('Tổng')}: ${templatesData?.total} ${_.capitalize(
          t('mẫu vé'),
        )}`}
        toolBar={
          <Space>
            <CustomAntButton
              text="Tạo mới mẫu vé"
              iconCn="fa-regular fa-plus"
              antProps={{
                type: 'primary',
                onClick: () => {
                  if (isCreateTemplateInvoice)
                    navigate('/hoa-don-dau-ra/tem-ve/mau-tem-ve/chon')
                  if (!isCreateTemplateInvoice) setShowNoRight(true)
                },
              }}
            />
            <AppDnD
              defaultColumns={columns}
              localStorageName={PreferenceKeys.listTemplateTicket}
            />
          </Space>
        }
      >
        <Space>
          <CustomAntInput
            type="search"
            borderRadius="sm"
            inputProps={{
              name: 'searchQuery',
              value: search,
              onChange: (e) => setSearch(e.target.value),
              allowClear: true,
            }}
          />
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={dynamicColumns}
          dataSource={templatesData?.templates}
          isLoading={gettingTemplatesData}
          isClickable
          handleDoubleClickRow={(row) => {
            if (isViewTemplateInvoice) {
              setActiveInvoiceTemplateId(row.invoiceTemplateId)
              viewPdfTemplateInvoice(row.invoiceTemplateId)
            } else setShowNoRight(true)
          }}
          pagination={{
            currentPage: filters?.page,
            rowsPerPage: filters?.limit,
            total: templatesData?.total,
            onChangePage: (newPage) => {
              let iNewPage = parseInt(newPage)
              setFilters({
                page: iNewPage,
              })
            },
            onChangeRowsPerPage: (newPerPage) => {
              const iNewPerPage = parseInt(newPerPage)
              setFilters({
                limit: iNewPerPage,
                page: 1,
              })
            },
          }}
        />
      </ContentContainer.Body>

      <ModalViewPdf
        pdfUrl={pdfUrl}
        showModalViewPdf={showModalViewPdf}
        setShowModalViewPdf={setShowModalViewPdf}
      />

      <ModalExportTemplate
        exportTemplateByIdHandler={exportTemplateByIdHandler}
      />

      <ModalConfirm
        show={showModalConfirm}
        setShow={setShowModalConfirm}
        header="Xác nhận"
        content="Bạn có thực sự muốn xóa Mẫu hóa đơn đã chọn không?"
        handleConfirm={handleConfirmDeleteTemplate}
      />
      <ModalUnauthorized show={showNoRight} setShow={setShowNoRight} />
    </ContentContainer>
  )
}

export default ListTemplateTicket
