import { thunkGetAccountProfile } from 'app/authSlice'
import { thunkGetCompanyProfile } from 'features/System/systemSlice'
import UserHelper from 'general/helpers/UserHelper'
import Utils from 'general/utils/Utils'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

function AccountListener(props) {
  const dispatch = useDispatch()
  const { signedIn, currentAccount, currentCompany } = useSelector(
    (state) => state?.auth,
  )

  useEffect(() => {
    if (
      Utils.isObjectEmpty(currentAccount) &&
      UserHelper.checkAccessTokenValid()
    ) {
      // get account infor
      dispatch(thunkGetAccountProfile())
    }

    if (currentAccount.companyId) {
      // get company infor
      dispatch(thunkGetCompanyProfile({ companyId: currentAccount.companyId }))
    }
  }, [currentAccount, dispatch, currentAccount.companyId])

  return <></>
}

export default AccountListener
