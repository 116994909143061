import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import ticketApi from 'api/ticketApi'
import { setAppSpinning, showNotification } from 'app/appSlice'
import dayjs from 'dayjs'
import {
  setClearRows,
  setModalsState,
  setSelectedTicket,
} from 'features/Ticket/ticketSlice'
import InvoiceHelper from 'general/helpers/InvoiceHelper'
import PrintHelper from 'general/helpers/PrintHelper'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import { useAppDispatch } from 'hooks/useRedux'
import { compact, get, isEmpty, isNil, split } from 'lodash'

const keyFactory = {
  base: { scope: ['ticket', 'listIssuedTicket'] },
  list: () => ({
    ...keyFactory.base,
    entity: 'ticket',
  }),
  lists: (q, p) => [
    {
      ...keyFactory.list(),
      params: { ...p, q },
    },
  ],
  sendEmail: () => [
    {
      ...keyFactory.base,
      action: 'sendEmail',
    },
  ],
  downloadTicket: () => [
    {
      ...keyFactory.base,
      action: 'downloadTicket',
    },
  ],
  cancelTicket: () => [
    {
      ...keyFactory.base,
      action: 'cancelTicket',
    },
  ],
  printPdf: () => [
    {
      ...keyFactory.base,
      action: 'printPdf',
    },
  ],
  checkMessages: () => [
    {
      ...keyFactory.base,
      action: 'checkMessages',
    },
  ],
}

export const useQueryListIssuedTicket = (q, p) => {
  return useQuery({
    staleTime: 1000 * 30,
    refetchOnWindowFocus: true,
    queryKey: keyFactory.lists(q, p),
    queryFn: ({ signal }) =>
      ticketApi.list(
        {
          ...p,
          q,
        },
        signal,
      ),
    select: (res) => {
      const dataTicket = res?.rows?.map((row, index) => ({
        ...row,
        id: index + 1,
        no: row.no ? InvoiceHelper.padNumber(row.no, 8) : null,
      }))
      return {
        total: get(res, 'count', 0),
        rows: dataTicket ?? [],
      }
    },
  })
}

export const useMutationSendEmail = () => {
  const dispatch = useAppDispatch()
  const queryCache = useInvalidateListIssuedTicket()
  return useMutation({
    mutationKey: keyFactory.sendEmail(),
    mutationFn: (params) => ticketApi.sendTicketToCustomer(params),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onError: (error) => console.log(error.message),
    onSuccess: () => {
      ToastHelper.showSuccess('Gửi vé cho khách hàng thành công')
      dispatch(setModalsState({ modalName: 'sentMail', status: false }))
      queryCache.invalidate()
    },
  })
}

export const useMutationDownloadTicket = () => {
  const dispatch = useAppDispatch()
  const today = dayjs().format('L')
  return useMutation({
    mutationKey: keyFactory.downloadTicket(),
    mutationFn: ({ ticketIds }) => {
      if (isEmpty(ticketIds) || isNil(ticketIds))
        return ToastHelper.showError('Không thể tải xuống các vé này')
      return ticketApi.download({ ticketIds })
    },
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onError: (error) => console.log(error.message),
    onSuccess: (res) => {
      Utils.handleDownloadZipFile(res, `Tem_ve_dien_tu_${today}.zip`)
      dispatch(setSelectedTicket([]))
      dispatch(setClearRows())
    },
  })
}

export const useMutationCancelTicket = () => {
  const dispatch = useAppDispatch()
  const queryCache = useInvalidateListIssuedTicket()

  return useMutation({
    mutationKey: keyFactory.cancelTicket(),
    mutationFn: ({ customerEmailCc, customerEmailBcc, ...others }) => {
      const ccEmails = compact(split(customerEmailCc, ';'))
      const bccEmails = compact(split(customerEmailBcc, ';'))
      return ticketApi.cancel({
        ...others,
        ccEmails,
        bccEmails,
      })
    },
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onError: (error) => console.log(error.message),
    onSuccess: () => {
      ToastHelper.showSuccess('Hủy vé thành công')
      dispatch(setModalsState({ modalName: 'cancelTicket', status: false }))
      queryCache.invalidate()
    },
  })
}

export const useMutationPrintPdf = () => {
  const dispatch = useAppDispatch()
  const queryCache = useInvalidateListIssuedTicket()
  return useMutation({
    mutationKey: keyFactory.printPdf(),
    mutationFn: (params) => ticketApi.bulkPrint(params),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onError: (error) => console.log(error.message),
    onSuccess: (res) => {
      dispatch(setSelectedTicket([]))
      dispatch(setClearRows())
      PrintHelper(res, 'pdf')
      queryCache.invalidate()
    },
  })
}

export const useMutationCheckMessage = () => {
  const dispatch = useAppDispatch()
  const queryCache = useInvalidateListIssuedTicket()
  return useMutation({
    mutationKey: keyFactory.checkMessages(),
    mutationFn: ({ startDate, endDate }) => {
      return ticketApi.checkMessages({
        startDate,
        endDate,
      })
    },
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onError: (error) => console.log(error.message),
    onSuccess: (res) => {
      let describeText = `Kiểm tra mã thành công cho ${res?.count} hóa đơn.`
      dispatch(showNotification({ describeText }))
      queryCache()
    },
  })
}

export const useInvalidateListIssuedTicket = () => {
  const queryClient = useQueryClient()
  return {
    invalidate: () => queryClient.invalidateQueries([keyFactory.list()]),
  }
}
