import { Space } from 'antd'
import dayjs from 'dayjs'
import ModalExportTemplate from 'features/TemplateInvoice/components/ModalExportTemplate'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import ModalConfirm from 'general/components/Modal/ModalConfirm'
import ModalUnauthorized from 'general/components/Modal/ModalUnauthorized'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import Utils from 'general/utils/Utils'
import useAppDnD from 'hooks/useAppDnD'
import useFilter from 'hooks/useFilter'
import { useAppSelector } from 'hooks/useRedux'
import useRouter from 'hooks/useRouter'
import useSearchDebounce from 'hooks/useSearchDebounce'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalViewPdf from './components/ModalViewPdf'
import {
  useInvalidateListTemplateInvoice,
  useMutationChangeActive,
  useMutationDeleteTemplate,
  useMutationExportTemplate,
  useMutationViewPdf,
  useQueryTemplateInvoice,
} from './queries'
import './style.scss'

function ListTemplateInvoice() {
  // MARK --- Parmas: ---
  const { navigate } = useRouter()
  const { t } = useTranslation()
  const [showNoRight, setShowNoRight] = useState(false)
  const userFunction = useAppSelector((state) => state?.function?.userFunctions)
  const listFunction = useAppSelector((state) => state?.function?.listFunctions)
  const [filters, setFilters] = useFilter(
    'templateInvoiceList',
    'gFiltersTemplateInvoiceList',
  )

  // const { totalTemplate, templates } = useSelector(
  //   (state) => state.templateInvoice,
  // )
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const [showModalViewPdf, setShowModalViewPdf] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [pdfUrl, setPdfUrl] = useState(false)

  const [activeInvoiceTemplateId, setActiveInvoiceTemplateId] = useState(null)

  const openStuffInPdf = (blobData) => {
    const newFile = new File([blobData], 'Mẫu hóa đơn.pdf', {
      type: 'application/pdf',
    })
    setShowModalViewPdf(true)
    setPdfUrl(URL.createObjectURL(newFile))
  }

  const { invalidate } = useInvalidateListTemplateInvoice()

  const {
    data: templatesData,
    isLoading: gettingTemplatesData,
    refetch,
  } = useQueryTemplateInvoice(debouncedSearch, filters)

  useEffect(() => {
    if (location.pathname === '/hoa-don-dau-ra/mau-hoa-don') {
      refetch() // Gọi lại API thủ công
    }
  }, [location.pathname, refetch])

  const { mutate: viewPdfTemplateInvoice } = useMutationViewPdf(openStuffInPdf)

  const { mutate: exportTemplateByIdHandler } = useMutationExportTemplate(
    activeInvoiceTemplateId,
  )

  const { mutate: handleConfirmDeleteTemplate } = useMutationDeleteTemplate()

  const { mutate: handleChangeActive } = useMutationChangeActive()

  const [
    isViewTemplateInvoice,
    isCreateTemplateInvoice,
    isEditTemplateInvoice,
    isDeleteTemplateInvoice,
  ] = useMemo(() => {
    return Utils.checkFunction(
      [
        {
          listFunctionCode: 'TEMPLATE_INVOICE_VIEW_LIST',
        },
        {
          listFunctionCode: 'TEMPLATE_INVOICE_ADD',
        },
        {
          listFunctionCode: 'TEMPLATE_INVOICE_EDIT',
        },
        {
          listFunctionCode: 'TEMPLATE_INVOICE_DELETE',
        },
      ],
      userFunction,
      listFunction,
    )
  }, [userFunction, listFunction])

  const fullColumns = useMemo(() => {
    return [
      {
        name: 'Tên mẫu hóa đơn',
        sortable: true,
        sortFunction: (a, b) => {
          if (!a.name || !b.name) return 0
          return a.name.localeCompare(b.name)
        },
        // width: 'auto',s
        minWidth: '250px',
        cell: (row) => {
          return (
            <div
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-bold m-0 text-maxline-3 d-flex align-items-center"
            >
              {row?.name}
            </div>
          )
        },
      },
      {
        name: 'Loại hóa đơn',
        sortable: true,
        sortFunction: (a, b) => {
          if (!a.form || !b.form) return 0
          return a.form.localeCompare(b.form)
        },
        minWidth: '150px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {row?.form}
            </p>
          )
        },
      },

      {
        name: 'Loại mẫu hóa đơn',
        sortable: true,
        sortFunction: (a, b) => {
          if (!a.categoryTitle || !b.categoryTitle) return 0
          return a.categoryTitle.localeCompare(b.categoryTitle)
        },
        // width: 'auto',
        minWidth: '180px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {row?.categoryTitle}
            </p>
          )
        },
      },

      {
        name: 'Loại thuế suất',
        sortable: true,
        sortFunction: (a, b) => {
          if (!a.taxTypeTitle || !b.taxTypeTitle) return 0
          return a.taxTypeTitle.localeCompare(b.taxTypeTitle)
        },
        // width: 'auto',
        minWidth: '180px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {row?.taxTypeTitle}
            </p>
          )
        },
      },

      {
        name: 'Ký hiệu',
        sortable: true,
        sortFunction: (a, b) => {
          if (!a.serial || !b.serial) return 0
          return a.serial.localeCompare(b.serial)
        },
        minWidth: '90px',
        // width: 'auto',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {row?.serial}
            </p>
          )
        },
      },
      {
        name: 'Phương thức chuyển dữ liệu',
        sortable: true,
        sortFunction: (a, b) => {
          if (!a.directTranfer || !b.directTranfer) return 0
          return a.directTranfer.localeCompare(b.directTranfer)
        },
        // width: 'auto',
        minWidth: '260px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {row?.directTranfer}
            </p>
          )
        },
      },
      {
        name: 'Người tạo',
        sortable: true,
        sortFunction: (a, b) => {
          if (!a.createdByAccount || !b.createdByAccount) return 0
          return a.createdByAccount.localeCompare(b.createdByAccount)
        },
        // width: 'auto',
        minWidth: '250px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {row?.createdByAccount}
            </p>
          )
        },
      },
      {
        name: 'Ngày cập nhật',
        sortable: true,
        sortFunction: (a, b) => {
          const dateA = dayjs(a.updatedAt)
          const dateB = dayjs(b.updatedAt)
          if (dateA.isBefore(dateB)) {
            return -1
          } else if (dateA.isAfter(dateB, 's')) {
            return 1
          }
          return 0
        },
        // width: 'auto',
        minWidth: '180px',
        center: true,
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {row?.updatedAt && dayjs(row.updatedAt).format('DD/MM/YYYY')}
            </p>
          )
        },
      },
      {
        name: 'Sử dụng',
        sortable: false,
        // width: 'auto',
        minWidth: '130px',
        center: true,
        cell: (row) => {
          return (
            <span
              data-tag="allowRowEvents"
              // className="text-dark-75 font-weight-normal m-0 text-maxline-3"
            >
              {row?.active ? (
                <i
                  className="fa-solid fa-circle-check"
                  style={{ color: '#00B416' }}
                ></i>
              ) : (
                <i className="fa-solid fa-circle-minus"></i>
              )}
            </span>
          )
        },
      },
      {
        name: 'Hành động',
        sortable: false,
        minWidth: '150px',
        center: true,
        compact: true,
        grow: 0,
        cell: (row) => {
          return (
            <Space
              align="center"
              onClick={() => setActiveInvoiceTemplateId(row.invoiceTemplateId)}
            >
              <TableAction
                titleActionText="Xem"
                icon={<IconAction className="fa-light fa-eye" />}
                onClick={() => {
                  if (isViewTemplateInvoice) {
                    viewPdfTemplateInvoice(row.invoiceTemplateId)
                  } else setShowNoRight(true)
                }}
              />
              <TableAction
                titleActionText="Sửa"
                icon={<IconAction className="fa-light fa-pen-to-square" />}
                onClick={() => {
                  if (isEditTemplateInvoice)
                    navigate(
                      `/hoa-don-dau-ra/mau-hoa-don/sua/${row.invoiceTemplateId}`,
                    )
                  if (!isEditTemplateInvoice) setShowNoRight(true)
                }}
              />
              <TableAction
                type="dropdown"
                items={[
                  {
                    label: 'Nhân bản',
                    icon: <IconAction className="fa-light fa-copy" />,
                    onClick: () => {
                      if (isCreateTemplateInvoice)
                        navigate(
                          `/hoa-don-dau-ra/mau-hoa-don/tao/${row.invoiceTemplateId}`,
                        )
                      else setShowNoRight(true)
                    },
                  },
                  {
                    label: 'Xóa',
                    icon: <IconAction className="fa-light fa-trash" />,
                    onClick: () => {
                      if (isDeleteTemplateInvoice) setShowModalConfirm(true)
                      else setShowNoRight(true)
                    },
                  },
                  {
                    label: row?.active
                      ? 'Ngừng sử dụng'
                      : 'Sử dụng mẫu hoá đơn',
                    icon: row?.active ? (
                      <IconAction className="fa-light fa-file-slash" />
                    ) : (
                      <IconAction className="fa-light fa-file" />
                    ),
                    onClick: () => {
                      if (isEditTemplateInvoice)
                        handleChangeActive({
                          id: activeInvoiceTemplateId,
                          active: !row.active ? 1 : 0,
                        })
                      else setShowNoRight(true)
                    },
                  },
                ]}
              />
            </Space>
          )
        },
      },
    ]
  }, [
    activeInvoiceTemplateId,
    handleChangeActive,
    isCreateTemplateInvoice,
    isDeleteTemplateInvoice,
    isEditTemplateInvoice,
    isViewTemplateInvoice,
    navigate,
    viewPdfTemplateInvoice,
  ])

  const dynamicColumns = useAppDnD(fullColumns)

  // MARK --- Effects: ---

  useEffect(() => {
    if (!showModalViewPdf) {
      URL.revokeObjectURL(pdfUrl)
    }
  }, [pdfUrl, showModalViewPdf])

  useEffect(() => {
    setFilters({ q: debouncedSearch, page: 1 })
  }, [debouncedSearch, setFilters])

  useEffect(() => {
    invalidate()
  }, [invalidate])

  return (
    <>
      <ContentContainer wrapperClassName="ListTemplateInvoice">
        <ContentContainer.Header
          titleContent={t('Danh sách mẫu hóa đơn')}
          description={`${t('Tổng')}: ${Utils.formatNumber(
            templatesData?.total,
          )} ${t('mẫu hóa đơn')}`}
          toolBar={
            <Space>
              <CustomAntInput
                type="search"
                borderRadius="sm"
                inputProps={{
                  value: search,
                  onChange: (e) => setSearch(e.target.value),
                }}
                width={200}
              />
              <CustomAntButton
                text={t('AddNew')}
                iconCn="fa-regular fa-plus"
                antProps={{
                  type: 'primary',
                  onClick: () => {
                    if (isCreateTemplateInvoice)
                      navigate('/hoa-don-dau-ra/mau-hoa-don/chon')
                    if (!isCreateTemplateInvoice) setShowNoRight(true)
                  },
                }}
              />
              <AppDnD
                defaultColumns={fullColumns}
                localStorageName={PreferenceKeys.savedColumnsInvoiceTemplate}
              />
            </Space>
          }
        />
        <ContentContainer.Body>
          <CustomDataTable
            columns={dynamicColumns}
            dataSource={templatesData?.templates}
            isLoading={gettingTemplatesData}
            isClickable
            handleDoubleClickRow={(row) => {
              if (!isViewTemplateInvoice) return setShowNoRight(true)
              setActiveInvoiceTemplateId(row.invoiceTemplateId)
              viewPdfTemplateInvoice(row.invoiceTemplateId)
            }}
            pagination={{
              currentPage: filters?.page,
              rowsPerPage: filters?.limit,
              total: templatesData?.total,
              onChangePage: (page) => setFilters({ page }),
              onChangeRowsPerPage: (limit) => setFilters({ limit, page: 1 }),
            }}
          />
        </ContentContainer.Body>
      </ContentContainer>

      <ModalViewPdf
        pdfUrl={pdfUrl}
        showModalViewPdf={showModalViewPdf}
        setShowModalViewPdf={setShowModalViewPdf}
      />

      <ModalExportTemplate
        exportTemplateByIdHandler={exportTemplateByIdHandler}
      />

      <ModalConfirm
        show={showModalConfirm}
        setShow={setShowModalConfirm}
        header="Xác nhận"
        content="Bạn có thực sự muốn xóa Mẫu hóa đơn đã chọn không?"
        handleConfirm={() => {
          handleConfirmDeleteTemplate(activeInvoiceTemplateId)
          setShowModalConfirm(false)
        }}
      />
      <ModalUnauthorized show={showNoRight} setShow={setShowNoRight} />
    </>
  )
}

export default ListTemplateInvoice
