import PropTypes from 'prop-types'
import CustomDataTable from 'general/components/CustomDataTable'
import { useMemo } from 'react'
import './styles.scss'
import { useAppSelector } from 'hooks/useRedux'
import { map } from 'lodash'
import { getRefObjFromHeaderBaseOnMappingKeyword } from 'general/helpers/ImportTicketHelper'
import customDataTableStyle from 'assets/styles/customDataTableStyle'

ImportTicketStepThree.propTypes = {
  tableData: PropTypes.array,
  setTableData: PropTypes.func,
}

ImportTicketStepThree.defaultProps = {}

function ImportTicketStepThree(props) {
  const { excelData } = useAppSelector((state) => state.importTicket) || {}

  const defaultColumns = useMemo(() => {
    let firstColumn = {
      name: 'Dòng số',
      width: '90px',
      center: true,
      cell: (row) => {
        return (
          <p
            style={{
              textAlign: 'center',
            }}
            data-tag="allowRowEvents"
            className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 text-truncate"
            title={row?.rowNum}
          >
            {row?.rowNum}
          </p>
        )
      },
    }
    return [firstColumn].concat(
      map(excelData?.headers, ({ text }) => {
        let refObject = getRefObjFromHeaderBaseOnMappingKeyword(text)
        return {
          name: refObject?.header?.replace('(*)', '').trim(),
          width: refObject?.webWidth || 'auto',
          center: true,
          cell: (row) => {
            return (
              <p
                style={{
                  textAlign: 'center',
                }}
                data-tag="allowRowEvents"
                className="text-dark-75 font-weight-normal m-0 text-maxline-3 w-100 text-truncate"
                title={row[refObject?.key]}
              >
                {row[refObject?.key]}
              </p>
            )
          },
        }
      }),
    )
  }, [excelData?.headers])

  return (
    <div className="ImportTicketStepThree">
      <div className="mb-2">
        <i className="text-dark" style={{ fontSize: '0.9em' }}>
          (Chương trình chỉ hiển thị tối đa 20 dòng dữ liệu để kiểm tra dữ liệu
          ghép cột.)
        </i>
      </div>

      <CustomDataTable
        wrapperClassName="w-100"
        columns={defaultColumns}
        dataSource={excelData?.rows || []}
        customTableStyle={customDataTableStyle}
      />
    </div>
  )
}

export default ImportTicketStepThree
