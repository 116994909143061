import dayjs from 'dayjs'
import { PERIOD } from 'general/constants/AppConstants'
import AppData from 'general/constants/AppData'
import SelectOptions from 'general/constants/SelectOptions'
import { transformToStartAndEnd } from 'general/helpers/DateHelper'
import Utils from './Utils'

// Global variables
const Global = {
  //dungna
  gDefaultPagination: 20,
  gNeedToRefreshCompanyProfile: false,
  gNeedToRefreshCustomerList: false,
  gNeedToRefreshMoneyTypeList: false,
  gNeedToRefreshCompanyUnitList: false,
  gNeedToRefreshCompanyTitleList: false,
  gOpenModalCreateCompanyUnit: false,
  gNeedToRefreshGoodsGroupList: false,
  gNeedToRefreshUsedInvoicesByDay: false,
  gNeedToRefreshIssuedInvoiceCount: false,
  gNeedToRefreshNotIssuedInvoiceCount: false,
  gNeedToRefreshIssuedInvoiceValue: false,
  gNeedToRefreshStatisticInvoice: false,
  gNeedToRefreshAllInvoice: false,

  gFiltersInvoiceList: {
    q: '',
    page: 1,
    limit: 20,
    serials: null,
    issueStatus: null,
    types: null,
    toNumberInvoice: null,
    fromNumberInvoice: null,
    isSent: null,
    period: PERIOD.THIS_MONTH,
    goodsName: null,
  },

  gFilterListAdjustedInvoice: {
    q: '',
    page: 1,
    limit: 20,
    isReplace: null,
    reportStatus: null,
  },

  gFilterCancelledInvoice: {
    q: '',
    page: 1,
    limit: 20,
    cancelInvoiceStatus: null,
    invoiceErrAnnouceStatus: null,
    reportStatus: null,
  },

  gFiltersErrAnnouceList: {
    q: '',
    page: 1,
    limit: 20,
    issueStatus: null,
    error_annoucements: null,
    invoiceTypeErrAnnouceList: null,
    invoiceErrAnnouceStatus: null,
    serials: null,
    invoiceType: null,
  },

  gFiltersTaxErrorAnnouceList: {
    q: '',
    page: 1,
    limit: 20,
    issueStatus: null,
    error_annoucements: null,
    invoiceTypeErrAnnouceList: null,
    invoiceErrAnnouceStatus: null,
    serials: null,
    invoiceType: null,
  },

  gFilterListReplacedInvoice: {
    q: '',
    page: 1,
    limit: 20,
    statusReleaseInvoiceReplace: null,
    isSent: null,
    errAnnouceIssueStatus: null,
  },

  gFiltersGeneralReport: {
    startDate: dayjs().subtract(29, 'days').format('DD/MM/YYYY'),
    endDate: dayjs().format('DD/MM/YYYY'),
    period: 'RECENT_30_DAYS',
  },

  gFiltersUsedInvoiceStatistic: {
    page: 0,
    limit: 20,
  },

  gFiltersCustomerList: {
    page: 0,
    limit: 20,
    type: '',
    status: '',
  },

  gFiltersMoneyTypeList: {
    q: '',
    page: 0,
    limit: 20,
    status: '',
  },

  gFiltersCompanyTitleList: {
    q: '',
    page: 0,
    limit: 20,
    status: '',
  },

  gFiltersCompanyUnitList: {
    text: '',
    status: '',
    page: 1,
    limit: 20,
  },

  gFiltersEmployeeList: {
    q: '',
    status: '',
    page: 0,
    limit: 20,
  },

  gFiltersGoodsGroupList: {
    q: '',
    status: '',
    page: 0,
    limit: 20,
  },

  gFiltersGoodsList: {
    q: '',
    status: '',
    page: 0,
    limit: 20,
    goodsGroupId: '',
  },

  // trung
  gExecutingSignErrAnnouceTimeOut: null,
  gExecutingSignInvoiceTimeOut: null,

  //huy
  gExecutingSignRegistrationTimeOut: null,
  gExecutingSignInvoiceReportTimeOut: null,
  gFiltersTemplateInvoiceList: {
    q: '',
    page: 1,
    limit: 20,
  },
  gFiltersRegistrationList: {
    q: '',
    page: 0,
    limit: 20,
    status: null,
  },

  gFiltersUsedInvoicesByDay: {
    period: PERIOD.RECENT_30_DAYS,
    startDate: dayjs().subtract(29, 'days'),
    endDate: dayjs(),
  },

  gFiltersIssuedInvoiceCount: {
    period: PERIOD.RECENT_30_DAYS,
    startDate: dayjs().subtract(29, 'days'),
    endDate: dayjs(),
  },

  gFiltersNotIssueInvoiceCount: {
    period: PERIOD.RECENT_30_DAYS,
    startDate: dayjs().subtract(29, 'days'),
    endDate: dayjs(),
  },

  gFiltersIssuedInvoiceValue: {
    period: PERIOD.RECENT_30_DAYS,
    startDate: dayjs().subtract(29, 'days'),
    endDate: dayjs(),
  },

  gFiltersAllInvoice: {
    period: PERIOD.RECENT_30_DAYS,
    startDate: dayjs().subtract(29, 'days'),
    endDate: dayjs(),
    invoiceClassify: 'ALL',
  },

  gFiltersInvoiceValue: {
    period: PERIOD.RECENT_30_DAYS,
    startDate: dayjs().subtract(29, 'days'),
    endDate: dayjs(),
  },

  gFiltersStatisticInvoice: {
    period: PERIOD.TODAY,
    startDate: dayjs(),
    endDate: dayjs(),
  },
  // report
  gFiltersUsedInvoices: {
    page: 1,
    limit: 20,
    q: '',
    startDate: Utils.getDateFilterOption(
      AppData.dateFilter.baMuoiNgayGanNhat,
    )[0].format('YYYY-MM-DD'),
    endDate: Utils.getDateFilterOption(
      AppData.dateFilter.baMuoiNgayGanNhat,
    )[1].format('YYYY-MM-DD'),
    serials: null,
    fromNumberInvoice: null,
    toNumberInvoice: null,
    accountIds: null,
    statusReleaseInvoiceReplace: null,
    types: null,
    isSents: null,
  },
  gFiltersDetailInvoices: {
    page: 1,
    limit: 20,
    q: '',
    startDate: Utils.getDateFilterOption(
      AppData.dateFilter.baMuoiNgayGanNhat,
    )[0].format('YYYY-MM-DD'),
    endDate: Utils.getDateFilterOption(
      AppData.dateFilter.baMuoiNgayGanNhat,
    )[1].format('YYYY-MM-DD'),
    serials: null,
    fromNumberInvoice: null,
    toNumberInvoice: null,
  },
  gFiltersIssuedInvoices: {
    // page: 1,
    limit: 20,
    // q: '',
    startDate: Utils.getDateFilterOption(
      AppData.dateFilter.baMuoiNgayGanNhat,
    )[0].format('YYYY-MM-DD'),
    endDate: Utils.getDateFilterOption(
      AppData.dateFilter.baMuoiNgayGanNhat,
    )[1].format('YYYY-MM-DD'),
  },
  gFilterInvoicesByTax: {
    // page: 1,
    // limit: 20,
    // q: '',
    startDate: Utils.getDateFilterOption(
      AppData.dateFilter.baMuoiNgayGanNhat,
    )[0].format('YYYY-MM-DD'),
    endDate: Utils.getDateFilterOption(
      AppData.dateFilter.baMuoiNgayGanNhat,
    )[1].format('YYYY-MM-DD'),
  },
  gFiltersExportReportFile: {
    period: PERIOD.RECENT_30_DAYS,
    serial: null,
    type: null,
    issueStatus: null,
    startDate: transformToStartAndEnd('RECENT_30_DAYS')[0].format(),
    endDate: transformToStartAndEnd('RECENT_30_DAYS')[1].format(),
    exportTemplateId: SelectOptions.exportTemplate[0].value,
  },

  gFiltersReport: {
    serial: '',
    fromNumberInvoice: '',
    toNumberInvoice: '',
    accountIds: '',
    statusReleaseInvoiceReports: '',
    types: '',
    isSents: '',
    startDate: Utils.getDateFilterOption(
      AppData.dateFilter.baMuoiNgayGanNhat,
    )[0].format('YYYY-MM-DD'),
    endDate: Utils.getDateFilterOption(
      AppData.dateFilter.baMuoiNgayGanNhat,
    )[1].format('YYYY-MM-DD'),
  },
  gFilterTicketRoute: {
    page: 1,
    limit: 20,
    q: '',
  },
  gFilterTicketCheckPoint: {
    page: 1,
    limit: 20,
    q: '',
  },
  gFilterTicketReport: {
    page: 1,
    limit: 20,
    q: '',
    serials: null,
    accountIds: null,
    typeRelease: null,
    typeTicket: null,
    typeSendCustomer: null,
    typeSendCQTs: null,
    classify: 'ALL',
  },
  gFilterTicketPublished: {
    startDate: dayjs().startOf('month').format('DD/MM'),
    endDate: dayjs().endOf('month').format('DD/MM'),
    year: dayjs().format('YYYY'),
    period: SelectOptions.ticketPublishedDateOptions[0].value,
    limit: 20,
    page: 1,
  },
  gFilterListIssuedTicket: {
    q: '',
    page: 1,
    limit: 20,
    serials: null,
    isSent: null,
    accountIds: null,
    typeTickets: null,
    typeRelease: null,
    serialDevices: null,
    period: 'RECENT_30_DAYS',
    classify: 'HD',
  },
  gFilterListIssuedTicketFromPOS: {
    q: '',
    page: 1,
    limit: 20,
    serials: null,
    isSent: null,
    accountIds: null,
    typeTickets: null,
    typeRelease: null,
    serialDevices: null,
    period: 'RECENT_30_DAYS',
    classify: 'MTT',
    sendTaxStatus: null,
  },
  gFilterTaxMessageRegistrationList: {
    q: '',
    page: 0,
    limit: 20,
    status: null,
  },
  gFilterListTaxMessageInvoice: {
    q: '',
    page: 1,
    limit: 20,
    issueStatus: null,
    typeRelease: null,
  },
  gFilterTicketExportedList: {
    startDate: dayjs().subtract(29, 'days'),
    endDate: dayjs(),
    accountIds: null,
    typeTickets: null,
    typeSendCQTs: null,
    typeReleases: null,
    serials: null,
  },
  gFilterCanceledTicket: {
    q: '',
    page: 1,
    limit: 20,
    reportStatus: null,
    invoiceErrAnnouceStatus: null,
  },

  gFiltersTemplateTicketList: {
    q: '',
    page: 1,
    limit: 20,
    invoiceType: 'TICKET',
  },
  gFilterReplacedTicket: {
    q: '',
    page: 1,
    limit: 20,
    isSent: null,
  },
  gFiltersInvoiceList_findmodal: {
    q: '',
    period: PERIOD.RECENT_30_DAYS,
    page: 1,
    limit: 20,
    startDate: transformToStartAndEnd('RECENT_30_DAYS')[0].format(),
    endDate: transformToStartAndEnd('RECENT_30_DAYS')[1].format(),
    classify: 'HD',
  },
  gFilterAdjustedTicketList: {
    q: '',
    page: 1,
    limit: 20,
  },

  gFilterErrorTicketAnnoucement: {
    q: '',
    page: 1,
    limit: 20,
    issueStatus: null,
    error_annoucements: null,
    ticketTypeErrAnnouce: null,
    ticketErrAnnouceStatus: null,
    serials: null,
    invoiceType: null,
  },

  gFilterListTransactions: {
    q: '',
    page: 1,
    limit: 20,
    stationCode: null,
    nozzleCode: null,
    status: null,
    startDate: dayjs().subtract(29, 'days').startOf('day'),
    endDate: dayjs().endOf('day'),
    totalAmountStart: null,
    totalAmountEnd: null,
    period: PERIOD.RECENT_30_DAYS,
    productCode: null,
  },

  gFilterListInvoiceFromPOS: {
    q: '',
    page: 1,
    limit: 20,
    serials: null,
    fromNumberInvoice: null,
    toNumberInvoice: null,
    issueStatus: null,
    classify: 'MTT',
    sendTaxStatus: null,
    period: PERIOD.THIS_MONTH,
  },

  gFilterTaxMessageInvoiceFromPos: {
    page: 1,
    limit: 20,
    startDate: dayjs().subtract(29, 'days').startOf('day'),
    endDate: dayjs().endOf('day'),
    statusRelease: null,
  },

  gFilterTaxMessageTicketFromPos: {
    page: 1,
    limit: 20,
    startDate: dayjs().subtract(29, 'days').startOf('day'),
    endDate: dayjs().endOf('day'),
    statusRelease: null,
    invoiceType: 'TICKET',
  },

  gCheckTicketPortal: {
    q: '',
    page: 1,
    limit: 20,
  },

  gFilterGoodsDeliveryNoteList: {
    q: '',
    page: 1,
    limit: 20,
    fromNumberInvoice: null,
    toNumberInvoice: null,
    serials: null,
    issueStatus: null,
    classify: 'PXK',
    confirmed: null,
    unit: null,
    isSent: null,
    type: null,
  },
}

export default Global

/**
 * @typedef {keyof typeof Global} GlobalFilterType
 */
