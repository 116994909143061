import ticketDraftApi from 'api/ticektDraftApi'
import { setAppSpinning } from 'app/appSlice'
import TableAction from 'general/components/GroupButton/TableAction'
import { IconAction } from 'general/components/GroupButton/styles'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import './styles.scss'

ListDraftTicketDropdown.propTypes = {
  //   draftTickets: PropTypes.array,
}

ListDraftTicketDropdown.defaultProps = {
  //   draftTickets: [],
}
function ListDraftTicketDropdown(props) {
  const {
    fetchListDraftTickets,
    draftTickets,
    handleSelectDraftTicket,
    handleChangeTableData,
    classify,
  } = props

  const dispatch = useDispatch()

  const deleteDraftTicketById = async (ticketDraftId) => {
    try {
      dispatch(setAppSpinning(true))
      const res = await ticketDraftApi.deleteById(ticketDraftId)
      if (res?.result === 'success') {
        fetchListDraftTickets()
        ToastHelper.showSuccess('Xoá thành công')
      }
    } catch (err) {
      ToastHelper.showError('Xoá không thành công')
    } finally {
      dispatch(setAppSpinning(false))
    }
  }

  const deleteAllDraftTicket = async () => {
    try {
      dispatch(setAppSpinning(true))
      const res = await ticketDraftApi.deleteAll({ classify })
      if (res?.result === 'success') {
        fetchListDraftTickets()
        ToastHelper.showSuccess('Xoá thành công')
      }
    } catch (err) {
      ToastHelper.showError('Xoá không thành công')
    } finally {
      dispatch(setAppSpinning(false))
    }
  }

  return (
    <div className="listDraftTicketDropdown">
      <div className="body">
        {draftTickets.map((draftTicket, index) => {
          const { name, createdAt, ticketDraftItems, ticketDraftId } =
            draftTicket
          let quantity = ticketDraftItems?.length
          // for (let item of ticketDraftItems) {
          //   quantity += item.quantity
          // }
          return (
            <div
              className="draftTicketDropdownItem"
              key={index}
              onClick={() => {
                handleSelectDraftTicket(draftTicket)
              }}
            >
              <div className="ticketInfo">
                <span className="ticketName">{name}</span>
                <span className="ticketDate">
                  {Utils.formatDateTime(createdAt)}
                </span>
              </div>
              <div className="ticketQuantity">
                <div className="iconDelete">
                  <TableAction
                    titleActionText="Xoá"
                    icon={
                      <IconAction
                        fontSize="14px"
                        className="fa-regular fa-trash"
                      />
                    }
                    onClick={(e) => {
                      e.stopPropagation()
                      deleteDraftTicketById(ticketDraftId)
                    }}
                  />
                </div>
                <span>{quantity} vé</span>
              </div>
            </div>
          )
        })}
      </div>
      <div
        className="footer"
        onClick={deleteAllDraftTicket}
        style={
          _.isEmpty(draftTickets)
            ? { pointerEvents: 'none', opacity: '0.5' }
            : null
        }
      >
        <IconAction className="fa-regular fa-trash" />
        Xoá tất cả vé nháp
      </div>
    </div>
  )
}

export default ListDraftTicketDropdown
