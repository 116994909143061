import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import Empty from 'general/components/Empty'
import Loading from 'general/components/Loading'
import AppData from 'general/constants/AppData'
import AppResource from 'general/constants/AppResource'
import Utils from 'general/utils/Utils'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

ChartJS.register(ArcElement, Tooltip, Legend)
CardDonutChartV2.propTypes = {
  additionalClassName: PropTypes.string,
  chartSeries: PropTypes.array,
  chartLabels: PropTypes.array,
  title: PropTypes.string,
  chartColors: PropTypes.array,
  additonalElement: PropTypes.element,
  loading: PropTypes.bool,
  subTitle: PropTypes.string,
  toolbarElement: PropTypes.element,
  headerSidebar: PropTypes.element,
}

CardDonutChartV2.defaultProps = {
  additionalClassName: '',
  chartSeries: [],
  chartLabels: [],
  title: '',
  chartColors: AppData.chartColors,
  additonalElement: <></>,
  loading: false,
  subTitle: '',
  toolbarElement: null,
  headerSidebar: null,
}

/**
 *
 * @param {{
 * additionalClassName: string,
 * chartLabels: string[],
 * chartSeries: number[],
 * title: string,
 * }} props
 * @returns
 */
function CardDonutChartV2(props) {
  // MARK: --- Params ---
  const {
    additionalClassName,
    chartLabels,
    chartSeries,
    title,
    chartColors,
    loading,
    subTitle,
    headerSidebar,
  } = props
  const { t } = useTranslation()
  const options = useMemo(
    () => ({
      // responsive: true,
      devicePixelRatio: 2,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            font: {
              size: 12,
            },
            usePointStyle: true,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return Utils.formatNumber(context.parsed)
            },
          },
        },
      },
    }),
    [],
  )

  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: '',
        data: chartSeries,
        backgroundColor: chartColors,
        borderColor: '#fff',
        borderWidth: 1,
      },
    ],
  }

  useEffect(() => {
    // console.log(`options`, options)
    // console.log(`data`, data)
    // console.log('chartSeries', chartSeries)
  }, [options, data]) // Chỉ chạy khi `count` thay đổi

  return (
    <div className="CardDonutChartV2 h-100">
      <div className={`card card-custom card-stretch ${additionalClassName}`}>
        {/* card header */}
        <div className="card-header border-0 py-6 d-flex flex-wrap align-items-center justify-content-between">
          <div
            className={`${
              headerSidebar
                ? 'd-flex flex-wrap justify-content-between w-100'
                : ''
            }`}
          >
            {/* card title */}
            <h3 className="card-title">
              <span className="card-label font-weight-bolder font-size-h4 text-dark-75">
                {title}
              </span>
              <span className="d-block text-muted mt-2 font-size-base">
                {subTitle}
              </span>
            </h3>
            {headerSidebar}
          </div>
          {/* <div className="w-100 d-flex justify-content-end">
          </div> */}
        </div>
        {/* card body */}
        <div className="card-body d-flex align-items-center justify-content-center pb-3 pt-0 flex-wrap">
          <div className="w-100 h-lg-100">
            {loading ? (
              <div className="d-flex align-items-center justify-content-center h-100 w-100">
                <Loading showBackground={false} />
              </div>
            ) : chartSeries.length > 0 ||
              !chartSeries?.every((item) => item === 0) ? (
              <Doughnut options={options} data={data} />
            ) : (
              <div className="pt-12">
                <Empty
                  text={t('NoData')}
                  visible={false}
                  imageEmpty={AppResource.icons.icEmptyBox}
                  imageEmptyPercentWidth={50}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardDonutChartV2
