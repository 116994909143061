const { default: axiosClient } = require('./axiosClient')
const taxpayerApi = {
  find: (body) => {
    const url = '/taxpayer/find'
    return axiosClient.post(url, { body })
  },
  save: (body) => {
    const url = '/taxpayer/save'
    return axiosClient.post(url, { body })
  },
  remove: (params) => {
    const url = '/taxpayer/remove'
    return axiosClient.delete(url, { params })
  },
  findById: (params) => {
    const url = '/taxpayer/findById'
    return axiosClient.get(url, { params })
  },
  automatically: (params) => {
    const url = '/taxpayer/automatically'
    return axiosClient.get(url, { params })
  },
  deleteAll: (params) => {
    const url = '/taxpayer/deleteAll'
    return axiosClient.delete(url, { params })
  },
  restoreAll: (params) => {
    const url = '/taxpayer/restoreAll'
    return axiosClient.get(url, { params })
  },
}
export default taxpayerApi
