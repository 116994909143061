import { DatePicker, Popover, Select, Space, Typography, theme } from 'antd'
import locale from 'antd/es/date-picker/locale/vi_VN'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import SelectOptions from 'general/constants/SelectOptions'
import { transformToStartAndEnd } from 'general/helpers/DateHelper'
import { find, range, toLower } from 'lodash'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomAntButton from '../Button/CustomAntButton'

export default function CustomDatepicker({
  initialPeriod,
  initialStartDate,
  initialEndDate,
  onApply,
  menuPosition,
  menuWidth,
}) {
  const { token } = theme.useToken()
  const t = useTranslation().t
  const [popoverIsOpen, setPopoverIsOpen] = useState(false)
  const handleTogglePopover = (state) => {
    setPopoverIsOpen((prev) => state ?? !prev)
  }
  const formik = useFormik({
    initialValues: {
      period: find(
        SelectOptions.periodFilterOptions,
        (opt) => opt.value === initialPeriod,
      ).value,
      startDate: initialStartDate ?? dayjs().subtract(29, 'days'),
      endDate: initialEndDate ?? dayjs(),
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      onApply(values)
      setClonedValues(values)
      setPopoverIsOpen(false)
    },
  })
  const [clonedvalues, setClonedValues] = useState(formik.values)

  return (
    <Popover
      trigger="click"
      getPopupContainer={(trigger) => trigger.parentElement}
      open={popoverIsOpen}
      onOpenChange={(vis) => handleTogglePopover(vis)}
      placement={menuPosition ?? 'bottom'}
      destroyTooltipOnHide
      arrow={false}
      overlayInnerStyle={{
        width: menuWidth ?? '20rem',
        borderRadius: token.borderRadiusLG,
      }}
      content={
        <Space direction="vertical" className="w-100">
          <Typography.Text strong>{t('Period')}</Typography.Text>
          <Select
            className="w-100"
            notFoundContent="Không tìm thấy lựa chọn"
            placeholder="Chọn hoặc tìm kiếm"
            showSearch
            options={SelectOptions.periodFilterOptions}
            value={formik.values.period}
            onChange={(value) => {
              const dateRange = transformToStartAndEnd(value)
              formik.setFieldValue('period', value)
              formik.setFieldValue('startDate', dateRange[0])
              formik.setFieldValue('endDate', dateRange[1])
            }}
            allowClear={false}
            filterOption={(input, opt) =>
              toLower(opt.label).includes(toLower(input))
            }
            suffixIcon={<i className="fa-regular fa-angle-down"></i>}
          />
          {range(0, 2).map((idx) => (
            <Fragment key={idx}>
              <Typography.Text strong>
                {idx === 0 ? t('FromDate') : t('ToDate')}
              </Typography.Text>
              <DatePicker
                {...formik.getFieldProps(idx === 0 ? 'startDate' : 'endDate')}
                placement="bottomLeft"
                allowClear={false}
                value={formik.values[idx === 0 ? 'startDate' : 'endDate']}
                onChange={(date) => {
                  formik.setFieldValue(
                    idx === 0 ? 'startDate' : 'endDate',
                    date,
                  )
                  formik.setFieldValue('period', 'CUSTOM')
                }}
                changeOnBlur
                locale={locale}
                format={'DD/MM/YYYY'}
                nextIcon={<i className=" fa-regular fa-angle-right"></i>}
                prevIcon={<i className=" fa-regular fa-angle-left"></i>}
                superNextIcon={
                  <i className=" fa-regular fa-angle-double-right"></i>
                }
                superPrevIcon={
                  <i className=" fa-regular fa-angle-double-left"></i>
                }
                style={{
                  width: '100%',
                }}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Fragment>
          ))}
          <CustomAntButton
            text="Áp dụng"
            antProps={{
              type: 'primary',
              block: true,
              onClick: formik.submitForm,
            }}
            fontSizeText={13}
          />
        </Space>
      }
    >
      <CustomAntButton
        text={
          clonedvalues.startDate.format('DD/MM/YYYY') +
          ' - ' +
          clonedvalues.endDate.format('DD/MM/YYYY')
        }
        antProps={{
          type: 'default',
          onClick: handleTogglePopover,
        }}
        customStyle={{
          boxShadow: 'none',
          gap: '5px',
          minWidth: 'auto',
          flexGrow: 1,
        }}
      />
    </Popover>
  )
}
