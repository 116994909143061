import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RightContent, FormCustom } from 'features/TaxDeductionDocuments/styled'
import { TabCustom } from 'features/TaxDeduction/styled'
import Maintenance from 'features/TaxDeduction/component/Maintenance'
import { Form } from 'antd'
import TabSetiingPublic from './TabRight/tabSetiingPublic'
import TabBackground from './TabRight/tabBackground'
import TabContent from './TabRight/tabContent'
import { debounce } from 'lodash'
import ModalCustomField from './TabRight/Modal/modalCustomField'
function RightContentTaxDeductionDocuments({
  form,
  tabActiveKey,
  onChangeTab,
  listDataTemplate,
  resetForm,
  imgLogo,
  setImgLogo,
  chonseBorder,
  setChonseBorder,
  fileBorderBackground,
  setFileBorderBackground,
  fileContentBackground,
  setFileContentBackground,
  fileOwnWallpaper,
  setFileOwnWallpaper,
  colorBorderBackground,
  setColorBorderBackground,
  setFormState,
  backgroundImg,
  setBackgroundImg,
  chonseContent,
  setChonseContent,
  contentBackgroundColor,
  setContentBackgroundColor,
  activeFeild,
  setActiveFeild,
  activeLayout,
  activeTd,
  listContentFeild,
  setListContentFeild,
  setActiveTd,
  setActiveLayout,
  language,
  formState,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [visibleAddFeild, setVisibleAddFeild] = useState(false)
  const [dataAddFeild, setDataAddFeild] = useState(null)
  // Sử dụng useCallback để tạo hàm debounce
  const debouncedSetFormState = useCallback(
    debounce((dataForm) => {
      console.log('dataForm', dataForm)
      console.log('dataFormNew', formState)
      setFormState(dataForm)
    }, 300), // 300ms delay
    [],
  )

  const onValuesChange = (changedValues, allValues) => {
    console.log('allValues', allValues)
    debouncedSetFormState(allValues)
  }

  useEffect(() => {
    if (tabActiveKey === '2') {
      onChangeTab('1')
    }
  }, [])

  return (
    <RightContent>
      <FormCustom form={form} onValuesChange={onValuesChange}>
        <TabCustom
          activeKey={tabActiveKey != null ? tabActiveKey : '1'}
          onChange={onChangeTab}
        >
          <TabCustom.TabPane
            tab={t('taxDeductionDocuments.settingPublic')}
            key="1"
          >
            <TabSetiingPublic
              form={form}
              listDataTemplate={listDataTemplate}
              listContentFeild={listContentFeild}
              setListContentFeild={setListContentFeild}
              resetForm={resetForm}
              imgLogo={imgLogo}
              setImgLogo={setImgLogo}
              tabActiveKey={tabActiveKey}
              activeLayout={activeLayout}
              setActiveLayout={setActiveLayout}
              activeTd={activeTd}
              setActiveTd={setActiveTd}
              activeFeild={activeFeild}
              setActiveFeild={setActiveFeild}
              formState={formState}
            />
          </TabCustom.TabPane>
          <TabCustom.TabPane
            tab={t('taxDeductionDocuments.background')}
            key="2"
          >
            <TabBackground
              form={form}
              resetForm={resetForm}
              tabActiveKey={tabActiveKey}
              chonseBorder={chonseBorder}
              setChonseBorder={setChonseBorder}
              fileBorderBackground={fileBorderBackground}
              setFileBorderBackground={setFileBorderBackground}
              fileContentBackground={fileContentBackground}
              setFileContentBackground={setFileContentBackground}
              fileOwnWallpaper={fileOwnWallpaper}
              setFileOwnWallpaper={setFileOwnWallpaper}
              colorBorderBackground={colorBorderBackground}
              setColorBorderBackground={setColorBorderBackground}
              backgroundImg={backgroundImg}
              setBackgroundImg={setBackgroundImg}
              chonseContent={chonseContent}
              setChonseContent={setChonseContent}
              contentBackgroundColor={contentBackgroundColor}
              setContentBackgroundColor={setContentBackgroundColor}
              setFormState={setFormState}
              formState={formState}
            />
          </TabCustom.TabPane>
          <TabCustom.TabPane tab={t('taxDeductionDocuments.content')} key="3">
            <TabContent
              form={form}
              resetForm={resetForm}
              activeFeild={activeFeild}
              setActiveFeild={setActiveFeild}
              activeLayout={activeLayout}
              setActiveLayout={setActiveLayout}
              activeTd={activeTd}
              listContentFeild={listContentFeild}
              setListContentFeild={setListContentFeild}
              setActiveTd={setActiveTd}
              listDataTemplate={listDataTemplate}
              visibleAddFeild={visibleAddFeild}
              setVisibleAddFeild={(value) => {
                setVisibleAddFeild(value)
              }}
              data={dataAddFeild}
              setData={(value) => {
                setDataAddFeild(value)
              }}
              formState={formState}
            />
          </TabCustom.TabPane>
        </TabCustom>
      </FormCustom>
      <ModalCustomField
        formStatet={formState}
        visibleModal={visibleAddFeild}
        listContentFeild={listContentFeild}
        setListContentFeild={setListContentFeild}
        activeTd={activeTd}
        language={language}
        setActiveTd={setActiveTd}
        activeLayout={activeLayout}
        setActiveLayout={setActiveLayout}
        setVisibleModal={(value) => {
          setVisibleAddFeild(value)
        }}
        data={dataAddFeild}
        setData={(value) => {
          setDataAddFeild(value)
        }}
      />
    </RightContent>
  )
}
export default RightContentTaxDeductionDocuments
