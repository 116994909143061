import { TICKET_UPLOAD_HEADER_COLUMNS } from 'general/constants/AppConstants'
import { isArray } from 'lodash'

export const filterColumnsBaseOnTicketTemplate = ({ baseColumns, serial }) => {
  if (!isArray(baseColumns) || !serial) return baseColumns

  let columns = [...baseColumns]
  if (serial.routeType != 'MULTIPLE')
    columns = columns.filter(({ key }) => key != 'tuyenduong')
  if (serial.category_ticket.typeDeduct == 'TT')
    columns = columns.filter(({ key }) => key != 'thuesuatgtgt')
  if (serial.category_ticket.typePrice == 1) {
    columns = columns.filter(({ key }) => key != 'tongtien')
    if (serial.category_ticket.typeDeduct == 'KT')
      columns = columns.filter(({ key }) => key != 'thuesuatgtgt')
  }

  return columns
}

export const getRefObjFromHeaderBaseOnMappingKeyword = (header) => {
  return TICKET_UPLOAD_HEADER_COLUMNS.find(({ mappingKeywords }) =>
    mappingKeywords?.split(';')?.includes(header),
  )
}
export const renderTicket = (reportStatus) => {
  switch (reportStatus) {
    case 1:
      return 'Đã lập, NCC chưa ký'
    case 2:
      // return 'NCC đã ký, Chưa gửi khách hàng'
      return 'NCC đã ký'
    case 3:
      return 'Đã gửi khách hàng, Chờ KH ký'
    case 4:
      return 'KH đã ký'
    default:
      return ''
  }
}
