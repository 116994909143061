import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { v4 as uuid4 } from 'uuid'

export const transformingElements = (arr = []) =>
  isEmpty(arr)
    ? []
    : map(arr, (item) => ({
        id: item?.id ?? uuid4().split('-').join(''),
        name: item.name,
        isAppear: item.isAppear ?? true,
        hidden: item.hidden ?? false,
        disabled: isEmpty(item.name) || item.disabled,
      }))

export const transformingBack = (changed, original) =>
  map(changed, (changedItem) => {
    const found = find(original, { name: changedItem.name })
    return {
      ...found,
      omit: changedItem.hidden ?? false,
    }
  })
