import { useQuery } from '@tanstack/react-query'
import templateInvoiceApi from 'api/templateInvoiceApi'
import _ from 'lodash'
import { useState } from 'react'

const keyFactory = {
  base: { scope: ['shared', 'ticketTemplates'] },
  list: (f) => [
    {
      ...keyFactory.base,
      params: f,
    },
  ],
}

export default function useGetTicketTemplates({ ticketClassify }) {
  const filterParams = {
    invoiceType: 'TICKET',
    page: 1,
    limit: 100,
    q: '',
    invoiceClassify: ticketClassify,
  }
  return useQuery({
    staleTime: 1000 * 50, // Stay fresh for 50 seconds
    refetchOnWindowFocus: true,
    queryKey: keyFactory.list(filterParams),
    queryFn: () => templateInvoiceApi.getTemplateSerials(filterParams),
    select: (res) => {
      // if (res.result !== 'success') return []
      return _.isUndefined(res?.data) || _.isNull(res?.data)
        ? []
        : _.map(res?.data, (item) => ({
            ...item,
            label: item.serial,
            value: item.serial,
            invoiceTemplateId: item?.invoiceTemplateId,
            invoiceTemplateName: item?.name,
          }))
    },
  })
}
