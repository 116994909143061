import { useQuery } from '@tanstack/react-query'
import errAnnouceApi from 'api/errAnnouceApi'
import ticketApi from 'api/ticketApi'
import _get from 'lodash/get'

export const errorTicketKeys = {
  query: ['errorTicket', 'list'],
}

export default function useErrorTicketQuery(
  filterParam,
  currentTab,
  debouncedSearch,
) {
  const whichType =
    currentTab === 0
      ? 'TICKET'
      : currentTab === 1
        ? 'ERR_ANNOUCED'
        : 'NEED_ERR_ANNOUCED'

  const returnQuery = useQuery({
    queryKey: [
      ...errorTicketKeys.query,
      filterParam,
      whichType,
      currentTab,
      debouncedSearch,
    ],
    queryFn: () => {
      return currentTab === 0
        ? errAnnouceApi.list({
            ...filterParam,
            invoiceType: whichType,
            q: debouncedSearch,
          })
        : ticketApi.list({
            ...filterParam,
            ticketType: whichType,
            q: debouncedSearch,
          })
    },
    select: (res) => {
      return {
        total: _get(res, 'count', 0),
        rows: _get(res, 'rows', []),
      }
    },
  })

  return {
    ...returnQuery,
  }
}
