import {
  CANCELLATION_REPORT_STATUS,
  CANCEL_INVOICE_STATUS,
  ERROR_ANNOUCEMENTS,
  ERR_ANNOUCE_STATUS,
  INVOICE_ERR_ANNOUCE_STATUS,
  INVOICE_STATUS,
  INVOICE_TYPE,
  PERIOD,
  REGISTRATION_STATUS,
  RESOURCE_TYPE,
  SEND_MAIL_STATUS,
  SEND_TAX_STATUS,
  SUMMARY_INVOICE_STATUS,
  STATUS_RELEASE,
  SYSTEM_TYPE_INVOICE,
  TICKET_RELEASE_STATUS,
  TICKET_MAIL_SENT_AUTHORITY_STATUS,
  TICKET_MAIL_SENT_CUSTOMER_STATUS,
  TICKET_PUBLISHED_STATUS,
  TICKET_STATUS,
  TICKET_TYPE_ERR_ANNOUCEMENT,
  TYPE_TIME,
  CONFIRM_STATUS,
  INVOICE_CLASSIFY,
  TICKET_CLASSIFY,
} from './AppConstants'

const SelectOptions = {
  GTGT: [
    { value: '0', label: '0%' },
    { value: '5', label: '5%' },
    { value: '8', label: '8%' },
    { value: '10', label: '10%' },
    { value: 'KCT', label: 'KCT' },
    { value: 'KHAC', label: 'KHAC' },
    { value: 'KKKNT', label: 'KKKNT' },
  ],
  TaxReductionRate: [
    // { value: 0, label: '0%' },
    { value: 1, label: '1%' },
    { value: 2, label: '2%' },
    { value: 3, label: '3%' },
    { value: 5, label: '5%' },
  ],
  paymentMethod: [
    { value: 1, label: 'TM/CK' },
    { value: 2, label: 'Tiền mặt' },
    { value: 3, label: 'Chuyển khoản' },
    { value: 4, label: 'Đối trừ công nợ' },
    { value: 5, label: 'Không thu tiền' },
    { value: 6, label: 'Xuất hàng cho chi nhánh' },
    { value: 7, label: 'Hàng biếu tặng' },
    { value: 8, label: 'Cấn trừ công nợ' },
    { value: 9, label: 'Trả hàng' },
    { value: 10, label: 'Hàng khuyến mại không thu tiền' },
    { value: 11, label: 'Xuất sử dụng' },
    { value: 12, label: 'D/A' },
    { value: 13, label: 'D/P' },
    { value: 14, label: 'TT' },
    { value: 15, label: 'L/C' },
    { value: 16, label: 'Công nợ' },
    { value: 17, label: 'Nhờ thu' },
    { value: 18, label: 'TM/CK/B' },
    { value: 19, label: 'Thẻ tín dụng' },
    { value: 20, label: 'CK/Cấn trừ công nợ' },
    { value: 21, label: 'Hàng hóa' },
    { value: 22, label: 'Hàng mẫu' },
    { value: 23, label: 'Thẻ tín dụng' },
    { value: 24, label: 'Bù trừ công nợ' },
    { value: 25, label: 'Qua LAZADA' },
    { value: 26, label: 'Qua TIKI' },
    { value: 27, label: 'Xuất hóa đơn nội bộ' },
    { value: 28, label: 'T/T' },
    { value: 29, label: 'TTR' },
    { value: 30, label: 'TM/CK/Qua LAZADA' },
    { value: 31, label: 'TM/CK/Qua TIKI' },
    { value: 32, label: 'TM/The' },
    { value: 33, label: 'CK/The' },
    { value: 34, label: 'TM/CK/The' },
    { value: 35, label: 'CK/LC' },
    { value: 36, label: 'L/C at sight - Thư tín dụng trả ngay' },
    { value: 37, label: 'Xuất hàng hoá, dịch vụ trả thay lương' },
    { value: 38, label: 'CAD - Giao chứng từ trả tiền' },
    { value: 39, label: 'Qua SHOPEE' },
    { value: 40, label: 'TM/CK/Qua SHOPEE' },
    { value: 41, label: 'Cho vay/Cho mượn' },
    { value: 42, label: 'Ví điện tử' },
    { value: 43, label: 'Điểm' },
    { value: 44, label: 'Voucher' },
    { value: 45, label: 'LC/TT' },
    { value: 46, label: 'Qua TIKTOK' },
    { value: 47, label: 'TM/CK/Qua TIKTOK' },
    { value: 48, label: 'Tạm xuất tái nhập' },
    { value: 49, label: 'Tạm nhập tái xuất' },
  ],
  typeOfGood: [
    { value: 1, label: 'Hàng hóa, dịch vụ' },
    { value: 2, label: 'Hàng khuyến mại' },
    { value: 3, label: 'Chiết khấu thương mại' },
    { value: 4, label: 'Ghi chú/diễn giải' },
  ],
  typeOfGoodInDeliveryBill: [
    { value: 1, label: 'Hàng hóa, dịch vụ' },
    { value: 4, label: 'Ghi chú/diễn giải' },
  ],
  dataType: [
    { value: 'TEXT', label: 'Chữ' },
    { value: 'CURRENCY', label: 'Tiền tệ' },
    { value: 'NUMBER', label: 'Số lượng' },
    { value: 'DATE', label: 'Ngày' },
    { value: 'PERCENT', label: 'Phần trăm' },
  ],
  discountType: [
    { value: 0, label: 'Không chiết khấu' },
    { value: 1, label: 'Theo từng mặt hàng' },
    { value: 2, label: 'Theo tổng giá trị' },
  ],
  discountTypeMultipleTaxRate: [
    { value: 0, label: 'Không chiết khấu' },
    { value: 1, label: 'Theo từng mặt hàng' },
  ],
  // invoiceType: [
  //   { value: 'TM/CK', label: '1. Hóa đơn điện tử theo Nghị định số 123/2020/NĐ-CP' },
  //   {
  //     value: '5%',
  //     label:
  //       '2. Hóa đơn điện tử có mã xác thực của cơ quan thuế theo Quyết định số 1209/QĐ-BTC ngày 23 tháng 6 năm 2015 và Quyết định số 2660/QĐ-BTC ngày 14 tháng 12 năm 2016 của Bộ Tài chính (Hóa đơn có mã xác thực của CQT theo Nghị định số 51/2010/NĐ-CP và Nghị định số 04/2014/NĐ-CP)',
  //   },
  //   {
  //     value: '10%',
  //     label:
  //       '3. Các loại hóa đơn theo Nghị định số 51/2010/NĐ-CP và Nghị định số 04/2014/NĐ-CP (Trừ hóa đơn điện tử có mã xác thực của cơ quan thuế theo Quyết định số 1209/QĐ-BTC và Quyết định số 2660/QĐ-BTC)',
  //   },
  // ],
  currency: [
    { value: 'VNĐ', label: 'VNĐ', exchangeRate: 1, name: 'đồng' },
    // { value: 'USD', label: 'USD', exchangeRate: 23.7, unit: 'đô la mỹ' },
    // { value: 'EUR', label: 'EUR', exchangeRate: 25.8, unit: 'euro' },
    // { value: 'CNY', label: 'CNY', exchangeRate: 3.39, unit: 'nhân dân tệ' },
  ],
  issueStatus: [
    { value: null, label: 'Tất cả trạng thái' },
    { value: INVOICE_STATUS.NOT_SENT_TO_AUTHORITY, label: 'Chưa phát hành' },
    { value: INVOICE_STATUS.WAITING_FOR_AUTHORITY, label: 'CQT đang xử lý' },
    { value: INVOICE_STATUS.SENDING_TAX, label: 'Đang gửi CQT' },
    { value: INVOICE_STATUS.AUTHORITY_CODE_GIVEN, label: 'Đã cấp mã' },
    {
      value: INVOICE_STATUS.AUTHORITY_CODE_NOT_GIVEN,
      label: 'Từ chối cấp mã',
    },
    { value: INVOICE_STATUS.AUTHORITY_ACCEPT, label: 'CQT chấp nhận' },
    { value: INVOICE_STATUS.AUTHORITY_DENY, label: 'CQT từ chối' },
    { value: INVOICE_STATUS.SEND_ERROR, label: 'Gửi lỗi' },
    { value: INVOICE_STATUS.SIGN_ERROR, label: 'Ký lỗi' },
  ],
  taxMessageInvoiceStatus: [
    { value: null, label: 'Tất cả trạng thái' },
    { value: INVOICE_STATUS.WAITING_FOR_AUTHORITY, label: 'CQT đang xử lý' },
    { value: INVOICE_STATUS.AUTHORITY_CODE_GIVEN, label: 'Đã cấp mã' },
    {
      value: INVOICE_STATUS.AUTHORITY_CODE_NOT_GIVEN,
      label: 'Từ chối cấp mã',
    },
    { value: INVOICE_STATUS.AUTHORITY_ACCEPT, label: 'CQT chấp nhận' },
    { value: INVOICE_STATUS.AUTHORITY_DENY, label: 'CQT từ chối' },
  ],
  invoiceType: [
    {
      value: null,
      label: 'Tất cả loại hóa đơn',
    },
    {
      value: INVOICE_TYPE.ORIGINAL,
      label: 'Hóa đơn gốc',
    },
    { value: INVOICE_TYPE.REPLACE, label: 'Thay thế' },
    {
      value: INVOICE_TYPE.ADJUST,
      label: 'Điều chỉnh',
    },
    {
      value: INVOICE_TYPE.CANCELLED,
      label: 'Đã huỷ',
    },
    {
      value: INVOICE_TYPE.REPLACED,
      label: 'Đã bị thay thế',
    },
    {
      value: INVOICE_TYPE.ADJUSTED,
      label: 'Đã bị điều chỉnh',
    },
    {
      value: INVOICE_TYPE.DELETED,
      label: 'Đã bị xóa',
    },
  ],
  invoiceTypeHDChuaLapTBSS: [
    {
      value: null,
      label: 'Tất cả',
    },
    // {
    //   value: INVOICE_TYPE.ORIGINAL,
    //   label: 'Hóa đơn gốc',
    // },
    //{ value: INVOICE_TYPE.REPLACE, label: 'Thay thế' },
    // {
    //   value: INVOICE_TYPE.ADJUST,
    //   label: 'Điều chỉnh',
    // },
    {
      value: TICKET_STATUS.CANCELLED,
      label: 'Đã huỷ',
    },
    {
      value: TICKET_STATUS.REPLACED,
      label: 'Đã bị thay thế',
    },
    {
      value: TICKET_STATUS.ADJUSTED,
      label: 'Đã bị điều chỉnh',
    },
    // {
    //   value: INVOICE_TYPE.DELETED,
    //   label: 'Đã bị xóa',
    // },
  ],
  invoiceTypeErrAnnouce: [
    {
      value: 1,
      label: 'Huỷ',
    },
    { value: 2, label: 'Điều chỉnh' },
    {
      value: 3,
      label: 'Thay thế',
    },
    {
      value: 4,
      label: 'Giải trình',
    },
  ],
  _ticketTypeErrAnnouce: [
    {
      value: 'CANCEL',
      label: 'Huỷ',
    },
    { value: 'ADJUST', label: 'Điều chỉnh' },
    {
      value: 'REPLACE',
      label: 'Thay thế',
    },
    {
      value: 'EXPLANATION',
      label: 'Giải trình',
    },
  ],
  invoiceTypeErrAnnouceList: [
    {
      value: null,
      label: 'Tất cả',
    },
    {
      value: 1,
      label: 'Huỷ',
    },
    { value: 2, label: 'Điều chỉnh' },
    {
      value: 3,
      label: 'Thay thế',
    },
    {
      value: 4,
      label: 'Giải trình',
    },
  ],
  errAnnouceIssueStatus: [
    { value: ERR_ANNOUCE_STATUS.ALL, label: 'Tất cả trạng thái' },
    {
      value: ERR_ANNOUCE_STATUS.NOT_SENT_TO_AUTHORITY,
      label: 'Chưa phát hành',
    },
    {
      value: ERR_ANNOUCE_STATUS.WAITING_FOR_AUTHORITY,
      label: 'CQT đang xử lý',
    },
    { value: ERR_ANNOUCE_STATUS.AUTHORITY_ACCEPT, label: 'CQT chấp nhận' },
    { value: ERR_ANNOUCE_STATUS.AUTHORITY_DENY, label: 'CQT từ chối' },
    { value: ERR_ANNOUCE_STATUS.SEND_ERROR, label: 'Gửi lỗi' },
  ],
  registrationStatus: [
    { value: null, label: 'Tất cả trạng thái' },
    {
      value: REGISTRATION_STATUS.DRAFT,
      label: 'Chưa gửi CQT',
    },
    {
      value: REGISTRATION_STATUS.SENT_TAX,
      label: 'Đã gửi CQT',
    },
    { value: REGISTRATION_STATUS.TAX_ACCEPT, label: 'CQT chấp nhận' },
    { value: REGISTRATION_STATUS.TAX_NOT_ACCEPT, label: 'CQT từ chối' },
    { value: REGISTRATION_STATUS.TAX_RECEIVE, label: 'CQT tiếp nhận' },
    {
      value: REGISTRATION_STATUS.TAX_NOT_RECEIVE,
      label: 'CQT không tiếp nhận',
    },
    {
      value: REGISTRATION_STATUS.TAX_NOT_VALID,
      label: 'Thông điệp không hợp lệ',
    },
  ],

  taxMessageErrAnnouceIssueStatus: [
    { value: null, label: 'Tất cả trạng thái' },
    // { value: ERR_ANNOUCE_STATUS.NOT_SENT_TO_AUTHORITY, label: 'Chưa phát hành' },
    {
      value: ERR_ANNOUCE_STATUS.WAITING_FOR_AUTHORITY,
      label: 'CQT đang xử lý',
    },
    { value: ERR_ANNOUCE_STATUS.AUTHORITY_ACCEPT, label: 'CQT đã xử lý' },
    { value: ERR_ANNOUCE_STATUS.AUTHORITY_DENY, label: 'CQT từ chối' },
  ],

  cancelInvoiceStatus: [
    { value: CANCEL_INVOICE_STATUS.ALL, label: 'Tất cả trạng thái HĐ' },
    { value: CANCEL_INVOICE_STATUS.CANCELLED, label: 'Đã huỷ hoá đơn' },
    { value: CANCEL_INVOICE_STATUS.NOT_CANCELLED, label: 'Chưa huỷ hoá đơn' },
  ],

  invoiceErrAnnouceStatus: [
    {
      value: INVOICE_ERR_ANNOUCE_STATUS.ALL,
      label: 'Tất cả trạng thái TBSS',
    },
    {
      value: INVOICE_ERR_ANNOUCE_STATUS.NOT_CREATED,
      label: 'Chưa tạo TBSS',
    },
    {
      value: INVOICE_ERR_ANNOUCE_STATUS.NOT_SENT_TO_AUTHORITY,
      label: 'Chưa gửi TBSS',
    },
    {
      value: INVOICE_ERR_ANNOUCE_STATUS.WAITING_FOR_AUTHORITY,
      label: 'CQT đang xử lý',
    },
    {
      value: INVOICE_ERR_ANNOUCE_STATUS.AUTHORITY_DENY,
      label: 'CQT từ chối huỷ',
    },
    {
      value: INVOICE_ERR_ANNOUCE_STATUS.AUTHORITY_ACCEPT,
      label: 'CQT đồng ý huỷ',
    },
    {
      value: INVOICE_ERR_ANNOUCE_STATUS.SEND_ERROR,
      label: 'Gửi lỗi',
    },
  ],

  reportStatus: [
    {
      value: CANCELLATION_REPORT_STATUS.ALL,
      label: 'Tất cả trạng thái BB',
    },
    {
      value: CANCELLATION_REPORT_STATUS.NOT_CREATED,
      label: 'Chưa lập biên bản',
    },
    {
      value: CANCELLATION_REPORT_STATUS.CREATED_NOT_SIGNED,
      label: 'Đã lập biên bản chưa ký',
    },
    {
      value: CANCELLATION_REPORT_STATUS.NOT_SENT_TO_CUSTOMER,
      label: 'Chưa gửi khách hàng',
    },
    {
      value: CANCELLATION_REPORT_STATUS.WAIT_CUSTOMER_SIGN,
      label: 'Chờ khách hàng ký',
    },
    {
      value: CANCELLATION_REPORT_STATUS.SIGNED_BY_CUSTOMER,
      label: 'Khách hàng đã ký',
    },
  ],
  statusReleaseInvoiceReplace: [
    {
      value: null,
      label: 'Tất cả trạng thái',
    },
    {
      value: INVOICE_STATUS.NOT_SENT_TO_AUTHORITY,
      label: 'Chưa phát hành',
    },
    {
      value: INVOICE_STATUS.WAITING_FOR_AUTHORITY,
      label: 'Đang phát hành',
    },
    {
      value: INVOICE_STATUS.AUTHORITY_CODE_NOT_GIVEN,
      label: 'Phát hành lỗi',
    },
    {
      value: INVOICE_STATUS.AUTHORITY_CODE_GIVEN,
      label: 'Đã phát hành',
    },
  ],
  isSent: [
    {
      value: null,
      label: 'Tất cả trạng thái',
    },
    {
      value: SEND_MAIL_STATUS.NOT_SEND_MAIL,
      label: 'Chưa gửi mail cho KH',
    },
    {
      value: SEND_MAIL_STATUS.SENT_MAIL,
      label: 'Đã gửi mail cho KH',
    },
    {
      value: SEND_MAIL_STATUS.SENT_MAIL_ERROR,
      label: 'Gửi lỗi',
    },
    {
      value: SEND_MAIL_STATUS.SENDING_MAIL,
      label: 'Đang gửi',
    },
  ],

  sendTaxStatus: [
    {
      value: null,
      label: 'Tất cả trạng thái',
    },
    {
      value: SEND_TAX_STATUS.NOT_SENT_TO_AUTHORITY,
      label: 'Chưa gửi CQT',
    },
    {
      value: SEND_TAX_STATUS.WAITING_FOR_AUTHORITY,
      label: 'Chờ CQT xử lý',
    },
    {
      value: SEND_TAX_STATUS.INVOICE_VALID,
      label: 'Hóa đơn hợp lệ',
    },
    {
      value: SEND_TAX_STATUS.INVOICE_INVALID,
      label: 'Hóa đơn không hợp lệ',
    },
    {
      value: SEND_TAX_STATUS.SENDING_TAX,
      label: 'Đang gửi CQT',
    },
    {
      value: SEND_TAX_STATUS.SEND_ERROR,
      label: 'Gửi lỗi',
    },
    {
      value: SEND_TAX_STATUS.SIGN_ERROR,
      label: 'Ký lỗi',
    },
  ],

  statusRelease: [
    {
      value: null,
      label: 'Tất cả trạng thái',
    },
    {
      value: STATUS_RELEASE.NOT_SENT_TO_AUTHORITY,
      label: 'Chưa gửi CQT',
    },
    {
      value: STATUS_RELEASE.AUTHORITY_ACCEPT,
      label: 'CQT chấp nhận',
    },
    {
      value: STATUS_RELEASE.INVOICE_INVALID,
      label: 'Hóa đơn không hợp lệ',
    },
    {
      value: STATUS_RELEASE.WAITING_FOR_AUTHORITY,
      label: 'Chờ CQT xử lý',
    },
    {
      value: STATUS_RELEASE.TAX_VALID,
      label: 'Thông điệp hợp lệ',
    },
    {
      value: STATUS_RELEASE.TAX_NOT_VALID,
      label: 'Thông điệp không hợp lệ',
    },
    {
      value: STATUS_RELEASE.AUTHORITY_ACCEPT,
      label: 'CQT chấp nhận',
    },
    {
      value: STATUS_RELEASE.SEND_ERROR,
      label: 'Gửi lỗi',
    },
  ],
  periodFilterOptions: [
    { label: 'Hôm qua', value: PERIOD.YESTERDAY },
    { label: 'Hôm nay', value: PERIOD.TODAY },
    { label: 'Tuần trước', value: PERIOD.LAST_WEEK },
    { label: 'Tuần này', value: PERIOD.THIS_WEEK },
    { label: 'Tháng trước', value: PERIOD.LAST_MONTH },
    { label: 'Tháng này', value: PERIOD.THIS_MONTH },
    { label: '30 ngày gần nhất', value: PERIOD.RECENT_30_DAYS },
    { label: '7 ngày gần nhất', value: PERIOD.RECENT_7_DAYS },
    { label: 'Quý trước', value: PERIOD.LAST_QUARTER },
    { label: 'Quý này', value: PERIOD.THIS_QUARTER },
    { label: '6 tháng đầu năm', value: PERIOD.FIRST_HALF_YEAR },
    { label: '6 tháng cuối năm', value: PERIOD.SECOND_HALF_YEAR },
    { label: 'Năm trước', value: PERIOD.LAST_YEAR },
    { label: 'Năm nay', value: PERIOD.THIS_YEAR },
    { label: 'Tháng 1', value: PERIOD.MONTH_1 },
    { label: 'Tháng 2', value: PERIOD.MONTH_2 },
    { label: 'Tháng 3', value: PERIOD.MONTH_3 },
    { label: 'Tháng 4', value: PERIOD.MONTH_4 },
    { label: 'Tháng 5', value: PERIOD.MONTH_5 },
    { label: 'Tháng 6', value: PERIOD.MONTH_6 },
    { label: 'Tháng 7', value: PERIOD.MONTH_7 },
    { label: 'Tháng 8', value: PERIOD.MONTH_8 },
    { label: 'Tháng 9', value: PERIOD.MONTH_9 },
    { label: 'Tháng 10', value: PERIOD.MONTH_10 },
    { label: 'Tháng 11', value: PERIOD.MONTH_11 },
    { label: 'Tháng 12', value: PERIOD.MONTH_12 },
    { label: 'Quý 1', value: PERIOD.QUARTER_1 },
    { label: 'Quý 2', value: PERIOD.QUARTER_2 },
    { label: 'Quý 3', value: PERIOD.QUARTER_3 },
    { label: 'Quý 4', value: PERIOD.QUARTER_4 },
    { label: 'Tùy chọn', value: PERIOD.CUSTOM },
  ],
  error_annoucements: [
    { value: ERROR_ANNOUCEMENTS.ALL, label: 'Tất cả' },
    {
      value: ERROR_ANNOUCEMENTS.TAX_PAYER_RELATED,
      label: 'Thông báo hủy/giải trình của NNT',
    },
    {
      value: ERROR_ANNOUCEMENTS.TAX_AUTHORITIES_RELATED,
      label: 'Thông báo theo CQT',
    },
  ],
  isReplace: [
    {
      value: null,
      label: 'Tất cả',
    },
    {
      value: false,
      label: 'Chưa được lập',
    },
    {
      value: true,
      label: 'Đã được lập',
    },
  ],
  ticketReleaseStatus: [
    { value: null, label: 'Tất cả' },
    { value: TICKET_RELEASE_STATUS.AUTHORITY_PROCESS, label: 'CQT đang xử lý' },
    { value: TICKET_RELEASE_STATUS.AUTHORITY_CODE_GIVEN, label: 'Đã cấp mã' },
    {
      value: TICKET_RELEASE_STATUS.AUTHORITY_CODE_NOT_GIVEN,
      label: 'Từ chối cấp mã',
    },
    { value: TICKET_RELEASE_STATUS.AUTHORITY_ACCEPT, label: 'CQT chấp nhận' },
    {
      value: TICKET_RELEASE_STATUS.AUTHORITY_DENY,
      label: 'CQT từ chối',
    },
  ],
  ticketStatus: [
    { value: TICKET_STATUS.ALL, label: 'Tất cả' },
    { value: TICKET_STATUS.NEW, label: 'Vé mới' },
    { value: TICKET_STATUS.REPLACE, label: 'Vé thay thế' },
    { value: TICKET_STATUS.ADJUST, label: 'Vé điều chỉnh' },
    { value: TICKET_STATUS.CANCELLED, label: 'Vé đã bị huỷ' },
    { value: TICKET_STATUS.REPLACED, label: 'Vé đã bị thay thế' },
    { value: TICKET_STATUS.ADJUSTED, label: 'Vé đã bị điều chỉnh' },
  ],
  ticketMailSentCustomerStatus: [
    { value: TICKET_MAIL_SENT_CUSTOMER_STATUS.ALL, label: 'Tất cả' },
    { value: TICKET_MAIL_SENT_CUSTOMER_STATUS.NOT_SENT, label: 'Chưa gửi vé' },
    { value: TICKET_MAIL_SENT_CUSTOMER_STATUS.SENDING, label: 'Đang gửi vé' },
    { value: TICKET_MAIL_SENT_CUSTOMER_STATUS.SEND_ERROR, label: 'Gửi lỗi' },
    { value: TICKET_MAIL_SENT_CUSTOMER_STATUS.SENT, label: 'Đã gửi vẽ' },
  ],
  ticketMailSentAuthorityStatus: [
    { value: TICKET_MAIL_SENT_AUTHORITY_STATUS.ALL, label: 'Tất cả' },
    {
      value: TICKET_MAIL_SENT_AUTHORITY_STATUS.NOT_SENT_TO_AUTHORITY,
      label: 'Chưa gửi CQT',
    },
    {
      value: TICKET_MAIL_SENT_AUTHORITY_STATUS.SENT_TO_AUTHORITY,
      label: 'Đã gửi CQT',
    },
    {
      value: TICKET_MAIL_SENT_AUTHORITY_STATUS.MESSAGE_NOT_VALID,
      label: 'TĐ không hợp lệ',
    },
    {
      value: TICKET_MAIL_SENT_AUTHORITY_STATUS.TICKET_VALID,
      label: 'Vé hợp lệ',
    },
    {
      value: TICKET_MAIL_SENT_AUTHORITY_STATUS.TICKET_NOT_VALID,
      label: 'Vé không hợp lệ',
    },
    { value: TICKET_MAIL_SENT_AUTHORITY_STATUS.EMPTY, label: 'Để trống' },
  ],

  ticketPublishedDateOptions: [
    { label: 'Tháng này', value: 'THANG_NAY' },
    { label: 'Tháng 1', value: 'THANG_1' },
    { label: 'Tháng 2', value: 'THANG_2' },
    { label: 'Tháng 3', value: 'THANG_3' },
    { label: 'Tháng 4', value: 'THANG_4' },
    { label: 'Tháng 5', value: 'THANG_5' },
    { label: 'Tháng 6', value: 'THANG_6' },
    { label: 'Tháng 7', value: 'THANG_7' },
    { label: 'Tháng 8', value: 'THANG_8' },
    { label: 'Tháng 9', value: 'THANG_9' },
    { label: 'Tháng 10', value: 'THANG_10' },
    { label: 'Tháng 11', value: 'THANG_11' },
    { label: 'Tháng 12', value: 'THANG_12' },
    { label: 'Quý này', value: 'QUY_NAY' },
    { label: 'Quý 1', value: 'QUY_1' },
    { label: 'Quý 2', value: 'QUY_2' },
    { label: 'Quý 3', value: 'QUY_3' },
    { label: 'Quý 4', value: 'QUY_4' },
    { label: 'Năm nay', value: 'NAM_NAY' },
    { label: '6 tháng đầu năm', value: 'SAU_THANG_DAU_NAM' },
    { label: '6 tháng cuối năm', value: 'SAU_THANG_CUOI_NAM' },
    { label: 'Năm trước', value: 'NAM_TRUOC' },
    { label: 'Cả năm', value: 'CA_NAM' },
  ],

  ticketPublishedStatus: [
    { value: TICKET_PUBLISHED_STATUS.ALL, label: 'Tất cả' },
    { value: TICKET_PUBLISHED_STATUS.NOT_PUBLISHED, label: 'Chưa phát hành' },
    { value: TICKET_PUBLISHED_STATUS.IS_PUBLISHING, label: 'Đang phát hành' },
    { value: TICKET_PUBLISHED_STATUS.ERROR, label: 'Phát hành lỗi' },
    {
      value: TICKET_PUBLISHED_STATUS.WAITING_FOR_AUTHORITY,
      label: 'Chờ cấp mã',
    },
    { value: TICKET_PUBLISHED_STATUS.GRANTED, label: 'Đã cấp mã' },
    { value: TICKET_PUBLISHED_STATUS.DENIED, label: 'Từ chối cấp mã' },
    { value: TICKET_PUBLISHED_STATUS.INVALID, label: 'TĐ không hợp lệ' },
  ],

  rateVatInAmount: [
    {
      value: 0,
      label:
        '0%: Hàng hóa dịch vụ không chịu thuế GTGT hoặc hàng hóa dịch vụ áp dụng thuế suất 0%',
    },
    {
      value: 1,
      label: '1%: Phân phối, cung cấp hàng hóa áp dụng thuế suất 1%',
    },
    { value: 2, label: '2%: Hoạt động kinh doanh khác áp dụng thuế suất 2%' },
    {
      value: 3,
      label:
        '3%: Sản xuất, vận tải, dịch vụ có gắn với hàng hóa, xây dựng có bao thầu nguyên vật liệu áp dụng thuế suất 3%',
    },
    {
      value: 5,
      label:
        '5%: Dịch vụ, xây dựng không bao thầu nguyên vật liệu áp dụng thuế suất 5%',
    },
  ],
  ticketTypeErrAnnouce: [
    // { value: TICKET_TYPE_ERR_ANNOUCEMENT.ALL, label: 'Tất cả' },
    {
      value: TICKET_TYPE_ERR_ANNOUCEMENT.CANCELED,
      label: 'Huỷ',
    },
    { value: TICKET_TYPE_ERR_ANNOUCEMENT.ADJUSTED, label: 'Điều chỉnh' },
    {
      value: TICKET_TYPE_ERR_ANNOUCEMENT.REPLACED,
      label: 'Thay thế',
    },
    {
      value: TICKET_TYPE_ERR_ANNOUCEMENT.EXPLAINED,
      label: 'Giải trình',
    },
  ],
  ticketTypeErrAnnouceForPrintPrice: [
    {
      value: TICKET_TYPE_ERR_ANNOUCEMENT.CANCELED,
      label: 'Huỷ',
    },
    {
      value: TICKET_TYPE_ERR_ANNOUCEMENT.EXPLAINED,
      label: 'Giải trình',
    },
  ],
  ticketTypeErrAnnouceText: [
    { value: TICKET_TYPE_ERR_ANNOUCEMENT.ALL, label: 'Tất cả' },
    {
      value: 'CANCEL',
      label: 'Huỷ',
    },
    { value: 'ADJUST', label: 'Điều chỉnh' },
    {
      value: 'REPLACE',
      label: 'Thay thế',
    },
    {
      value: 'EXPLANATION',
      label: 'Giải trình',
    },
  ],
  ticketErrAnnouceStatus: [
    {
      value: INVOICE_ERR_ANNOUCE_STATUS.ALL,
      label: 'Tất cả trạng thái TBSS',
    },
    {
      value: INVOICE_ERR_ANNOUCE_STATUS.NOT_CREATED,
      label: 'Chưa tạo TBSS',
    },
    {
      value: INVOICE_ERR_ANNOUCE_STATUS.NOT_SENT_TO_AUTHORITY,
      label: 'Chưa gửi TBSS',
    },
    {
      value: INVOICE_ERR_ANNOUCE_STATUS.WAITING_FOR_AUTHORITY,
      label: 'CQT đang xử lý',
    },
    {
      value: INVOICE_ERR_ANNOUCE_STATUS.AUTHORITY_DENY,
      label: 'CQT từ chối huỷ',
    },
    {
      value: INVOICE_ERR_ANNOUCE_STATUS.AUTHORITY_ACCEPT,
      label: 'CQT đồng ý huỷ',
    },
    {
      value: INVOICE_ERR_ANNOUCE_STATUS.SEND_ERROR,
      label: 'Gửi lỗi',
    },
  ],
  resourceType: [
    {
      value: RESOURCE_TYPE.INVOICE,
      label: 'Hóa đơn điện tử',
    },
    {
      value: RESOURCE_TYPE.INVOICE_MANAGEMENT,
      label: 'Quản lý hóa đơn',
    },
    {
      value: RESOURCE_TYPE.PERSONAL_INCOME,
      label: 'Thu nhập cá nhân',
    },
    {
      value: RESOURCE_TYPE.RECEIPT,
      label: 'Biên lai',
    },
    {
      value: RESOURCE_TYPE.TICKET,
      label: 'Tem vé',
    },
  ],
  typeTime: [
    {
      value: TYPE_TIME.LOG,
      label: 'Chờ xuất',
    },
    {
      value: TYPE_TIME.MOMENT,
      label: 'Xuất cuối ngày',
    },
  ],
  systemConfigTypeInvoice: [
    {
      value: SYSTEM_TYPE_INVOICE.SINGLE,
      label: 'Xuất lẻ',
    },
    {
      value: SYSTEM_TYPE_INVOICE.MULTIPLE,
      label: 'Xuất gộp',
    },
  ],
  partner: [
    {
      value: 1,
      label: 'Xgas',
      disabled: true,
    },
    {
      value: 2,
      label: 'ATC',
      disabled: true,
    },
  ],

  summaryInvoiceStatusRelease: [
    {
      value: SUMMARY_INVOICE_STATUS.NOT_SENT_TO_AUTHORITY,
      label: 'Chưa gửi CQT',
    },
    {
      value: SUMMARY_INVOICE_STATUS.WAITING_FOR_AUTHORITY,
      label: 'Chờ CQT xử lý',
    },
    {
      value: SUMMARY_INVOICE_STATUS.INVOICE_INVALID,
      label: 'Hóa đơn không hợp lệ',
    },
    { value: SUMMARY_INVOICE_STATUS.AUTHORITY_ACCEPT, label: 'CQT chấp nhận' },
    { value: SUMMARY_INVOICE_STATUS.SEND_ERROR, label: 'Gửi lỗi' },
  ],

  exportTemplate: [
    {
      value: 'default',
      label: 'Mặc định',
    },
    {
      value: 'c1',
      label: 'BẢNG KÊ HOÁ ĐƠN, CHỨNG TỪ HÀNG HOÁ, DỊCH VỤ BÁN RA',
      // disabled: true,
    },
    {
      value: 'c2',
      label: 'BÁO CÁO TÌNH HÌNH SỬ DỤNG HÓA ĐƠN',
      // disabled: true,
    },
    {
      value: 'sismac',
      label: 'Mẫu SISMAC',
    },
  ],

  confirmStatus: [
    {
      value: null,
      label: 'Tất cả',
    },
    {
      value: CONFIRM_STATUS.DRAFT,
      label: 'Bản nháp',
    },
    {
      value: CONFIRM_STATUS.REJECTED,
      label: 'Từ chối',
    },
    {
      value: CONFIRM_STATUS.WAITING,
      label: 'Chờ duyệt',
    },
    {
      value: CONFIRM_STATUS.CONFIRMED,
      label: 'Đã duyệt',
    },
    {
      value: CONFIRM_STATUS.EMPTY,
      label: 'Để trống',
    },
  ],

  invoiceClassify: [
    {
      value: 'ALL',
      label: 'Tất cả',
    },
    {
      value: INVOICE_CLASSIFY.HD,
      label: 'Hóa đơn',
    },
    {
      value: INVOICE_CLASSIFY.MTT,
      label: 'Máy tính tiền',
    },
    {
      value: INVOICE_CLASSIFY.PXK,
      label: 'Phiếu xuất kho',
    },
  ],
  ticketClassify: [
    {
      value: 'ALL',
      label: 'Tất cả',
    },
    {
      value: TICKET_CLASSIFY.HD,
      label: 'Vé điện tử',
    },
    {
      value: TICKET_CLASSIFY.MTT,
      label: 'Vé từ máy tính tiền',
    },
  ],
  fontText: [
    { value: 'Times New Roman', label: 'Times New Roman' },
    // { value: 'Open Sans', label: 'Open Sans' },
    { value: 'Arial', label: 'Arial' },
    // { value: 'Tahoma', label: 'Tahoma' },
    // { value: 'Cambria', label: 'Cambria' },
    { value: 'Roboto', label: 'Roboto' },
  ],

  listWorkingStatus: [
    {
      value: 'Đang làm việc',
      label: 'Đang làm việc',
      color: '#019160',
      backGroundColor: '#ebfef7',
    },
    {
      value: 'Đã nghỉ việc',
      label: 'Đã nghỉ việc',
      color: '#000000',
      backGroundColor: '#f1f3f5',
    },
  ],

  listTypeEmployee: [
    {
      value: 1,
      label: 'Nhân viên',
    },
    {
      value: 2,
      label: 'Vãng lai',
    },
  ],

  listIndentify: [
    {
      value: 1,
      label: 'CMND',
    },
    {
      value: 2,
      label: 'CCCD',
    },
    {
      value: 3,
      label: 'Hộ chiếu',
    },
  ],
  listStatusDepartment: [
    {
      value: 0,
      label: 'Ngừng theo dõi',
      color: '#000000',
    },
    {
      value: 1,
      label: 'Đang theo dõi',
      color: '#2cb84b',
    },
  ],
  listStatusDepartmentAll: [
    {
      value: 0,
      label: 'Ngừng theo dõi',
      color: '#000000',
    },
    {
      value: 1,
      label: 'Đang theo dõi',
      color: '#2cb84b',
    },
    {
      value: 2,
      label: 'Tất cả',
      color: '#2cb84b',
    },
  ],
  listStatusGamil: [
    {
      value: 0,
      label: 'Chưa gửi',
      color: '#212121',
      backGroundColor: '#f4f4f4',
    },
    {
      value: 1,
      label: 'Đang gửi',
      color: '#212121',
      backGroundColor: '#f4f4f4',
    },
    {
      value: 2,
      label: 'Thành công',
      color: '#019160',
      backGroundColor: '#ebfef7',
    },
    {
      value: 4,
      label: 'Thất bại',
      color: '#e61d1d',
      backGroundColor: '#ffcdcd',
    },
  ],
  listStatusAccountingDocuments: [
    {
      value: 0,
      label: 'Chưa phát hành',
      color: '#212121',
      backGroundColor: '#f4f4f4',
    },
    {
      value: 1,
      label: 'Đã phát hành',
      color: '#019160',
      backGroundColor: '#ebfef7',
    },
    {
      value: 2,
      label: 'Đã hủy',
      color: '#e61d1d',
      backGroundColor: '#ffcdcd',
    },
    // {
    //   value: 3,
    //   label: 'Chưa in',
    //   color: '#212121',
    //   backGroundColor: '#f4f4f4',
    // },
    // {
    //   value: 4,
    //   label: 'Đã in',
    //   color: '#212121',
    //   backGroundColor: '#f4f4f4',
    // },
  ],
  listStatusAccountingDocumentsAndAll: [
    {
      value: null,
      label: 'Tất cả',
    },
    {
      value: 0,
      label: 'Chưa phát hành',
      color: '#212121',
      backGroundColor: '#f4f4f4',
    },
    {
      value: 1,
      label: 'Đã phát hành',
      color: '#019160',
      backGroundColor: '#ebfef7',
    },
    {
      value: 2,
      label: 'Đã hủy',
      color: '#e61d1d',
      backGroundColor: '#ffcdcd',
    },
    // {
    //   value: 3,
    //   label: 'Chưa in',
    //   color: '#212121',
    //   backGroundColor: '#f4f4f4',
    // },
    // {
    //   value: 4,
    //   label: 'Đã in',
    //   color: '#212121',
    //   backGroundColor: '#f4f4f4',
    // },
  ],
  cancellationReport: [
    {
      value: 0,
      label: 'Không tạo biên bản',
    },
    {
      value: 1,
      label: 'Tạo biên bản đính kèm (Không ký)',
    },
    {
      value: 2,
      label: 'Tạo và ký điện tử bản đính kèm',
    },
  ],
  listCitizenidentificationLocation: [
    {
      value: '1',
      label: 'Cục Cảnh sát ĐKQL cư trú và DLQG về dân cư',
    },
    {
      value: '2',
      label: 'Cục Cảnh sát quản lý hành chính về trật tự xã hội',
    },
  ],
}

export default SelectOptions
