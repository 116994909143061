import axiosClient from './axiosClient'

const ticketApi = {
  createAdjustReplace: (params) => {
    const url = '/ticket/create-adjust-replace'
    return axiosClient.post(url, params)
  },

  findById: (invoiceId) => {
    const url = `/ticket/find-by-id/${invoiceId}`

    return axiosClient.get(url)
  },

  list: (params, signal) => {
    const url = '/ticket/list'
    return axiosClient.get(url, { params, signal })
  },

  download: async (params) => {
    const url = `/ticket/download`
    return axiosClient.get(url, { params, responseType: 'blob' })
  },

  cancel: (params) => {
    const url = '/ticket/cancel'
    return axiosClient.put(url, params)
  },

  exportById: (ticketId) => {
    const url = `/ticket/export-by-id/${ticketId}`

    return axiosClient.get(url, { responseType: 'blob' })
  },

  bulkCancels: (ticketIds) =>
    axiosClient.delete('/ticket/cancels', {
      data: {
        ticketIds,
      },
    }),

  sendTicketToCustomer: (params) =>
    axiosClient.post('/ticket/send-ticket-to-customer', params),

  bulkPrint: (ticketIds) =>
    axiosClient.post('/ticket/bulk-print', ticketIds, { responseType: 'blob' }),

  cloneMutilTickets: ({ invoiceId, quantity }) => {
    const url = '/ticket/copy'
    return axiosClient.post(url, { invoiceId, quantity })
  },

  hsmSignSummaryTicket: (params) => {
    const url = '/ticket/hsm-sign-summary-ticket'

    return axiosClient.post(url, params)
  },

  // kéo mã
  checkMessages: (params) => {
    const url = '/ticket/check-messages'
    return axiosClient.get(url, { params })
  },
}

export default ticketApi
