import KTBSButton from 'general/components/OtherKeenComponents/KTBSButton'

import { useTranslation } from 'react-i18next'

import KTAdvanceNav from 'general/components/OtherKeenComponents/KTAdvanceNav'

import Utils from 'general/utils/Utils'

import UserHelper from 'general/helpers/UserHelper'

import Swal from 'sweetalert2'

import { useDispatch } from 'react-redux'

import { thunkLogOut } from 'app/authSlice'

import ModalChangePassword from 'features/Account/components/modalChangePassword'

import { useAppSelector } from 'hooks/useRedux'
import { useState } from 'react'
import AppConfigs from 'general/constants/AppConfigs'
import PreferenceKeys from 'general/constants/PreferenceKeys'

DropdownHeaderAccountInfo.propTypes = {}

function DropdownHeaderAccountInfo(props) {
  // ----- Params -----

  const { t } = useTranslation()

  const { fullname, phone, email } = useAppSelector(
    (state) => state?.auth.currentAccount,
  )
  const dispatch = useDispatch()

  const [showModalChangePassword, setShowModalChangePassword] = useState(false)

  // ----- Methods ------

  function handleSignOut() {
    Swal.fire({
      title: t('Confirm'),

      text: t('MessageConfirmSignOut'),

      icon: 'question',

      showCancelButton: true,

      confirmButtonText: t('Yes'),

      cancelButtonText: t('Cancel'),

      customClass: {
        confirmButton: 'btn btn-danger font-weight-bolder',

        cancelButton: 'btn btn-light font-weight-bolder',
      },
    }).then(async function (result) {
      if (result.value) {
        Object.keys(localStorage).filter((item) => {
          if (item.startsWith('gFilter')) {
            localStorage.removeItem(item)
          }
        })
        // dang xuat
        dispatch(thunkLogOut())
        removeSessionAccount()
      }
    })
  }

  const removeSessionAccount = () => {
    localStorage.removeItem(PreferenceKeys.accessToken)
    localStorage.removeItem(PreferenceKeys.qlbhAccessToken)
    localStorage.removeItem(PreferenceKeys.accessTokenExpired)
    Object.keys(localStorage).map((key) => {
      if (key.startsWith(AppConfigs.packageName) || key.startsWith('gFilter')) {
        localStorage.removeItem(key)
      }
    })
    window.location.href = '/dang-nhap'
  }

  return (
    <div className="w-260px">
      <div className="">
        <div className="card-body px-4 pb-4">
          <div className="text-center">
            <div className="symbol symbol-60 symbol-circle symbol-xl-90">
              <div
                className="symbol-label"
                style={{
                  backgroundImage: `url('${Utils.getFullUrl(
                    UserHelper.getAvatar(),
                  )}')`,
                }}
              ></div>
            </div>

            <h4 className="font-weight-bolder my-2">{fullname}</h4>

            <div className="text-muted font-weight-bold">{email}</div>

            <div className="text-muted font-weight-bold mb-2">{phone}</div>
          </div>

          {/* nav */}

          <KTAdvanceNav
            enableHoverStyle
            enableLinkRounded={true}
            itemList={[
              {
                text: t('ChangePassword'),

                iconElement: <i className="fa-regular fa-key-skeleton" />,

                click: () => {
                  setShowModalChangePassword(true)
                },
              },

              {
                text: t('SetupAccount'),

                iconElement: <i className="fa-regular fa-user" />,
              },

              // { text: t('SetupSecurity'), iconElement: <i className="fa-regular fa-shield" /> },

              // {

              //   text: `${t('Introduce')} - ${t('AccumulatePoints')}`,

              //   iconElement: <i className="fa-regular fa-gift" />,

              // },

              // {

              //   text: t('Language'),

              //   iconElement: <img src={AppResource.icons.keens.flagEn} className="h-20px" />,

              //   showArrow: true,

              // },
            ]}
          />
        </div>

        <div className="card-footer px-6 py-4">
          <KTBSButton
            additionalClassName="justify-content-center"
            iconElement={<i className="fa-regular fa-sign-out" />}
            title={t('SignOut')}
            onPress={handleSignOut}
          />
        </div>
      </div>

      {/* Modal change password */}

      <ModalChangePassword
        show={showModalChangePassword}
        onClose={() => {
          setShowModalChangePassword(false)
        }}
      />
    </div>
  )
}

export default DropdownHeaderAccountInfo
