import { useMutation, useQuery } from '@tanstack/react-query'
import { useDebounce } from '@uidotdev/usehooks'
import { Space } from 'antd'
import routeApi from 'api/routeApi'
import { setAppSpinning } from 'app/appSlice'
import AppDnD from 'general/components/AppDragAndDrop/AppDnD'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import CustomList from 'general/components/CustomList'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import TableAction from 'general/components/GroupButton/TableAction'
import { IconAction } from 'general/components/GroupButton/styles'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useAppDnD from 'hooks/useAppDnD'
import useFilter from 'hooks/useFilter'
import { assign, get, includes, keys, map } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { RouteActionModal } from './Components/Modals/RouteActionModal'
import Text from './Components/Text'
import { DEFAULT_MODAL_STATE, ROUTE_ACTION_TYPE } from './enums'

export default function RouteScreen() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [modalState, setModalState] = useState(DEFAULT_MODAL_STATE)

  const [filter, setFilter] = useFilter('ticketRoute', 'gFilterTicketRoute')
  const [searchRoute, setSearchRoute] = useState('')
  const debouncedSearchRoute = useDebounce(searchRoute, 1000)
  const [activeRouteId, setActiveRouteId] = useState('')
  const [activeRouteIds, setActiveRouteIds] = useState([])
  const [clearSelectedRows, setClearSelectedRows] = useState(false)
  const [actionType, setActionType] = useState(ROUTE_ACTION_TYPE.NEW)

  const columns = useMemo(() => {
    /**
     * An array of table columns.
     * @type {import('react-data-table-component').TableColumn<ReturnRouteData>[]}
     */
    const arr = [
      {
        name: 'Tên tuyến đường',
        center: true,
        grow: 2,
        cell: ({ nameRoute }) => (
          <Text content={nameRoute} textAlign={'left'} />
        ),
      },
      {
        name: 'Điểm đi',
        center: true,
        cell: ({ routeStart }) => (
          <Text content={routeStart} textAlign={'left'} />
        ),
      },
      {
        name: 'Điểm đến',
        center: true,
        cell: ({ routeEnd }) => <Text content={routeEnd} textAlign={'left'} />,
      },
      {
        name: 'Thời gian khởi hành',
        center: true,
        width: '200px',
        cell: (row) => (
          <CustomList
            items={row.startTime.split(';')}
            maxItemCount={5}
            ellispsis={(remain) => (
              <div className="badge badge-light-primary">+{remain}</div>
            )}
            render={(item, idx) => (
              <div key={idx} className="badge badge-light-primary">
                {item}
              </div>
            )}
          />
        ),
      },
      {
        name: 'Số xe',
        center: true,
        width: '200px',
        cell: (row) => (
          <CustomList
            items={row.noCar.split(';')}
            maxItemCount={5}
            render={(item, idx) => (
              <div key={idx} className="badge badge-light-primary">
                {item}
              </div>
            )}
          />
        ),
      },
      {
        name: 'Trạng thái',
        center: true,
        cell: (row) => (
          <div
            data-tag="allowRowEvents"
            className={`badge badge-light-${
              row?.availability ? 'success' : 'danger'
            } `}
          >
            {row.availability ? 'Đang sử dụng' : 'Ngừng sử dụng'}
          </div>
        ),
      },
      {
        name: 'Hành động',
        grow: 0,
        minWidth: '120px',
        compact: true,
        center: true,
        disabled: true,
        cell: (row) => (
          <Space
            align="center"
            onClick={() => {
              setActiveRouteId(row.routeId)
            }}
          >
            <TableAction
              titleActionText="Sửa"
              icon={<IconAction className="fa-light fa-pen-to-square" />}
              onClick={() => {
                setActionType(ROUTE_ACTION_TYPE.EDIT)
                setModalState((prev) => ({ ...prev, editModal: true }))
              }}
            />
            <TableAction
              titleActionText="Xoá"
              icon={<IconAction className="fa-light fa-trash" />}
              onClick={() => {
                setModalState((prev) => ({
                  ...prev,
                  deleteModal: true,
                }))

                setActionType(ROUTE_ACTION_TYPE.DELETE)
              }}
            />
            <TableAction
              titleActionText="Nhân bản"
              icon={<IconAction className="fa-light fa-copy" />}
              onClick={() => {
                setModalState((prev) => ({
                  ...prev,
                  createNewModal: true,
                }))
                setActionType(ROUTE_ACTION_TYPE.DUPLICATE)
              }}
            />
          </Space>
        ),
      },
    ]
    return arr
  }, [])

  const dynamicColumns = useAppDnD(columns)

  // ==================== Queries, mutations ====================
  const {
    data: listRouteData,
    isFetching: gettingList,
    refetch: refetchListRoute,
  } = useQuery({
    queryKey: ['getListRoute', filter, debouncedSearchRoute],
    queryFn: () =>
      routeApi.getListOfRoute({ ...filter, q: debouncedSearchRoute }),
    select: (res) => {
      return {
        total: get(res, 'total', 0),
        rows: get(res, 'routes', []),
      }
    },
  })

  const {
    data: defaultFormData,
    isFetching: gettingFormData,
    refetch: refetchRouteData,
  } = useQuery({
    enabled: activeRouteId !== '',
    queryKey: ['getRouteById', activeRouteId],
    queryFn: () => routeApi.getDetailInfoRoute(activeRouteId),
    select: (res) => res?.route ?? {},
  })

  const createNewMutation = useMutation({
    mutationKey: 'createNewRoute',
    mutationFn: (params) => routeApi.createNewRoute(params),
    onSuccess: () => {
      ToastHelper.showSuccess('Tạo mới tuyến đường thành công')
      setModalState(DEFAULT_MODAL_STATE)
      refetchListRoute()
      setActiveRouteIds([])
      setClearSelectedRows(true)
    },
  })
  const updateRouteMutation = useMutation({
    mutationKey: 'updateRoute',
    mutationFn: (params) => routeApi.updateRoute(params),
    onSuccess: () => {
      ToastHelper.showSuccess('Thay đổi thông tin tuyến đường thành công')
      setModalState(DEFAULT_MODAL_STATE)
      refetchListRoute()
      refetchRouteData()
      setActiveRouteIds([])
      setClearSelectedRows(true)
    },
  })

  const deleteRouteMutation = useMutation({
    mutationKey: 'deleteRoute',
    mutationFn: (data) => routeApi.deleleRoute(data),
    onSuccess: () => {
      ToastHelper.showSuccess('Xoá tuyến đường thành công')
      setModalState(DEFAULT_MODAL_STATE)
      refetchListRoute()
      setActiveRouteIds([])
      setClearSelectedRows(true)
    },
  })

  // ==================== Functions ====================
  const onClickApply = useCallback(
    (values) => {
      const checkMissingFields = () => {
        let isMissingValues = false
        const requiredFields = [
          { value: 'nameRoute', label: 'Tên tuyến đường' },
          { value: 'routeStart', label: 'Điểm đi' },
          { value: 'routeEnd', label: 'Điểm đến' },
        ]
        requiredFields.map((field) => {
          if (!values?.[field.value]) {
            ToastHelper.showError(`${field.label} không được để trống`)
            isMissingValues = true
          }
        })
        return isMissingValues
      }
      switch (actionType) {
        case ROUTE_ACTION_TYPE.NEW:
        case ROUTE_ACTION_TYPE.DUPLICATE:
          const checkMissingFieldsResult = checkMissingFields()
          if (checkMissingFieldsResult) break
          createNewMutation.mutate(values)
          break
        case ROUTE_ACTION_TYPE.EDIT:
          const isMissingValues = checkMissingFields()
          if (isMissingValues) break
          updateRouteMutation.mutate(values)
          break
        case ROUTE_ACTION_TYPE.DELETE:
          deleteRouteMutation.mutate({
            routeIds:
              activeRouteIds.length > 0 ? activeRouteIds : [activeRouteId],
          })
          break
      }
    },
    [
      actionType,
      activeRouteId,
      activeRouteIds,
      createNewMutation,
      deleteRouteMutation,
      updateRouteMutation,
    ],
  )
  /**
   *  @param {keyof DEFAULT_MODAL_STATE} modalName
   *  @param {boolean} status
   */
  const toggleModalState = (modalName, status) => {
    setModalState((prev) => {
      const notModalName = keys(prev).filter((key) => key !== modalName)
      const turnEverythingOff = map(notModalName, (key) => ({ [key]: false }))
      return {
        ...assign({}, ...turnEverythingOff),
        [modalName]: status,
      }
    })
  }

  // ------------------- Effects -------------------
  useEffect(() => {
    gettingFormData
      ? dispatch(setAppSpinning(true))
      : dispatch(setAppSpinning(false))
  }, [dispatch, gettingFormData])

  return (
    <ContentContainer>
      <ContentContainer.Header
        titleContent="Danh sách tuyến đường"
        description={Utils.cn('Tổng:', listRouteData?.total, 'tuyến đường')}
        toolBar={
          <Space>
            <CustomAntButton
              text={t('AddNew')}
              iconCn="fa-regular fa-plus text-white"
              antProps={{
                type: 'primary',
                onClick: () => {
                  setActionType(ROUTE_ACTION_TYPE.NEW)
                  setModalState((prev) => ({ ...prev, createNewModal: true }))
                },
              }}
            />
            <AppDnD
              defaultColumns={columns}
              localStorageName={PreferenceKeys.routeScreen}
            />
          </Space>
        }
      >
        <Space wrap>
          {activeRouteIds.length > 0 ? (
            <>
              <CustomAntButton
                antProps={{
                  type: 'primary',
                  danger: true,
                  onClick: () => {
                    setActionType(ROUTE_ACTION_TYPE.DELETE)
                    setModalState((prev) => ({ ...prev, deleteModal: true }))
                  },
                }}
                text={'Xoá ' + activeRouteIds.length}
                iconCn="fa-regular fa-trash"
                fontSizeText={13}
              />
            </>
          ) : (
            <CustomAntInput
              type="search"
              inputProps={{
                placeholder: t('Search') + ' tên tuyến đường...',
                value: searchRoute,
                onChange: (e) => setSearchRoute(e.target.value),
              }}
              borderRadius="sm"
            />
          )}
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={dynamicColumns}
          dataSource={listRouteData?.rows}
          isLoading={gettingList}
          isClickable
          isSelectable
          pagination={{
            currentPage: filter.page,
            rowsPerPage: filter.limit,
            total: listRouteData?.total,
            onChangePage: (newPage) => {
              setFilter({
                page: newPage,
              })
            },
            onChangeRowsPerPage: (newPerPage) => {
              const iNewPerPage = parseInt(newPerPage)
              setFilter({
                limit: iNewPerPage,
                page: 1,
              })
            },
          }}
          selectedRows={{
            clearSelectedRows,
            handleSelectedRows: ({ selectedRows }) => {
              setActiveRouteIds(selectedRows.map((row) => row.routeId))
            },
            selectableRowSelected: (row) =>
              includes(activeRouteIds, row.routeId),
          }}
          handleClickRow={(row) => {
            setActiveRouteId(row.routeId)
            setModalState((prev) => ({ ...prev, editModal: true }))
            setActionType(ROUTE_ACTION_TYPE.EDIT)
          }}
        />
      </ContentContainer.Body>
      <RouteActionModal
        open={modalState.createNewModal}
        title={'Thêm mới tuyến đường'}
        toggleModalState={(status) =>
          toggleModalState('createNewModal', status)
        }
        actionType={actionType}
        onClickApply={onClickApply}
        isLoading={createNewMutation.isPending}
        formData={defaultFormData}
      />
      <RouteActionModal
        open={modalState.editModal}
        title={'Chỉnh sửa tuyến đường'}
        toggleModalState={(status) => toggleModalState('editModal', status)}
        actionType={actionType}
        formData={defaultFormData}
        onClickApply={onClickApply}
        isLoading={updateRouteMutation.isPending}
      />
      <RouteActionModal
        open={modalState.deleteModal}
        title={'Xác nhận xoá ?'}
        description={
          activeRouteIds.length > 0
            ? 'Bạn có chắc chắn muốn xoá các tuyến đường này ?'
            : 'Bạn có chắc chắn muốn xoá tuyến đường này ?'
        }
        toggleModalState={(status) => toggleModalState('deleteModal', status)}
        actionType={actionType}
        formData={defaultFormData}
        onClickApply={onClickApply}
        isLoading={deleteRouteMutation.isPending}
      />
    </ContentContainer>
  )
}
