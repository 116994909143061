import { useDebounce } from '@uidotdev/usehooks'
import { useState } from 'react'

/**
 *
 * @param {string} defaultValue
 * @param {number} [timeout]
 */
export default function useSearchDebounce(defaultValue, timeout) {
  const [search, setSearch] = useState(defaultValue)

  const debouncedSearch = useDebounce(search, timeout ?? 1000)
  return {
    search,
    setSearch,
    debouncedSearch,
  }
}
