import { useMutation } from '@tanstack/react-query'
import { Card, Typography } from 'antd'
import authApi from 'api/authApi'
import { Field, Form, Formik } from 'formik'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import KTFormGroup from 'general/components/Forms/KTFormGroup'
import KTFormInput, {
  KTFormInputType,
} from 'general/components/Forms/KTFormInput'
import AppRegex from 'general/constants/AppRegex'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useRouter from 'hooks/useRouter'
import _ from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

export default function SignUpScreen() {
  const { navigate } = useRouter()
  const [searchParams] = useSearchParams()
  const handleSignUp = useMutation({
    mutationKey: ['auth', 'signUp'],
    mutationFn: (params) => {
      const cloned = _.cloneDeep(params)
      _.set(cloned, 'password', Utils.sha256(cloned.password))
      return authApi.signUp({
        linkParams: searchParams.toString(),
        body: cloned,
      })
    },
    onSuccess: (res, values) => {
      if (res.result !== 'success') return
      ToastHelper.showSuccess('Đăng ký tài khoản thành công')
      localStorage.setItem(PreferenceKeys.savedLoginEmail, values.email)
      localStorage.setItem(PreferenceKeys.savedLoginTaxCode, values.taxCode)
      localStorage.setItem(PreferenceKeys.savedLoginPassword)
      navigate('/dang-nhap', {
        replace: true,
      })
    },
  })

  return (
    <Formik
      initialValues={{
        email: '',
        fullname: '',
        password: '',
        taxCode: '',
      }}
      enableReinitialize
      validationSchema={Yup.object({
        email: Yup.string()
          .email('Email không hợp lệ')
          .required('Email không được để trống'),
        fullname: Yup.string().required('Họ và tên không được để trống'),
        password: Yup.string()
          .trim()
          .min(6, 'Mật khẩu chứa ít nhất 6 ký tự')
          .required('Mật khẩu không được để trống'),
        taxCode: Yup.string()
          .matches(AppRegex.mstRegex, 'Mã số thuế không hợp lệ')
          .optional()
          .nullable(),
      })}
      onSubmit={handleSignUp.mutate}
    >
      {() => (
        <Card
          bordered={false}
          title={
            <Typography.Title level={2} className="text-center m-0">
              Đăng ký tài khoản
            </Typography.Title>
          }
          bodyStyle={{
            padding: 0,
          }}
          style={{
            maxWidth: 500,
          }}
        >
          <Form>
            <section className="p-5 bg-light">
              <div>
                <KTFormGroup
                  label={
                    <>
                      Tên doanh nghiệp
                      <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="fullname"
                  inputElement={
                    <Field name="fullname">
                      {({ field, form, meta }) => (
                        <KTFormInput
                          name="fullname"
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue('fullname', value)
                          }}
                          onBlur={() => {
                            form.setFieldTouched('fullname', true)
                          }}
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                          type={KTFormInputType.text}
                          placeholder="Tên doanh nghiệp"
                        />
                      )}
                    </Field>
                  }
                />
                <KTFormGroup
                  label={
                    <>
                      Mã số thuế
                      <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="taxCode"
                  inputElement={
                    <Field name="taxCode">
                      {({ field, form, meta }) => (
                        <KTFormInput
                          name="taxCode"
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue('taxCode', value)
                          }}
                          onBlur={() => {
                            form.setFieldTouched('taxCode', true)
                          }}
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                          type={KTFormInputType.text}
                          placeholder="Nhập mã số thuế"
                        />
                      )}
                    </Field>
                  }
                />
                <KTFormGroup
                  label={
                    <>
                      Email
                      <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="email"
                  inputElement={
                    <Field name="email">
                      {({ field, form, meta }) => (
                        <KTFormInput
                          name="email"
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue('email', value)
                          }}
                          onBlur={() => {
                            form.setFieldTouched('email', true)
                          }}
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                          type={KTFormInputType.email}
                          placeholder="Nhập email"
                        />
                      )}
                    </Field>
                  }
                />
                <KTFormGroup
                  label={
                    <>
                      Mật khẩu
                      <span className="text-danger">(*)</span>
                    </>
                  }
                  inputName="password"
                  inputElement={
                    <Field name="password">
                      {({ field, form, meta }) => (
                        <KTFormInput
                          name="password"
                          value={field.value}
                          onChange={(value) => {
                            form.setFieldValue('password', value)
                          }}
                          onBlur={() => {
                            form.setFieldTouched('password', true)
                          }}
                          enableCheckValid
                          isValid={_.isEmpty(meta.error)}
                          isTouched={meta.touched}
                          feedbackText={meta.error}
                          type={KTFormInputType.password}
                          placeholder="Nhập mật khẩu"
                        />
                      )}
                    </Field>
                  }
                />
              </div>
              <div className="mt-5 text-end">
                Đã có tài khoản?{' '}
                <Link
                  to="/auth/dang-nhap"
                  className="font-size-base font-weight-bolder cursor-pointer text-primary text-hover-muted"
                >
                  Đăng nhập ngay
                </Link>
              </div>
            </section>
            <section className="p-5">
              <CustomAntButton
                text={'Đăng ký'}
                isLoading={handleSignUp.isPending}
                antProps={{
                  htmlType: 'submit',
                  block: true,
                  type: 'primary',
                }}
              />
            </section>
          </Form>
        </Card>
      )}
    </Formik>
  )
}
