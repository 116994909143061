import { useMutation } from '@tanstack/react-query'
import { Checkbox, Flex, Radio, Typography } from 'antd'
import { Formik } from 'formik'
import CustomAntSelect from 'general/components/CustomAntSelect'
import AppData from 'general/constants/AppData'
import ToastHelper from 'general/helpers/ToastHelper'
import { env } from 'general/utils/env'
import { useAppDispatch } from 'hooks/useRedux'
import _ from 'lodash'
import { useState } from 'react'
import * as Yup from 'yup'
import {
  getAllSystemInvoiceSetting,
  updateSystemInvoiceSetting,
} from '../../asyncThunks'
import { autoSentTime, systemInvoice } from '../../constants'
import { useSystemConfigSelector } from '../../SystemConfigurationSlice'
import FooterCard from '../FooterCard'

export default function TabInvoiceSetting() {
  const { invoiceSetting, timeRemind } = useSystemConfigSelector()
  const dispatch = useAppDispatch()

  const [timeSelection] = useState(() => {
    return _.flatMap(_.range(24), (hour) =>
      [0, 30].map(
        (minute) =>
          `${_.padStart(String(hour), 2, '0')}:${_.padStart(String(minute), 2, '0')}`,
      ),
    )
  })

  const handleSaveChange = useMutation({
    mutationKey: ['systemConfig', 'invoice'],
    mutationFn: (values) =>
      dispatch(updateSystemInvoiceSetting(values)).unwrap(),
    onSettled: async () => {
      ToastHelper.showSuccess('Cập nhật thành công')
      await dispatch(getAllSystemInvoiceSetting())
    },
  })

  return (
    <Formik
      initialValues={{
        ...invoiceSetting,
        timeRemind,
        hasTimeRemind: !_.isEmpty(timeRemind),
      }}
      validationSchema={Yup.object({
        timeRemind: Yup.array()
          .notRequired()
          .of(Yup.string())
          .when('hasTimeRemind', {
            is: true,
            then: Yup.array()
              .min(1, 'Bạn cần thiết lập ít nhất 1 mốc thời gian nhắc nhở')
              .max(4, 'Bạn chỉ được thiết lập tối đá 4 mốc thời gian nhắc nhở'),
            otherwise: Yup.array().notRequired(),
          }),
      })}
      enableReinitialize
      validateOnBlur
      validateOnChange
      onSubmit={(values) => {
        handleSaveChange.mutate(_.omit(values, ['hasTimeRemind']))
      }}
    >
      {(formikProps) => (
        <Flex justify={'space-between'} vertical className="w-100 h-100">
          <section>
            <Typography.Title level={5} className="">
              Hóa đơn
            </Typography.Title>
            <div className="ml-10 row">
              <div className="col-lg-6 col-12 mb-5">
                <div className="pl-10 mb-5">
                  <Checkbox
                    checked={formikProps.values.priceAfterTax}
                    onChange={(e) => {
                      formikProps.setFieldValue(
                        'priceAfterTax',
                        e.target.checked,
                      )
                      formikProps.setFieldValue('caculatedAfterTax', false)
                      formikProps.setFieldValue('amountAfterTax', false)
                      formikProps.setFieldValue(
                        'caculatedAfterAmountAndTax',
                        false,
                      )
                    }}
                  >
                    Có phát sinh bán hàng theo đơn giá sau thuế
                  </Checkbox>
                </div>
                <div className="pl-10">
                  <Checkbox
                    checked={formikProps.values.managementFromCash}
                    onChange={(e) =>
                      formikProps.setFieldValue(
                        'managementFromCash',
                        e.target.checked,
                      )
                    }
                  >
                    Quản lý hóa đơn khởi tạo từ máy tính tiền
                  </Checkbox>
                </div>
                {formikProps.values.managementFromCash == 1 && (
                  <div className="pl-20">
                    <Checkbox
                      checked={formikProps.values.hasTimeRemind}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          formikProps.setFieldValue('timeRemind', [])
                        }
                        formikProps.setFieldValue(
                          'hasTimeRemind',
                          e.target.checked,
                        )
                      }}
                      style={{ display: 'none' }}
                    >
                      Thiết lập thời gian nhắc nhở gửi hóa đơn khởi tạo từ máy
                      tính tiền đến cơ quan thuế
                    </Checkbox>
                    <br style={{ display: 'none' }} />
                    {!formikProps.values.hasTimeRemind ? null : (
                      <div style={{ maxWidth: '450px', display: 'none' }}>
                        <CustomAntSelect
                          isMultiSelect
                          maxTagCount={4}
                          options={timeSelection.map((v) => ({
                            value: v,
                            label: v,
                          }))}
                          value={formikProps.values.timeRemind}
                          onChange={(values) => {
                            formikProps.setFieldValue('timeRemind', values)
                          }}
                          error={
                            formikProps.errors.timeRemind &&
                            formikProps.touched.timeRemind
                          }
                          errorText={formikProps.errors.timeRemind}
                        />
                      </div>
                    )}
                    <br />
                    <Checkbox
                      checked={
                        formikProps.values.isAutoSentFromPosToTaxAuthority
                      }
                      onChange={(e) =>
                        formikProps.setFieldValue(
                          'isAutoSentFromPosToTaxAuthority',
                          e.target.checked,
                        )
                      }
                    >
                      Tự động gửi hóa đơn từ máy tính tiền đến cơ quan thuế
                    </Checkbox>
                    <br />
                    {!formikProps.values
                      .isAutoSentFromPosToTaxAuthority ? null : (
                      <div className="pl-10">
                        <Radio.Group
                          value={formikProps.values.autoSentType}
                          onChange={(e) =>
                            formikProps.setFieldValue(
                              'autoSentType',
                              e.target.value,
                            )
                          }
                        >
                          <Flex vertical={true}>
                            <Radio value={'FIXED_TIME'}>Theo khung giờ</Radio>
                            {formikProps.values.autoSentType ==
                              'FIXED_TIME' && (
                              <div className="pl-7">
                                <Checkbox.Group
                                  options={autoSentTime}
                                  value={
                                    formikProps.values.autoSentTime ??
                                    autoSentTime
                                  }
                                  onChange={(values) => {
                                    formikProps.setFieldValue(
                                      'autoSentTime',
                                      values,
                                    )
                                  }}
                                />
                                <br />
                                <Typography.Paragraph className="font-size-md fw-light font-italic">
                                  <b>Lưu ý: </b> Đơn vị cần chủ động gửi{' '}
                                  <Typography.Text strong className="p">
                                    hoá đơn/ vé phát hành sau 23:59{' '}
                                  </Typography.Text>
                                  đến CQT{' '}
                                  <Typography.Text strong>
                                    ngay trong ngày,
                                  </Typography.Text>{' '}
                                  để tránh bị phạt do chuyển dữ liệu quá thời
                                  hạn quy định.
                                </Typography.Paragraph>
                              </div>
                            )}
                            <Radio value={'PERIOD_TIME'}>
                              1-5 phút sau khi phát hành
                            </Radio>
                          </Flex>
                        </Radio.Group>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="pl-14 col-lg-6 col-12">
                {systemInvoice
                  .filter((item) =>
                    env.PROJECT_TYPE === 'SONPHAT'
                      ? ['dateSameDate'].includes(item.state)
                      : true,
                  )
                  .map((item) => {
                    return (
                      <div key={item.state} className="mb-5">
                        <Checkbox
                          checked={formikProps.values[item?.state]}
                          onChange={() =>
                            formikProps.setFieldValue(
                              item?.state,
                              !formikProps.values[item?.state],
                            )
                          }
                        >
                          {item.label}
                        </Checkbox>
                      </div>
                    )
                  })}
              </div>
            </div>
          </section>
          <FooterCard
            onRestoreDefault={() => {
              _.entries(AppData.default_invoiceSetting).forEach(
                ([key, value]) => {
                  formikProps.setFieldValue(key, value)
                },
              )
            }}
            onRestoreChange={formikProps.resetForm}
            onSave={formikProps.submitForm}
            isLoading={formikProps.isSubmitting}
            show={formikProps.dirty}
          />
        </Flex>
      )}
    </Formik>
  )
}
