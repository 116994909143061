import TableAction from 'general/components/GroupButton/TableAction'
import './styles.scss'
import { IconAction } from 'general/components/GroupButton/styles'
import PropTypes from 'prop-types'
import InputNumberNoFormik from 'general/components/Forms/InputNumerNoFormik'
import Utils from 'general/utils/Utils'
import ModalTicketDetail from '../ModalTicketDetail'
import { useMemo, useState } from 'react'
import _ from 'lodash'
import ToastHelper from 'general/helpers/ToastHelper'
import { useAppSelector } from 'hooks/useRedux'
import { useDispatch } from 'react-redux'
import { setAppSpinning } from 'app/appSlice'
import ticketDraftApi from 'api/ticektDraftApi'
import ModalTicketGXDetail from '../ModalTicketGXDetail'

export const TicketCreateTableRow = (props) => {
  const {
    row,
    tableData,
    handleChangeTableData,
    handleRemoveRow,
    ticketTemplates,
  } = props
  const {
    invoice_template,
    serial,
    noCar,
    noSeatCar,
    dateCreate,
    totalPrice,
    quantity,
  } = row
  const dispatch = useDispatch()

  const { currency } = useAppSelector((state) => state.systemConfig)
  const { company } = useAppSelector((state) => state.system)

  const [modalTicketDetailShow, setModalTicketDetailShow] = useState(false)
  const [modalTicketGXDetailShow, setModalTicketGXDetailShow] = useState(false)
  const [activeTicketTemplate, setActiveTicketTemplate] = useState({})

  const handleSetActiveTicketTemplate = (invoiceTemplateId) => {
    if (!invoiceTemplateId) {
      ToastHelper.showError('Không có ID mẫu vé')
      return
    }
    const template = _.find(ticketTemplates, {
      invoiceTemplateId: invoiceTemplateId,
    })
    setActiveTicketTemplate(template)
  }

  const handleChangeTicketItem = ({
    ticketTemplate,
    noCar,
    noSeatCar,
    departureDate,
    departureTime,
    price,
    taxRate,
    taxMoney,
    totalPrice,
    routeStart,
    routeEnd,
    nameRoute,
    ticketPriceReduce,
    ticketPriceWithoutReduce,
    rateVatInAmount,
    isTaxReduction,
    index,
    departureDateTimeTemp,
  }) => {
    const { name, serial, invoiceTemplateId } = ticketTemplate
    const today = new Date()
    const departureDateTime =
      departureDate && departureTime
        ? departureDate + ' ' + departureTime
        : null
    let newTableData = _.clone(tableData)
    const newTicket = {
      nameTicket: name,
      invoiceTemplateId,
      serial,
      quantity: 1,
      price,
      taxRate,
      taxMoney,
      totalPrice,
      dateCreate: Utils.formatDate(today),
      nameRoute,
      routeStart,
      routeEnd,
      noSeatCar: noSeatCar,
      noCar,
      departureDateTime: departureDateTimeTemp?.format('YYYY-MM-DD HH:mm'),
      ticketPriceReduce,
      ticketPriceWithoutReduce,
      rateVatInAmount,
      isTaxReduction,
      departureDateTimeTemp,
    }
    newTableData[index] = newTicket

    switch (activeTicketTemplate?.category_ticket?.typeServices) {
      case 'VT':
        setModalTicketDetailShow(false)
        break
      case 'GX':
        setModalTicketGXDetailShow(false)
        break
    }
    // setModalTicketTaxRateDetailShow(false)
    handleChangeTableData(newTableData)
  }

  const handleChangeQuantity = (row, quantity) => {
    if (_.isNaN(quantity)) {
      quantity = 1
    }
    let newTableData = tableData
    const index = _.findIndex(tableData, { serial: row?.serial })
    if (index !== -1) {
      newTableData[index].quantity = quantity
      console.log(newTableData)
      handleChangeTableData([...newTableData])
    }
  }

  const exportTicketDraftItem = async () => {
    const totalPriceVnese = Utils.numberToWords(
      row.totalPrice,
      'vi',
      '',
      'đồng', //endSymbol
      'phẩy', // afterdecimal
      '',
      '',
      currency?.zeroInten,
      currency?.zeroInThousand,
      currency?.evenDisplay,
    )

    let params = {
      ...row,
      totalPriceVnese,
      sellerName: company?.companyName,
      sellerTaxCode: company?.taxCode,
      sellerFullAddress: company?.businessPermitAddress,
      sellerPhone: company?.companyPhone,
      sellerEmail: company?.companyEmail,
      sellerAccountNumber: company?.bankAccountNumber,
      sellerBankName: company.bankName,
      sellerFax: company?.fax,
      sellerWebsite: company?.website,
    }

    var res
    try {
      res = await ticketDraftApi.exportTicketDraftItem(params)
      dispatch(setAppSpinning(false))
      let newFile = new File([res], 'Vé nháp.pdf', {
        type: 'application/pdf',
      })
      var fileURL = URL.createObjectURL(newFile)
      window.open(fileURL, '_blank')
    } catch (e) {
      dispatch(setAppSpinning(false))
      console.log(e)
    }
  }

  const canEditQuantityTicket = useMemo(() => {
    return !_.some(ticketTemplates, {
      serial: serial,
      category: 1,
    })
  }, [serial, ticketTemplates])

  return (
    <>
      <tr className="ticketCreateTableRow">
        <td className="">
          <div className="d-flex flex-column justify-content-center h-100 pr-3">
            <span className="cellTicketName">{invoice_template?.name}</span>
            <span className="cellTicketSerial">{serial}</span>
          </div>
        </td>
        <td className="">
          {canEditQuantityTicket && (
            <div className="d-flex flex-column justify-content-center">
              {noCar}
              <span>Số ghế: {noSeatCar}</span>
            </div>
          )}
        </td>
        <td>{Utils.formatDate(dateCreate, 'DD-MM-YYYY')}</td>
        <td>
          {_.isNumber(totalPrice) ? Utils.formatNumber(totalPrice) : null}
        </td>
        <td>
          <InputNumberNoFormik
            disabled={canEditQuantityTicket}
            className=""
            value={quantity}
            onBlur={(e) => {
              e.preventDefault()
              handleChangeQuantity(
                row,
                Utils.parseFormattedNumberToFloat(e.target.value),
              )
            }}
          />
        </td>

        <td>
          <div className="d-flex flex-row justify-content-center align-items-center h-100">
            <TableAction
              titleActionText="Xem"
              icon={<IconAction className="fa-regular fa-eye" />}
              onClick={exportTicketDraftItem}
            />
            <TableAction
              titleActionText="Sửa vé"
              icon={<IconAction className="fa-regular fa-pen-to-square" />}
              onClick={() => {
                const { invoiceTemplateId } = row
                handleSetActiveTicketTemplate(invoiceTemplateId)

                switch (activeTicketTemplate?.category_ticket?.typeServices) {
                  case 'VT':
                    setModalTicketDetailShow(true)
                    break
                  case 'GX':
                    setModalTicketGXDetailShow(true)
                    break
                }
              }}
            />
            <TableAction
              titleActionText="Xoá"
              icon={<IconAction className="fa-regular fa-trash" />}
              onClick={() => {
                handleRemoveRow(row)
              }}
            />
          </div>
        </td>
      </tr>

      {modalTicketDetailShow && (
        <ModalTicketDetail
          show={modalTicketDetailShow}
          header="Chi tiết vé"
          setShow={setModalTicketDetailShow}
          ticketTemplate={activeTicketTemplate}
          handleConfirm={handleChangeTicketItem}
          currentTicket={row}
          key={2}
        />
      )}

      {modalTicketGXDetailShow && (
        <ModalTicketGXDetail
          show={modalTicketGXDetailShow}
          header="Chi tiết vé"
          setShow={setModalTicketGXDetailShow}
          ticketTemplate={activeTicketTemplate}
          handleConfirm={handleChangeTicketItem}
          currentTicket={row}
          key={2}
        />
      )}
    </>
  )
}

TicketCreateTableRow.propTypes = {
  tableData: PropTypes.array,
  handleChangeTableData: PropTypes.func,
}

TicketCreateTableRow.defaultProps = {}
