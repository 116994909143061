/**
 * @type {import("antd").ThemeConfig}
 * @description Custom theme for Ant Design
 */
export const customTheme = {
  token: {
    fontFamily:
      "-apple-system, Inter, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    // fontFamily: "'Be Vietnam Pro', Helvetica, 'sans-serif'",
    colorPrimary: '#304ffd',
    colorSuccess: '#22c55e',
    colorWarning: '#ea580c',
    colorInfo: '#11AEFC',
    colorError: '#dc2626',
    colorText: '#3C4376',
  },
}
