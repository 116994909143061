import { useQuery } from '@tanstack/react-query'
import customDataTableStyle from 'assets/styles/customDataTableStyle'
import dayjs from 'dayjs'
import { listInvoiceFromPosQueries } from 'features/Invoice/screens/ListInvoiceFromPOS/queries'
import CustomDataTable from 'general/components/CustomDataTable'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import Utils from 'general/utils/Utils'
import useFilter from 'hooks/useFilter'
import { useMemo, useRef } from 'react'

/**
 *
 * @param {object} props
 * @param {boolean} props.show
 * @param {(v: boolean) => void} props.onOpenChange
 */
export default function ModalListInvoiceFromPOS(props) {
  const modalRef = useRef(null)
  const [filterParams, setFilterParams] = useFilter(
    'listInvoiceFromPOS',
    'gFilterListInvoiceFromPOS',
  )

  const {
    data: queryData,
    isLoading,
    isRefetching,
  } = useQuery(
    listInvoiceFromPosQueries.listInvoiceMttNeedSendTax(filterParams),
  )

  console.log('queryData', queryData)

  const columns = useMemo(() => {
    /** @type {import('react-data-table-component').TableColumn<InvoiceFromPos>[]} */
    const arr = [
      {
        id: '',
        name: 'Ký hiệu',
        center: true,
        selector: (row) => row.serial,
      },
      {
        id: '',
        name: 'Ngày hóa đơn',
        center: true,
        selector: (row) => row.date,
        format: (row) =>
          dayjs(row.date).isValid() ? dayjs(row.date).format('L') : '',
      },
      {
        id: '',
        name: 'Số hóa đơn',
        center: true,
        selector: (row) => row.no,
      },
      {
        id: '',
        name: 'Mã CQT',
        center: true,
        selector: (row) => row.taxAuthorityCode,
      },
      {
        id: '',
        name: 'Khách hàng',
        center: true,
        selector: (row) => row.customerCompanyName,
      },
      {
        id: '',
        name: 'Mã số thuế',
        center: true,
        selector: (row) => row.customerTaxCode,
      },
      {
        id: '',
        name: 'Tổng tiền',
        center: true,
        style: { justifyContent: 'flex-end' },
        selector: (row) =>
          props.invoiceType == 'TICKET' ? row.price : row.total,
        format: (row) =>
          props.invoiceType == 'TICKET'
            ? row.price
              ? Utils.formatNumber(row.price)
              : ''
            : row.totalAfterTax
              ? Utils.formatNumber(row.totalAfterTax)
              : '',
      },
    ]
    return arr
  }, [props.invoiceType])
  return (
    <DraggableModal
      ref={modalRef}
      title={`Danh sách dữ liệu ${props.invoiceType == 'TICKET' ? 'vé' : 'hoá đơn'} máy tính tiền gửi đến CQT`}
      description={
        `Số lượng ${props.invoiceType == 'TICKET' ? 'vé' : 'hoá đơn'} gửi: ` +
        Utils.formatNumber(
          props.invoiceType == 'TICKET'
            ? queryData?.tickets?.length
            : queryData?.invoices?.length,
        )
      }
      isOpen={props.show}
      onOpenChange={props.onOpenChange}
      handleApplyChanges={() => {
        alert('Gửi hoá đơn')
        props.onOpenChange(false)
      }}
      applyText="Gửi"
      cancelText="Hủy bỏ"
      width={'70%'}
    >
      <CustomDataTable
        scrollY="300px"
        columns={columns}
        dataSource={
          props.invoiceType == 'TICKET'
            ? queryData?.tickets
            : queryData?.invoices
        }
        customTableStyle={customDataTableStyle}
        pagination={{
          currentPage: filterParams.page,
          rowsPerPage: filterParams.limit,
          total:
            props.invoiceType == 'TICKET'
              ? queryData?.tickets?.length
              : queryData?.invoices?.length,
          onChangePage: (page) => setFilterParams({ page: parseInt(page) }),
          onChangeRowsPerPage: (limit) =>
            setFilterParams({ limit: parseInt(limit) }),
        }}
        isLoading={isLoading || isRefetching}
        persistTableHead
        noShowEmpty
      />
    </DraggableModal>
  )
}
