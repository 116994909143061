import { DatePicker, Input, InputNumber, theme, Tooltip } from 'antd'
import localeVi from 'antd/es/date-picker/locale/vi_VN'
import Utils from 'general/utils/Utils'
import isEmpty from 'lodash/isEmpty'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
/**
 * @typedef {'text' | 'number' | 'password' | 'textarea' | 'search'} TypeProp
 * @typedef {'base' | 'sm' | 'lg' | 'xs' | 'none'} SizeProps
 * @typedef {import('antd').InputProps | import('antd/es/input/TextArea').TextAreaProps | import('antd').InputNumberProps | import('antd').DatePickerProps} CustomInputProp
 */

/**
 * A custom input component that renders different types of inputs based on the 'type' prop.
 * @param {Object} props - The props object.
 * @param {TypeProp} props.type - The type of input to render.
 * @param {CustomInputProp} props.inputProps - The props object containing the input properties.
 * @param {SizeProps} props.borderRadius - The border radius type. Can be 'base', 'sm', 'lg', or 'xs'.
 * @param {string | number} props.width - The width of the input.
 * @param {boolean} props.loading - The loading state.
 * @param {import('react').Ref} props.ref - The ref object.
 * @returns {JSX.Element} - The JSX element representing the custom input component.
 */
function CustomAntInput(
  { type, borderRadius, inputProps, width, loading, placeholder },
  ref,
) {
  const { token } = theme.useToken()
  const { t } = useTranslation()
  /**
   * @type {import('react').CSSProperties}
   */
  const commonStyle = {
    padding: '0.5rem 0.75rem',
    borderRadius:
      borderRadius === 'base'
        ? token.borderRadius
        : borderRadius === 'sm'
          ? token.borderRadiusSM
          : borderRadius === 'lg'
            ? token.borderRadiusLG
            : borderRadius === 'xs'
              ? token.borderRadiusXS
              : borderRadius === 'none'
                ? 0
                : token.borderRadius,
    ...inputProps.style,
  }
  return (
    <>
      {type === 'text' && (
        <Input
          {...inputProps}
          style={commonStyle}
          ref={ref}
          styles={{
            input: {
              width: width ?? '100%',
            },
          }}
        />
      )}
      {type === 'number' && (
        <InputNumber
          {...inputProps}
          onKeyDown={(e) => Utils.removeLetterInInputNumber(e)}
          onKeyUp={(e) => Utils.removeLetterInInputNumber(e)}
          style={commonStyle}
          ref={ref}
        />
      )}
      {type === 'password' && (
        <Input.Password
          {...inputProps}
          style={commonStyle}
          ref={ref}
          styles={{
            input: {
              width: width ?? 200,
            },
          }}
        />
      )}
      {type === 'textarea' && (
        <Input.TextArea
          {...inputProps}
          style={{
            ...commonStyle,
          }}
          ref={ref}
        />
      )}
      {type === 'datepicker' && (
        <DatePicker
          changeOnBlur
          locale={localeVi}
          {...inputProps}
          style={{
            ...commonStyle,
            width: width ?? 200,
          }}
          ref={ref}
          format={'DD/MM/YYYY'}
          nextIcon={<i className=" fa-regular fa-angle-right"></i>}
          prevIcon={<i className=" fa-regular fa-angle-left"></i>}
          superNextIcon={<i className=" fa-regular fa-angle-double-right"></i>}
          superPrevIcon={<i className=" fa-regular fa-angle-double-left"></i>}
        />
      )}
      {type === 'search' && (
        <Input
          {...inputProps}
          allowClear={false}
          style={commonStyle}
          ref={ref}
          placeholder={inputProps.placeholder ?? t('Search') + '...'}
          prefix={
            <i
              className="fa-light fa-magnifying-glass"
              style={{
                marginRight: '3px',
              }}
            />
          }
          suffix={
            loading ? (
              <i className="fa-light fa-spinner-third fa-spin text-primary"></i>
            ) : isEmpty(inputProps.value) ? null : (
              <Tooltip title={t('ClearSearch')}>
                <i
                  className="fa-regular fa-times text-hover-danger mt-1 cursor-pointer"
                  onClick={() => {
                    inputProps.onChange({ target: { value: '' } })
                  }}
                ></i>
              </Tooltip>
            )
          }
          styles={{
            input: {
              minWidth: '200px',
              width: width ?? '100%',
            },
          }}
        />
      )}
    </>
  )
}
export default forwardRef(CustomAntInput)
