import { useMutation } from '@tanstack/react-query'
import accountApi from 'api/accountApi'
import { setAppSpinning } from 'app/appSlice'
import { setActiveInvoice } from 'app/authSlice'
import InvoiceIcon from 'assets/images/Einvoice/invoice.svg'
import PersonalCollectionDoc from 'assets/images/Einvoice/personal-collection-documents.svg'
import TicketIcon from 'assets/images/Einvoice/ticket.svg'
import DropdownHeaderAccountInfo from 'features/Others/components/DropdownHeaderAccountInfo'
import KTTooltip from 'general/components/eInvoiceComponents/KTTooltip'
import KTBSDropdown, {
  KTBSDropdowAutoCloseBehavior,
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown'
import { KTTooltipPositions } from 'general/components/OtherKeenComponents/KTTooltip'
import UserHelper from 'general/helpers/UserHelper'
import Utils from 'general/utils/Utils'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import useRouter from 'hooks/useRouter'
import { useMemo } from 'react'
import { Button, Nav } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

function TopBarHeader() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const currentLoggedInUser = useAppSelector(
    (state) => state.auth?.currentAccount,
  )
  const { navigate } = useRouter()

  const systemType = useAppSelector((s) => s.auth.activeInvoice, shallowEqual)

  const { mutate } = useMutation({
    mutationKey: ['changeTab', 'activeInvoice'],
    mutationFn: (param) => accountApi.changeActiveInvoice(param),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: (res) => {
      dispatch(setActiveInvoice(res.activeInvoice))
      const prefixPath = '/hoa-don-dau-ra'
      const toPath = res.activeInvoice.ticket
        ? prefixPath + '/tem-ve/ve-dien-tu/ve-da-xuat'
        : res.activeInvoice.invoice
          ? prefixPath + '/hoa-don/danh-sach-hoa-don'
          : prefixPath + ''
      navigate(toPath, {
        replace: true,
      })
    },
  })

  const items = useMemo(
    () => [
      {
        id: 'invoice',
        img: InvoiceIcon,
        text: 'Hoá đơn, PXK điện tử',
        isActive: systemType?.invoice,
        onClick: () => mutate({ invoice: true }),
      },
      {
        id: 'ticket',
        img: TicketIcon,
        text: 'Vé điện tử',
        isActive: systemType?.ticket,
        onClick: () => mutate({ ticket: true }),
      },
      {
        id: 'personalIncomeTax',
        img: PersonalCollectionDoc,
        text: 'Chứng từ thuế TNCN điện tử',
        isActive: systemType?.personalIncomeTax,
        onClick: () => mutate({ personalIncomeTax: true }),
      },
    ],
    [
      mutate,
      systemType?.invoice,
      systemType?.personalIncomeTax,
      systemType?.ticket,
    ],
  )

  return (
    <div className="topbar">
      <Nav
        // variant="pills"
        activeKey={items.find((item) => item.isActive)?.id}
        defaultActiveKey={items.find((item) => item.isActive)?.id}
        className="topbar-item font-weight-bold"
        onSelect={(key) => {
          items.find((item) => item.id === key)?.onClick()
        }}
      >
        {items.map((item) => (
          <>
            <Nav.Item>
              <Nav.Link as={'button'} eventKey={item.id}>
                {item.text}
              </Nav.Link>
            </Nav.Item>
          </>
        ))}
      </Nav>
      {/* border */}
      <div className="border-icon"></div>
      {/* <div className="topbar-item">
        <KTTooltip
          text={t('Procedure')}
          position={KTTooltipPositions.bottomEnd}
        >
          <Button
            disabled={systemType?.ticket}
            variant="icon"
            size="lg"
            className="btn-clean"
            onClick={() => {
              console.log('systemType', systemType)
              if (systemType?.invoice) {
                navigate('/hoa-don-dau-ra/quy-trinh-nghiep-vu')
              } else if (systemType?.personalIncomeTax) {
                navigate(
                  '/hoa-don-dau-ra/chung-tu-tncn/khau-tru-thue-thu-nhap-ca-nhan',
                )
              }
            }}
          >
            <i className="fa-regular fa-lightbulb-on" />
          </Button>
        </KTTooltip>
      </div> */}
      <div className="border-icon"></div>
      <KTBSDropdown
        contentEl={<DropdownHeaderAccountInfo />}
        toggleElement={
          <div className="topbar-item ml-2">
            <KTTooltip text={t('Account')} position={KTTooltipPositions.auto}>
              <div className="btn btn-icon btn-clean d-flex align-items-center btn-lg">
                <div className="symbol symbol-circle symbol-35 bg-white overflow-hidden">
                  <div className="symbol-label bg-primary">
                    <img
                      className="w-100 h-100 object-fit-cover"
                      loading="lazy"
                      alt="avatar"
                      src={Utils.getFullUrl(
                        UserHelper.getAvatar(currentLoggedInUser),
                      )}
                    />
                  </div>
                </div>
              </div>
            </KTTooltip>
          </div>
        }
        alignment={KTBSDropdownAlignments.end}
        autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
      />
    </div>
  )
}

export default TopBarHeader
