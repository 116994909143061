import { useMutation, useQuery } from '@tanstack/react-query'
import reportApi from 'api/reportApi'
import dayjs from 'dayjs'
import reportHelper from 'features/Report/reportHelper'
import {
  calcMaxCellWidth,
  generateCellObject,
  generateCellStyle,
} from 'general/helpers/ExcelsHelper'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import { useAppSelector } from 'hooks/useRedux'
import { get, isNil, isNumber, map, merge, repeat } from 'lodash'
import { utils, writeFile } from 'xlsx-js-style'
import $ from 'jquery'

const keyFactory = {
  base: { scope: ['report', 'usedInvoiceStatistic'] },
  lists: (q, f) => [
    {
      ...keyFactory.base,
      params: {
        ...f,
        q,
      },
    },
  ],
  exportExcel: () => [
    {
      ...keyFactory.base,
      action: 'exportExcel',
    },
  ],
}

export function useExportMutation(dynamicColumns = []) {
  const sysConfig = useAppSelector((s) => s.systemConfig.formatNumber)
  return useMutation({
    mutationKey: keyFactory.exportExcel(),
    mutationFn: (params) => reportApi.getUsedInvoices(params),
    onError: (err) => ToastHelper.showError(err?.message),
    onSuccess: (res, filterParams) => {
      if (res.result !== 'success')
        return ToastHelper.showError('Không có dữ liệu')
      const commonCellStyle = generateCellStyle({
        alignment: {
          horizontal: 'left',
          vertical: 'center',
        },
        isTextWrap: true,
        border: {
          directions: 'around',
          widthStyle: 'thin',
        },
      })
      const numberFormat = (config) =>
        config === 0 ? '#,##0' : `#,##0.${repeat('0', config)}`
      //====================HEADER====================
      const headerStyles = merge(
        {},
        commonCellStyle,
        generateCellStyle({
          font: {
            bold: true,
          },
          border: {
            directions: 'around',
            widthStyle: 'medium',
          },
        }),
      )

      const headers = dynamicColumns.map((col) =>
        generateCellObject(col.name, 's', headerStyles),
      )
      //====================CELLS====================
      const numberFormatStyle = merge(
        {},
        commonCellStyle,
        generateCellStyle({
          alignment: {
            horizontal: 'right',
          },
        }),
      )
      const cells = map(res?.rows, (row) => {
        const getFormatNumber = (key) =>
          isNil(row?.numberFormat)
            ? get(sysConfig, key, 0)
            : get(row?.numberFormat, key, 0)
        const xml = reportHelper.readXmlString(row?.xmlString)

        return map(dynamicColumns, (col) => {
          let denominator
          let rowValue = get(row, col.id, '')
          rowValue = isNil(rowValue) ? '' : rowValue
          switch (col.id) {
            default:
              return generateCellObject(rowValue, 's', commonCellStyle)
            case 'no':
              rowValue = xml ? $(xml).find('SHDon').text() : rowValue
              rowValue =
                rowValue !== '' ? Utils.padString(rowValue, 8, '0') : ''
              return generateCellObject(rowValue, 's', commonCellStyle)
            case 'denominator':
              rowValue = xml ? $(xml).find('KHMSHDon').text() : row?.serial[0]
              return generateCellObject(rowValue, 'n', commonCellStyle)
            case 'serial':
              rowValue = xml
                ? $(xml).find('KHMSHDon').text() + $(xml).find('KHHDon').text()
                : rowValue
              return generateCellObject(rowValue, 's', commonCellStyle)
            case 'date':
              rowValue = xml ? $(xml).find('NLap').text() : rowValue
              if (dayjs(rowValue).isValid())
                return generateCellObject(
                  dayjs(rowValue).toDate(),
                  'd',
                  commonCellStyle,
                  'DD/MM/YYYY',
                )
              return generateCellObject('', 's', commonCellStyle)
            case 'customerTaxCode':
              rowValue = xml ? $(xml).find('NMua > MST').text() : rowValue
              return generateCellObject(rowValue, 's', commonCellStyle)
            case 'customerCompanyName':
              rowValue = xml ? $(xml).find('NMua > Ten').text() : rowValue
              return generateCellObject(rowValue, 's', commonCellStyle)
            case 'customerFullAddress':
              rowValue = xml ? $(xml).find('NMua > DChi').text() : rowValue
              return generateCellObject(rowValue, 's', commonCellStyle)
            case 'taxCode':
              rowValue = xml ? $(xml).find('NMua > MST').text() : rowValue
              return generateCellObject(rowValue, 's', commonCellStyle)
            case 'customerName':
              rowValue = xml ? $(xml).find('NMua > HVTNMHang').text() : rowValue
              return generateCellObject(rowValue, 's', commonCellStyle)
            case 'total':
              denominator = xml
                ? $(xml).find('KHMSHDon').text()
                : row?.serial[0]
              rowValue = xml
                ? denominator === '2'
                  ? $(xml).find('TToan > TGTKhac').text() //chốt nếu xml không có trường này là lỗi, phải hiện theo xml thì đọc luôn k check null
                    ? Number($(xml).find('TToan > TgTTTBSo').text()) +
                      Number($(xml).find('TToan > TGTKhac').text())
                    : rowValue
                  : parseFloat($(xml).find('TToan > TgTCThue').text())
                : rowValue
              if (isNumber(rowValue)) {
                rowValue = Utils.formatNumber(
                  rowValue,
                  getFormatNumber('totalCost'),
                )
                  .replace(/\./g, '')
                  .replace(/,/g, '.')
                return generateCellObject(
                  rowValue,
                  'n',
                  numberFormatStyle,
                  numberFormat(
                    row?.numberFormat
                      ? row?.numberFormat?.totalCost
                      : sysConfig.totalCost,
                  ),
                )
              }
              return generateCellObject(rowValue, 's', commonCellStyle)
            case 'discountMoney':
              rowValue = xml
                ? parseFloat($(xml).find('TToan > TTCKTMai').text())
                : rowValue
              if (isNaN(rowValue)) rowValue = ''
              if (isNumber(rowValue)) {
                rowValue = Utils.formatNumber(
                  rowValue,
                  getFormatNumber('currencyExchange'),
                )
                  .replace(/\./g, '')
                  .replace(/,/g, '.')
                return generateCellObject(
                  rowValue,
                  'n',
                  numberFormatStyle,
                  numberFormat(
                    row?.numberFormat
                      ? row?.numberFormat?.totalCost
                      : sysConfig.totalCost,
                  ),
                )
              }
              return generateCellObject(rowValue, 's', commonCellStyle)
            case 'taxMoney':
              rowValue = xml
                ? parseFloat($(xml).find('TToan > TgTThue').text())
                : rowValue
              if (isNaN(rowValue)) rowValue = ''
              if (isNumber(rowValue)) {
                rowValue = Utils.formatNumber(
                  rowValue,
                  getFormatNumber('totalCost'),
                )
                  .replace(/\./g, '')
                  .replace(/,/g, '.')
                return generateCellObject(
                  rowValue,
                  'n',
                  numberFormatStyle,
                  numberFormat(
                    row?.numberFormat
                      ? row?.numberFormat?.totalCost
                      : sysConfig.totalCost,
                  ),
                )
              }
              if (isNaN(rowValue)) rowValue = ''

              return generateCellObject(rowValue, 's', commonCellStyle)

            case 'taxReduction':
              denominator = xml
                ? $(xml).find('KHMSHDon').text()
                : row?.serial[0]
              rowValue =
                denominator === '2'
                  ? $(xml).find('TToan > TGTKhac').text() //chốt nếu xml không có trường này là lỗi, phải hiện theo xml thì đọc luôn k check null
                    ? parseFloat($(xml).find('TToan > TGTKhac').text())
                    : rowValue !== 0
                      ? rowValue
                      : ''
                  : ''
              if (isNaN(rowValue)) rowValue = ''
              if (isNumber(rowValue)) {
                rowValue = Utils.formatNumber(
                  rowValue,
                  getFormatNumber('totalCost'),
                )
                  .replace(/\./g, '')
                  .replace(/,/g, '.')
                return generateCellObject(
                  rowValue,
                  'n',
                  numberFormatStyle,
                  numberFormat(
                    row?.numberFormat
                      ? row?.numberFormat?.totalCost
                      : sysConfig.totalCost,
                  ),
                )
              }
              if (isNaN(rowValue)) rowValue = ''

              return generateCellObject(rowValue, 's', commonCellStyle)

            case 'totalAfterTax':
              rowValue = xml
                ? parseFloat($(xml).find('TToan > TgTTTBSo').text())
                : rowValue
              if (isNaN(rowValue)) rowValue = ''
              if (isNumber(rowValue)) {
                rowValue = Utils.formatNumber(
                  rowValue,
                  getFormatNumber('totalCost'),
                )
                  .replace(/\./g, '')
                  .replace(/,/g, '.')
                return generateCellObject(
                  rowValue,
                  'n',
                  numberFormatStyle,
                  numberFormat(
                    row?.numberFormat
                      ? row?.numberFormat?.totalCost
                      : sysConfig.totalCost,
                  ),
                )
              }
              return generateCellObject(rowValue, 's', commonCellStyle)

            case 'type':
              rowValue = reportHelper.getInvoiceTypes(rowValue)
              return generateCellObject(rowValue ?? '', 's', commonCellStyle)
            case 'issueStatus':
              rowValue = reportHelper.getInvoiceStatus(rowValue)
              return generateCellObject(rowValue ?? '', 's', commonCellStyle)
            case 'searchCode':
              rowValue = reportHelper.getSearchCode(
                row?.invoiceCode,
                row?.lookupCode,
              )
              return generateCellObject(rowValue ?? '', 's', commonCellStyle)
          }
        })
      })
      const aoa = [headers, ...cells]
      const newBook = utils.book_new()
      const ws = utils.aoa_to_sheet(aoa)
      utils.book_append_sheet(newBook, ws, 'sheet 1')
      ws['!cols'] = calcMaxCellWidth(aoa, ws['!ref'], 15)
      writeFile(
        newBook,
        `Bảng kê hóa đơn đã sử dụng từ ${dayjs(filterParams.startDate).format(
          'L',
        )} đến ${dayjs(filterParams.endDate).format('L')}.xlsx`,
        {
          cellStyles: true,
          sheetStubs: true,
        },
      )
    },
  })
}

export function useGetInvoiceStatistic(debouncedSearch, filterParams) {
  return useQuery({
    enabled: !isNil(filterParams),
    queryKey: keyFactory.lists(debouncedSearch, filterParams),
    queryFn: ({ signal }) =>
      reportApi.getUsedInvoices(
        {
          ...filterParams,
          q: debouncedSearch,
          classify: 'ALL',
        },
        signal,
      ),
    select: (res) => {
      return {
        rows: get(res, 'rows', []),
        total: get(res, 'count', 0),
      }
    },
  })
}
