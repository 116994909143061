import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Flex, Space, Tooltip } from 'antd'
import invoiceApi from 'api/invoiceApi'
import invoiceReportApi from 'api/invoiceReportApi'
import { setAppSpinning } from 'app/appSlice'
import { hasPinnedActionCol } from 'assets/styles/DatatableStyles/styles'
import dayjs from 'dayjs'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import Empty from 'general/components/Empty'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import { IconAction } from 'general/components/GroupButton/styles'
import TableAction from 'general/components/GroupButton/TableAction'
import Loading from 'general/components/Loading'
import CancelInvoiceModal from 'general/components/Modal/CancelInvoiceModal'
import FindInvoiceModal from 'general/components/Modal/FindInvoiceModal'
import ModalSendNoticeCancel from 'general/components/Modal/ModalSendNoticeCancel'
import ModalUnauthorized from 'general/components/Modal/ModalUnauthorized'
import SendInvoiceReportModal from 'general/components/Modal/SendInvoiceReportModal'
import Pagination from 'general/components/Pagination'
import AppResource from 'general/constants/AppResource'
import SelectOptions from 'general/constants/SelectOptions'
import InvoiceReportHelper from 'general/helpers/InvoiceReportHelper'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import useFilter from 'hooks/useFilter'
import useRouter from 'hooks/useRouter'
import useSearchDebounce from 'hooks/useSearchDebounce'
import _ from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { queries } from './queries'
import './style.scss'
import { useAppSelector } from 'hooks/useRedux'

function ListCancelledInvoice() {
  // MARK --- Parmas: ---
  const modalSentMailRef = useRef(null)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { navigate } = useRouter()
  const { debouncedSearch, search, setSearch } = useSearchDebounce('')
  const [activeInvoice, setActiveInvoice] = useState(null)
  const [cancelModalShow, setCancelModalShow] = useState(false)
  const [FindInvoiceModalShow, setFindInvoiceModalShow] = useState(false)
  const [activeInvoiceCancellationReport, setActiveInvoiceCancellationReport] =
    useState()
  const [showSendCancellationReportModal, setShowSendCancellationReportModal] =
    useState(false)
  const [sentMailModalShow, setSentMailModalShow] = useState(false)

  const [showNoRight, setShowNoRight] = useState(false)
  const listFunction = useAppSelector((state) => state?.function?.listFunctions)
  const userFunction = useAppSelector((state) => state?.function?.userFunctions)
  const [isCreateCancelInvoice] = useMemo(() => {
    return Utils.checkFunction(
      [
        {
          listFunctionCode: 'INVOICE_CREATE_CANCEL_INVOICE',
        },
      ],
      userFunction,
      listFunction,
    )
  }, [userFunction, listFunction])
  const [filters, setFilters] = useFilter(
    'cancelledInvoiceList',
    'gFilterCancelledInvoice',
  )
  const queryClient = useQueryClient()
  const { data: listCancelledData, isFetching: gettingListData } = useQuery(
    queries.lists(filters),
  )

  const { mutate: viewPdfInvoice } = useMutation({
    mutationKey: 'viewPdfInvoice',
    mutationFn: (invoiceId) => invoiceApi.exportInvoiceById(invoiceId),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: (res) =>
      Utils.openFileInNewTab({
        blobData: res,
        fileName: 'Hóa đơn.pdf',
        fileType: 'application/pdf',
      }),
  })

  const { mutate: cancelInvoice } = useMutation({
    mutationKey: 'cancelInvoice',
    mutationFn: (values) =>
      invoiceApi.cancel(activeInvoice?.invoiceId, {
        ...values,
        ccEmails: splitInputIntoArr(values.customerEmailCc),
        bccEmails: splitInputIntoArr(values.customerEmailBcc),
      }),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onError: () => ToastHelper.showError('Huỷ hoá đơn thất bại'),
    onSuccess: () => {
      setCancelModalShow(false)
      setFindInvoiceModalShow(false)
      queryClient.invalidateQueries(queries.lists().queryKey)
    },
  })

  const splitInputIntoArr = (input) =>
    _.split(input, ';').filter((item) => item) ?? []

  const columns = useMemo(
    () => [
      {
        name: 'Đơn vị',
        center: true,
        width: '300px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.customerCompanyName}
            </p>
          )
        },
      },
      {
        name: 'Biên bản điện tử',
        center: true,
        width: '160px',
        style: { justifyContent: 'space-between' },
        cell: (row) => {
          const no = row?.report?.no && Utils.padString(row?.report?.no, 8, '0')
          return (
            <>
              <Tooltip
                title={InvoiceReportHelper.renderInvoiceReportStatus(
                  row?.reportStatus,
                )}
                placement="bottom"
              >
                <p
                  data-tag="allowRowEvents"
                  className={`text-hover-decoration font-weight-normal m-0 text-maxline-3 mr-4 ${
                    no ? 'text-danger' : 'text-primary'
                  }`}
                  onClick={() => {
                    // if (no) {
                    let invoiceId = row.invoiceId
                    if (row.reportStatus == 1) {
                      navigate(
                        `/hoa-don-dau-ra/hoa-don/bien-ban-huy/them-moi/${invoiceId}`,
                      )
                    }

                    if (row.reportStatus == 2) {
                      navigate(
                        `/hoa-don-dau-ra/hoa-don/bien-ban-huy/cap-nhat/${invoiceId}`,
                      )
                    }

                    if (![1, 2].includes(row.reportStatus)) {
                      navigate(
                        `/hoa-don-dau-ra/hoa-don/bien-ban-huy/xem/${invoiceId}`,
                      )
                    }
                    // }
                  }}
                >
                  {no ?? 'Lập biên bản'}
                </p>
              </Tooltip>
              {![1, 2].includes(row.reportStatus) && (
                <i
                  className="fa-light fa-eye"
                  style={{ color: 'inherit' }}
                  title="Xem biên bản điện tử"
                  onClick={async () => {
                    let res

                    dispatch(setAppSpinning(true))
                    try {
                      res = await invoiceReportApi.preview({
                        reportId: row.report?.reportId,
                      })
                      dispatch(setAppSpinning(false))

                      let _newFile = new File([res], 'Biên bản hủy.pdf', {
                        type: 'application/pdf',
                      })
                      var _fileURL = URL.createObjectURL(_newFile)
                      window.open(_fileURL, '_blank')
                    } catch (e) {
                      dispatch(setAppSpinning(false))
                    }
                  }}
                ></i>
              )}
            </>
          )
        },
      },
      {
        name: 'Ký hiệu',
        center: true,
        width: '120px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.serial}
            </p>
          )
        },
      },
      {
        name: 'Ngày HĐ',
        center: true,
        width: '120px',
        cell: (row) => {
          const date = dayjs(row?.date)
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {date.isValid() ? date.format('L') : ''}
            </p>
          )
        },
      },
      {
        name: 'Số hóa đơn',
        center: true,
        width: '110px',
        cell: (row) => {
          const no = row?.no && Utils.padString(row?.no, 8, '0')
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {no}
            </p>
          )
        },
      },
      {
        name: 'Mã của CQT',
        center: true,
        // sortable: false,
        // right: true,
        width: '200px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.taxAuthorityCode}
            </p>
          )
        },
      },
      {
        name: 'Khách hàng',
        center: true,
        // sortable: false,
        // right: true,
        width: '200px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.customerCompanyName}
            </p>
          )
        },
      },
      {
        name: 'Mã số thuế',
        center: true,
        // sortable: false,
        // right: true,
        width: '200px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.customerTaxCode}
            </p>
          )
        },
      },
      {
        name: 'Tổng tiền',
        center: true,
        // sortable: false,
        // right: true,
        width: '200px',
        cell: (row) => {
          const totalAfterTax =
            row?.totalAfterTax && Utils.padString(row?.totalAfterTax, 8, '0')
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {totalAfterTax} {row?.currency}
            </p>
          )
        },
      },
      {
        name: 'Ngày hủy',
        center: true,
        // sortable: false,
        // right: true,
        width: '200px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.cancelDate && dayjs(row.cancelDate).format('DD/MM/YYYY')}
            </p>
          )
        },
      },
      {
        name: 'Lý do huỷ',
        center: true,
        // sortable: false,
        // right: true,
        width: '200px',
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className="text-dark-75 font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.errAnnouceReason}
            </p>
          )
        },
      },

      {
        name: 'Hành động',
        center: true,
        minWidth: '100px',
        compact: true,
        cell: (row) => {
          return (
            <Flex justify="flex-end" className="w-100">
              <TableAction
                icon={<IconAction className="fa-light fa-eye" />}
                titleActionText="Xem HĐ"
                onClick={() => viewPdfInvoice(row?.invoiceId)}
              />
              {[3, 4].includes(row?.reportStatus) && (
                <TableAction
                  titleActionText="Gửi biên bản điện tử"
                  icon={<IconAction className="fa-light fa-paper-plane" />}
                  onClick={() => {
                    setActiveInvoiceCancellationReport(row)
                    setShowSendCancellationReportModal(true)
                  }}
                />
              )}
              <TableAction
                icon={<IconAction className="fa-light fa-envelope" />}
                titleActionText="Gửi thông báo hủy HĐ"
                onClick={() => {
                  setActiveInvoiceCancellationReport(row)
                  setSentMailModalShow(true)
                }}
              />
            </Flex>
          )
        },
      },
    ],
    [dispatch, navigate, viewPdfInvoice],
  )
  // Effects

  useEffect(() => {
    setFilters({
      q: debouncedSearch,
      page: 1,
    })
  }, [debouncedSearch, setFilters])
  return (
    <ContentContainer wrapperClassName="ListCancelledInvoice">
      <ContentContainer.Header
        titleContent={t('Bảng kê hóa đơn huỷ')}
        toolBar={
          <Space>
            <CustomAntButton
              text="Hủy hóa đơn"
              iconCn="fa-regular fa-plus"
              antProps={{
                type: 'primary',
                onClick: () => {
                  if (isCreateCancelInvoice) setFindInvoiceModalShow(true)
                  if (!isCreateCancelInvoice) setShowNoRight(true)
                  // navigate('/hoa-don/huy-bo');
                },
              }}
            />
          </Space>
        }
      >
        <Space>
          <CustomAntInput
            borderRadius="sm"
            type="search"
            inputProps={{
              defaultValue: search,
              onChange: (e) => setSearch(e.target.value),
            }}
          />
          <FilterPopover
            defaultValues={[
              {
                label: 'Trạng thái hoá đơn huỷ',
                alias: 'Trạng thái HĐ huỷ',
                formName: 'cancelInvoiceStatus',
                options: SelectOptions.cancelInvoiceStatus,
                value: SelectOptions.cancelInvoiceStatus[0].value,
              },
              {
                label: 'Trạng thái thông báo sai sót',
                alias: 'Trạng thái TBSS',
                formName: 'invoiceErrAnnouceStatus',
                options: SelectOptions.invoiceErrAnnouceStatus,
                value: SelectOptions.invoiceErrAnnouceStatus[0].value,
              },
              {
                label: 'Trạng thái biên bản',
                alias: 'Trạng thái BB',
                formName: 'reportStatus',
                options: SelectOptions.reportStatus,
                value: SelectOptions.reportStatus[0].value,
                colSpan: 'FULL',
              },
            ]}
            onApply={setFilters}
            savedKey="gFilterCancelledInvoice"
          />
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <DataTable
          striped
          className="max-h-100 flex-grow-1"
          fixedHeader
          columns={columns}
          data={listCancelledData?.data}
          customStyles={hasPinnedActionCol}
          responsive={true}
          noDataComponent={
            <div className="pt-12">
              <Empty
                text={t('NoData')}
                visible={false}
                imageEmpty={AppResource.icons.icEmptyBox}
                imageEmptyPercentWidth={170}
              />
            </div>
          }
          progressPending={gettingListData}
          progressComponent={
            <Loading showBackground={false} message={`${t('Loading')}...`} />
          }
          pointerOnHover
          highlightOnHover
          onRowClicked={(row) => {
            setActiveInvoiceCancellationReport(row)
          }}
        />
        {listCancelledData?.total > 0 && (
          <div className="d-flex align-items-center justify-content-center mt-3">
            <Pagination
              rowsPerPage={filters?.limit}
              rowCount={listCancelledData?.total}
              currentPage={filters?.page}
              onChangePage={(newPage) => {
                let iNewPage = parseInt(newPage)
                setFilters({
                  page: iNewPage,
                })
              }}
              onChangeRowsPerPage={(newPerPage) => {
                const iNewPerPage = parseInt(newPerPage)
                setFilters({
                  limit: iNewPerPage,
                  page: 1,
                })
              }}
            />
          </div>
        )}
      </ContentContainer.Body>
      {cancelModalShow && (
        <CancelInvoiceModal
          show={cancelModalShow}
          onHide={() => setCancelModalShow(false)}
          title="Huỷ hoá đơn"
          // invoiceId={selectedInvoiceId}
          invoice={activeInvoice}
          buttonAgreeOnClick={cancelInvoice}
        />
      )}

      {FindInvoiceModalShow && (
        <FindInvoiceModal
          show={FindInvoiceModalShow}
          onHide={() => setFindInvoiceModalShow(false)}
          title="Chọn hoá đơn thực hiện huỷ"
          showTab={false}
          checkBoxShow={false}
          onRowClicked={(row) => {
            console.log(row)
            setActiveInvoice(row)
            setCancelModalShow(true)
          }}
        />
      )}

      <SendInvoiceReportModal
        reportType={'CANCEL'}
        invoice={activeInvoiceCancellationReport}
        show={showSendCancellationReportModal}
        onHide={() => setShowSendCancellationReportModal(false)}
        addonsSubmitAction={() =>
          queryClient.invalidateQueries(queries.lists().queryKey)
        }
      />
      <ModalSendNoticeCancel
        ref={modalSentMailRef}
        isOpen={sentMailModalShow}
        invoiceId={activeInvoiceCancellationReport?.invoiceId}
        onOpenChange={setSentMailModalShow}
      />
      <ModalUnauthorized show={showNoRight} setShow={setShowNoRight} />
    </ContentContainer>
  )
}

export default ListCancelledInvoice
