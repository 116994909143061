import { Button, DatePicker, Popover, Space, Typography } from 'antd'
import localeVn from 'antd/es/date-picker/locale/vi_VN'
import { useFormik } from 'formik'
import { PERIOD } from 'general/constants/AppConstants'
import SelectOptions from 'general/constants/SelectOptions'
import { transformToStartAndEnd } from 'general/helpers/DateHelper'
import Utils from 'general/utils/Utils'
import useKeyboardPress from 'hooks/useKeyboardPress'
import useToken from 'hooks/useToken'
import _ from 'lodash'
import { Fragment, useCallback, useMemo, useState } from 'react'
import CustomAntButton from '../Button/CustomAntButton'
import CustomAntSelect from '../CustomAntSelect'
import { useAppSelector } from 'hooks/useRedux'
import { shallowEqual } from 'react-redux'

const dateOptions = SelectOptions.periodFilterOptions

/**
 * @param {object} props
 * @param {'sm' | 'md' | 'lg'} [props.borderRadius]
 * @param {(range?: [dayjs.Dayjs, dayjs.Dayjs], period ?: string) => void} props.onChange
 * @param {import('antd').PopoverProps['placement']} [props.position]
 * @param {string | import('react').ReactNode} [props.separator]
 * @param {string} props.dateFormat
 * @param {string} [props.className]
 * @param {[dayjs.Dayjs, dayjs.Dayjs]} props.defaultValue
 * @param {string} [props.defaultPeriod]
 * @param {React.CSSProperties} [props.customStyles]
 * @param {React.CSSProperties['color']} [props.colorStyle]
 * @param {Array<object>} [props.additionalFilter]
 */
export default function CustomAntRangePicker(props) {
  const [isOpen, setIsOpen] = useState(false)
  const { token } = useToken()
  /** @type {import('react').CSSProperties} */
  const baseStyles = {
    minWidth: '20rem',
    borderRadius:
      props.borderRadius === 'sm'
        ? token.borderRadiusSM
        : props.borderRadius === 'md'
          ? token.borderRadius
          : props.borderRadius === 'lg'
            ? token.borderRadiusLG
            : token.borderRadius,
  }
  const systemType = useAppSelector((s) => s.auth.activeInvoice, shallowEqual)
  const styles = _.merge({}, baseStyles, props.customStyles)
  const formik = useFormik({
    initialValues: {
      period: _.isNil(props.defaultPeriod)
        ? PERIOD.RECENT_30_DAYS
        : SelectOptions.periodFilterOptions.find(
            (v) => v.value === props.defaultPeriod,
          )?.value,
      startDate:
        props.defaultValue[0] ?? transformToStartAndEnd('RECENT_30_DAYS')[0],
      endDate:
        props.defaultValue[1] ?? transformToStartAndEnd('RECENT_30_DAYS')[1],
      ..._.fromPairs(
        _.map(
          props.additionalFilter,
          (item) => [item.formName, item.value] ?? null,
        ),
      ),
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const additionalFilter = {}
      _.forEach(props.additionalFilter, (item) => {
        if (item.onAdditionalFilterChange) {
          additionalFilter[item.formName] = values[item.formName]
        }
      })
      props.onChange(
        [values.startDate, values.endDate],
        values.period,
        additionalFilter,
      )
      setIsOpen(false)
      setTempValue(_.clone(values))
    },
  })
  const [tempValue, setTempValue] = useState(() => _.clone(formik.values))
  const buttonText = useMemo(() => {
    const period = dateOptions.find((v) => v.value === formik.values.period)
    if (period) {
      return period.label
    }
    return Utils.cn(
      formik.values.startDate.format(props.dateFormat),
      props.separator ?? ' - ',
      formik.values.endDate.format(props.dateFormat),
    )
  }, [
    dateOptions,
    formik.values.endDate,
    formik.values.period,
    formik.values.startDate,
    props.dateFormat,
    props.separator,
  ])
  const handleTogglePopover = useCallback(
    (state) => {
      setIsOpen((prev) => state ?? !prev)
      formik.setValues(tempValue)
    },
    [formik, tempValue],
  )
  const handleCancel = useCallback(() => {
    formik.setValues(tempValue)
    setIsOpen(false)
  }, [formik, tempValue])
  /**
   * @type {import('antd').DatePickerProps['cellRender']}
   */
  const customCellRender = useCallback(
    (current, info) => {
      if (info.type !== 'date') return info.originNode
      if (typeof current === 'number')
        return <div className="ant-picker-cell-inner">{current}</div>
      return (
        <div
          title={
            formik.values.endDate.isSame(current, 'date')
              ? 'Đến ngày'
              : formik.values.startDate.isSame(current, 'date')
                ? 'Từ ngày'
                : current.format('L')
          }
          className="ant-picker-cell-inner"
          style={
            formik.values.endDate.isSame(current, 'date')
              ? {
                  color: token.colorWhite,
                  backgroundColor: token.colorTextSecondary,
                }
              : formik.values.startDate.isSame(current, 'date')
                ? {
                    color: token.colorWhite,
                    backgroundColor: token.colorPrimary,
                  }
                : {}
          }
        >
          {current.date()}
        </div>
      )
    },
    [
      formik.values.endDate,
      formik.values.startDate,
      token.colorPrimary,
      token.colorTextSecondary,
      token.colorWhite,
    ],
  )
  useKeyboardPress('Escape', () => setIsOpen(false))
  return (
    <Popover
      trigger="click"
      getPopupContainer={(trigger) => trigger.parentElement}
      open={isOpen}
      onOpenChange={(vis) => handleTogglePopover(vis)}
      placement={props.position ?? 'bottomLeft'}
      destroyTooltipOnHide
      arrow={false}
      overlayInnerStyle={styles}
      title="Chọn khoảng thời gian"
      content={
        <Space direction="vertical" className="w-100">
          <CustomAntSelect
            className="w-100"
            notFoundContent="Không tìm thấy lựa chọn"
            placeholder="Chọn hoặc tìm kiếm"
            options={dateOptions}
            value={formik.values.period}
            listHeight={250}
            onChange={(value) => {
              const dateRange = transformToStartAndEnd(value)
              formik.setFieldValue('period', value)
              if (_.isEmpty(dateRange)) return
              formik.setFieldValue('startDate', dateRange[0])
              formik.setFieldValue('endDate', dateRange[1])
            }}
            suffixIcon={<i className="fa-regular fa-angle-down"></i>}
          />
          {_.range(0, 2).map((idx) => (
            <Fragment key={idx}>
              <Typography.Text strong>
                {idx === 0 ? 'Từ ngày' : 'Đến ngày'}
              </Typography.Text>
              <DatePicker
                {...formik.getFieldProps(idx === 0 ? 'startDate' : 'endDate')}
                placement="bottomLeft"
                allowClear={false}
                value={formik.values[idx === 0 ? 'startDate' : 'endDate']}
                onChange={(date) => {
                  formik.setFieldValue(
                    idx === 0 ? 'startDate' : 'endDate',
                    date,
                  )
                  formik.setFieldValue('period', PERIOD.CUSTOM)
                }}
                changeOnBlur
                locale={localeVn}
                format={props.dateFormat}
                cellRender={customCellRender}
                disabledDate={
                  idx === 0
                    ? null
                    : (current) => {
                        return (
                          current && current.isBefore(formik.values.startDate)
                        )
                      }
                }
                nextIcon={<i className=" fa-regular fa-angle-right"></i>}
                prevIcon={<i className=" fa-regular fa-angle-left"></i>}
                superNextIcon={
                  <i className=" fa-regular fa-angle-double-right"></i>
                }
                superPrevIcon={
                  <i className=" fa-regular fa-angle-double-left"></i>
                }
                style={{
                  width: '100%',
                }}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Fragment>
          ))}
          <>
            {_.isEmpty(props.additionalFilter)
              ? null
              : props.additionalFilter.map((item, idx) => (
                  <Fragment key={`add-item-${idx + 1}`}>
                    <Typography.Text strong>{item.label}</Typography.Text>
                    <CustomAntSelect
                      placeholder="Chọn hoặc tìm kiếm"
                      isMultiSelect={item.isMultiSelect}
                      value={formik.values[item.formName]}
                      options={item.options}
                      allowClear
                      onChange={(value) => {
                        formik.setFieldValue(item.formName, value)
                        // item.onAdditionalFilterChange(value)
                      }}
                    />
                  </Fragment>
                ))}
          </>
          <Space>
            <CustomAntButton
              text="Huỷ"
              antProps={{
                type: 'default',
                onClick: handleCancel,
              }}
            />
            <CustomAntButton
              text="Áp dụng"
              antProps={{
                type: 'primary',
                onClick: formik.submitForm,
              }}
            />
          </Space>
        </Space>
      }
    >
      <Button
        title={buttonText}
        type="default"
        // onClick={handleTogglePopover}
        className={props.className}
        style={{
          borderColor: props.colorStyle,
          padding: '8px 13px',
          fontWeight: '600',
          height: 'auto',
          display: 'inline-flex',
          width: '200px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span className="mr-2">{buttonText}</span>
        <i className="far fa-chevron-down" />
      </Button>
    </Popover>
  )
}
