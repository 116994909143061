import { Image } from 'antd'
import { env } from 'general/utils/env'
import Utils from 'general/utils/Utils'
import useProjectBranding from 'hooks/useProjectBranding'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

function GuestHeader() {
  // MARK --- Params: ---
  const { t } = useTranslation()
  const { logo } = useProjectBranding()
  // MARK --- Functions: ---
  function handleNavigation(path) {
    window.open(path)
  }

  return (
    <div
      className="d-flex flex-row justify-content-between align-items-center px-md-12"
      style={{
        boxShadow: '0 2px 5px 0 rgb(0 0 0 / 26%)',
        backgroundColor: '#f0f0f0',
      }}
    >
      <div
        className={Utils.cn(
          'container-xl d-flex flex-row align-items-center',
          ['TULUC', 'SONPHAT'].includes(env.PROJECT_TYPE)
            ? 'justify-content-center'
            : 'justify-content-between',
        )}
      >
        <Image preview={false} className="h-50px" src={logo} alt="logo" />
        {['TULUC', 'SONPHAT'].includes(env.PROJECT_TYPE) ? null : (
          <ul
            className="text-uppercase font-weight-bolder overflow-hidden d-none d-lg-block m-0"
            style={{
              listStyleType: 'none',
              color: '#2567b2',
            }}
          >
            <li
              className="d-inline mr-4 cursor-pointer"
              onClick={() =>
                handleNavigation('https://vietinvoice.vn/gioi-thieu')
              }
            >
              {t('Introduction')}
            </li>
            <li
              className="d-inline mx-4 cursor-pointer"
              onClick={() =>
                handleNavigation('https://vietinvoice.vn/bang-gia')
              }
            >
              {t('Price list')}
            </li>
            <li
              className="d-inline mx-4 cursor-pointer"
              onClick={() =>
                handleNavigation('https://vietinvoice.vn/mau-hoa-don')
              }
            >
              {t('Invoice template')}
            </li>
            <li
              className="d-inline mx-4 cursor-pointer"
              onClick={() =>
                handleNavigation('https://vietinvoice.vn/huong-dan')
              }
            >
              {t('Guide')}
            </li>
            <li
              className="d-inline mx-4 cursor-pointer"
              onClick={() => handleNavigation('https://vietinvoice.vn/van-ban')}
            >
              {t('Document')}
            </li>
            <li
              className="d-inline mx-4 cursor-pointer"
              onClick={() =>
                handleNavigation('https://vietinvoice.vn/khach-hang')
              }
            >
              {t('Customer')}
            </li>
            <li
              className="d-inline mx-4 cursor-pointer"
              onClick={() => handleNavigation('https://vietinvoice.vn/tin-tuc')}
            >
              {t('News')}
            </li>
            <li
              className="d-inline mx-4 cursor-pointer"
              onClick={() => handleNavigation('https://vietinvoice.vn/lien-he')}
            >
              {t('Contact')}
            </li>
            <li
              className="d-inline ml-4 cursor-pointer"
              onClick={() => handleNavigation('https://vietinvoice.vn/tai-ve')}
            >
              {t('Download')}
            </li>
          </ul>
        )}
        <Dropdown className="d-lg-none">
          <Dropdown.Toggle variant="" id="dropdown-basic">
            <i className="fas fa-bars text-dark-75"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              className="font-weight-bolder"
              onClick={() =>
                handleNavigation('https://vietinvoice.vn/gioi-thieu')
              }
            >
              {t('Introduction')}
            </Dropdown.Item>
            <Dropdown.Item
              className="font-weight-bolder"
              onClick={() =>
                handleNavigation('https://vietinvoice.vn/bang-gia')
              }
            >
              {t('Price list')}
            </Dropdown.Item>
            <Dropdown.Item
              className="font-weight-bolder"
              onClick={() =>
                handleNavigation('https://vietinvoice.vn/mau-hoa-don')
              }
            >
              {t('Invoice template')}
            </Dropdown.Item>
            <Dropdown.Item
              className="font-weight-bolder"
              onClick={() =>
                handleNavigation('https://vietinvoice.vn/huong-dan')
              }
            >
              {t('Guide')}
            </Dropdown.Item>
            <Dropdown.Item
              className="font-weight-bolder"
              onClick={() => handleNavigation('https://vietinvoice.vn/van-ban')}
            >
              {t('Document')}
            </Dropdown.Item>
            <Dropdown.Item
              className="font-weight-bolder"
              onClick={() =>
                handleNavigation('https://vietinvoice.vn/khach-hang')
              }
            >
              {t('Customer')}
            </Dropdown.Item>
            <Dropdown.Item
              className="font-weight-bolder"
              onClick={() => handleNavigation('https://vietinvoice.vn/tin-tuc')}
            >
              {t('News')}
            </Dropdown.Item>
            <Dropdown.Item
              className="font-weight-bolder"
              onClick={() => handleNavigation('https://vietinvoice.vn/lien-he')}
            >
              {t('Contact')}
            </Dropdown.Item>
            <Dropdown.Item
              className="font-weight-bolder"
              onClick={() => handleNavigation('https://vietinvoice.vn/tai-ve')}
            >
              {t('Download')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

export default GuestHeader
