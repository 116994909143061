import { Checkbox, DatePicker, Input } from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import CustomAntSelect from 'general/components/CustomAntSelect'
import CustomSelect from 'general/components/CustomSelect'
import Tagnify from 'general/components/CustomTag/Tagnify'
import AntdDropdown from 'general/components/Dropdown/AntdDropdown'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import CustomTimePicker from 'general/components/Forms/CustomTimePicker'
import FormLabel from 'general/components/Forms/FormLabel'
import InputNumberNoFormik from 'general/components/Forms/InputNumerNoFormik'
import {
  ROUTE_TYPE,
  TICKET_TYPE_DETUCT,
  TYPE_PRICE_TICKET,
} from 'general/constants/AppConstants'
import SelectOptions from 'general/constants/SelectOptions'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import { useAppSelector } from 'hooks/useRedux'
import _, { includes, split } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { string } from 'yup'

ModalTicketDetail.prototype = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  handleConfirm: PropTypes.func,
  ticketTemplate: PropTypes.object,
}

function ModalTicketDetail({
  show,
  setShow,
  header,
  handleConfirm,
  ticketTemplate = {},
  currentTicket, // vé để chỉnh sửa
}) {
  let {
    category_ticket = {},
    route = {},
    ticketPriceReduce,
    routeType,
    isTaxReduction,
    ticketPriceWithoutReduce,
    rateVatInAmount,
  } = ticketTemplate
  category_ticket = category_ticket ?? {}
  route = route ?? {}
  const totalPrice = ticketTemplate.ticketPrice
  const taxRate = ticketTemplate.vatRateTicket
  const taxMoney = Math.round(
    Utils.calculateTaxMoneyWithTotalPrice(totalPrice, taxRate),
  )
  const price = totalPrice - taxMoney

  const { listOfRoute } = useAppSelector((state) => state.ticket)
  const { startTime, noCar } = route
  const [listNoCarSelectOption, setListNoCarSelectOption] = useState([])
  const [listDepartureTimeArray, setListDepartureTimeArray] = useState([])
  const [taxRateForm, setTaxRateForm] = useState(0)
  const [timeOptions, setTimeOptions] = useState([])
  useEffect(() => {
    if (!currentTicket || !listOfRoute?.length) return

    setTimeOptions(
      split(
        listOfRoute?.find(
          ({ nameRoute }) => nameRoute == currentTicket?.nameRoute,
        )?.startTime,
        ';',
      ).map((time) => ({
        value: time,
        label: time,
      })),
    )
  }, [currentTicket, listOfRoute])

  const calculateAndSetTaxMoneyAndPrice = (totalPrice, taxRate) => {
    let taxMoney = 0,
      price = totalPrice
    if (_.isNumber(totalPrice) && totalPrice >= 0 && taxRate) {
      taxMoney = Math.round(
        Utils.calculateTaxMoneyWithTotalPrice(totalPrice, taxRate),
      )
      price = totalPrice - taxMoney
    }
    formik.setFieldValue('taxMoney', taxMoney)
    formik.setFieldValue('price', price)
  }

  const calculateAndSetTaxReduceMoneyAndTotalPrice = (
    ticketPriceWithoutReduce,
    rateVatInAmount,
  ) => {
    let totalPrice = ticketPriceWithoutReduce
    let ticketPriceReduce = 0
    if (
      _.isNumber(ticketPriceWithoutReduce) &&
      ticketPriceWithoutReduce >= 0 &&
      _.isNumber(rateVatInAmount)
    ) {
      ticketPriceReduce = Math.round(
        ((ticketPriceWithoutReduce * parseInt(rateVatInAmount)) / 100) * 0.2,
      )
      totalPrice = ticketPriceWithoutReduce - ticketPriceReduce
    }
    formik.setFieldValue('totalPrice', totalPrice)
    formik.setFieldValue('ticketPriceReduce', ticketPriceReduce)
  }

  const departureTimeArray = useMemo(() => {
    if (!startTime) return null
    const tokenizationString = ';'
    const hasToken = includes(startTime, tokenizationString)
    if (hasToken) {
      const inputArray = split(startTime, tokenizationString)
      return inputArray
    } else {
      return [startTime]
    }
  }, [startTime])

  const noCarSelectOption = useMemo(() => {
    let noCarArray
    if (!noCar) return null
    const tokenizationString = ';'
    const hasToken = includes(noCar, tokenizationString)
    if (hasToken) {
      const inputArray = split(noCar, tokenizationString)
      noCarArray = inputArray
    } else {
      noCarArray = [noCar]
    }

    return _.map(noCarArray, (item, key) => {
      return { label: item, value: item, key }
    })
  }, [noCar])

  const listOfRouteToSelect = useMemo(() => {
    if (_.isEmpty(listOfRoute)) {
      return []
    }
    return listOfRoute.map((route) => {
      const { routeId, nameRoute } = route
      return { ...route, value: nameRoute, label: nameRoute }
    })
  }, [listOfRoute])

  const canSelectRoute = useMemo(() => {
    if (routeType === ROUTE_TYPE.MULTIPLE) return true
    if (routeType === ROUTE_TYPE.SINGLE) return false
  }, [routeType])

  const { isPrePrintPrice, isDirectDeduct } = useMemo(() => {
    if (_.isEmpty(category_ticket)) {
      return { isPrePrintPrice: null, isDirectDeduct: null }
    }
    const { typePrice, typeDeduct } = category_ticket
    return {
      isPrePrintPrice: typePrice === TYPE_PRICE_TICKET.PREPRINTED_PRICE,
      isDirectDeduct: typeDeduct === TICKET_TYPE_DETUCT.TT,
    }
  }, [category_ticket])

  const today = new Date()
  const formik = useFormik({
    initialValues: {
      index: currentTicket?.index,
      noCar: currentTicket?.noCar ?? '',
      noSeatCar: currentTicket?.noSeatCar ? currentTicket?.noSeatCar : '',
      departureDate: currentTicket?.departureDateTime
        ? Utils.formatDateTime(currentTicket?.departureDateTime, 'YYYY-MM-DD')
        : currentTicket?.departureDateTimeTemp
          ? currentTicket?.departureDateTimeTemp.format('YYYY-MM-DD')
          : moment(today).format('YYYY-MM-DD'),
      departureTime: currentTicket?.departureDateTime
        ? Utils.formatDateTime(currentTicket?.departureDateTime, 'HH:mm')
        : currentTicket?.departureDateTimeTemp
          ? currentTicket?.departureDateTimeTemp.format('HH:mm')
          : null,
      nameRoute:
        currentTicket?.nameRoute ?? (!canSelectRoute ? route.nameRoute : ''),
      routeStart:
        currentTicket?.routeStart ?? (!canSelectRoute ? route.routeStart : ''),
      routeEnd:
        currentTicket?.routeEnd ?? (!canSelectRoute ? route.routeEnd : ''),
      taxRate: currentTicket?.taxRate ?? taxRate,
      taxMoney: currentTicket?.taxMoney ?? taxMoney,
      price: currentTicket?.price ?? price,
      totalPrice: currentTicket?.totalPrice ?? totalPrice ?? 0,
      ticketPriceReduce: currentTicket?.ticketPriceReduce ?? ticketPriceReduce,
      ticketPriceWithoutReduce:
        currentTicket?.ticketPriceWithoutReduce ?? ticketPriceWithoutReduce,
      rateVatInAmount: currentTicket?.rateVatInAmount ?? rateVatInAmount ?? 3,
      isTaxReduction: !_.isEmpty(currentTicket)
        ? currentTicket?.isTaxReduction
        : isTaxReduction
          ? true
          : false,
    },
    // validationSchema: ValidationSchema.createNewInvoice,
    onSubmit: async (values) => {},
    enableReinitialize: true,
    validateOnChange: true,
  })

  useEffect(() => {
    const { totalPrice, taxRate } = formik.values
    if (!isPrePrintPrice && !isDirectDeduct) {
      calculateAndSetTaxMoneyAndPrice(totalPrice, taxRate)
    }
  }, [formik.values.totalPrice, formik.values.taxRate])

  useEffect(() => {
    if (show) {
      if (currentTicket?.taxRate) {
        if (currentTicket?.taxRate) {
          setTaxRateForm(currentTicket?.taxRate)
        }
        formik.setFieldValue(
          'departureDate',
          currentTicket?.departureDateTimeTemp
            ? currentTicket?.departureDateTimeTemp.format('YYYY-MM-DD')
            : currentTicket?.departureDateTime
              ? dayjs(currentTicket.departureDateTime).format('YYYY-MM-DD')
              : moment(today).format('YYYY-MM-DD'),
        )
        formik.setFieldValue(
          'departureTime',
          currentTicket?.departureDateTimeTemp
            ? currentTicket?.departureDateTimeTemp.format('HH:mm')
            : currentTicket?.departureDateTime
              ? dayjs(currentTicket.departureDateTime).format('HH:mm')
              : moment(today).format('HH:mm'),
        )
      } else {
        formik.setFieldValue(
          'taxRate',
          ticketTemplate?.vatRateTicket ?? taxRate,
        )
        setTaxRateForm(ticketTemplate?.vatRateTicket ?? taxRate)
      }
    } else {
      setTaxRateForm(null)
    }
  }, [show])

  useEffect(() => {
    const { ticketPriceWithoutReduce, rateVatInAmount } = formik.values
    if (formik.values.isTaxReduction && !isPrePrintPrice) {
      calculateAndSetTaxReduceMoneyAndTotalPrice(
        ticketPriceWithoutReduce,
        rateVatInAmount,
      )
    }
  }, [formik.values.ticketPriceWithoutReduce, formik.values.rateVatInAmount])

  let handleClose = () => {
    setShow(false)
    resetForm()
  }

  const resetForm = () => {
    formik.resetForm()
    formik.setFieldValue('nameRoute', '')
  }

  const handleSelectRoute = (selectedOption) => {
    const { nameRoute, routeStart, routeEnd, routeId } = selectedOption
    formik.setFieldValue('nameRoute', nameRoute)
    formik.setFieldValue('routeStart', routeStart)
    formik.setFieldValue('routeEnd', routeEnd)
    formik.setFieldValue('routeId', routeId)
    formik.setFieldValue('departureTime', null)
    setTimeOptions(
      split(selectedOption?.startTime, ';').map((time) => ({
        value: time,
        label: time,
      })),
    )
  }

  const handleChangeDate = (date, dateString) => {
    console.log(date, dateString)
    formik.setFieldValue('departureDate', dateString)
  }

  const handleChangeNoCar = (e) => {
    e.preventDefault()
    formik.setFieldValue('noCar', e.target.value)
  }

  const handleSelectNoCar = ({ key }) => {
    let selectedNoCar
    if (!_.isEmpty(noCarSelectOption)) {
      selectedNoCar = noCarSelectOption[parseInt(key)].value
    } else {
      selectedNoCar = listNoCarSelectOption[parseInt(key)].value
    }
    formik.setFieldValue('noCar', selectedNoCar)
  }

  const onChangeIsTaxReduction = (e) => {
    formik.setFieldValue('isTaxReduction', e.target.checked)
    if (e.target.checked) {
      formik.setFieldValue('ticketPriceWithoutReduce', formik.values.totalPrice)
    }
  }

  useEffect(() => {
    setListNoCarSelectOption([])
    if (formik.values.nameRoute) {
      const route = listOfRouteToSelect.find(
        (route) => route.nameRoute === formik.values.nameRoute,
      )
      const carList =
        route?.noCar
          ?.split(';')
          .filter((item) => item !== '')
          .map((item, index) => ({ label: item, value: item, key: index })) ||
        []
      setListNoCarSelectOption(carList)
      setListDepartureTimeArray(
        route?.startTime?.split(';').filter((item) => item !== ''),
      )
    }
  }, [formik.values.nameRoute])

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="ModalTicketDetail"
      className="modal-ticket-detail"
      size="lg"
      // width={'55rem'}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div style={{ width: '120px' }}>
            <span>{header}</span>
          </div>
        </Modal.Title>
        {isDirectDeduct && (
          <div className="d-flex flexr-row justify-content-end w-100">
            <Checkbox
              checked={formik.values.isTaxReduction}
              onChange={onChangeIsTaxReduction}
              disabled={isPrePrintPrice}
            >
              <span style={{ color: '#333333', fontWeight: '500' }}>
                Giảm thuế theo NQ 101/2023/QH15
              </span>
            </Checkbox>
          </div>
        )}
      </Modal.Header>
      <Modal.Body className="p-0">
        <div
          className="w-100 d-flex flex-column p-4"
          style={{ backgroundColor: '#edeff2', gap: '5px' }}
        >
          <div className="d-flex flex-row align-items-center">
            <FormLabel>Tuyến đường:</FormLabel>
            {!canSelectRoute ? (
              <span style={{ fontSize: '14px', fontWeight: '600' }}>
                {/* {formik.values.nameRoute}{' '} */}
                {currentTicket?.nameRoute
                  ? currentTicket?.nameRoute
                  : !canSelectRoute
                    ? route.nameRoute
                    : ''}
              </span>
            ) : (
              <CustomSelect
                selectOptions={listOfRouteToSelect?.filter(
                  (option) => option.availability === true,
                )}
                menuParentRef={document.querySelector(
                  '.modal-ticket-detail-choose-route',
                )}
                currentValue={formik.values.nameRoute}
                onChange={handleSelectRoute}
                haveMenuParentRef={false}
                placeholder="Chọn tuyến đường"
                width="100%"
              />
            )}
          </div>
          <div className="d-flex flex-row">
            <div className="d-flex flex-row align-items-center w-50">
              <FormLabel>Bến đi:</FormLabel>
              <span style={{ fontSize: '14px', fontWeight: '600' }}>
                {formik.values.routeStart}
              </span>
            </div>
            <div className="d-flex flex-row align-items-center w-50">
              <FormLabel>Bến đến:</FormLabel>
              <span style={{ fontSize: '14px', fontWeight: '600' }}>
                {formik.values.routeEnd}
              </span>
            </div>
          </div>
          {formik.values.isTaxReduction && (
            <div className="d-flex flex-row justify-content-start">
              <div className="d-flex flex-row align-items-center w-50 pr-5">
                <FormLabel width={formik.values.isTaxReduction && '200px'}>
                  Tổng tiền(Chưa giảm thuế):
                </FormLabel>
                {!isPrePrintPrice ? (
                  <div style={{ height: '38px' }}>
                    <InputNumberNoFormik
                      onBlur={(e) => {
                        const value = Utils.parseFormattedNumberToFloat(
                          e.target.value,
                        )
                        formik.setFieldValue('ticketPriceWithoutReduce', value)
                      }}
                      value={formik.values.ticketPriceWithoutReduce}
                    />
                  </div>
                ) : (
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: '#ff6f00',
                    }}
                  >
                    {formik.values.ticketPriceWithoutReduce &&
                      Utils.formatNumber(
                        formik.values.ticketPriceWithoutReduce,
                      )}
                  </span>
                )}
              </div>
              {!isPrePrintPrice && (
                <div className="d-flex flex-row align-items-center w-50">
                  <FormLabel
                    width={formik.values.isTaxReduction ? '200px' : null}
                  >
                    % Tính thuế
                  </FormLabel>
                  <div className="flex-grow-1">
                    <CustomSelect
                      selectOptions={SelectOptions.rateVatInAmount}
                      menuParentRef={document.querySelector(
                        '.modal-ticket-detail',
                      )}
                      currentValue={formik.values.rateVatInAmount}
                      onChange={(selectedOption) => {
                        formik.setFieldValue(
                          'rateVatInAmount',
                          selectedOption.value,
                        )
                      }}
                      haveMenuParentRef={false}
                      width="100%"
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="d-flex flex-row justify-content-start">
            {!isPrePrintPrice && !isDirectDeduct && (
              <div className="d-flex flex-row align-items-center w-50 pr-5">
                <FormLabel>Thuế GTGT: </FormLabel>
                <div style={{ width: '110px' }}>
                  <CustomSelect
                    currentValue={taxRateForm}
                    selectOptions={SelectOptions.GTGT}
                    menuParentRef={document.querySelector(
                      '.modal-ticket-detail',
                    )}
                    onChange={(selectedOption) => {
                      setTaxRateForm(selectedOption.value)
                      formik.setFieldValue('taxRate', selectedOption.value)
                    }}
                    haveMenuParentRef={false}
                    width="100%"
                  />
                </div>
              </div>
            )}
            {formik.values.isTaxReduction && (
              <div className="d-flex flex-row align-items-center w-50 pr-5">
                <FormLabel width="200px">Tiền thuế GTGT được giảm:</FormLabel>
                {!isPrePrintPrice ? (
                  <div style={{ height: '38px' }}>
                    <InputNumberNoFormik
                      onBlur={(e) => {
                        const value = Utils.parseFormattedNumberToFloat(
                          e.target.value,
                        )
                        formik.setFieldValue('ticketPriceReduce', value)
                      }}
                      value={formik.values.ticketPriceReduce}
                      disabled={true}
                    />
                  </div>
                ) : (
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: '#ff6f00',
                    }}
                  >
                    {ticketPriceReduce && Utils.formatNumber(ticketPriceReduce)}
                  </span>
                )}
              </div>
            )}
            <div className="d-flex flex-row align-items-center w-50">
              <FormLabel
                width={
                  formik.values.isTaxReduction && !isPrePrintPrice
                    ? '200px'
                    : null
                }
              >
                Tiền vé:
              </FormLabel>
              {!isPrePrintPrice ? (
                <div style={{ height: '38px' }}>
                  <InputNumberNoFormik
                    onBlur={(e) => {
                      const value = Utils.parseFormattedNumberToFloat(
                        e.target.value,
                      )
                      formik.setFieldValue('totalPrice', value)
                    }}
                    value={formik.values.totalPrice}
                    disabled={!isPrePrintPrice && formik.values.isTaxReduction}
                  />
                </div>
              ) : (
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#ff6f00',
                  }}
                >
                  {formik.values.totalPrice &&
                    Utils.formatNumber(formik.values.totalPrice)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex flex-column p-4" style={{ gap: '8px' }}>
          <div className="d-flex flex-row align-items-start justify-content-between">
            <div
              className="d-flex flex-column"
              style={{ width: '40%', gap: '10px' }}
            >
              <div className="d-flex flex-row align-items-center">
                <FormLabel>Ngày khởi hành:</FormLabel>
                <DatePicker
                  onChange={handleChangeDate}
                  value={dayjs(formik.values.departureDate, 'YYYY-MM-DD')}
                  inputReadOnly={true}
                  allowClear={false}
                />
              </div>
              <div className="d-flex flex-row align-items-center position-relative">
                <FormLabel>Số xe:</FormLabel>
                <Input
                  placeholder="Nhập số xe"
                  onChange={handleChangeNoCar}
                  value={formik.values.noCar}
                />
                {!_.isEmpty(noCarSelectOption) ? (
                  <AntdDropdown
                    items={noCarSelectOption}
                    onClick={handleSelectNoCar}
                  />
                ) : (
                  listNoCarSelectOption?.length > 0 && (
                    <AntdDropdown
                      items={listNoCarSelectOption}
                      onClick={handleSelectNoCar}
                    />
                  )
                )}
              </div>
            </div>
            <div className="d-flex flex-row align-items-center mr-5">
              <FormLabel>Giờ khởi hành:</FormLabel>
              <CustomTimePicker
                initialValue={formik.values.departureTime}
                onBlur={(departureTime) => {
                  formik.setFieldValue('departureTime', departureTime)
                }}
                timeArray={
                  !_.isEmpty(departureTimeArray)
                    ? departureTimeArray
                    : listDepartureTimeArray?.length > 0 &&
                      listDepartureTimeArray
                }
              />
              {/* {!currentTicket?.invoice_template?.routeId ? (
                <CustomAntSelect
                  options={timeOptions}
                  value={formik.values.departureTime}
                  onChange={(value) => {
                    formik.setFieldValue('departureTime', value)
                  }}
                  placeholder="Chọn giờ khởi hành"
                  notFoundContent="Không tìm thấy giờ khởi hành"
                />
              ) : (
                <CustomAntInput
                  borderRadius="sm"
                  type="text"
                  inputProps={{
                    value: formik.values.departureTime,
                    onChange: (e) => {
                      formik.setFieldValue('departureTime', e.target.value)
                    },
                  }}
                />
              )} */}
            </div>
          </div>

          <div className="d-flex flex-row align-items-center">
            <FormLabel>Số ghế</FormLabel>
            {_.isEmpty(currentTicket) ? (
              <Tagnify
                placeholder="Thêm số ghế"
                setFieldValue={formik.setFieldValue}
                name="noSeatCar"
                delimiterString=";"
                pillColor="primary"
                restriction={{
                  type: 'text',
                  validationSchema: string(),
                }}
              />
            ) : (
              <Input
                placeholder="Nhập số ghế"
                onChange={(e) => {
                  formik.setFieldValue('noSeatCar', e.target.value)
                }}
                value={formik.values.noSeatCar}
              />
            )}
          </div>
          {!currentTicket ? (
            <div className="d-flex flex-row align-items-center">
              <div style={{ width: '130px' }}></div>
              <span style={{ color: '#ccc', fontSize: '14px' }}>
                Bấm "Enter" để thêm số ghế, mỗi ghế tương ứng với một vé. Nhập
                nhiều ghế cách nhau bằng dấu ";"
              </span>
            </div>
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="">
          <button
            className="btn btn-outline-secondary mr-4 px-8"
            onClick={handleClose}
          >
            Hủy bỏ
          </button>
          <button
            className="btn btn-primary px-8"
            onClick={() => {
              const { nameRoute, totalPrice, taxRate, routeStart, routeEnd } =
                formik.values
              if ((canSelectRoute && !nameRoute) || !routeStart || !routeEnd) {
                ToastHelper.showError('Chưa chọn tuyến đường')
                return
              }

              if (!isDirectDeduct) {
                if (!taxRateForm) {
                  ToastHelper.showError('Chưa có thuế GTGT')
                  return
                }
              }
              if (!_.isNumber(totalPrice) || totalPrice < 0) {
                ToastHelper.showError('Chưa nhập tiền vé')
                return
              }
              let departureDateTimeTemp = dayjs(
                `${formik?.values?.departureDate} ${formik?.values?.departureTime ? formik.values.departureTime : '12:00'}`,
              )

              console.log(
                'departureDateTime',
                `${formik?.values?.departureDate} ${formik?.values?.departureTime ? formik.values.departureTime : '12:00'}`,
              )
              handleConfirm({
                ticketTemplate,
                ...formik.values,
                taxRate: taxRateForm,
                departureDateTimeTemp,
              })
              resetForm()
            }}
          >
            Đồng ý
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalTicketDetail
