import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
// import './style.scss';
import { useState } from 'react'
import CustomButton from 'general/components/Button/CustomButton'
import CustomDropdown from 'general/components/Dropdown/CustomDropdown'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'

InputNumberNoFormik.propTypes = {
  //   name: PropTypes.string.isRequired,
  //   fieldProps: PropTypes.object,
  //   fieldMeta: PropTypes.object,
  //   fieldHelper: PropTypes.object,

  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
  spellCheck: PropTypes.bool,
  additionLabelClassName: PropTypes.string,
  additionalInputClassName: PropTypes.string,
  autoComplete: PropTypes.string,
  labelStyle: PropTypes.object,
  require: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,
  showDropdownButton: PropTypes.bool,
  showDropdown: PropTypes.bool,
  transparent: PropTypes.bool,
  showBorder: PropTypes.bool,
  rounded: PropTypes.bool,
  value: PropTypes.any,
  allowNegative: PropTypes.bool,
}

InputNumberNoFormik.defaultProps = {
  type: 'text',
  label: '',
  placeholder: '',
  disabled: false,
  text: '',
  spellCheck: false,
  //   fieldProps: {},
  //   fieldHelper: {},
  //   fieldMeta: {},
  className: 'form-group',
  additionLabelClassName: 'text-remaining',
  additionalInputClassName: '',
  autoComplete: 'on',
  labelStyle: {},
  require: false,
  onBlur: null,
  showDropdownButton: false,
  showDropdown: false,
  transparent: false,
  showBorder: true,
  rounded: true,
  allowNegative: false,
  decimalScale: null,
}

function InputNumberNoFormik(props) {
  // MARK: --- Params ---
  const {
    name,
    // fieldProps,
    // fieldHelper,
    type,
    label,
    placeholder,
    disabled,
    text,
    className,
    spellCheck,
    additionLabelClassName,
    additionalInputClassName,
    autoComplete,
    labelStyle,
    require,
    onBlur,
    showDropdownButton,
    transparent,
    showBorder,
    onChange,
    value,
    onValueChange,
    rounded,
    maxLength,
    textAlign,
    systemConfigType,
    allowNegative,
    decimalScale,
  } = props
  //   const { error, touched } = fieldMeta;
  //   const isError = error && touched;
  const dropdownContainerRef = useRef(null)
  const [showDropdown, setShowDropdown] = useState(false)
  const inputRef = useRef(null)

  const { formatNumber } = useSelector((state) => state?.systemConfig)

  const [currentType, setCurrentType] = useState(type)

  const handleFocus = (event) => {
    setTimeout(event.target.select.bind(event.target), 20)
  }

  useEffect(() => {
    if (!disabled && inputRef.current) {
      // When the input is not disabled and is rendered, focus it and move the cursor to the end
      inputRef.current.focus()
      const length = inputRef.current.value.length
      inputRef.current.setSelectionRange(length, length)
    }
  }, [disabled])

  function handleBlur(e) {
    if (onBlur) {
      onBlur(e)
    }
  }

  useEffect(() => {
    function handleClickOutside(e) {
      if (
        dropdownContainerRef.current &&
        !dropdownContainerRef.current?.contains(e.target)
      ) {
        setShowDropdown(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="BaseTextField position-relative">
      <div className={`${className}`} ref={dropdownContainerRef}>
        {label && (
          <div className={`${require && 'd-flex flex-row'}`}>
            <label
              className={additionLabelClassName}
              htmlFor={name}
              style={labelStyle}
            >
              {label}
            </label>
            {require && (
              <span
                className="font-weight-boldest ml-1"
                style={{ color: '#E92E4E' }}
              >{`*`}</span>
            )}
          </div>
        )}
        <div
          className={`BaseTextField_Group ${
            rounded ? 'rounded' : null
          } input-group ${showBorder ? null : 'border-0'} ${
            !disabled && 'bg-white'
          } ${
            transparent && 'bg-transparent'
          }  d-flex flex-row  justify-content-between ${
            disabled && 'BaseTextField_Disabled'
          }`}
        >
          <NumericFormat
            className={`ps-3 BaseTextField_Input w-100 ${
              rounded ? 'rounded' : null
            } border-0 bg-transparent ${additionalInputClassName}`}
            id={name}
            disabled={disabled}
            type={currentType}
            name={name}
            placeholder={placeholder}
            spellCheck={spellCheck}
            // onBlur={onBlur}
            // format="###"
            // {...fieldProps}
            // onKeyDown={onKeyDown}
            onBlur={handleBlur}
            value={value}
            onValueChange={onValueChange}
            onChange={onChange}
            onFocus={handleFocus}
            autoComplete="new-password"
            style={{
              color: '#333333',
              fontWeight: '500',
              textAlign: textAlign,
            }}
            // allowLeadingZeros
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={
              decimalScale ??
              (formatNumber ? (formatNumber[systemConfigType] ?? 0) : 0)
            }
            maxLength={maxLength}
            fixedDecimalScale
            allowNegative={allowNegative}
            // dir="rtl"
          />
        </div>
        {text.length > 0 && (
          <span className="form-text text-muted">{text}</span>
        )}
        {/* {isError && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{error}</div>
          </div>
        )} */}
      </div>
      {showDropdownButton && (
        <CustomButton
          className="d-flex align-items-center justify-content-center cursor-pointer"
          width="36px"
          height="36px"
          position="absolute"
          right="0"
          bottom="0"
          backgroundColor="#304FFD"
          icon="fal fa-chevron-down"
          color="white"
          borderRadius="4px"
          onClick={() => {
            setShowDropdown(!showDropdown)
          }}
        ></CustomButton>
      )}
      {showDropdown && <CustomDropdown></CustomDropdown>}
    </div>
  )
}

export default InputNumberNoFormik
