import { Radio, Table } from 'antd'
import errAnnouceApi from 'api/errAnnouceApi'
import invoiceApi from 'api/invoiceApi'
import ticketApi from 'api/ticketApi'
import { setAppSpinning } from 'app/appSlice'
import customSelectTableCellStyle from 'assets/styles/customSelectTableCellStyle'
import dayjs from 'dayjs'
import TaxCodeDisplay from 'features/Invoice/screens/CreateInvoice/Components/TaxCodeDisplay'
import { useFormik } from 'formik'
import CustomButton from 'general/components/Button/CustomButton'
import BaseTextFieldNoFormik from 'general/components/Forms/BaseTextFieldNoFormik'
import FindInvoiceModal from 'general/components/Modal/FindInvoiceModal'
import InvoiceFooter from 'general/components/eInvoiceComponents/AppFooter/InvoiceFooter'
import {
  ACTION_TYPE,
  INVOICE_TYPE_TO_QUERY,
} from 'general/constants/AppConstants'
import SelectOptions from 'general/constants/SelectOptions'
import ToastHelper from 'general/helpers/ToastHelper'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Select from 'react-select'
import { createErrAnnouce } from './Functions/createErrAnnouce'
import { updateErrAnnouce } from './Functions/updateErrAnnouce'
import './style.scss'

var submitAction
function CreateTicketErrorAnnouncement() {
  const dispatch = useDispatch()
  const today = new Date()
  const { errAnnouceId, ticketIds, invoiceInfo } = useParams()
  const navigate = useNavigate()
  const [searchParam] = useSearchParams()

  const invoiceClassify = searchParam.get('invoiceClassify')

  const { currentAccount } = useSelector((state) => state?.auth)
  const { company } = useSelector((state) => state?.system)

  const [findInvoiceModalShow, setFindInvoiceModalShow] = useState(false)
  const [errAnnouceInfo, setErrAnnouceInfo] = useState({})
  const [errAnnouceCustomerNo, setErrAnnouceCustomerNo] = useState('TBSS')
  const [tableData, setTableData] = useState([])
  const [actionType, setActionType] = useState('')
  const [isInternal, setIsInternal] = useState(true)

  const fetchTotalErrAnnouce = async () => {
    try {
      const res = await errAnnouceApi.getTotal({ invoiceType: 'TICKET' })
      if (res.result === 'success') {
        setErrAnnouceCustomerNo(`TBSS${res.count}`)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const fetchErrAnnouceInfo = async () => {
    try {
      const res = await errAnnouceApi.list({
        errAnnouceId,
        invoiceType: 'TICKET',
      })
      if (res?.result === 'success') {
        setErrAnnouceInfo(res?.rows[0])
        setTableData(
          res?.rows[0]?.tickets
            ?.sort(
              (a, b) =>
                a.error_annoucement_ticket?.errAnnouceNo -
                b.error_annoucement_ticket?.errAnnouceNo,
            )
            ?.map((ticket) => ({
              ...ticket,
              errAnnouceNo:
                ticket.error_annoucement_ticket
                  ?.errAnnouceNo /* nếu không có sẽ bị sai stt */,
              // errAnnouceType: ticket.error_annoucement_ticket?.errAnnouceType,
              errAnnouceReason:
                ticket.error_annoucement_ticket?.errAnnouceReason,
            })),
        )
        setErrAnnouceCustomerNo(res?.rows[0]?.customerNo)
      }
    } catch (err) {
      ToastHelper.showError('Có lỗi khi lấy thông tin thông báo')
      navigate('/hoa-don-dau-ra/xu-ly-hoa-don/danh-sach-thong-bao-sai-sot')
    }
  }

  useEffect(() => {
    const pathname = window.location.pathname
    // debugger;
    const parts = pathname.split('/')
    if (parts.includes('thong-bao-sai-sot-khac-he-thong')) {
      setIsInternal(false)
    }
  }, [])

  useEffect(() => {
    if (ticketIds) {
      const _ticketIds = ticketIds.split(',')
      fetchInvoiceData(_ticketIds, invoiceClassify)
    }
  }, [ticketIds, invoiceClassify])

  useEffect(() => {
    if (invoiceInfo) {
      const paramsArray = invoiceInfo.split('&')
      const jsonInvoice = { errAnnouceNo: 1, errAnnouceType: 1 }

      paramsArray.forEach((param) => {
        const [key, value] = param.split('=')
        jsonInvoice[key] = value
      })
      setTableData([jsonInvoice])
    }
  }, [invoiceInfo])

  useEffect(() => {
    if (errAnnouceId) {
      fetchErrAnnouceInfo()
    }
  }, [errAnnouceId])

  useEffect(() => {
    const pathname = window.location.pathname
    const parts = pathname.split('/')
    if (parts.includes('chinh-sua-thong-bao-sai-sot')) {
      setActionType(ACTION_TYPE.UPDATE)
    } else {
      setActionType(ACTION_TYPE.CREATE)
      fetchTotalErrAnnouce()
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      customerNo: errAnnouceCustomerNo ?? '',
      taxPayerName: errAnnouceInfo?.taxPayerName ?? company?.companyName,
      taxCode: errAnnouceInfo?.taxCode ?? company?.taxCode,
      taxAuthorityId: company?.taxAuthority?.taxAuthorityId ?? 70127,
      taxAuthorityName:
        errAnnouceInfo?.taxAuthorityName ?? company?.taxAuthority?.name,
      type: errAnnouceInfo?.type ?? 1,
      place: errAnnouceInfo?.place ?? 'Hà Nội',
      date:
        errAnnouceInfo?.date?.slice(0, 10) ??
        moment(today).format('YYYY-MM-DD'),
      taxAuthorityReviewNo: errAnnouceInfo?.taxAuthorityReviewNo ?? '',
      taxAuthorityReviewDate:
        errAnnouceInfo?.taxAuthorityReviewDate ??
        moment(today).format('YYYY-MM-DD'),
      errorAnnoucementId: errAnnouceInfo?.errorAnnoucementId ?? null,

      invoiceClassify: errAnnouceInfo?.invoiceClassify ?? invoiceClassify,
    },
    // validationSchema,
    onSubmit: async (values) => {
      if (!values?.place) {
        ToastHelper.showError('Vui lòng nhập nơi lập thông báo')
        return
      }

      let signingConfig = {
        signingMethod: company?.activeSigningMethod,
      }
      dispatch(setAppSpinning(true))
      // Hoá đơn trong hệ thống
      if (isInternal) {
        switch (actionType) {
          case ACTION_TYPE.CREATE:
            createErrAnnouce(
              company,
              submitAction,
              tableData,
              values,
              dispatch,
              setAppSpinning,
              navigate,
              signingConfig,
            )

            break
          case ACTION_TYPE.UPDATE:
            updateErrAnnouce(
              submitAction,
              tableData,
              values,
              dispatch,
              setAppSpinning,
              navigate,
              currentAccount,
              signingConfig,
            )

            break
          default:
            return
        }
      } else {
        switch (actionType) {
          case ACTION_TYPE.CREATE:
            try {
              const res = await invoiceApi.bulkCreateExternal({
                invoices: tableData,
              })
              if (res.result === 'success') {
                const { createInvoiceIds } = res.data

                createErrAnnouce(
                  company,
                  createInvoiceIds,
                  submitAction,
                  tableData,
                  values,
                  dispatch,
                  setAppSpinning,
                  navigate,
                  signingConfig,
                )
              }
            } catch (err) {
              console.log(err)
            }
            break
          case ACTION_TYPE.UPDATE:
            // debugger
            updateErrAnnouce(
              submitAction,
              tableData,
              values,
              dispatch,
              setAppSpinning,
              navigate,
              undefined,
              signingConfig,
            )

            break
          default:
            return
        }
      }
    },
    enableReinitialize: true,
    validateOnChange: false,
  })

  const fetchInvoiceData = async (ticketIds, invoiceClassify) => {
    try {
      let res = await ticketApi.list({ ticketIds, classify: invoiceClassify })
      if (res?.result === 'success') {
        setTableData(
          res.rows.map((item, index) => ({
            ...item,
            errAnnouceNo: index + 1,
            errAnnouceType: 1,
          })),
        )
      } else {
        ToastHelper.showError('Không tìm thấy thông tin hoá đơn')
      }
    } catch (err) {
      console.log(err)
    }
  }

  const FormLabel = (props) => {
    return (
      <div
        className=" font-weight-normal d-flex align-items-center"
        style={{ width: props.width ?? '220px' }}
      >
        {props.children}
      </div>
    )
  }

  const handleRemoveRow = (row) => {
    let newTableData = tableData.filter(
      (item) => item.errAnnouceNo !== row.errAnnouceNo,
    )

    for (let i = 0; i < newTableData.length; i++) {
      newTableData[i].errAnnouceNo = i + 1
    }
    setTableData(newTableData)
  }

  const [valueRadioButton, setValueRadioButton] = useState(formik.values.type)

  const onChangeRadioButton = (e) => {
    // debugger
    setValueRadioButton(e.target.value)
    formik.setFieldValue('type', e.target.value)
    if (e.target.value === 1) {
      formik.setFieldValue('taxAuthorityReviewNo', '')
      formik.setFieldValue(
        'taxAuthorityReviewDate',
        moment(today).format('YYYY-MM-DD'),
      )
    }
  }

  const handleChangeTableData = (errAnnouceNo, fieldName, data) => {
    let newTableData = [...tableData]
    newTableData[errAnnouceNo - 1][fieldName] = data
    setTableData(newTableData)
  }

  const columns = useMemo(() => {
    let ret = [
      {
        title: 'STT',
        dataIndex: 'no',
        key: '1',
        width: 60,
        render: (_, row) => (
          <p
            data-tag="allowRowEvents"
            className="d-flex justify-content-center font-weight-normal m-0 text-maxline-3"
          >
            {row?.errAnnouceNo}
          </p>
        ),
      },
      {
        title: 'Mã CQT cấp',
        dataIndex: 'taxAuthorityCode',
        key: 'taxAuthorityCode',
        width: 300,
        render: (_, row) => (
          <p
            data-tag="allowRowEvents"
            className="d-flex justify-content-center font-weight-normal m-0 text-maxline-3"
          >
            {row?.taxAuthorityCode}
          </p>
        ),
      },
      {
        title: 'Ký hiệu',
        dataIndex: 'serial',
        key: '3',
        width: 100,
        render: (_, row) => (
          <p
            data-tag="allowRowEvents"
            className="d-flex justify-content-center font-weight-normal m-0 text-maxline-3"
          >
            {row?.serial}
          </p>
        ),
      },
      {
        title: 'Số',
        dataIndex: 'no',
        key: '4',
        width: 100,
        render: (_, row) => (
          <p
            data-tag="allowRowEvents"
            className="d-flex justify-content-center font-weight-normal m-0 text-maxline-3"
          >
            {row?.no}
          </p>
        ),
      },
      {
        title: 'Ngày HĐ',
        dataIndex: 'date',
        key: '5',
        width: 100,
        render: (_, row) => {
          const date = dayjs(row?.date)
          return (
            <p
              data-tag="allowRowEvents"
              className="d-flex justify-content-center font-weight-normal m-0 text-maxline-3"
            >
              {date.isValid() ? date.format('L') : ''}
            </p>
          )
        },
      },
      {
        title: 'Hủy/Điều chỉnh/Thay thế/Giải trình',
        dataIndex: 'serial',
        key: '6',
        width: 200,
        render: (_, row) => (
          <Select
            styles={customSelectTableCellStyle}
            className="w-100"
            classNamePrefix="select"
            options={
              // Nếu đó là vé in sẵn mệnh giá
              row.invoice_template?.category_ticket?.typePrice == 1
                ? SelectOptions.ticketTypeErrAnnouceForPrintPrice
                : SelectOptions.ticketTypeErrAnnouce
            }
            value={
              row?.errAnnouceType
                ? SelectOptions.ticketTypeErrAnnouce.find(
                    (option) => option.value === row?.errAnnouceType,
                  )
                : SelectOptions.ticketTypeErrAnnouce[0]
            }
            onChange={(selectedOption) => {
              handleChangeTableData(
                row?.errAnnouceNo,
                'errAnnouceType',
                selectedOption?.value,
              )
            }}
            // defaultValue={SelectOptions.invoiceTypeErrAnnouce[0]}
            name="color"
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
            }}
            menuPortalTarget={document.querySelector('body')}
            menuPosition="fixed"
            menuPlacement="auto"
          />
        ),
      },
      {
        title: 'Lý do',
        key: '4',
        // width: 80,
        render: (_, row) => (
          <div className="w-100 input-table-cover">
            <BaseTextFieldNoFormik
              onChange={(e) => {
                handleChangeTableData(
                  row.errAnnouceNo,
                  'errAnnouceReason',
                  e.target.value,
                )
              }}
              showBorder={false}
              inputValue={row?.errAnnouceReason}
              // disabled={row?.type === 4 ? true : false}
              rounded={row?.type === 4 ? false : true}
              transparent={row?.type === 4 ? false : true}
            />
          </div>
        ),
      },
      {
        title: '',
        dataIndex: 'option',
        key: 'option',
        width: 50,
        align: 'center',
        render: (_, row) => (
          <div className="d-flex justify-content-center">
            <div
              className="d-flex h-100 align-items-center cursor-pointer h-100 icon-table"
              style={{ zIndex: '1' }}
              onClick={() => {
                if (tableData.length !== 1) {
                  handleRemoveRow(row)
                }
              }}
            >
              <i className="far fa-trash-alt" style={{ color: 'red' }}></i>
            </div>
          </div>
        ),
      },
    ]

    return ret
  }, [tableData])

  return (
    <>
      <div className="d-flex justify-content-start container-xxl mt-5">
        <label style={{ fontSize: 24, fontWeight: '700', color: 'black' }}>
          Lập thông báo sai sót
        </label>
      </div>
      <div className="error-announce-cover font-sfpro-regular">
        <div className="error-announce">
          <div className="d-flex flex-column align-items-center">
            <h4
              className="invoice-title font-weight-boldest"
              style={{ letterSpacing: '1px' }}
            >
              CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM
            </h4>
            <h4
              className="invoice-title font-weight-boldest"
              style={{ letterSpacing: '1px' }}
            >
              Độc lập - Tự do - Hạnh phúc
            </h4>
          </div>
          <div className="d-flex flex-column align-items-center my-3">
            <h4
              className=""
              style={{ fontWeight: '600', letterSpacing: '0.5px' }}
            >
              THÔNG BÁO HÓA ĐƠN ĐIỆN TỬ CÓ SAI SÓT
            </h4>
            <div className="d-flex flex-row  flex-grow-1 align-content-center mt-2">
              <FormLabel width="100px">Số thông báo:</FormLabel>
              <div>
                <input
                  type="text"
                  className="form-control input-dot"
                  // readOnly={screenAction == 'xem'}
                  value={formik.values.customerNo}
                  onChange={(e) =>
                    formik.setFieldValue('customerNo', e.target.value)
                  }
                  required
                />
              </div>
              {/* <BaseTextField
                fieldProps={formik.getFieldProps('customerNo')}
                fieldMeta={formik.getFieldMeta('customerNo')}
                fieldHelper={formik.getFieldHelpers('customerNo')}
                name="1"
                className=""
              ></BaseTextField> */}
            </div>
          </div>
          <div className="d-flex flex-column" style={{ gap: '12px' }}>
            <div className="d-flex flex-row w-100">
              <FormLabel>Loại thông báo:</FormLabel>
              <Radio.Group
                onChange={onChangeRadioButton}
                value={valueRadioButton}
              >
                <Radio value={1}>Thông báo của NNT</Radio>
                <Radio value={2}>
                  Thông báo của NNT theo thông báo rà soát của CQT
                </Radio>
              </Radio.Group>
            </div>
            {valueRadioButton == 2 && (
              <div className="d-flex flex-row  w-100">
                <div className="d-flex flex-row  flex-grow-1 align-content-center">
                  <FormLabel width="220px">
                    Số thông báo rà soát của CQT:
                  </FormLabel>
                  <div>
                    <input
                      type="text"
                      className="form-control input-dot"
                      // readOnly={screenAction == 'xem'}
                      value={formik.values.taxAuthorityReviewNo}
                      onChange={(e) =>
                        formik.setFieldValue(
                          'taxAuthorityReviewNo',
                          e.target.value,
                        )
                      }
                      required
                    />
                  </div>
                </div>
                <div className="d-flex flex-row  flex-grow-1 align-content-center">
                  <FormLabel width="230px">
                    Ngày thông báo rà soát của CQT:
                  </FormLabel>
                  <div>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      customInput={
                        <input
                          type="text"
                          className="form-control input-dot input-date"
                        />
                      }
                      todayButton={<button>Hôm nay</button>}
                      // readOnly={screenAction == 'xem'}
                      selected={new Date(formik.values.taxAuthorityReviewDate)}
                      // selected={new Date(registration.date) || new Date()}
                      onChange={(date) => {
                        formik.setFieldValue(
                          'taxAuthorityReviewDate',
                          dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex flex-row w-100">
              <FormLabel>Cơ quan thuế tiếp nhận:</FormLabel>
              <span className="">{formik.values.taxAuthorityName}</span>
            </div>
            <div className="d-flex flex-row  w-100">
              <FormLabel>Tên người nộp thuế:</FormLabel>
              <span className="">{formik.values.taxPayerName}</span>
            </div>
            <div className="d-flex flex-row  w-100">
              <FormLabel>Mã số thuế:</FormLabel>
              <TaxCodeDisplay taxCode={formik.values?.taxCode} />
            </div>
          </div>
          <div className="mt-5 d-flex flex-row justify-content-between mb-5">
            <span>
              Người nộp thuế thông báo về việc hóa đơn điện tử có sai sót như
              sau:
            </span>
          </div>
          <Table
            dataSource={tableData}
            columns={columns}
            bordered
            checkbox
            pagination={false}
            // rowSelection={currentTab === 0 ? rowSelection : false}
            scroll={{
              x: 1100,
            }}
          />
          <CustomButton
            text="Thêm mới"
            color="#304FFD"
            backgroundColor="transparent"
            width="120px"
            showBorder={false}
            icon={'fal fa-plus'}
            gap="5px"
            className="mt-2"
            onClick={() => {
              setFindInvoiceModalShow(true)
            }}
          />
          <div
            className="d-flex flex-column mt-5"
            style={{ gap: '10px', width: '350px' }}
          >
            <div className="d-flex flex-row align-items-center">
              <FormLabel width="250px">Nơi lập:</FormLabel>
              <div>
                <input
                  type="text"
                  className="form-control input-dot"
                  // readOnly={screenAction == 'xem'}
                  value={formik.values.place}
                  onChange={(e) =>
                    formik.setFieldValue('place', e.target.value)
                  }
                  required
                />
              </div>
            </div>

            <div className="d-flex flex-row align-items-center mt-4">
              <FormLabel width="200px">Ngày lập thông báo:</FormLabel>
              <div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  customInput={
                    <input
                      type="text"
                      className="form-control input-dot input-date"
                    />
                  }
                  todayButton={<button>Hôm nay</button>}
                  // readOnly={screenAction == 'xem'}
                  selected={new Date(formik.values.date)}
                  // selected={new Date(registration.date) || new Date()}
                  onChange={(date) => {
                    formik.setFieldValue(
                      'date',
                      dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
                    )
                  }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex w-100 justify-content-end">
            <div
              className="d-flex flex-column align-items-center"
              style={{ marginRight: '100px' }}
            >
              <span style={{ fontSize: '14px' }}>
                {formik.values?.place}, ngày{' '}
                {moment(formik.values?.date).date()} tháng{' '}
                {moment(formik.values?.date).month() + 1} năm{' '}
                {moment(formik.values?.date).year()}
              </span>
              <h5 className="mt-2" style={{ fontWeight: '600' }}>
                NGƯỜI NỘP THUẾ
              </h5>
              <span style={{ fontWeight: '400' }}>
                (Chữ ký số, chữ ký điện tử của người nộp thuế)
              </span>
              <div style={{ height: '50px' }}></div>
            </div>
          </div>
        </div>
        <FindInvoiceModal
          show={findInvoiceModalShow}
          onHide={() => setFindInvoiceModalShow(false)}
          title="Chọn vé sai sót"
          invoiceType={INVOICE_TYPE_TO_QUERY.CAN_ERR_ANNOUCED}
          buttonAgreeOnlick={(selectedInvoices) => {
            for (let i = 0; i < selectedInvoices.length; i++) {
              for (let j = 0; j < tableData.length; j++) {
                if (selectedInvoices[i].ticketId === tableData[j].ticketId) {
                  ToastHelper.showError(
                    `Vé kí hiệu ${selectedInvoices[i].serial}, số  ${selectedInvoices[i].no} đã được chọn`,
                  )
                  return
                }
              }
            }
            const newTableData = [...tableData, ...selectedInvoices]
            newTableData.map((invoice, index) => {
              invoice.errAnnouceNo = index + 1
              invoice.errAnnouceType = 1
            })
            setTableData(newTableData)
            setFindInvoiceModalShow(false)
          }}
          buttonAgreeTab2Onlick={(selectedInvoice) => {
            const newTableData = [...tableData, selectedInvoice]
            newTableData.map((invoice, index) => {
              invoice.errAnnouceNo = index + 1
              invoice.errAnnouceType = 1
            })
            setTableData(newTableData)
            setFindInvoiceModalShow(false)
          }}
          buttonAgreeVisibility={true}
          checkBoxShow={false}
          selectableRows={true}
          showTab={false}
          defaultTab={isInternal ? 0 : 1}
          isInvoice={false}
        />
        <InvoiceFooter
          // buttonCancel={true}
          // buttonPrint={true}
          buttonCancel={true}
          buttonCancelOnClick={() => {
            navigate(-1)
          }}
          buttonCreate={true}
          buttonSaveAndSign={true}
          buttonSaveAndSignOnClick={() => {
            submitAction = 'SaveAndSign'
            formik.handleSubmit()
          }}
          buttonCreateOnClick={() => {
            submitAction = 'Save'
            formik.handleSubmit()
          }}
        />
        {/* <ModalUnauthorized show={showNoRight} setShow={setShowNoRight} /> */}
      </div>
    </>
  )
}

export default CreateTicketErrorAnnouncement
