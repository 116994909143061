import axiosClient from './axiosClient'

const reportApi = {
  getUsedInvoices: (params, signal) => {
    const url = '/invoice/list-all'
    return axiosClient.get(url, { params, signal })
  },

  getIssuedInvoice: (params, signal) => {
    const url = '/statistic/get-invoice-template-serial-stats'
    return axiosClient.get(url, { params, signal })
  },

  getDetailInvoices: (params, signal) => {
    const url = '/statistic/get-invoice-stats-detail'
    return axiosClient.get(url, { params, signal })
  },

  exportData: (params) => {
    const url = '/statistic/export-invoice-stats-detail'
    return axiosClient.get(url, { params, responseType: 'blob' })
  },

  getReportBytax: (params, signal) => {
    const url = '/statistic/get-invoice-stats-by-tax'
    return axiosClient.get(url, { params, signal })
  },

  getInvoiceStatFile: (params) => {
    const url = '/statistic/export-invoice-stats-detail'
    return axiosClient.get(url, { params, responseType: 'blob' })
  },
}

export default reportApi
