import axiosClient from './axiosClient'

const customerApi = {
  find: (params, signal) => {
    const url = '/customer/find'
    return axiosClient.get(url, { params, signal })
  },
  findFromCreateInvoice: (params, signal) => {
    const url = '/customer/find-from-create-invoice'
    return axiosClient.get(url, { params, signal })
  },
  detail: (customerTaxCode) => {
    const url = `/customer/detail/${customerTaxCode}`
    return axiosClient.get(url)
  },

  create: (params) => {
    const url = '/customer/create'
    return axiosClient.post(url, params)
  },

  update: (params) => {
    const url = `/customer/update/${params.customerId}`
    return axiosClient.put(url, params)
  },

  delete: (params) => {
    const url = '/customer/delete'
    return axiosClient.delete(url, {
      data: {
        customerIds: params,
      },
    })
  },

  upload: (file, typeInvoice) => {
    const url = '/customer/upload'
    const formData = new FormData()
    formData.set('file', file)
    formData.set('typeInvoice', typeInvoice)
    return axiosClient.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
}

export default customerApi
