import { useMutation, useQuery } from '@tanstack/react-query'
import { Checkbox, DatePicker, Typography } from 'antd'
import locale from 'antd/es/date-picker/locale/vi_VN'
import routeApi from 'api/routeApi'
import templateInvoiceApi from 'api/templateInvoiceApi'
import ticketApi from 'api/ticketApi'
import { setAppSpinning } from 'app/appSlice'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import CustomAntSelect from 'general/components/CustomAntSelect'
import CustomLabel from 'general/components/CustomLabel'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import FormLabel from 'general/components/Forms/FormLabel'
import DraggableModal from 'general/components/Modal/DragableModal/DraggableModal'
import {
  ROUTE_TYPE,
  SIGN_TOOL_PARAMETER,
  TYPE_TICKET,
} from 'general/constants/AppConstants'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import SelectOptions from 'general/constants/SelectOptions'
import Utils from 'general/utils/Utils'
import ValidationSchema from 'general/utils/ValidationSchema'
import useGetTicketSerial from 'hooks/Queries/useGetTicketSerial'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import _, { get, includes, isNumber, map, split } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual } from 'react-redux'
import './style.scss'
import ticketDraftApi from 'api/ticektDraftApi'
import ToastHelper from 'general/helpers/ToastHelper'
import CustomTimePicker from 'general/components/Forms/CustomTimePicker'
import CustomSelect from 'general/components/CustomSelect'
import InputNumberNoFormik from 'general/components/Forms/InputNumerNoFormik'

AdjustReplaceTicket.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExistDone: PropTypes.func,
  title: PropTypes.string,
  describeText: PropTypes.string,
  buttonAgreeOnlick: PropTypes.func,
}

AdjustReplaceTicket.defaultProps = {
  title: 'Xác nhận',
  open: false,
  onClose: null,
  describeText:
    'Bạn cần hủy hóa đơn bị sai sót trước khi lập hóa đơn thay thế. Bạn có muốn hủy hóa đơn này không?',
  onExistDone: null,
}

function AdjustReplaceTicket(props) {
  const {
    title,
    open,
    ticket,
    typeTicket,
    toggleModalState,
    classify = 'HD',
  } = props
  const dispatch = useAppDispatch()
  const { company } = useAppSelector((state) => state.system)
  const [listDataRoute, setListDataRoute] = useState([])
  const modalRef = useRef(null)

  const typeDeduct = useMemo(() => {
    return ticket?.invoice_template?.category_ticket?.typeDeduct
  }, [ticket])

  const { data: serials } = useGetTicketSerial({
    includeAllOption: false,
    categoryTicketTypePrice: 2,
    classify,
    categoryTicketTypeDeduct: typeDeduct,
    disabled: !open,
  })

  useEffect(() => {
    // console.log('Component mounted!', ticket)
    // console.log('templateData', templateData)
    if (open) getListRoute()
  }, [open])

  const { currency, VATConfig } = useAppSelector(
    (state) => state?.systemConfig,
    shallowEqual,
  )
  const systemTaxRate = useMemo(() => {
    return VATConfig?.value?.replace('%', '') ?? '0'
  }, [VATConfig])

  const [timeOptions, setTimeOptions] = useState([])
  const [routeSelect, setRouteSelect] = useState(false)
  const [checkAutoCalculate, setCheckAutoCalculate] = useState(true)

  const routeQuery = useQuery({
    enabled: routeSelect,
    queryKey: ['getListRoute'],
    queryFn: () =>
      routeApi.getListOfRoute(
        // { limit: 10, availability: 1 },
        {},
      ),
    select: (res) => {
      const routeInfo = map(
        get(res, 'routes'),
        ({ routeId, nameRoute, routeStart, routeEnd, startTime }) => ({
          value: routeId,
          label: nameRoute,
          routeStart,
          routeEnd,
          startTime,
        }),
      )
      return routeInfo ?? []
    },
  })

  const getListRoute = async () => {
    const data = await routeApi.getListOfRoute({})
    setListDataRoute(data?.routes)
    console.log('data', data)
  }

  const { mutate: createAdjustReplaceTicket } = useMutation({
    mutationKey: 'createAdjustReplaceTicket',
    mutationFn: (params) => ticketApi.createAdjustReplace(params),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: (newTicketDraft) => {
      toggleModalState(false)
      const { ticketDraftItemId, ticketDraftId } = newTicketDraft.data
      if (classify == 'MTT') {
        handleIssueTicketDraftFromPos(ticketDraftId)
        return
      }
      if (company?.activeSigningMethod == 'HSM') {
        handleHsmSignTicketDraft(ticketDraftId)
      } else {
        const accessToken = localStorage.getItem(PreferenceKeys.accessToken)
        let urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},${SIGN_TOOL_PARAMETER.TICKETS_SIGNED_BY_SELLER},${ticketDraftItemId}`
        Utils.openToolSignInvoice(urlSchema, accessToken)
      }
    },
  })

  const handleHsmSignTicketDraft = async (ticketDraftId) => {
    let res = await ticketDraftApi.hsmSign({
      ticketDraftId,
    })

    let { errorSignTickets, successSignTickets } = res
    let describeText = `Phát hành thành công ${successSignTickets.length} vé, thất bại ${errorSignTickets.length} vé.`

    ToastHelper.showSuccess(describeText)
  }

  const handleIssueTicketDraftFromPos = async (ticketDraftId) => {
    let res = await ticketDraftApi.issueTicketsFromPos({
      ticketDraftId,
    })

    let { successIssueTickets } = res
    if (successIssueTickets.length > 0) {
      ToastHelper.showSuccess('Phát hành vé thành công')
    } else {
      ToastHelper.showError('Có lỗi xảy ra khi phát hành vé')
    }
  }

  const initialValues = useMemo(
    () => ({
      sellerName: company?.companyName,
      sellerTaxCode: company?.taxCode,
      sellerFullAddress: company?.businessPermitAddress,
      originalTicketId: ticket?.ticketId,
      nameTicket: ticket?.nameTicket,
      ticketReason: '',
      taxMoney: 0,
      price: 0,
      totalPrice: 0,
      taxRate: systemTaxRate,
      dateRelease: dayjs().utc(),
      typeTicket: typeTicket,
      autoCalculate: true,
      startDate: dayjs(),
      serial: ticket?.serial,
      // nameRoute: currentTemplate?.route?.nameRoute,
      // routeStart: currentTemplate?.route?.routeStart,
      // routeEnd: currentTemplate?.route?.routeEnd,
      nameRoute: '',
      routeStart: '',
      routeEnd: '',
      invoiceTemplateId: ticket?.invoiceTemplateId,
      startHour: null,
      noCar: typeTicket === TYPE_TICKET.ADJUST ? '' : ticket?.noCar,
      noSeatCar: typeTicket === TYPE_TICKET.ADJUST ? '' : ticket?.noSeatCar,
    }),
    [
      company?.businessPermitAddress,
      company?.companyName,
      company?.taxCode,
      // currentTemplate?.route?.nameRoute,
      // currentTemplate?.route?.routeEnd,
      // currentTemplate?.route?.routeStart,
      systemTaxRate,
      ticket?.invoiceTemplateId,
      ticket?.nameTicket,
      ticket?.noCar,
      ticket?.noSeatCar,
      ticket?.serial,
      ticket?.ticketId,
      typeTicket,
    ],
  )

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      let totalPriceVnese = ''
      if (isNumber(values.totalPrice)) {
        totalPriceVnese = Utils.numberToWords(
          values.totalPrice, //num
          'vi', // unit
          '', // separation
          'đồng', //endSymbol
          'phẩy', // afterdecimal
          '',
          '',
          currency?.zeroInten,
          currency?.zeroInThousand,
          currency?.evenDisplay,
        )
      }
      if (typeTicket == TYPE_TICKET.ADJUST || !values.autoCalculate) {
        totalPriceVnese = Utils.changeVneseAdjustInvoice(
          totalPriceVnese,
          values.totalPrice,
          currency?.readMoneyInvoice,
        )
      }
      const dayFormat = dayjs(values.startDate).format('YYYY-MM-DD')
      const hourFormat = values?.startHour ? values.startHour : '12:00'
      const departureDateTimeFormat = `${dayFormat} ${hourFormat}`
      // const departureDateTime = dayjs(departureDateTimeFormat)
      const params = {
        ticketDraftItems: [
          {
            ...values,
            totalPriceVnese,
            dateCreate: dayjs(),
            departureDateTime: departureDateTimeFormat,
            nameRoute: values.nameRoute
              ? values.nameRoute
              : typeTicket === TYPE_TICKET.REPLACE
                ? ticket?.nameRoute
                : values.nameRoute,
            routeStart: values.routeStart
              ? values.routeStart
              : typeTicket === TYPE_TICKET.REPLACE
                ? ticket?.routeStart
                : values.routeStart,
            routeEnd: values.routeEnd
              ? values.routeEnd
              : typeTicket === TYPE_TICKET.REPLACE
                ? ticket?.routeEnd
                : values.routeEnd,
          },
        ],
        classify,
      }
      createAdjustReplaceTicket(params)
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema.adjustReplaceTicket,
  })

  const { values, setFieldValue, setErrors } = formik

  const { mutate: getDetailTemplate, data: templateData } = useMutation({
    mutationKey: 'getDetailTemplate',
    mutationFn: (invoiceTemplateId) =>
      templateInvoiceApi.getDetail(invoiceTemplateId),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: (template) => {
      // setCurrentTemplate(template)
      if (template?.route) {
        formik.setFieldValue('nameRoute', template?.route?.nameRoute)
        formik.setFieldValue('routeStart', template?.route?.routeStart)
        formik.setFieldValue('routeEnd', template?.route?.routeEnd)
      } else {
        formik.setFieldValue('nameRoute', null)
        formik.setFieldValue('routeStart', '')
        formik.setFieldValue('routeEnd', '')
      }
    },
  })

  // const handleCalculateTaxMoneyAndTotalPrice = useCallback(
  //   (price, taxRate) => {
  //     let taxMoney = 0,
  //       totalPrice = price ?? 0
  //     if (price && taxRate) {
  //       taxMoney = Utils.calculateTaxMoney(price, taxRate)
  //       totalPrice = price + taxMoney
  //     }
  //     setFieldValue('taxMoney', taxMoney)
  //     setFieldValue('totalPrice', totalPrice)
  //   },
  //   [setFieldValue],
  // )

  const calculateAndSetTaxReduceMoneyAndTotalPrice = useCallback(
    (ticketPriceWithoutReduce, rateVatInAmount) => {
      let totalPrice = ticketPriceWithoutReduce
      let ticketPriceReduce = 0
      if (_.isNumber(ticketPriceWithoutReduce) && _.isNumber(rateVatInAmount)) {
        ticketPriceReduce = Math.round(
          ((ticketPriceWithoutReduce * parseInt(rateVatInAmount)) / 100) * 0.2,
        )
        totalPrice = ticketPriceWithoutReduce - ticketPriceReduce
      }
      setFieldValue('totalPrice', totalPrice)
      setFieldValue('ticketPriceReduce', ticketPriceReduce)
    },
    [setFieldValue],
  )

  const onChangeIsTaxReduction = (e) => {
    formik.setFieldValue('isTaxReduction', e.target.checked)
    setCheckAutoCalculate(e.target.checked)
    if (e.target.checked) {
      formik.setFieldValue('rateVatInAmount', 2)
      formik.setFieldValue('ticketPriceWithoutReduce', formik.values.totalPrice)
    }
  }

  const handleNumberInputChange = (e, fieldName) => {
    // setCheckTotalPrice(true)
    let value = 0
    if (e.target.value) {
      value = e.target.value
    }
    let iValue = Utils.parseFormattedNumberToFloat(value)
    if (isNaN(iValue)) {
      iValue = 0
    }
    formik.setFieldValue(fieldName, iValue)
    if (checkAutoCalculate) {
      if (fieldName == 'totalPrice') {
        // alert(formik.values.taxRate)
        if (iValue != null && iValue != undefined) {
          let price = (iValue * 100) / (100 + getTaxRate(formik.values.taxRate))
          formik.setFieldValue('taxMoney', iValue - price)
          formik.setFieldValue('price', price)
        } else {
          formik.setFieldValue('taxMoney', 0)
          formik.setFieldValue('price', 0)
        }
      } else if (fieldName == 'price') {
        if (iValue != null && iValue != undefined) {
          let taxMoney = Math.trunc(
            (iValue * getTaxRate(formik?.values?.taxRate)) / 100,
          ).roundedValue
          let totalPrice = 0
          if (iValue && formik?.values?.taxRate) {
            taxMoney = getTaxMoney(iValue, formik?.values?.taxRate)
            totalPrice = iValue + taxMoney
          }
          setFieldValue('taxMoney', taxMoney)
          setFieldValue('totalPrice', totalPrice)
        } else {
          formik.setFieldValue('taxMoney', 0)
          formik.setFieldValue('totalPrice', 0)
        }
      }
    }
  }

  const getTaxMoney = (price, taxRate) => {
    return Utils.parseFormattedNumberToFloat(
      (price * getTaxRate(taxRate)) / 100,
    )
  }

  const getTaxRate = (taxRate) => {
    if (taxRate === 'KCT' || taxRate === 'KHAC' || taxRate === 'KKKNT') {
      return 0
    } else {
      return _.parseInt(taxRate)
    }
  }

  const departureTimeArray = useMemo(() => {
    let startTime =
      templateData?.route?.startTime ||
      ticket?.invoice_template?.route?.startTime

    if (!startTime) return null

    const tokenizationString = ';'
    const hasToken = includes(startTime, tokenizationString)
    if (hasToken) {
      const inputArray = split(startTime, tokenizationString)
      return inputArray
    } else {
      return [startTime]
    }
  }, [
    templateData?.route?.startTime,
    ticket?.invoice_template?.route?.startTime,
  ])

  useEffect(() => {
    if (values.autoCalculate) {
      // handleCalculateTaxMoneyAndTotalPrice(values.price, values.taxRate)
    }
  }, [
    values.price,
    values.taxRate,
    // handleCalculateTaxMoneyAndTotalPrice,
    values.autoCalculate,
  ])

  useEffect(() => {
    if (values.autoCalculate && values.isTaxReduction) {
      calculateAndSetTaxReduceMoneyAndTotalPrice(
        values.ticketPriceWithoutReduce,
        values.rateVatInAmount,
      )
    }
  }, [
    values.autoCalculate,
    values.isTaxReduction,
    values.ticketPriceWithoutReduce,
    values.rateVatInAmount,
    calculateAndSetTaxReduceMoneyAndTotalPrice,
  ])

  useEffect(() => {
    open && setErrors({})
  }, [open, setErrors])

  return (
    <DraggableModal
      title={title}
      cancelText="Huỷ bỏ"
      applyText="Xuất vé"
      ref={modalRef}
      isOpen={open}
      onOpenChange={(state) => toggleModalState(state)}
      handleApplyChanges={() => formik.submitForm()}
      width={1000}
      styles={{
        body: {
          padding: 0,
        },
      }}
    >
      <div className="AdjustReplaceTicketModal">
        <div className="bg-light w-100 p-4">
          <div className=" d-flex flex-row justify-content-between ">
            <div className="d-flex flex-column">
              <Typography.Title level={4} style={{ fontWeight: '700' }}>
                {`${
                  typeTicket === TYPE_TICKET.ADJUST ? 'Điều chỉnh' : 'Thay thế'
                } cho vé`}
              </Typography.Title>
              <div className="d-flex flex-row" style={{ gap: '30px' }}>
                <div className="d-flex flex-row align-items-center">
                  <FormLabel width="60px" fontWeight="600">
                    Số vé:
                  </FormLabel>
                  <span style={{ fontWeight: '500' }}>{ticket?.no}</span>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <FormLabel width="60px" fontWeight="600">
                    Ký hiệu
                  </FormLabel>
                  <span style={{ fontWeight: '500' }}>{ticket?.serial}</span>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <FormLabel width="100px" fontWeight="600">
                    Ngày xuất vé:
                  </FormLabel>
                  <span style={{ fontWeight: '500' }}>
                    {dayjs(ticket?.dateRelease).format('L')}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column align-items-end">
              <span style={{ fontWeight: '500' }}>Tổng tiền thanh toán</span>
              <span style={{ fontWeight: '700', fontSize: '18px' }}>
                {Utils.formatNumber(ticket?.totalPrice)}
              </span>
            </div>
          </div>
          <div className="d-flex flex-row mt-2">
            <CustomLabel
              text={Utils.cn(
                'Lý do',
                typeTicket === TYPE_TICKET.ADJUST ? 'điều chỉnh' : 'thay thế',
              )}
              isRequired
              fontWeight={500}
            />
            <div className="flex-fill d-inline-flex flex-column gap-2">
              <CustomAntInput
                borderRadius="sm"
                type="text"
                inputProps={{
                  autoComplete: 'off',
                  placeholder: 'Nhập lý do',
                  status:
                    formik.getFieldMeta('ticketReason').error &&
                    formik.touched.ticketReason
                      ? 'error'
                      : '',
                  ...formik.getFieldProps('ticketReason'),
                }}
              />
              {formik.errors.ticketReason && formik.touched.ticketReason && (
                <Typography.Text type="danger" className="font-size-sm">
                  {formik.errors.ticketReason}
                </Typography.Text>
              )}
            </div>
          </div>
        </div>
        <div className="p-4 d-flex flex-column" style={{ gap: '15px' }}>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <Typography.Title
              level={4}
              style={{ fontWeight: '600' }}
              className="mb-0 mr-5"
            >
              {`Thông tin vé ${
                typeTicket === TYPE_TICKET.ADJUST ? 'điều chỉnh' : 'thay thế'
              }`}
            </Typography.Title>
            <div className="d-flex flex-row flex-fill justify-content-between">
              <Checkbox
                checked={formik.values.autoCalculate}
                onChange={(ev) => {
                  setCheckAutoCalculate(ev.target.checked)
                  formik.setFieldValue('autoCalculate', ev.target.checked)
                  if (ev.target.checked) {
                    //tính lại tổng tiền và tiền thuế gtgt
                    let price = formik?.values?.price
                    if (price != null && price != undefined) {
                      let taxMoney = getTaxMoney(price, formik?.values?.taxRate)
                      formik.setFieldValue('taxMoney', taxMoney)
                      formik.setFieldValue('totalPrice', price + taxMoney)
                    } else {
                      formik.setFieldValue('totalPrice', 0)
                      formik.setFieldValue('taxMoney', 0)
                    }
                  }
                }}
                style={{ fontWeight: '500' }}
              >
                Tự động tính toán số liệu
              </Checkbox>
              {typeDeduct == 'TT' && (
                <Checkbox
                  checked={formik.values.isTaxReduction}
                  onChange={onChangeIsTaxReduction}
                >
                  <span style={{ color: '#333333', fontWeight: '500' }}>
                    Giảm thuế theo NQ 101/2023/QH15
                  </span>
                </Checkbox>
              )}
            </div>
          </div>
          {/* Serial */}
          <div className="d-flex flex-row">
            <FormLabel fontWeight="600">Ký hiệu</FormLabel>
            <CustomAntSelect
              className="adjustReplaceTicket-select"
              options={serials}
              value={formik.values.serial}
              onChange={(value, option) => {
                formik.setFieldValue('serial', value)
                formik.setFieldValue('nameTicket', option?.name)
                formik.setFieldValue('startHour', null)
                setTimeOptions([])
                getDetailTemplate(option?.invoiceTemplateId)
              }}
              customFilterOptionFn={(input, option) =>
                includes(option?.label?.toLowerCase(), input.toLowerCase())
              }
            />
          </div>
          <div className="d-flex w-100 gap-3">
            {/* Left info */}
            <div className="left-info d-flex flex-column gap-2">
              <div className="d-flex flex-row">
                <FormLabel fontWeight="600">Ngày xuất vé</FormLabel>
                <CustomAntInput
                  borderRadius="sm"
                  type="text"
                  inputProps={{
                    autoComplete: 'off',
                    value: dayjs(
                      formik.getFieldProps('dateCreate').value,
                    ).format('L'),
                    disabled: true,
                    name: formik.getFieldProps('dateCreate').name,
                    onChange: formik.getFieldProps('dateCreate').onChange,
                  }}
                />
              </div>
              {typeDeduct == 'KT' && (
                <div className="d-flex flex-row">
                  <FormLabel fontWeight="600">Số tiền chưa thuế</FormLabel>
                  {/* <CustomAntInput
                    borderRadius="sm"
                    type="text"
                    inputProps={{
                      autoComplete: 'off',
                      status: formik.getFieldMeta('price').error ? 'error' : '',
                      value: Utils.formatNumber(
                        formik.getFieldProps('price').value,
                      ),
                      name: formik.getFieldProps('price').name,
                      onChange: (e) => {
                        handleNumberInputChange(e, 'price')
                      },
                    }}
                  /> */}
                  <InputNumberNoFormik
                    onChange={(e) => handleNumberInputChange(e, 'price')}
                    value={formik.getFieldProps('price').value}
                    allowNegative={true}
                    decimalScale={0}
                  />
                </div>
              )}
              {typeDeduct == 'TT' && formik.values.isTaxReduction && (
                <div className="d-flex flex-row">
                  <FormLabel fontWeight="600">
                    Tổng tiền (Chưa giảm thuế)
                  </FormLabel>
                  <InputNumberNoFormik
                    onChange={(e) => {
                      const value = Utils.parseFormattedNumberToFloat(
                        e.target.value,
                      )
                      formik.setFieldValue('ticketPriceWithoutReduce', value)
                    }}
                    value={formik.values.ticketPriceWithoutReduce}
                    allowNegative={true}
                  />
                </div>
              )}
              {typeDeduct == 'KT' && (
                <div className="d-flex flex-row">
                  <FormLabel fontWeight="600">Tiền thuế GTGT</FormLabel>
                  {/* <CustomAntInput
                    borderRadius="sm"
                    type="text"
                    inputProps={{
                      autoComplete: 'off',
                      status: formik.getFieldMeta('taxMoney').error
                        ? 'error'
                        : '',
                      value: Utils.formatNumber(
                        formik.getFieldProps('taxMoney').value,
                      ),
                      name: formik.getFieldProps('taxMoney').name,
                      onChange: (e) => {
                        handleNumberInputChange(e, 'taxMoney')
                      },
                    }}
                  /> */}
                  <InputNumberNoFormik
                    onChange={(e) => handleNumberInputChange(e, 'taxMoney')}
                    value={formik.getFieldProps('taxMoney').value}
                    allowNegative={true}
                    decimalScale={0}
                    disabled={checkAutoCalculate}
                  />
                </div>
              )}
              {typeDeduct == 'TT' && formik.values.isTaxReduction && (
                <div className="d-flex flex-row">
                  <FormLabel fontWeight="600">% tính thuế</FormLabel>
                  <CustomSelect
                    selectOptions={SelectOptions.rateVatInAmount}
                    currentValue={formik.values.rateVatInAmount}
                    onChange={(selectedOption) => {
                      formik.setFieldValue(
                        'rateVatInAmount',
                        selectedOption.value,
                      )
                    }}
                    haveMenuParentRef={false}
                    width="100%"
                  />
                </div>
              )}
              {typeDeduct == 'KT' && (
                <div className="d-flex flex-row">
                  <FormLabel fontWeight="600">Thuế GTGT</FormLabel>
                  <CustomAntSelect
                    className="adjustReplaceTicket-select"
                    options={SelectOptions.GTGT}
                    value={formik.values.taxRate}
                    onChange={(value) => {
                      formik.setFieldValue('taxRate', value)
                      if (checkAutoCalculate) {
                        let price = formik?.values?.price
                        let taxMoney = getTaxMoney(price, value)
                        formik.setFieldValue('taxMoney', taxMoney)
                        formik.setFieldValue('totalPrice', price + taxMoney)
                      }
                    }}
                  />
                </div>
              )}
              {typeDeduct == 'TT' && formik.values.isTaxReduction && (
                <div className="d-flex flex-row">
                  <FormLabel fontWeight="600">
                    Tiền thuế GTGT được giảm:
                  </FormLabel>
                  <InputNumberNoFormik
                    onBlur={(e) => {
                      const value = Utils.parseFormattedNumberToFloat(
                        e.target.value,
                      )
                      formik.setFieldValue('ticketPriceReduce', value)
                    }}
                    value={formik.values.ticketPriceReduce}
                    disabled={true}
                    allowNegative={true}
                  />
                </div>
              )}
              <div className="d-flex flex-row">
                <FormLabel fontWeight="600">Tổng tiền</FormLabel>
                {/* <CustomAntInput
                  borderRadius="sm"
                  type="text"
                  inputProps={{
                    autoComplete: 'off',
                    status: formik.getFieldMeta('totalPrice').error
                      ? 'error'
                      : '',
                    value: Utils.formatNumber(
                      formik.getFieldProps('totalPrice').value,
                    ),
                    name: formik.getFieldProps('totalPrice').name,
                    onChange: (e) => {
                      handleNumberInputChange(e, 'totalPrice')
                    },
                  }}
                /> */}
                <InputNumberNoFormik
                  onChange={(e) => handleNumberInputChange(e, 'totalPrice')}
                  value={formik.getFieldProps('totalPrice').value}
                  allowNegative={true}
                  decimalScale={0}
                />
              </div>
            </div>
            {/* Right info */}
            <div className="right-info d-flex flex-column gap-2 flex-fill">
              <div className="d-flex flex-row">
                <FormLabel fontWeight="600">Tuyến đường</FormLabel>
                <div
                  style={{
                    display:
                      ticket?.invoice_template?.routeType == ROUTE_TYPE.MULTIPLE
                        ? 'none'
                        : 'inherit',
                    marginTop: 8,
                    width: '100%',
                  }}
                >
                  {formik.values?.nameRoute
                    ? formik.values.nameRoute
                    : ticket?.nameRoute}
                </div>
                <div
                  style={{
                    display:
                      ticket?.invoice_template?.routeType == ROUTE_TYPE.SINGLE
                        ? 'none'
                        : 'inherit',
                    width: '100%',
                  }}
                >
                  {!templateData?.routeId ? (
                    <CustomAntSelect
                      isOpen={routeSelect}
                      onOpenChange={setRouteSelect}
                      isLoading={routeQuery?.isLoading}
                      className="adjustReplaceTicket-select"
                      placeholder="Chọn tuyến đường"
                      customFilterOptionFn={(input, option) =>
                        option?.routeStart
                          ?.toLowerCase()
                          .includes(input.toLowerCase()) ||
                        option?.routeEnd
                          ?.toLowerCase()
                          .includes(input.toLowerCase()) ||
                        option?.label
                          ?.toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      notFoundContent="Không tìm thấy tuyến đường"
                      options={routeQuery?.data}
                      value={
                        ticket?.invoice_template?.routeType ==
                        ROUTE_TYPE.MULTIPLE
                          ? typeTicket === TYPE_TICKET.ADJUST
                            ? formik.values.nameRoute
                            : formik.values?.nameRoute
                              ? formik.values.nameRoute
                              : ticket?.nameRoute
                          : typeTicket === TYPE_TICKET.ADJUST
                            ? formik.values?.nameRoute
                            : formik.values?.nameRoute
                              ? formik.values.nameRoute
                              : ticket?.nameRoute
                      }
                      onChange={(selected, selectedOption) => {
                        formik.setFieldValue('nameRoute', selectedOption?.label)
                        formik.setFieldValue(
                          'routeStart',
                          selectedOption?.routeStart,
                        )
                        formik.setFieldValue(
                          'routeEnd',
                          selectedOption?.routeEnd,
                        )
                        formik.setFieldValue('startHour', null)
                        setTimeOptions(
                          // split(selectedOption?.startTime, ';').map((time) => ({
                          //   value: time,
                          //   label: time,
                          // })),
                          selectedOption?.startTime
                            ?.split(';')
                            .filter((item) => item !== ''),
                        )
                      }}
                    />
                  ) : (
                    <CustomAntInput
                      borderRadius="sm"
                      type="text"
                      inputProps={{
                        disabled: true,
                        value: formik.values.nameRoute,
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="d-flex flex-row">
                <FormLabel fontWeight="600">Bến</FormLabel>
                <div className="d-flex justify-content-between align-items-center flex-fill">
                  <Typography.Text>
                    {ticket?.invoice_template?.routeType == ROUTE_TYPE.MULTIPLE
                      ? typeTicket === TYPE_TICKET.ADJUST
                        ? formik.values.routeStart
                        : formik.values?.routeStart
                          ? formik.values.routeStart
                          : ticket?.routeStart
                      : typeTicket === TYPE_TICKET.ADJUST
                        ? ticket?.routeStart
                        : formik.values?.routeStart
                          ? formik.values.routeStart
                          : ticket?.routeStart}
                  </Typography.Text>
                  <i className="fa-solid fa-arrow-right fa-lg text-primary" />
                  <Typography.Text>
                    {ticket?.invoice_template?.routeType == ROUTE_TYPE.MULTIPLE
                      ? typeTicket === TYPE_TICKET.ADJUST
                        ? formik.values.routeEnd
                        : formik.values?.routeEnd
                          ? formik.values.routeEnd
                          : ticket?.routeEnd
                      : typeTicket === TYPE_TICKET.ADJUST
                        ? ticket?.routeEnd
                        : formik.values?.routeEnd
                          ? formik.values.routeEnd
                          : ticket?.routeEnd}
                  </Typography.Text>
                </div>
              </div>
              <div className="d-flex flex-row w-100 gap-2">
                <div className="d-flex flex-row w-50">
                  <FormLabel fontWeight="600">Ngày khởi hành</FormLabel>
                  <DatePicker
                    allowClear={false}
                    format={'L'}
                    locale={locale}
                    value={formik.values.startDate}
                    disabledDate={(current) =>
                      current && current > dayjs().endOf('day')
                    }
                    onChange={(date) => formik.setFieldValue('startDate', date)}
                  />
                </div>
                <div className="d-flex flex-row w-50">
                  <CustomLabel
                    text="Giờ khởi hành"
                    width={'150px'}
                    fontWeight="600"
                  />
                  {/* {!templateData?.routeId ? (
                  <CustomAntSelect
                    options={timeOptions}
                    value={formik.values.startHour}
                    onChange={(value) => {
                      formik.setFieldValue('startHour', value)
                    }}
                    placeholder="Chọn giờ khởi hành"
                    notFoundContent="Không tìm thấy giờ khởi hành"
                  />
                ) : (
                  <CustomAntInput
                    borderRadius="sm"
                    type="text"
                    inputProps={{
                      value: formik.values.startHour,
                      onChange: (e) => {
                        formik.setFieldValue('startHour', e.target.value)
                      },
                    }}
                  />
                )} */}
                  <CustomTimePicker
                    initialValue={formik.values.startHour}
                    onBlur={(startHour) => {
                      formik.setFieldValue('startHour', startHour)
                    }}
                    timeArray={
                      !_.isEmpty(departureTimeArray)
                        ? departureTimeArray
                        : timeOptions && timeOptions?.length > 0
                    }
                  />
                </div>
              </div>
              <div className="d-flex flex-row">
                <FormLabel fontWeight="600">Số xe</FormLabel>
                <CustomAntInput
                  borderRadius="sm"
                  type="text"
                  inputProps={{
                    value: formik.values.noCar,
                    onChange: (e) => {
                      formik.setFieldValue('noCar', e.target.value)
                    },
                  }}
                />
              </div>
              <div className="d-flex flex-row">
                <FormLabel fontWeight="600">Số ghế</FormLabel>
                <CustomAntInput
                  borderRadius="sm"
                  type="text"
                  inputProps={{
                    value: formik.values.noSeatCar,
                    onChange: (e) => {
                      formik.setFieldValue('noSeatCar', e.target.value)
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DraggableModal>
  )
}

export default AdjustReplaceTicket
