import { Image } from 'antd'
import KTLayoutAside from 'assets/plugins/aside/aside'
import KTLayoutAsideMenu from 'assets/plugins/aside/aside-menu'
import KTLayoutAsideToggle from 'assets/plugins/aside/aside-toggle'
import 'assets/styles/keen/theme01/layout/aside/dark.css'
import AppResource from 'general/constants/AppResource'
import useProjectBranding from 'hooks/useProjectBranding'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ResourceStatistics from './Components/ResourceStatistics'
import SideMenuContent from './Components/SideMenuContent'
import './style.scss'
import { useQuery } from '@tanstack/react-query'
import axiosClient from 'api/axiosClient'

function KT01Sidebar() {
  // MARK: --- Params ---
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { logo } = useProjectBranding()
  const { data: banners } = useQuery({
    staleTime: 1000 * 60 * 5,
    queryKey: ['banner'],
    queryFn: ({ signal }) =>
      axiosClient.get('/notification-marquee/show', {
        signal,
      }),
  })

  // MARK: --- Hooks ---

  useEffect(() => {
    // Init Aside

    if (KTLayoutAside !== undefined) {
      KTLayoutAside.init('kt_aside')
    }

    // Init Aside Toggle

    if (KTLayoutAsideToggle !== undefined) {
      KTLayoutAsideToggle.init('kt_aside_toggle')
    }

    // Init Aside Menu

    if (KTLayoutAsideMenu !== undefined) {
      KTLayoutAsideMenu.init('kt_aside_menu')
    }
  }, [])

  return (
    <div
      className="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
      id="kt_aside"
      style={banners?.data?.length > 0 ? { paddingTop: '30px' } : {}}
    >
      {/* Aside Header */}

      <div className="brand flex-column-auto">
        {/* logo */}

        <div className="d-flex align-items-center">
          <div
            onClick={() => {
              navigate('/hoa-don-dau-ra/ban-lam-viec')
            }}
            className="brand-logo cursor-pointer"
          >
            <Image className="h-50px" src={logo} alt="logo" preview={false} />
          </div>
        </div>

        {/* button toggle */}

        <button
          className="brand-toggle btn btn-sm px-0 border-0"
          id="kt_aside_toggle"
        >
          <span className="svg-icon svg-icon svg-icon-xl">
            <img src={AppResource.icons.keens.toggleRight} alt="toggle" />
          </span>
        </button>
      </div>

      {/* Aside Menu */}

      <div
        id="kt_aside_menu"
        className="aside-menu my-0"
        data-menu-scroll="1" // enable scroll
        data-menu-vertical="1"

        // data-menu-dropdown-timeout='500'
      >
        {/* Nav menu */}

        <SideMenuContent />
      </div>

      {/* Số liệu tài nguyên */}
      <ResourceStatistics />
      {/* customer support */}

      <div className="text-center aside-support">
        <div
          className="text-white py-2"
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
          }}
        >
          <p className="font-size-h5 m-0">
            <i className="text-white fa-regular fa-headset mr-4"></i>

            {t('CustomerSupport')}

            {':'}
          </p>

          <p className="font-size-h5 m-0" style={{ color: '#00BBB3' }}>
            <a style={{ color: '#00BBB3' }} href="tel: 19000099">
              1900.4751
            </a>{' '}
            {' - '}
            <a style={{ color: '#00BBB3' }} href="tel: 0988399516">
              0208.3607.668
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default KT01Sidebar
