import invoiceApi from 'api/invoiceApi'
import { MESSAGE_SUCCESS } from 'general/constants/AppConstants'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'

const createNewInvoice = async (
  values,
  submitAction,
  invoiceProduct,
  company,
  dispatch,
  setAppSpinning,
  navigate,
  buyerInfoExtendFields,
  signingConfig,
  successFunction,
  failedFunction,
  isFromPOS,
  isDeliveryBill,
  handleIssueFromPos,
) => {
  if (values.customerTaxCode) {
    if (!(values.customerCompanyName && values.customerFullAddress)) {
      ToastHelper.showError(
        'Tên đơn vị và địa chỉ người mua không được để trống khi có MST người mua',
      )
      return
    }
  }
  // if (invoiceProduct[0].productId === 0) {
  //   ToastHelper.showError('Vui lòng nhập thêm thông tin Hàng hoá/ Dịch vụ');
  // } else {
  // item cuối không có tên thì không gửi lên api
  if (invoiceProduct[invoiceProduct.length - 1].name === '') {
    invoiceProduct.pop()
  }
  // debugger
  const params = {
    ...values,
    invoiceProduct,
    buyerInfoExtendFields,
    total: parseFloat(values.total),
    totalAfterTax: parseFloat(values.totalAfterTax),
    taxMoney: parseFloat(values.taxMoney),
  }

  const navigateHandler = () => {
    if (isFromPOS)
      return '/hoa-don-dau-ra/hoa-don/may-tinh-tien/danh-sach-hoa-don'
    if (isDeliveryBill) return '/hoa-don-dau-ra/hoa-don/phieu-xuat-kho'
    return '/hoa-don-dau-ra/hoa-don/danh-sach-hoa-don'
  }

  if (isFromPOS) params.classify = 'MTT'
  else if (isDeliveryBill) params.classify = 'PXK'

  dispatch(setAppSpinning(true))
  switch (submitAction) {
    case 'SaveNoNavigate':
    case 'Save': {
      try {
        const res = await invoiceApi.create(params)
        dispatch(setAppSpinning(false))
        if (res) {
          if (res.result === 'success') {
            console.log('Thành công!')
            ToastHelper.showSuccess('Lưu hoá đơn nháp thành công')

            if (submitAction != 'SaveNoNavigate') {
              navigate(navigateHandler())
            } else if (successFunction) {
              successFunction()
            }

            return res?.data
          } else {
            console.log('thất bại')
            if (failedFunction) failedFunction()
            ToastHelper.showSuccess('Thêm hoá đơn thất bại')
          }
        }
      } catch (err) {
        if (failedFunction) failedFunction()
        dispatch(setAppSpinning(false))
        ToastHelper.showError('Thêm hoá đơn thất bại')
      }
      break
    }

    case 'SaveAndSign': {
      try {
        const res = await invoiceApi.create(params)
        if (res) {
          if (res.result === 'success') {
            ToastHelper.showSuccess('Lưu hoá đơn nháp thành công')

            let invoiceId = res.data?.invoiceId

            /* Trường hợp phát hành hóa đơn mtt */
            if (isFromPOS) {
              handleIssueFromPos(invoiceId)
              return
            }

            /* Trường hợp không phải hóa đơn mtt */
            let { signingMethod } = signingConfig
            if (signingMethod == 'HSM') {
              const hsmInvoicesSignParams = {
                invoiceIds: [invoiceId],
              }
              if (isDeliveryBill) hsmInvoicesSignParams.classify = 'PXK'
              let _res = await invoiceApi.hsmInvoicesSign(hsmInvoicesSignParams)
              if (_res.data) _res = _res.data

              // Kí thành công
              if (
                !!_res?.successSignInvoices?.find(
                  ({ invoiceId: _invoiceId }) => _invoiceId == invoiceId,
                )
              ) {
                ToastHelper.showSuccess(MESSAGE_SUCCESS.ISSUED_INVOICE)
                navigate(navigateHandler())
                return
              }
              // Kí thất bại
              ToastHelper.showError(_res?.errorSignInvoices?.at(0)?.reason)
            } else {
              let accessToken = localStorage.getItem(PreferenceKeys.accessToken)
              const urlSchema = `${process.env.REACT_APP_BASE_URL_SCHEMA}${accessToken},${company?.taxCode},${invoiceId}${isDeliveryBill ? ',PXK' : ''}`
              Utils.openToolSignInvoice(urlSchema, accessToken)
            }
          } else {
            ToastHelper.showSuccess('Thêm hoá đơn thất bại')
          }
        }
      } catch (err) {
        console.log(err)
        ToastHelper.showError('Thêm hoá đơn thất bại')
      } finally {
        dispatch(setAppSpinning(false))
      }
      break
    }

    //huy
    case 'Preview':
      {
        dispatch(setAppSpinning(true))
        var res
        try {
          res = await invoiceApi.exportInvoice({ ...params })
        } catch (e) {
          console.log(e)
        } finally {
          dispatch(setAppSpinning(false))
        }

        let newFile = new File([res], 'Hóa đơn.pdf', {
          type: 'application/pdf',
        })
        var fileURL = URL.createObjectURL(newFile)
        window.open(fileURL, '_blank')
      }
      break
    default:
      return null
  }
  // }
}

export default createNewInvoice
