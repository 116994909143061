import { useQuery } from '@tanstack/react-query'
import { useDebounce } from '@uidotdev/usehooks'
import { Space } from 'antd'
import ticketApi from 'api/ticketApi'
import dayjs from 'dayjs'
import ExportTicketModal from 'features/HandleTicket/Components/Modals/ExportTicketModal'
import 'features/HandleTicket/styles.scss'
import CustomAntButton from 'general/components/Button/CustomAntButton'
import ContentContainer from 'general/components/ContentContainer'
import CustomDataTable from 'general/components/CustomDataTable'
import FilterPopover from 'general/components/CustomPopover/FilterPopover'
import CustomAntInput from 'general/components/Forms/CustomAntInput/CustomAntInput'
import TableAction from 'general/components/GroupButton/TableAction'
import { IconAction } from 'general/components/GroupButton/styles'
import SelectOptions from 'general/constants/SelectOptions'
import Utils from 'general/utils/Utils'
import useExportById from 'hooks/Mutations/useExportById'
import useFilter from 'hooks/useFilter'
import { assign, get, keys, map } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import AdjustReplaceTicketModal from 'features/HandleTicket/Components/Modals/AdjustReplaceTicketModal'
import { TYPE_TICKET } from 'general/constants/AppConstants'

export default function ReplacedTicketScreen() {
  const [modalState, setModalState] = useState({
    viewCanceledReason: false,
    viewDetail: false,
    sendMail: false,
    exportReplaced: false,
    adjustReplaceTicket: false,
  })
  const [search, setSearch] = useState('')
  const [activeTicket, setActiveTicket] = useState(null)
  const debouncedValue = useDebounce(search, 1000)
  const [filter, setFilter] = useFilter(
    'replacedTicketList',
    'gFilterReplacedTicket',
  )

  const {
    data: listData,
    isFetching: isGettingList,
    isSuccess,
  } = useQuery({
    queryKey: ['replacedTicketList', debouncedValue, filter],
    queryFn: () =>
      ticketApi.list({ ...filter, q: debouncedValue, ticketType: 'REPLACED' }),
    select: (res) => ({
      total: get(res, 'count', 0),
      rows: get(res, 'rows', []),
    }),
  })

  const { exportFunc } = useExportById(
    (ticketId) => ticketApi.exportById(ticketId),
    'Hóa đơn.pdf',
    'application/pdf',
  )

  /**
   * Toggles the state of a modal.
   * @param {Object} options - The options object.
   * @param {keyof modalState} options.modalName - The name of the modal to toggle.
   * @param {boolean} options.status - The status to set for the modal.
   */

  const toggleModalState = ({ modalName, status }) =>
    setModalState((prev) => {
      const notModalName = keys(prev).filter((key) => key !== modalName)
      const turnEverythingOff = map(notModalName, (key) => ({ [key]: false }))
      return {
        ...assign({}, ...turnEverythingOff),
        [modalName]: status,
      }
    })

  const columns = useMemo(() => {
    /**
     * @type {import('react-data-table-component').TableColumn<ReplacedTicketData>[]}
     */
    const arr = [
      {
        name: 'Ký hiệu',
        center: true,
        cell: ({ serial }) => (
          <p style={{ textAlign: 'left' }} className="w-100 m-0">
            {serial}
          </p>
        ),
      },
      {
        name: 'Số vé',
        center: true,
        cell: ({ no }) => (
          <p style={{ textAlign: 'left' }} className="w-100 m-0">
            {no}
          </p>
        ),
      },
      {
        name: 'Ngày xuất vé',
        center: true,
        selector: ({ dateRelease }) => dayjs(dateRelease).format('DD/MM/YYYY'),
      },
      {
        name: 'Mã CQT',
        center: true,
        selector: ({ taxAuthorityCode }) => taxAuthorityCode,
      },
      {
        name: 'Tổng tiền',
        center: true,
        cell: ({ totalPrice }) => (
          <p style={{ textAlign: 'right' }} className="w-100 m-0">
            {Utils.formatCurrency(totalPrice)}
          </p>
        ),
      },
      {
        name: 'Ngày thay thế',
        center: true,
        selector: ({ cancelDate }) =>
          cancelDate && dayjs(cancelDate).format('DD/MM/YYYY'),
      },
      {
        name: 'Lý do thay thế',
        center: true,
        cell: ({ errAnnouceReason, TicketRelated }) => {
          const dataRelated = TicketRelated?.[0]
          return (
            <>
              {dataRelated?.ticketReason}
              {/* <TableAction
              type="link"
              titleActionText="Xem chi tiết"
              text={ticketReason}
              onClick={() =>
                toggleModalState({
                  modalName: 'viewCanceledReason',
                  status: true,
                })
              }
            /> */}
            </>
          )
        },
        selector: ({ errAnnouceReason }) => errAnnouceReason,
      },
      //Vé thay thế
      {
        name: 'Ký hiệu',
        center: true,
        cell: ({ TicketRelated }) => (
          <p style={{ textAlign: 'left' }} className="w-100 m-0">
            {TicketRelated[0]?.serial}
          </p>
        ),
      },
      {
        name: 'Số vé',
        center: true,
        cell: ({ TicketRelated }) => (
          <p style={{ textAlign: 'left' }} className="w-100 m-0">
            {TicketRelated[0]?.no}
          </p>
        ),
      },
      {
        name: 'Ngày xuất vé',
        center: true,
        selector: ({ TicketRelated }) =>
          dayjs(TicketRelated[0]?.dateRelease).format('DD/MM/YYYY'),
      },
      {
        name: 'Tổng tiền',
        center: true,
        cell: ({ TicketRelated }) => (
          <p style={{ textAlign: 'right' }} className="w-100 m-0">
            {TicketRelated[0]?.totalPrice &&
              Utils.formatCurrency(TicketRelated[0]?.totalPrice)}
          </p>
        ),
      },
      {
        name: 'Gửi vé',
        center: true,
        cell: ({ isSent }) =>
          isSent ? (
            <i className="fa-light fa-circle-check text-success" />
          ) : (
            <i className="fa-duotone fa-do-not-enter" />
          ),
      },
      {
        name: '',
        center: true,
        cell: ({ ticketId, TicketRelated }) => (
          <Space>
            <TableAction
              titleActionText="Xem vé đã bị thay thế"
              icon={<IconAction className="fa-light fa-eye text-danger" />}
              onClick={() => exportFunc(ticketId)}
            />
            <TableAction
              titleActionText="Xem vé thay thế"
              icon={<IconAction className="fa-light fa-eye text-primary" />}
              onClick={() => exportFunc(TicketRelated[0]?.ticketId)}
            />
          </Space>
        ),
      },
    ]
    return arr
  }, [exportFunc])

  useEffect(() => {
    const setUpGroup = () => {
      const ticket_been_replaced = {
        startRange: 1,
        endRange: 7,
        width: 0,
      }

      document
        .querySelectorAll('#rowGroupContainer')
        .forEach((group) => group.remove())

      const rowDiv = document.querySelector('div[role="rowgroup"]')
      if (!rowDiv) return
      const hasRowGroup = rowDiv.querySelector('#rowGroupContainer')
      let containerDiv = null
      if (!hasRowGroup) {
        containerDiv = document.createElement('div')
        containerDiv.id = 'rowGroupContainer'
        containerDiv.classList.add('d-flex')
      } else containerDiv = hasRowGroup

      ticket_been_replaced.width = Utils.calcWidthColumns(
        ticket_been_replaced.startRange,
        ticket_been_replaced.endRange,
      )
      const ticket_been_replaced_div = document.createElement('div'),
        ticket_replaced_div = document.createElement('div')
      ticket_been_replaced_div.id = 'group_ticket_been_replaced'
      ticket_been_replaced_div.classList.add('table-error-header')
      ticket_been_replaced_div.style.width = `${ticket_been_replaced.width}px`
      ticket_been_replaced_div.textContent = 'Vé đã bị thay thế'

      ticket_replaced_div.id = 'group_ticket_replaced'
      ticket_replaced_div.classList.add('table-primary-header', 'flex-fill')
      ticket_replaced_div.textContent = 'Vé thay thế'

      containerDiv.append(ticket_been_replaced_div, ticket_replaced_div)
      rowDiv.parentNode.insertBefore(containerDiv, rowDiv)
    }

    window.addEventListener('resize', setUpGroup)
    isSuccess && setUpGroup()
    return () => {
      window.removeEventListener('resize', setUpGroup)
    }
  }, [isSuccess, isGettingList])

  return (
    <ContentContainer>
      <ContentContainer.Header
        titleContent="Thay thế vé"
        toolBar={
          <CustomAntButton
            text="Lập vé thay thế"
            iconCn="fa-regular fa-plus"
            antProps={{
              type: 'primary',
              onClick: () =>
                toggleModalState({
                  modalName: 'exportReplaced',
                  status: true,
                }),
            }}
          />
        }
      >
        <Space wrap align="center">
          <CustomAntInput
            type="search"
            inputProps={{
              defaultValue: search,
              onChange: (e) => setSearch(e.target.value),
            }}
          />
          <FilterPopover
            menuPosition="bottomLeft"
            onApply={setFilter}
            savedKey="gFilterReplacedTicket"
            defaultValues={[
              {
                label: 'Trạng thái gửi vé',
                alias: 'Trạng thái gửi',
                formName: 'isSent',
                value: null,
                options: SelectOptions.isSent,
                colSpan: 24,
              },
            ]}
          />
        </Space>
      </ContentContainer.Header>
      <ContentContainer.Body>
        <CustomDataTable
          columns={columns}
          dataSource={listData?.rows}
          isLoading={isGettingList}
          pagination={{
            currentPage: filter.page,
            rowsPerPage: filter.limit,
            total: listData?.total,
            onChangePage: (page) => setFilter({ page }),
            onChangeRowsPerPage: (limit) => setFilter({ limit, page: 1 }),
          }}
        />
      </ContentContainer.Body>
      <ExportTicketModal
        title="Xuất vé thay thế"
        ticketType={'CAN_REPLACED'}
        open={modalState.exportReplaced}
        onOpenChange={(s) =>
          toggleModalState({
            modalName: 'exportReplaced',
            status: s,
          })
        }
        handleApplyChanges={(activeRow, classify) => {
          setModalState((pre) => ({ ...pre, adjustReplaceTicket: true }))
          setActiveTicket({ ...activeRow, classify })
        }}
        isClickable={true}
        hasApplyButton={false}
        hasTabs={false}
        statusNotInCanReplaced
      />

      <AdjustReplaceTicketModal
        toggleModalState={(status) =>
          setModalState((prev) => ({ ...prev, adjustReplaceTicket: status }))
        }
        open={modalState.adjustReplaceTicket}
        title={'Xuất vé thay thế'}
        ticket={activeTicket}
        typeTicket={TYPE_TICKET.REPLACE}
        classify={activeTicket?.classify}
      />
    </ContentContainer>
  )
}
