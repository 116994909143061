import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonSave, StyleButtonClose } from '../../../../TaxDeduction/styled'
import { LeftOutlined } from '@ant-design/icons'
import {
  ContainerHeader,
  DivRight,
  IconLeftOutlined,
  SpanNotYetReleased,
  TitleLeft,
} from 'features/TaxDeductionDocuments/styled'
function HeaderAccountDocuments(
  {
    setOpenModalAlert,
    setOpenModalAlertTemplate,
    onClickSaveAndSign,
    setOpenModalMaintenance,
    openModalAlertTemplateSave,
    setOpenModalAlertTemplateSave,
    nameTemplate,
    onClickSave,
    disableSubmit,
  },
  props,
) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  //   useEffect(() => {

  //   }, [dataSource])

  return (
    <>
      <ContainerHeader>
        <div className="row">
          <TitleLeft className="col-sm-8">
            <IconLeftOutlined
              onClick={() => {
                navigate(
                  // '/hoa-don-dau-ra/chung-tu-tncn/khau-tru-thue-thu-nhap-ca-nhan?tab=2',
                  '/hoa-don-dau-ra/chung-tu-tncn/khau-tru-thue-thu-nhap-ca-nhan',
                )
              }}
            />
            {nameTemplate}
            <SpanNotYetReleased>
              {t('taxDeductionDocuments.notYetReleased')}
            </SpanNotYetReleased>
          </TitleLeft>
          <div className="col-sm-4">
            <DivRight>
              <StyleButtonClose
                onClick={() => {
                  setOpenModalAlert(true)
                }}
              >
                {t('taxDeductionDocuments.cancel')}
              </StyleButtonClose>
              <StyleButtonClose
                disabled={disableSubmit}
                onClick={() => {
                  onClickSave()
                }}
              >
                {t('taxDeductionDocuments.save')}
              </StyleButtonClose>
              <ButtonSave
                disabled={disableSubmit}
                type="primary"
                onClick={() => {
                  onClickSaveAndSign()
                }}
              >
                {t('taxDeductionDocuments.saveAndSign')}
              </ButtonSave>
            </DivRight>
          </div>
        </div>
        <TitleLeft></TitleLeft>
      </ContainerHeader>
    </>
  )
}
export default HeaderAccountDocuments
