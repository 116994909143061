import { queryOptions, useQuery } from '@tanstack/react-query'

import reportApi from 'api/reportApi'

const issuedInvoiceQueries = {
  base: {
    scope: ['report', 'issuedInvoice'],
  },

  all: () => ({
    ...issuedInvoiceQueries.base,

    entity: ['issuedInvoices'],
  }),

  getList: (params) =>
    queryOptions({
      queryKey: [issuedInvoiceQueries.all(), params],

      queryFn: ({ signal }) => reportApi.getIssuedInvoice(params, signal),

      staleTime: 1000 * 30,
    }),
}

export const useQueryIssuedInvoice = (params) => {
  return useQuery({
    ...issuedInvoiceQueries.getList(params),

    select: (res) => {
      return {
        total: res?.data?.invoiceTemplateStats?.length ?? 0,

        issuedInvoice: res?.data?.invoiceTemplateStats?.map((item) => ({
          ...item,

          used: item?.isssuedInvoiceCount - item?.cancelledInvoiceCount,
        })),
      }
    },
  })
}
