import { Checkbox } from 'antd'
import ModalEditCustomer from 'features/Category/components/ModalEditCustomer'
import CustomButton from 'general/components/Button/CustomButton'
import CustomSelect from 'general/components/CustomSelect'
import Option from 'general/components/CustomSelect/CustomOption'
import MenuList from 'general/components/CustomSelect/MenuList'
import { useRevalidateBuyerByTaxCode } from 'general/components/Dropdown/CustomDropdown/queries'
import BaseTextField from 'general/components/Forms/BaseTextField'
import FormLabel from 'general/components/Forms/FormLabel'
import TextFieldSelect from 'general/components/Forms/TextFieldSelect'
import { ACTION_TYPE, INVOICE_TYPE } from 'general/constants/AppConstants'
import SelectOptions from 'general/constants/SelectOptions'
import ToastHelper from 'general/helpers/ToastHelper'
import useMutationGetCompanyInfo from 'hooks/Mutations/useMutationGetCompanyInfo'
import _ from 'lodash'
import { Fragment, useState } from 'react'
import CheckCustomerStatusModal from '../CheckCustomerStatusModal'
import TaxCodeDisplay from '../TaxCodeDisplay'

function InvoiceGeneralInfo(props) {
  const {
    formik,
    // currentCompany,
    buyerInfoExtendFields,
    templateInvoiceSerials,
    actionType,
    originalInvoiceInfo,
    // tableData,
    setTableData,
    isTaxReduction,
    onCheckTaxReduction,
    systemTaxRate,
    isFromPOS,
  } = props
  const [checkCustomerStatusModalShow, setCheckCustomerStatusModalShow] =
    useState(false)
  const queryCache = useRevalidateBuyerByTaxCode()

  const [showingModalEditCustomer, setShowingModalEditCustomer] =
    useState(false)

  const handleSelectInvoiceSerial = (invoiceTemplate) => {
    const { invoiceTemplateId, serial } = invoiceTemplate
    formik.setFieldValue('invoiceTemplateId', invoiceTemplateId)
    formik.setFieldValue('serial', serial)
    formik.setFieldValue('invoiceTemplateName', invoiceTemplate.name)
    const category = invoiceTemplate?.category
    if (category) {
      // chỉ 1 thuế suất
      formik.setFieldValue(
        'multipleTaxRate',
        invoiceTemplate?.category?.taxType === 2,
      )
      formik.setFieldValue('taxType', invoiceTemplate?.category?.taxType)

      if (category?.code == 'BV') {
        formik.setFieldValue('advanceAmount', 0)
        formik.setFieldValue('patientExemptedAmount', 0)
        formik.setFieldValue('hospitalReturnAmount', 0)
        formik.setFieldValue('patientPaidAdditionalAmount', 0)
      } else {
        formik.setFieldValue('advanceAmount', '')
        formik.setFieldValue('patientExemptedAmount', '')
        formik.setFieldValue('hospitalReturnAmount', '')
        formik.setFieldValue('patientPaidAdditionalAmount', '')
      }
    }
    formik.setFieldValue('isTaxReduction', false)
    const taxRate = serial.charAt(0) == '2' ? 0 : systemTaxRate
    // if (serial.charAt(0) == '2') {
    //   formik.setFieldValue('taxRate', 0)
    // }
    formik.setFieldValue('taxRate', taxRate)
    formik.setFieldValue('discount', 0)
    setTableData([
      {
        no: 1,
        type: 1,
        name: '',
        unit: '',
        unitPrice: 0,
        quantity: 0,
        discount: 0,
        totalUnitPrice: 0,
        unitPriceAfterTax: 0,
        totalUnitPriceAfterTax: 0,
        taxRate: taxRate ?? '0',
        taxReduction: 0,
        taxReductionRate: 0,
        taxMoney: 0,
        discountMoney: 0,
        productId: 0,
      },
    ])
  }

  const { mutateAsync, isPending: isGettingCompanyInfo } =
    useMutationGetCompanyInfo()

  const fetchInfoByTaxCode = async () => {
    if (_.isEmpty(formik.values.customerTaxCode)) {
      ToastHelper.showError('Vui lòng nhập mã số thuế')
      return
    }
    const result = await mutateAsync({
      document_type: 'TAX',
      tax_code: formik.values.customerTaxCode,
    })
    if (!result) return
    const { document_data, message } = result.data
    if (message !== 'SUCCESSFULLY') {
      formik.setFieldValue('customerFullAddress', '')
      formik.setFieldValue('customerCompanyName', '')
      formik.setFieldValue('customerName', '')
      formik.setFieldValue('customerEmail', '')
      formik.setFieldValue('customerPhone', '')
      formik.setFieldValue('dateOfOperation', '')
      formik.setFieldValue('status', '')
      formik.setFieldValue('customerAccountNumber', '')
      formik.setFieldValue('customerBankName', '')
    }

    const taxInfo = document_data.tax_informations[0]

    formik.setFieldValue(
      'customerFullAddress',
      taxInfo.company_information.address ?? '',
    )
    formik.setFieldValue(
      'customerCompanyName',
      taxInfo.company_information.official_name ?? '',
    )
    // formik.setFieldValue(
    //   'customerName',
    //   taxInfo.owner_information.full_name ?? '',
    // )
    formik.setFieldValue(
      'dateOfOperation',
      taxInfo.company_information.operation_start_date ?? '',
    )
  }

  const handleSelectCustomer = (customer) => {
    console.log('customer', customer)
    formik.setFieldValue('customerCompanyName', customer?.name ?? '')
    formik.setFieldValue('customerFullAddress', customer?.address ?? '')
    formik.setFieldValue('customerTaxCode', customer?.taxCode ?? '')
    formik.setFieldValue('customerAccountNumber', customer?.bankAccount ?? '')
    formik.setFieldValue('customerBankName', customer?.bankName ?? '')
    formik.setFieldValue('customerPhone', customer?.buyerPhone ?? '')
    formik.setFieldValue('customerName', customer?.buyerName ?? '')
    formik.setFieldValue(
      'customerEmail',
      customer?.buyerEmail
        ? customer?.buyerEmail
        : (customer.contractRecipientEmails ?? ''),
    )
  }
  // useEffect(() => {
  //   fetchInfoByTaxCode();
  // }, []);
  // console.log(invoiceTemplateId, formik.values.invoiceTemplateId, isChangeOriginInvoiceInfo);
  const renderDescription = () => {
    if (
      actionType === ACTION_TYPE.ADJUST ||
      (actionType === ACTION_TYPE.UPDATE &&
        formik.values.type === INVOICE_TYPE.ADJUST)
    ) {
      return (
        <>
          <span className="my-2">
            Điều chỉnh cho hóa đơn ký hiệu{' '}
            <span className="font-weight-bolder">
              {originalInvoiceInfo?.serial}
            </span>
            , số{' '}
            <span className="font-weight-bolder">
              {originalInvoiceInfo?.no}
            </span>
            , ngày{' '}
            <span className="font-weight-bolder">
              {new Date(originalInvoiceInfo?.date).getDate()}
            </span>{' '}
            tháng{' '}
            <span className="font-weight-bolder">
              {new Date(originalInvoiceInfo?.date).getMonth() + 1}
            </span>{' '}
            năm{' '}
            <span className="font-weight-bolder">
              {new Date(originalInvoiceInfo?.date).getFullYear()}
            </span>
            {/* <span className="font-weight-bolder">
              {originalInvoiceInfo?.date?.slice(0, 10)}
            </span> */}
          </span>
          <div className="d-flex flex-row align-items-center w-100">
            <span style={{ color: 'red', width: '140px' }}>Lý do *</span>
            <div className="w-100">
              <BaseTextField
                className="flex-grow-1"
                name="description"
                fieldProps={formik.getFieldProps('description')}
                fieldMeta={formik.getFieldMeta('description')}
                fieldHelper={formik.getFieldHelpers('description')}
              />
            </div>
          </div>
        </>
      )
    }
    if (
      actionType === ACTION_TYPE.REPLACE ||
      (actionType === ACTION_TYPE.UPDATE &&
        formik.values.type === INVOICE_TYPE.REPLACE)
    ) {
      return (
        <>
          <span className="my-2">
            Thay thế cho hóa đơn ký hiệu{' '}
            <span className="font-weight-bolder">
              {originalInvoiceInfo?.serial}
            </span>
            , số{' '}
            <span className="font-weight-bolder">
              {originalInvoiceInfo?.no}
            </span>
            , ngày{' '}
            <span className="font-weight-bolder">
              {new Date(originalInvoiceInfo?.date).getDate()}
            </span>{' '}
            tháng{' '}
            <span className="font-weight-bolder">
              {new Date(originalInvoiceInfo?.date).getMonth() + 1}
            </span>{' '}
            năm{' '}
            <span className="font-weight-bolder">
              {new Date(originalInvoiceInfo?.date).getFullYear()}
            </span>
          </span>
          <div className="d-flex flex-row align-items-center w-100">
            <span style={{ color: 'red', width: '140px' }}>Lý do *</span>
            <div className="w-100">
              <BaseTextField
                className="flex-grow-1"
                name="description"
                fieldProps={formik.getFieldProps('description')}
                fieldMeta={formik.getFieldMeta('description')}
                fieldHelper={formik.getFieldHelpers('description')}
              />
            </div>
          </div>
        </>
      )
    }
    if (actionType === ACTION_TYPE.UPDATE) {
      return <></>
    }
  }

  return (
    <>
      <div className="d-flex flex-column" style={{ gap: '10px' }}>
        <div className="d-flex flex-row justify-content-between">
          <div className="create-invoice-left-col">
            <div className="d-flex flex-row align-items-center w-100">
              <FormLabel>Đơn vị bán hàng:</FormLabel>
              <span className="">{formik.values?.sellerName}</span>
            </div>
            <div className="d-flex flex-row align-items-center w-100">
              <FormLabel>Mã số thuế:</FormLabel>
              <TaxCodeDisplay taxCode={formik.values?.sellerTaxCode} />
            </div>

            <div className="d-flex flex-row">
              <div className="d-flex flex-row align-items-center">
                <FormLabel>Địa chỉ:</FormLabel>
                <span className="">{formik.values.sellerFullAddress}</span>
              </div>
            </div>

            <div className="d-flex flex-row align-items-center  w-100">
              <FormLabel>MST người mua:</FormLabel>
              <div style={{ width: '35%' }}>
                <TextFieldSelect
                  className="flex-grow-1 w-100"
                  fieldHelper={formik.getFieldHelpers('customerTaxCode')}
                  fieldMeta={formik.getFieldMeta('customerTaxCode')}
                  fieldProps={formik.getFieldProps('customerTaxCode')}
                  onCreateNew={() => {
                    setShowingModalEditCustomer(true)
                  }}
                  onSelect={handleSelectCustomer}
                />
              </div>
              <CustomButton
                marginLeft={8}
                color="#304FFD"
                text="Lấy thông tin"
                backgroundColor="white"
                // width="120px"
                onClick={fetchInfoByTaxCode}
                loading={isGettingCompanyInfo}
                disabled={isGettingCompanyInfo}
                spinnerColorClassName="spinner-primary"
              />
              <CustomButton
                marginLeft={8}
                color="#304FFD"
                text="KT tình trạng hoạt động của KH"
                backgroundColor="white"
                width="250px"
                onClick={() => {
                  setCheckCustomerStatusModalShow(true)
                }}
              />
            </div>
            <div className="d-flex flex-row align-items-center w-100">
              <FormLabel>Tên đơn vị:</FormLabel>
              <div className="w-100">
                <BaseTextField
                  className="flex-grow-1 w-100"
                  name="customerCompanyName"
                  fieldProps={formik.getFieldProps('customerCompanyName')}
                  fieldMeta={formik.getFieldMeta('customerCompanyName')}
                  fieldHelper={formik.getFieldHelpers('customerCompanyName')}
                />
              </div>
            </div>
            <div className="d-flex flex-row align-items-center w-100">
              <FormLabel>Địa chỉ:</FormLabel>
              <div className="w-100">
                <BaseTextField
                  className="flex-grow-1 w-100"
                  name="customerFullAddress"
                  fieldProps={formik.getFieldProps('customerFullAddress')}
                  fieldMeta={formik.getFieldMeta('customerFullAddress')}
                  fieldHelper={formik.getFieldHelpers('customerFullAddress')}
                />
              </div>
            </div>

            <div
              className="d-flex flex-row"
              style={{ flexWrap: 'wrap', gap: '10px 10px' }}
            >
              <div className="d-flex flex-row align-items-center flex-grow-1">
                <FormLabel>Người mua hàng: </FormLabel>
                <div className="w-100">
                  <BaseTextField
                    className="flex-grow-1"
                    name="customerName"
                    fieldProps={formik.getFieldProps('customerName')}
                    fieldMeta={formik.getFieldMeta('customerName')}
                    fieldHelper={formik.getFieldHelpers('customerName')}
                  />
                </div>
              </div>
              <div className="d-flex flex-row align-items-center flex-grow-1">
                <FormLabel>Số điện thoại: </FormLabel>
                <div className="w-100">
                  <BaseTextField
                    className="flex-grow-1"
                    name="customerPhone"
                    fieldProps={formik.getFieldProps('customerPhone')}
                    fieldMeta={formik.getFieldMeta('customerPhone')}
                    fieldHelper={formik.getFieldHelpers('customerPhone')}
                  />
                </div>
              </div>
            </div>

            {isFromPOS ? (
              <>
                <div className="d-flex flex-row flex-wrap gap-3">
                  <div className="d-flex flex-row align-items-center flex-grow-1">
                    <FormLabel>Căn cước CD:</FormLabel>
                    <div className="w-100">
                      <BaseTextField
                        name="customerPersonalIdentificationNumber"
                        fieldProps={formik.getFieldProps(
                          'customerPersonalIdentificationNumber',
                        )}
                        fieldMeta={formik.getFieldMeta(
                          'customerPersonalIdentificationNumber',
                        )}
                        fieldHelper={formik.getFieldHelpers(
                          'customerPersonalIdentificationNumber',
                        )}
                        className="flex-grow-1"
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            {isFromPOS ? null : (
              <div
                className="d-flex flex-row"
                style={{ flexWrap: 'wrap', gap: '10px 10px' }}
              >
                <div className="d-flex flex-row align-items-center flex-grow-1">
                  <FormLabel>STK ngân hàng: </FormLabel>
                  <div className="w-100">
                    <BaseTextField
                      className="flex-grow-1"
                      name="customerAccountNumber"
                      fieldProps={formik.getFieldProps('customerAccountNumber')}
                      fieldMeta={formik.getFieldMeta('customerAccountNumber')}
                      fieldHelper={formik.getFieldHelpers(
                        'customerAccountNumber',
                      )}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center flex-grow-1 ">
                  <FormLabel>Tên ngân hàng:</FormLabel>
                  <div className="w-100">
                    <BaseTextField
                      className="flex-grow-1"
                      name="customerBankName"
                      fieldProps={formik.getFieldProps('customerBankName')}
                      fieldMeta={formik.getFieldMeta('customerBankName')}
                      fieldHelper={formik.getFieldHelpers('customerBankName')}
                    />
                  </div>
                </div>
              </div>
            )}

            <div
              className="d-flex flex-row"
              style={{ flexWrap: 'wrap', gap: '10px 10px' }}
            >
              <div
                className="d-flex flex-row align-items-center"
                style={{ width: '49.45%' }}
              >
                <FormLabel>Hình thức TT:</FormLabel>
                <div className="w-100">
                  <CustomSelect
                    autoSize
                    width="max-content"
                    selectOptions={SelectOptions.paymentMethod}
                    currentValue={formik.values.paymentMethod}
                    onChange={(selectedOption) => {
                      formik.setFieldValue(
                        'paymentMethod',
                        selectedOption.value,
                      )
                    }}
                  />
                </div>
              </div>
              <div
                className="d-flex flex-row align-items-center"
                style={{ width: '49.45%' }}
              >
                <FormLabel>Email: </FormLabel>
                <div className="w-100">
                  <BaseTextField
                    className="flex-grow-1"
                    name="customerEmail"
                    fieldProps={formik.getFieldProps('customerEmail')}
                    fieldMeta={formik.getFieldMeta('customerEmail')}
                    fieldHelper={formik.getFieldHelpers('customerEmail')}
                  />
                </div>
              </div>
            </div>

            <div
              className="d-flex flex-row"
              style={{ flexWrap: 'wrap', gap: '10px 10px' }}
            ></div>

            <div
              className="d-flex flex-row"
              style={{ flexWrap: 'wrap', gap: '10px 10px' }}
            >
              {buyerInfoExtendFields
                ?.filter(({ forceShow, show }) => forceShow || show)
                .map((item, index, { length }) => (
                  <Fragment key={index}>
                    <div className="d-flex flex-row align-items-center flex-grow-1 ">
                      <FormLabel>{item?.label}</FormLabel>
                      <div className="w-100">
                        <BaseTextField
                          className="flex-grow-1"
                          name={`extendFields.${item?.name}`}
                          fieldProps={formik.getFieldProps(
                            `extendFields.${item?.name}`,
                          )}
                          fieldMeta={formik.getFieldMeta(
                            `extendFields.${item?.name}`,
                          )}
                          fieldHelper={formik.getFieldHelpers(
                            `extendFields.${item?.name}`,
                          )}
                        />
                      </div>
                    </div>
                    {length % 2 != 0 && (
                      <div className="d-flex flex-row align-items-center flex-grow-1 opacity-0 visibility-hidden">
                        <FormLabel></FormLabel>
                        <div className="w-100">
                          <BaseTextField className="flex-grow-1" />
                        </div>
                      </div>
                    )}
                  </Fragment>
                ))}
            </div>
          </div>
          <div className="create-invoice-right-col">
            <div className="d-flex flex-row align-items-center w-100">
              <FormLabel width="60px">Ký hiệu: </FormLabel>
              <CustomSelect
                classNamePrefix="rs-kyhieu"
                placeholder={'Chọn ký hiệu'}
                currentValue={formik.values.invoiceTemplateId}
                width="300px"
                selectOptions={templateInvoiceSerials}
                customFilterFn={(option, searchText) => {
                  return (
                    option.label
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    option.data.name
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  )
                }}
                onChange={(selectedOption) =>
                  handleSelectInvoiceSerial(selectedOption)
                }
                components={{
                  MenuList,
                  Option,
                }}
                // menuIsOpen
              />
            </div>
            <div className="d-flex flex-row align-items-center w-100">
              <FormLabel width="60px">Số:</FormLabel>
              <div className="w-100">
                <BaseTextField
                  disabled={true}
                  className="flex-grow-1"
                ></BaseTextField>
              </div>
            </div>
            {parseInt(formik.values?.serial?.charAt(0)) === 2 && (
              <div className="d-flex flex-row mt-2">
                <Checkbox
                  checked={isTaxReduction}
                  onChange={onCheckTaxReduction}
                >
                  Áp dụng giảm thuế GTGT theo nghị quyết số 142/2024/QH15
                </Checkbox>
              </div>
            )}
          </div>
        </div>
        {renderDescription()}
      </div>
      <CheckCustomerStatusModal
        show={checkCustomerStatusModalShow}
        onHide={() => setCheckCustomerStatusModalShow(false)}
        taxCode={formik.values?.customerTaxCode}
        companyName={formik.values?.customerCompanyName}
        address={formik.values?.customerFullAddress}
        dateOfOperation={formik.values?.dateOfOperation}
        status={formik.values?.status}
      />
      <ModalEditCustomer
        show={showingModalEditCustomer}
        onClose={() => {
          setShowingModalEditCustomer(false)
        }}
        active="ADD"
        onRefreshCustomerList={() => {
          queryCache.invalidate()
        }}
      />
    </>
  )
}

export default InvoiceGeneralInfo
