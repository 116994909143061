import { useMutation } from '@tanstack/react-query'
import axiosClient from 'api/axiosClient'
import { saveAs } from 'file-saver'
import ToastHelper from 'general/helpers/ToastHelper'
import { useState } from 'react'
import { toast } from 'react-toastify'
const keyFactory = {
  base: {
    scope: ['report', 'export'],
  },
  downloadExcel: () => [
    {
      ...keyFactory.base,
      action: 'downloadExcel',
    },
  ],
}

export const useMutationDownloadExcel = () => {
  const [toastId, setToastId] = useState(null)
  const [dataParam, setDataParam] = useState(null)
  const mutation = useMutation({
    mutationKey: keyFactory.downloadExcel(),
    mutationFn: (params) =>
      axiosClient.get(
        '/statistic/get-invoice-stats-file',
        // params.exportTemplateId==="sismac"?
        {
          params,
          responseType: 'blob',
        },
        //: {params},
        setDataParam(params),
      ),
    onMutate: () => {
      const id = toast.loading('Hệ thống đang xuất dữ liệu...', {
        draggable: true,
        closeOnClick: true,
      })
      setToastId(id)
    },

    onSettled: () => {
      toast.dismiss(toastId)
      setToastId(null)
    },

    onError: (err) => {
      ToastHelper.showError(err.message)
    },

    onSuccess: async (res) => {
      let fileName
      switch (dataParam.exportTemplateId) {
        case 'sismac':
          fileName = 'SISMAC.xlsx'
          break
        case 'default':
          fileName = 'DataReport.xlsx'
          break
        case 'c1':
          fileName = 'Hospital_c1.xlsx'
          break
        case 'c2':
          fileName = 'Hospital_c2.xlsx'
          break
        default:
          fileName = 'Rename.xlsx'
          break
      }
      saveAs(res, fileName)
    },
  })

  return mutation
}
