import { Input } from 'antd'
import DraggableModal from '../DragableModal/DraggableModal'
import { useRef } from 'react'
import FormLabel from 'general/components/Forms/FormLabel'
import { Formik } from 'formik'
import { useMutation } from '@tanstack/react-query'
import invoiceApi from 'api/invoiceApi'
import { setAppSpinning } from 'app/appSlice'
import { useDispatch } from 'react-redux'
import ToastHelper from 'general/helpers/ToastHelper'
import { setModalsState } from 'features/Invoice/invoiceSlice'
import { setModalsState as setModalsStateTicket } from 'features/Ticket/ticketSlice'
import ticketApi from 'api/ticketApi'
import * as Yup from 'yup'

export const CloneMultiInvoiceModal = (props) => {
  const { invoiceId, type, show, onOpenChange, reloadList } = props
  const dispatch = useDispatch()
  const cloneMultiInvoiceModal = useRef(null)
  const cloneTypes = {
    hd: 'hóa đơn',
    pxk: 'phiếu xuất kho',
    mtt: 'máy tính tiền',
    ve: 'vé',
  }
  const { mutate } = useMutation({
    mutationKey: ['cloneMutilInvoices'],
    mutationFn: async (params) =>
      type === 've'
        ? ticketApi.cloneMutilTickets(params)
        : invoiceApi.cloneMutilInvoices(params),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSuccess: () => {
      ToastHelper.showSuccess(`Nhân bản ${cloneTypes[type]} thành công!`)
      dispatch(setAppSpinning(false))
      dispatch(
        setModalsState({
          modalName: 'cloneMultiInvoiceModal',
          status: false,
        }),
      )
      dispatch(
        setModalsStateTicket({
          modalName: 'cloneMultiInvoiceModal',
          status: false,
        }),
      )
      reloadList()
    },
    onError: () => dispatch(setAppSpinning(false)),
  })

  const validationSchema = Yup.object({
    quantity: Yup.number()
      .required('Vui lòng nhập số lượng')
      .min(1, 'Số lượng phải lớn hơn 0'),
  })

  return (
    <Formik
      initialValues={{ invoiceId, quantity: null }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (!values.invoiceId)
          ToastHelper.showWarning(
            `Không tìm thấy thông tin ${cloneTypes[type]} vui lòng thử lại!`,
          )
        mutate(values)
      }}
      enableReinitialize={true}
    >
      {({ handleSubmit, handleChange, values, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <DraggableModal
            title={`Nhân bản ${cloneTypes[type]} hàng loạt`}
            // description
            isOpen={show}
            onOpenChange={onOpenChange}
            handleApplyChanges={() => handleSubmit()}
            applyText="Xác nhận"
            cancelText="Hủy bỏ"
            // isLoading
            // styles
            // status
            hasCancelButton
            ref={cloneMultiInvoiceModal}
          >
            <FormLabel>{`Số lượng ${cloneTypes[type]}`}</FormLabel>
            <Input
              name="quantity"
              value={values.quantity}
              placeholder={`Nhập số lượng ${cloneTypes[type]} cần nhân bản`}
              type="number"
              onChange={handleChange}
            ></Input>
            {touched.quantity && errors.quantity ? (
              <div style={{ color: 'red' }}>{errors.quantity}</div>
            ) : null}
          </DraggableModal>
        </form>
      )}
    </Formik>
  )
}
