import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import invoiceApi from 'api/invoiceApi'
import { setAppSpinning } from 'app/appSlice'
import {
  setClearRows,
  setSelectedInvoice,
  setSelectedInvoices,
} from 'features/Invoice/invoiceSlice'
import { saveAs } from 'file-saver'
import ToastHelper from 'general/helpers/ToastHelper'
import Utils from 'general/utils/Utils'
import { useAppDispatch } from 'hooks/useRedux'
import JSZip from 'jszip'
import _ from 'lodash'
import { useCallback } from 'react'

export const listInvoiceFromPosQueries = {
  base: {
    scope: ['invoice', 'listInvoiceFromPOS'],
  },
  listInvoice: (params) =>
    queryOptions({
      queryKey: [
        listInvoiceFromPosQueries.base,
        { entity: 'listInvoice' },
        params,
      ],
      queryFn: ({ signal }) => invoiceApi.listAll(params, signal),
      select: (res) => ({
        total: _.get(res, 'count', 0),
        tableData: !res.rows
          ? []
          : _.map(res.rows, (row, idx) => ({
              ...row,
              stt: idx + 1,
              no: row?.no ? Utils.padString(row.no, 8, '0') : '',
              isSent: row.isSent === 1,
            })),
      }),
    }),
  listInvoiceMttNeedSendTax: (params) =>
    queryOptions({
      queryKey: [
        listInvoiceFromPosQueries.base,
        { entity: 'listInvoiceMttNeedSendTax' },
        params,
      ],
      queryFn: ({ signal }) =>
        invoiceApi.getInvoiceMttNeedSendTax(params, signal),
      select: (res) => ({
        invoices: !res.data
          ? []
          : _.map(res.data.invoices, (row, idx) => ({
              ...row,
              stt: idx + 1,
              no: row?.no ? Utils.padString(row.no, 8, '0') : '',
              isSent: row.isSent === 1,
            })),
        tickets: !res.data
          ? []
          : _.map(res.data.tickets, (row, idx) => ({
              ...row,
              stt: idx + 1,
              no: row?.no ? Utils.padString(row.no, 8, '0') : '',
              isSent: row.isSent === 1,
            })),
      }),
    }),
}

export const useMutationViewPdf = () => {
  return useMutation({
    mutationKey: [listInvoiceFromPosQueries.base, { action: 'viewInvoice' }],
    mutationFn: (params) => invoiceApi.exportInvoiceById(params),
    onSuccess: (res) => {
      if (_.isNil(res)) return
      const url = URL.createObjectURL(res)
      window.open(url, '_blank')
    },
  })
}

export const useMutationViewAllPdf = () => {
  return useMutation({
    mutationKey: [listInvoiceFromPosQueries.base, { action: 'viewAllInvoice' }],
    mutationFn: (params) => invoiceApi.print(params),
    onSuccess: (res) => {
      Utils.openFileInNewTab({
        blobData: res,
        fileName: 'invoices.pdf',
        fileType: 'application/pdf',
      })
    },
  })
}

export const useMutationInvoicePaidStatus = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [
      listInvoiceFromPosQueries.base,
      { action: 'updatePaidStatus' },
    ],
    mutationFn: ({ invoiceId, isPaid }) =>
      invoiceApi.updatePaidStatus(invoiceId, { isPayment: isPaid }),
    onError: (err) => console.error(err.message),
    onSuccess: (res) => {
      if (res.result === 'success' || res.status === 200) {
        ToastHelper.showSuccess('Cập nhật trạng thái thanh toán thành công')
        queryClient.invalidateQueries({
          queryKey: [listInvoiceFromPosQueries.base, { entity: 'listInvoice' }],
        })
      }
    },
  })
}

export const useMutationBulkInvoicePaidStatus = () => {
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  return useMutation({
    mutationKey: [
      listInvoiceFromPosQueries.base,
      { action: 'bulk-updatePaidStatus' },
    ],
    mutationFn: ({ invoiceIds, isPayment }) =>
      invoiceApi.bulkUpdatePaidStatus({ invoiceIds, isPayment }),
    onError: (err) => console.error(err.message),
    onSuccess: (res) => {
      if (res.result !== 'success') {
        ToastHelper.showError('Cập nhật trạng thái thanh toán thất bại')
        return
      }
      dispatch(setSelectedInvoices([]))
      dispatch(setClearRows())
      ToastHelper.showSuccess('Cập nhật trạng thái thanh toán thành công')
      queryClient.invalidateQueries({
        queryKey: [listInvoiceFromPosQueries.base, { entity: 'listInvoice' }],
      })
    },
  })
}

export const useMutationDeleteInvoice = () => {
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  return useMutation({
    mutationKey: [listInvoiceFromPosQueries.base, { action: 'deleteInvoice' }],
    mutationFn: (ids) => invoiceApi.delete(ids),
    onError: (err) => console.error(err.message),
    onSuccess: (res) => {
      if (res.result === 'success' || res.status === 200) {
        ToastHelper.showSuccess('Xoá thành công các hoá đơn chưa phát hành')
        dispatch(setSelectedInvoice(null))
        dispatch(setSelectedInvoices([]))
        queryClient.invalidateQueries({
          queryKey: [listInvoiceFromPosQueries.base, { entity: 'listInvoice' }],
        })
      }
    },
  })
}

export const useMutationDownloadInvoice = () => {
  const dispatch = useAppDispatch()
  const { mutate: handleDownloadPdf, data } = useMutation({
    mutationKey: [listInvoiceFromPosQueries.base, { action: 'download-pdf' }],
    mutationFn: (params) => invoiceApi.exportInvoiceById(params),
    onMutate: () => dispatch(setAppSpinning(true)),
    onSettled: () => dispatch(setAppSpinning(false)),
    onSuccess: (res) => {
      saveAs(res, 'invoice.pdf')
    },
  })
  const handleDownloadXml = useCallback((xmlString) => {
    saveAs(new Blob([xmlString], { type: 'application/xml' }), 'invoice.xml')
  }, [])

  const handleDownloadZip = useCallback(
    async (xmlString) => {
      const zip = new JSZip()
      const pdfData = data
      zip.file('invoice.pdf', pdfData)
      zip.file('invoice.xml', xmlString)
      const content = await zip.generateAsync({ type: 'blob' })
      saveAs(content, 'invoice.zip')
    },
    [data],
  )

  return {
    handleDownloadPdf,
    handleDownloadXml,
    handleDownloadZip,
  }
}

export const useMutationSendExplanationAnnounce = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [
      listInvoiceFromPosQueries.base,
      { action: 'sendExplanationAnnounce' },
    ],
    mutationFn: ({ customerEmailCc, customerEmailBcc, invoiceId, ...others }) =>
      invoiceApi.sendExplanationAnnounceToCustomer(invoiceId, {
        ...others,
        ccEmails: _.compact(_.split(customerEmailCc, ';')),
        bccEmails: _.compact(_.split(customerEmailBcc, ';')),
      }),
    onError: (err) => console.error(err.message),
    onSuccess: (res) => {
      if (res?.result === 'success') {
        ToastHelper.showSuccess(
          'Gửi thông báo giải trình cho khách hàng thành công',
        )
        queryClient.invalidateQueries({
          queryKey: [listInvoiceFromPosQueries.base, { entity: 'listInvoice' }],
        })
      }
    },
  })
}
