import React from 'react'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'

import './style.scss'

ModalSelectAdjustInvoiceMethod.prototype = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  createAdjustedReportSelect: PropTypes.func,
  createAdjustAnnounceSelect: PropTypes.func,
  adjustInvoiceSelect: PropTypes.func,
}

function ModalSelectAdjustInvoiceMethod(props) {
  const {
    show,
    setShow,
    header,
    content,
    createAdjustedReportSelect,
    createAdjustAnnounceSelect,
    adjustInvoiceSelect,
    typeTicket,
  } = props

  let handleClose = () => setShow(false)

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="ModalSelectAdjustInvoiceMethod"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span>
            <i
              className="fa-light fa-triangle-exclamation mr-3"
              style={{ fontSize: 20, color: '#ff9f0e' }}
            ></i>
            {header}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <div className="">
          <button
            className="btn btn-outline-secondary mr-2 px-5"
            onClick={handleClose}
          >
            Hủy bỏ
          </button>
          {createAdjustedReportSelect && (
            <button
              className="btn btn-outline-secondary mr-2 px-3"
              onClick={createAdjustedReportSelect}
            >
              Lập biên bản
            </button>
          )}

          {createAdjustAnnounceSelect && (
            <button
              className="btn btn-outline-secondary mr-2 px-3"
              onClick={createAdjustAnnounceSelect}
            >
              Lập thông báo
            </button>
          )}

          {adjustInvoiceSelect && (
            <button
              className="btn btn-danger px-3"
              onClick={adjustInvoiceSelect}
            >
              {typeTicket ? 'Điều chỉnh vé' : 'Điều chỉnh hóa đơn'}
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalSelectAdjustInvoiceMethod
